import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { SafrattiPromoCode } from 'src/models/safratti-promo-code';
import { SafrattiPromoCodeService } from 'src/services/safratti-promo-code.service';

@Component({
  selector: 'app-safratti-promo-code',
  templateUrl: './safratti-promo-code.component.html',
  styleUrls: ['./safratti-promo-code.component.css']
})
export class SafrattiPromoCodeComponent implements OnInit {
 
  displayedColumns: string[] = [
    "promotionName",
    "couponCode",
    "startDate",
    "endDate",
    "promotionBenfits",
    "discountAmountValue",
    "percentageValue",
    "rewardCreditValue",
    "approval",
    "status",
    "optionMenu"
  ];

  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
 
  constructor(
    private safrattiPromoCodeService: SafrattiPromoCodeService,
    private _snackBar: MatSnackBar,
    private router: Router
    ) {
      this.loadPromoCodes();
    }
    
    ngOnInit() {}

    toggleStatus(promoCode: SafrattiPromoCode) {
      this.safrattiPromoCodeService.toggleStatus(promoCode.couponCode).subscribe((x) => {
        this._snackBar.open("Updated Successfully", "OK", { duration: 3000 });
        this.loadPromoCodes();
      });
    }

    loadPromoCodes() {
      this.safrattiPromoCodeService
        .getSafrattiPromoCode()
        .pipe(take(1))
        .subscribe((x) => (this.dataSource = new MatTableDataSource(x)));
    }
}
