import { Component, OnInit } from '@angular/core';
import { ReferralService } from 'src/services/referral.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PasswordConfirmDialogComponent } from 'src/app/password-confirm-dialog/password-confirm-dialog.component';

@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.css']
})
export class ReferralComponent implements OnInit {

  displayedColumns: string[] = ['schemeName', 'starts', 'ends', 'audience', 'pointRatio', 'worth', 'status', 'type', 'optionMenu'];
  dataSource: any;

  constructor(private referralService: ReferralService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    const dialogRef = this.dialog.open(PasswordConfirmDialogComponent, { disableClose: true });

    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined) {
        this.getReferralScheme();
      }
    });

  }

  getReferralScheme() {
    this.referralService.getReferralScheme().subscribe(res => {
      this.dataSource = res;
    });
  }

  getStatus(element) {
    return element.isActive ? 'Active' : (new Date(element.endDate) > new Date() ? 'In Active' : 'Completed');
  }
  canBecomeActive(element) {
    return this.getStatus(element) === 'In Active';
  }

  makeActive(element) {
    this.referralService.makeActive(element.id).subscribe(res => {
      this.snackBar.open('Referral Scheme marked active.', 'CANCEL', {
        horizontalPosition: 'right',
        duration: 2000
      });
      this.getReferralScheme();
    }, err => {
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right'
      });
    });
  }
}
