import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/services/account.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  newPassword: string;
  userName:string;
  confirmPassword: string;

  oneCapital: boolean = false;
  oneSmall: boolean = false;
  oneNumber: boolean = false;
  oneSpacial: boolean = false;
  min6Char: boolean = false;

  allRequiredMatch: boolean = false;


  constructor(private accountService: AccountService, private snackBar: MatSnackBar,private route: ActivatedRoute) { }

  ngOnInit() {
  }

  resetPassword(form) {
    const token = this.route.snapshot.queryParamMap.get('token');
    this.userName = this.route.snapshot.queryParamMap.get('userName');
    const name = this.route.snapshot.queryParamMap.get('name');
    const data = {
      UserName : this.userName,
      Password  : this.newPassword,
      ConfirmPassword : this.confirmPassword,
      Token  :  token

    };
    this.accountService.resetPassword(data).subscribe(res => {
      window.location.href = 'https://saiyarti.com/changepassword-success?name=' + name;
      this.snackBar.open('Success', 'CANCEL', {
        horizontalPosition: 'right',
        duration: 2000
      });
    }, err => {
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right'
      });
    });
  }

  checkVelidation() {

    if (/(?=.*?[A-Z])/.test(this.newPassword)) {
      this.oneCapital = true;
    } else {
      this.oneCapital = false;
    }

    if (/(?=.*?[a-z])/.test(this.newPassword)) {
      this.oneSmall = true
    } else {
      this.oneSmall = false
    }

    if (/(?=.*?[0-9])/.test(this.newPassword)) {
      this.oneNumber = true
    } else {
      this.oneNumber = false
    }

    if (/(?=.*?[#?!@$%^&*-])/.test(this.newPassword)) {
      this.oneSpacial = true;
    } else {
      this.oneSpacial = false;
    }

    if (/.{6,}/.test(this.newPassword)) {
      this.min6Char = true;
    } else {
      this.min6Char = false;
    }

    if (this.oneCapital == true && this.oneSmall == true && this.oneNumber == true && this.oneSpacial == true && this.min6Char == true) {
      this.allRequiredMatch = true
    } else {
      this.allRequiredMatch = false
    }

  }

}
