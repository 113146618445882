import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';


@Component({
  selector: 'app-safratti-policy',
  templateUrl: './safratti-policy.component.html',
  styleUrls: ['./safratti-policy.component.css']
})
export class SafrattiPolicyComponent  {
  
  @Input() userData:any
  @Input() policyFormName:FormGroup

  
}
