import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Helpers } from 'src/helpers/helpers';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReferralService extends BaseService {

  constructor(private http: HttpClient,  helper: Helpers) {
    super(helper);
  }

  private pathAPI = environment.apiUrl;

  getReferralScheme() {
    return this.http.get<any>(this.pathAPI + 'ReferralScheme', super.header()).pipe(catchError(super.handleError));
  }

  addReferralScheme(data: any) {
    return this.http.post<any>(this.pathAPI + 'ReferralScheme', data, super.header()).pipe(catchError(super.handleError));
  }

  makeActive(id: number) {
    return this.http.put<any>(this.pathAPI + 'ReferralScheme/MarkActive/' + id, {}, super.header()).pipe(catchError(super.handleError));
  }

}
