import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient } from "@angular/common/http";
import { Helpers } from "src/helpers/helpers";
import { environment } from "src/environments/environment";
import { catchError } from "rxjs/operators";
import { Observable } from "rxjs";
import { SafrattiPromoCode } from "src/models/safratti-promo-code";
import { CreatePromoCodeDto } from "src/dto/request/create-promoCode-dto";
import { UpdateSafrattiPromoCode } from "src/models/updateSafrattiPromoCode";

@Injectable({
  providedIn: "root",
})
export class SafrattiPromoCodeService extends BaseService {
  constructor(private http: HttpClient, helper: Helpers) {
    super(helper);
  }

  getSafrattiPromoCode(): Observable<SafrattiPromoCode[]> {
    return this.http
      .get<SafrattiPromoCode[]>(environment.apiUrl + "Safratti/Promotion", super.header())
      .pipe(catchError(super.handleError));
  }

  createSafrattiPromoCode(body: CreatePromoCodeDto): Observable<void> {
    return this.http
      .post<void>(environment.apiUrl + "Safratti/Promotion", body, super.header())
      .pipe(catchError(super.handleError));
  }

  fetchPromoCode(couponCode: string): Observable<UpdateSafrattiPromoCode> {
    return this.http.get<UpdateSafrattiPromoCode>(environment.apiUrl + `Safratti/Promotion/`+ couponCode,super.header() )
    .pipe(catchError(super.handleError))
  }


  updateSafrattiPromoCode(couponCode:string, body: UpdateSafrattiPromoCode): Observable<void> {
    return this.http
      .put<void>(
        environment.apiUrl + "Safratti/Promotion/" + couponCode,
        body,
        super.header()
      )
      .pipe(catchError(super.handleError));
  }

toggleStatus(couponCode: string): Observable<void> {
    return this.http
      .put<void>(
        environment.apiUrl + `Safratti/Promotion/${couponCode}/ToggleStatus`,
        {},
        super.header()
      )
      .pipe(catchError(super.handleError));
  }
 }
