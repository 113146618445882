import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Helpers } from 'src/helpers/helpers';
import { RateCard } from 'src/models/rate-card';
import { BaseService } from './base.service';
import { ICountService } from './icount.service';

@Injectable({
  providedIn: 'root'
})
export class ComprehensiveRequestsService extends BaseService implements ICountService {

  private pathAPI = environment.apiUrl;

  constructor(private http: HttpClient, helper: Helpers) { super(helper); }

  getData(body: any): Observable<RateCard[]> {
    return this.http.post<RateCard[]>(this.pathAPI + 'Quote/GetAllRequests', body, super.header()).pipe(
      take(1),
      map(x => x.map(y => new RateCard(y.rateCardId, y))),
      catchError(super.handleError)
    );
  }

  getCount(body: any) {
    return this.http.post<any>(this.pathAPI + 'Quote/GetAllRequestsCount', body, super.header()).pipe(catchError(super.handleError));
  }

  getQuoteRequestByVehicleId(requestId: number): Observable<RateCard> {
    return this.http.get<RateCard>(this.pathAPI + "Quote/GetCustomerRateCard/" + requestId, super.header())
      .pipe(
        take(1),
        map(x => new RateCard(x.rateCardId, x)),
        catchError(super.handleError)
      )
  }

  updateRequestOfCustomer(quoteId: number, body: RateCard): Observable<any> {
    return this.http.put(this.pathAPI + "Quote/UpdateCustomerRateCard/" + quoteId, body, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  rateCardForwarded(rateCardId: number, rateCard: RateCard): Observable<any> {
    return this.http.put(this.pathAPI + "Quote/RateCardForwarded/" + rateCardId, rateCard, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  approveRateCard(rateCardId: number): Observable<any> {
    return this.http.put(this.pathAPI + "Quote/ApproveCustomerRateCard/" + rateCardId, {}, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  rejectRateCard(rateCardId: number): Observable<any> {
    return this.http.put(this.pathAPI + "Quote/RejectCustomerRateCard/" + rateCardId, {}, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getAllOrders(): Observable<any> {
    return this.http.get(this.pathAPI + "Quote/GetAllOrders", super.header()).pipe(
      catchError(super.handleError)
    );
  }


  uploadCarImages(vehicleId: string, body: any): Observable<any> {
    return this.http.post(this.pathAPI + "Quote/CarImages/" + vehicleId, body, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getCarImagesByVehicleId(vehicleId: number): Observable<any> {
    return this.http.get(environment.apiUrl + "Quote/CarImages/" + vehicleId, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  setPolicyNumberAndPremium(userId: string, vehicleId: number, body: any): Observable<any> {
    return this.http.put(this.pathAPI + "Quote/SetPolicyNumberAndPremium/" + userId + '/' + vehicleId, body, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getQuoteHistory(rateCardId: number): Observable<any> {
    return this.http.get(this.pathAPI + "Quote/QuoteHistory/" + rateCardId, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getQuoteById(id: number): Observable<any> {

    // TODO: Deprecate it;. Instead rename getQuoteRequestByVehicleId and use that
    return this.http.post(this.pathAPI + "Quote/Index/" + id, null, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getPolicyInfo(rateCardId: number): Observable<any> {
    return this.http.get(this.pathAPI + "Quote/Policy/" + rateCardId, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  setPolicyInfo(rateCardId: number, body: RateCard): Observable<any> {
    return this.http.post(this.pathAPI + "Quote/Policy/" + rateCardId, body, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  setPolicyUpdateStatus(policyId: number, status: string): Observable<any> {
    return this.http.post(this.pathAPI + "Quote/PolicyUpdateStatus/" + policyId + "?Status=" + status, null, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  editCar(id: number, body: any): Observable<any> {
    return this.http.put(this.pathAPI + 'ComprehensiveVehicle/EditCar/' + id, body, super.header()).pipe(
      catchError(super.handleError)
    );
  }
}
