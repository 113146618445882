import { Component, OnInit, AfterContentInit } from '@angular/core';
import { Router } from '@angular/router';
import { Helpers } from 'src/helpers/helpers';
import { TableComponent } from '../table.component';
import { Policy } from 'src/models/policy';
import jwt_decode from 'jwt-decode';
import { StoreService } from 'src/services/store.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.css']
})
export class StoreComponent extends TableComponent<Policy> implements OnInit, AfterContentInit {

  pageTitle = 'Stores';
  counts: any = { installs: 0, registration: 0, carsAdded: 0, conversionCounts: 0, totalNumberOfCars: 0, totalNumberOfSales: 0, totalFees: 0.000, supervisionFee: 0.000, issueFee: 0.000, premiumFees: 0.000, noOfPhysicalNetwork: 0, noOfVirtualNetwork: 0 };
  name: string;
  displayedColumns: string[];
  user: any;
  isStoreCoordinator: boolean;

  constructor(private storeService: StoreService,
    private router: Router,
    private confirmDialog: MatDialog,
    private snackBar: MatSnackBar,
    private helpers: Helpers) {
    super(storeService);
    this.user = this.getDecodedAccessToken(this.helpers.getToken());

  }

  ngOnInit() {
    super.ngOnInit();
    this.isStoreCoordinator = this.user.type.indexOf("StoreCoordinator") === 0;
    if (this.isStoreCoordinator) {
      this.displayedColumns = ['storeName', 'ownerName', 'civilCardNumber', 'phone', 'email', 'salesPerson', 'address', 'dateCreated', 'activated', 'optionMenu']
    } else {
      this.displayedColumns = ['storeName', 'ownerName', 'civilCardNumber', 'phone', 'email', 'address', 'dateCreated', 'activated', 'optionMenu']
    }

    this.storeService.getStoresDetail().subscribe(
      response => { this.counts = response },
      error => {
        this.snackBar.open(error, 'CANCEL', {
          horizontalPosition: 'right'
        });
      }
    );
  }

  ngAfterContentInit() {
    this.name = this.helpers.getName();
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  updateUserActivateStatus(store) {
    let dialogMsg = store.activated ? 'Once deactivated the store will not be visible in the list. Are you sure you want deactivate?' :
      'Once activated the store will be visible in the list. Are you sure you want activate?';
    const dialogRef = this.confirmDialog.open(ConfirmDialogComponent, { data: { title: 'Confirm', message: dialogMsg } });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true') {
        this.storeService.updateStoreStatus(store.id, !store.activated).subscribe(res => {
          this.loadRequests(this.getData());
          this.snackBar.open('Store ' + (store.activated ? 'de' : '') + 'activated successfully.', 'CANCEL', {
            horizontalPosition: 'right',
            duration: 2000
          });
        }, err => {
          this.snackBar.open(err, 'CANCEL', {
            horizontalPosition: 'right'
          });
        });
      }
    });
  }

  logout() {
    this.helpers.logout();
    this.router.navigate(['/login']);
  }

}
