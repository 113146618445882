import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/services/storage.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-vehicle-catalog',
  templateUrl: './vehicle-catalog.component.html',
  styleUrls: ['./vehicle-catalog.component.css']
})
export class VehicleCatalogComponent implements OnInit {

  vehicleCatalogFile: any;
  vehicleCatalogEle: string;
  SASTokenForUpload: string;
  SASToken: string;
  processing = false;
  constructor(private storageService: StorageService,
              private snackBar: MatSnackBar,
              protected http: HttpClient) {
    this.storageService.getSASTokenForContainer('vehicle-catalog').subscribe(token => {
      this.SASToken = token.sasToken;
      // this.AzureConfig.sas = this.SASToken;

    });
    // Token for upload file.
    this.storageService.getUploadOnlyTokenForContainer('vehicle-catalog').subscribe(token => {
      this.SASTokenForUpload = token.sasToken;
    });
  }

  ngOnInit() {
  }

  uploadCSV() {
    if (this.vehicleCatalogFile !== undefined) {
      const d = new Date();
      this.storageService
        .getUploadOnlyTokenForContainer("vehicle-catalog")
        .subscribe((token) => {
          this.storageService
            .uploadFileToAzure(
              `/vehicle-catalog/${
                d.getTime().toString() +
                ".csv"
              }${token.sasToken}`,
              this.vehicleCatalogFile,
              "image/jpg"
            )
            .subscribe({
              next: (res) => {
                this.snackBar.open(
                  "The file has been uploaded. An email will be sent to your account as soon as processed.",
                  "CANCEL",
                  {
                    horizontalPosition: "right",
                    duration: 4000,
                  }
                );
              },
            });
        });
    }
  }

  onVehicleCatalog(event) {
    const file = event.target.files[0];
    this.vehicleCatalogEle = file.name;
    this.vehicleCatalogFile = file;
  }

  public handleError(error: HttpErrorResponse | any) {

    // In a real-world app, we might use a remote logging infrastructure

    // let errMsg: string;
    let errMsg: any;

    if (error instanceof HttpErrorResponse) {

      const body = error.error || '';

      const err = body || JSON.stringify(body);

      errMsg = err.text;
      // errMsg = error;

    } else {

      errMsg = error.message ? error.message : error.toString();

    }

    return throwError(errMsg);



  }



  downloadLatestCSV() {
    this.storageService.getLastFileModifiedForContainer('vehicle-catalog').subscribe(res => {
      const fileName = res.fileName;
      const src = environment.cdnUrl + '/vehicle-catalog/' + fileName + this.SASToken;
      const headers = {};
      return this.http.get(src, { headers, observe: 'response', responseType: 'blob' })
        .pipe(catchError(this.handleError))
        .subscribe(response => {
          this.downLoadFile(response, fileName);
        }, err => {
          this.snackBar.open(err, 'CANCEL', {
            horizontalPosition: 'right',
            duration: 2000
          });
        });
    });
  }

  downLoadFile(response: any, fileName: string) {

    const contentType: string = response.headers.get('Content-Type');
    const blob = new Blob([response.body], { type: contentType });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.download = fileName;
    anchor.href = url;
    anchor.click();
  }


}
