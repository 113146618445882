import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { CompanyService } from 'src/services/company.service';

@Component({
  selector: 'app-forward-quote-dialog',
  templateUrl: './forward-quote-dialog.component.html',
  styleUrls: ['./forward-quote-dialog.component.css']
})
export class ForwardQuoteDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ForwardQuoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.openEmailComposer();
  }

  getEmailBody(): string {
    let emailFormat: string = '';
    if ( this.data ) {
      emailFormat += "mailto:" + this.data.email + "?Subject=Comprehensive Quote&body=sample";
    }
    return emailFormat;
  }

  openEmailComposer(): void {
    if ( this.data.insurerName ) {
      location.href = this.getEmailBody();

      // var mail = document.createElement("a");
      // mail.setAttribute("target", "_blank");
      // mail.href = this.getEmailBody();
      // mail.click();
    }
  }

}
