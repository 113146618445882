import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/services/account.service';
import { environment } from '../../environments/environment';
import { Analytics, logEvent } from '@angular/fire/analytics';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class RedirectComponent implements OnInit {

  constructor(private route: ActivatedRoute, private accountService: AccountService, analytics: Analytics) {
    const id = this.route.snapshot.paramMap.get('id');
    console.log(id);
    this.accountService.getName(id).subscribe(res => {
      const url = environment.dynamicLink + '?link=https://www.saiyarti.com/users/' + id + '/' + encodeURIComponent(encodeURIComponent(res.firstName + ' ' + res.lastName)) + '/unknown'
        + '&apn=com.rc.saiyarti&ibi=com.saiyarti&isi=1459650287&efr=1&ofl=' + environment.redirectWebURL + '/' + encodeURIComponent(id) + "/" + encodeURIComponent(res.firstName + ' ' + res.lastName);
      window.location.href = url;
    });
    logEvent(analytics, 'redirect', { referrerId: id });

  }

  ngOnInit() {
  }

}
