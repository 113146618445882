import { Component, OnInit } from '@angular/core';

import { UntypedFormControl } from '@angular/forms';

import { ActivatedRoute } from '@angular/router';

import { Router } from '@angular/router';

import { RequestService } from '../../../services/request.service';

import { CompanyService } from '../../../services/company.service';

import { Location } from '@angular/common';

import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';



@Component({
  selector: 'app-policy-info',
  templateUrl: './policy-info.component.html',
  styleUrls: ['./policy-info.component.css']
})
export class PolicyInfoComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router,
    private requestService: RequestService,
    private companyService: CompanyService, private dialog: MatDialog,
    private location: Location, private snackBar: MatSnackBar) { }
  tplStatus = 1;
  pageTitle = 'TPL Request';
  minDate: Date;
  startDate: Date;
  minStartDate: Date;
  selection = 'none';
  purchaseDetail: any;
  companies: any;
  IsBroker: boolean;
  selectedCompany: any;
  error: any;
  serializedStartDate: UntypedFormControl;
  serializedIssueDate: any = '';
  expiryDate: any = '';
  nextButton = 'NEXT';
  purposeOfLicenseList =
    ['خاصة', 'تاكسي', 'ونيت ياباني', 'ونيت أمريكي', 'خزان مياه', 'إسعاف', 'دراجة بخارية', '(رافعة (كرين', 'باص'];
  companyType:any

  ngOnInit() {
    // this.pageTitleService.setTitle('TPL Request');
    this.getPurchaseDetail();
    this.minDate = new Date();
    this.minStartDate = new Date();
    this.companyType=window.localStorage['projectType']
  }

  getCompanies(): void {
    this.companyService.getCompnayList('Saiyarti').subscribe((companies: any) => {
      this.companies = companies;
      this.selectedCompany = companies[0];
    });
  }

  getBrokers(): void {
    this.companyService.getBrokerList().subscribe((companies: any) => {
      this.companies = companies;
      this.selectedCompany = companies[0];
    });
  }

  getPurchaseDetail(): void {
    const id = +this.route.snapshot.paramMap.get('id');

    this.requestService.getPurchaseDetail(id).subscribe((purchaseDetail: any) => {

      if (purchaseDetail === undefined || purchaseDetail.tplStatus === 'REQUESTRESENT') {
        this.router.navigate(['/dashboard']);
      }

      // if (purchaseDetail.rcBook.purposeOfLicense === 'خاصة') {
      this.getCompanies();
      this.IsBroker = false;
      // } else {
      //   this.getBrokers();
      //   this.IsBroker = true;
      // }

      this.purchaseDetail = purchaseDetail;
      if (this.purchaseDetail.refund > 0) {
        this.selection = 'refund';
      } else if (this.purchaseDetail.collection > 0) {
        this.selection = 'collection';
      }

      this.purchaseDetail.rcBook.expiryDate = new Date(purchaseDetail.rcBook.expiryDate);

      this.serializedIssueDate = new UntypedFormControl(new Date(purchaseDetail.issueDate));
      if (purchaseDetail.startDate !== undefined) {
        this.serializedStartDate = new UntypedFormControl(new Date(purchaseDetail.startDate));
      } else {
        this.serializedStartDate = new UntypedFormControl(this.purchaseDetail.rcBook.expiryDate);
      }

      const startDate = new Date(this.serializedStartDate.value);
      startDate.setFullYear(startDate.getFullYear() + purchaseDetail.tpl.period);
      startDate.setDate(startDate.getDate() - 1);

      this.expiryDate = new UntypedFormControl(startDate);
      this.expiryDate.disable();

    }, err => {
      this.error = err;
      this.snackBar.open(this.error, 'CANCEL', {
        horizontalPosition: 'right',
      });
    });
  }


  changeStartDate(event: MatDatepickerInputEvent<Date>) {
    const startDate = new Date(event.value);
    this.setStartDate(startDate);
  }

  setStartDate(startDate: Date) {
    startDate.setFullYear(startDate.getFullYear() + this.purchaseDetail.tpl.period);
    startDate.setDate(startDate.getDate() - 1);
    this.expiryDate = new UntypedFormControl(startDate);
    this.expiryDate.disable();
  }

  changeIssueDate(event: MatDatepickerInputEvent<Date>) {
    const date = new Date(event.value);
    this.minStartDate = date;
    if (date > this.serializedStartDate.value) {
      this.serializedStartDate = new UntypedFormControl(date);
      this.setStartDate(new Date(date));
    }
  }

  goBack(): void {
    this.location.back();
  }


  updatePurchaseDetailDates(form): void {


    if (this.selection === 'refund' || this.selection === 'collection') {
      const diloagRef = this.dialog.open(ConfirmDialogComponent,
        { data: { title: 'Confirm', message: 'There was a change in ' + this.selection + '. Do you want to continue?' } }
      );
      diloagRef.afterClosed().subscribe(result => {

        if (result === 'true') {
          this.updatePurchaseDetail(form);
        }
      });
    } else {
      this.updatePurchaseDetail(form);
    }
  }
  updatePurchaseDetail(form) {
    this.purchaseDetail.issueDate = this.serializedIssueDate.value.toISOString();
    this.purchaseDetail.startDate = this.serializedStartDate.value.toISOString();
    this.purchaseDetail.companyId = form.value.company.id;
    if (this.selection === 'refund') {
      this.purchaseDetail.collection = 0;
    } else {
      this.purchaseDetail.refund = 0;
    }


    this.nextButton = 'Generating Documents...';
    this.requestService.updatePurchaseDetail(this.purchaseDetail.id, this.purchaseDetail).subscribe((response: any) => {

      this.requestService.updatePDFDocs(this.purchaseDetail.id, form.value.company.id).subscribe(() => {
        this.router.navigate(['/consumer-request/policy-verify-print/' + this.purchaseDetail.id]);
      }, err => {
        this.error = err;
        this.nextButton = 'Next';
        this.snackBar.open(err, 'CANCEL', {
          horizontalPosition: 'right'
        });
      });
    }, err => {
      this.error = err;
      this.nextButton = 'Next';
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right'
      });
    });
  }

  getSupervisionFeeForPeriod(): number {
    if ( this.purchaseDetail.fee ) {
      return ( this.purchaseDetail.expiryPeriod == 1 ? this.purchaseDetail.fee.supervisionFee : ( this.purchaseDetail.expiryPeriod == 2 ? ( this.purchaseDetail.fee.supervisionFee2 ? this.purchaseDetail.fee.supervisionFee2 : this.purchaseDetail.fee.supervisionFee ) : ( this.purchaseDetail.expiryPeriod == 3 ? ( this.purchaseDetail.fee.supervisionFee3 ? this.purchaseDetail.fee.supervisionFee3 : this.purchaseDetail.fee.supervisionFee ) : this.purchaseDetail.fee.supervisionFee ) ) );
    } else {
      return this.purchaseDetail.supervisionFee;
    }
  }

}
