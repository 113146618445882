import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { BaseService } from './base.service';
import { Helpers } from 'src/helpers/helpers';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TransportService extends BaseService {

  constructor(private http: HttpClient, helper: Helpers) {
    super(helper);
 }

 private pathAPI = environment.apiUrl;

  getDocLabels(): any {
    return this.http.get<any>(this.pathAPI + 'Label?DocumentType=TRANSPORT', super.header()).pipe(catchError(super.handleError));
  }

  updateDocLabels(data: any): any {
    return this.http.post<any>(this.pathAPI + 'Label?DocumentType=TRANSPORT', data, super.header()).pipe(catchError(super.handleError));
  }
}
