
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';



import { catchError } from 'rxjs/operators';

import { environment } from '../environments/environment';

import { BaseService } from './base.service';

import { Helpers } from '../helpers/helpers';


@Injectable()

export class RCBookService extends BaseService {

  private pathAPI = environment.apiUrl;

  public errorMessage: string;

  constructor(private http: HttpClient,  helper: Helpers) { super(helper); }

  updateRCBook(id: number, body: any): any {

    return this.http.post<any>(this.pathAPI + 'RCBook/Admin/' + id , body, super.header()).pipe(

        catchError(super.handleError)

      );

  }

  getRCBook(id: number): any {

    return this.http.get<any>(this.pathAPI + 'RCBook/' + id , super.header()).pipe(

        catchError(super.handleError)

      );

  }

  markCarAsSold(rcBookId: number, isSold: boolean): any {
    return this.http.post<any>(this.pathAPI + 'RCBook/IsSold/' + rcBookId + "?IsSold=" + isSold, null, super.header()).pipe(
        catchError(super.handleError)
      );
  }
}
