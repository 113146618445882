import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Helpers } from 'src/helpers/helpers';
import { BaseService } from './base.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TplService extends BaseService {

  constructor(private http: HttpClient,  helper: Helpers) {
    super(helper);
 }

 private pathAPI = environment.apiUrl;

  getFees(): any {
    return this.http.get<any>(this.pathAPI + 'TPL/GetTPLFees', super.header()).pipe(catchError(super.handleError));
  }

  updateFee(data: any): any {
    return this.http.post<any>(this.pathAPI + 'TPL/UpdateTPLFees', data, super.header()).pipe(catchError(super.handleError));
  }

  getTPLLabels(id: string): any {
    return this.http.get<any>(this.pathAPI + 'Label?DocumentType=TPL&CompanyId=' + id, super.header()).pipe(catchError(super.handleError));
  }

  updateTPLLabels(id: string, data: any): any {
    return this.http.post<any>(this.pathAPI + 'Label?DocumentType=TPL&CompanyId=' + id, data, super.header())
    .pipe(catchError(super.handleError));
  }

  getSafrattiLabels(id: string): any {
    return this.http.get<any>(this.pathAPI + 'Label?DocumentType=SAFRATTI&CompanyId=' + id, super.header()).pipe(catchError(super.handleError));
  }
  updateSafrattiLabels(id: string, data: any): any {
    return this.http.post<any>(this.pathAPI + 'Label?DocumentType=SAFRATTI&CompanyId=' + id, data, super.header())
    .pipe(catchError(super.handleError));
  }
}
