import { Component, OnInit } from '@angular/core';
import { TableComponent } from 'src/app/table.component';
import { RequestsReportService } from 'src/services/reports/requests.service';
import { IssuanceModel } from 'src/models/issuance';
import * as moment from 'moment';

@Component({
  selector: 'app-requests-report',
  templateUrl: './requests-report.component.html',
  styleUrls: ['./requests-report.component.css']
})
export class RequestsReportComponent extends TableComponent<IssuanceModel> implements OnInit {
  pageTitle = 'Requests Report';
  startDate: Date;
  endDate: moment.Moment;
  get EndDate() {
    return this.endDate;
  }
  set EndDate(value) {
    if ( value ) {
      this.endDate = value.endOf('day');
    }
  }

  displayedColumns: string[] = [
    'requestdate',
    'insuranceCompany',
    'product',
    'requestType',
    'customerID',
    'customerName',
    'vehicleRcId',
    'vehicleMake',
    'vehicleModel',
    // 'vehicleManufacturingDate',
    'vehiclePlateNumber',
    'addOnAmount',
    'basePremium',
    'fees',
    'totalPremium',
    'netAmount',
    'paymentReferenceNumber',
    'status'
  ];

  constructor(private reportService: RequestsReportService) {
    super(reportService);
  }


  getCount() {
    const data = {
      startDate: this.startDate,
      endDate: this.endDate,
      skip: this.paginator.pageIndex * this.paginator.pageSize,
      take: this.paginator.pageSize
    };
    return this.reportService.getCount(data);
  }


  getData(): any {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      skip: this.paginator.pageIndex * this.paginator.pageSize,
      take: this.paginator.pageSize,
      orderBy: this.sort.active,
      orderByDescending: this.sort.direction === 'desc'
    };
  }

  getDataByFilter() {
    const data = this.getData();
    this.getCount().subscribe(res => {
      this.count = res.count;
      this.loadRequests(data);
    });
  }

  downloadXLS(isXLS: boolean) {
    const data = {
      startDate: this.startDate,
      endDate: this.endDate,
      skip: 0,
      take: 2147483647,
      asXLS: isXLS
    };

    this.reportService.downloadReport(data);
  }

  // getRequestsReport(data: any) {
  //   this.reportService.getRequestsReport(data).subscribe(res => {

  //     const lMe = this;

  //     res.forEach(value => {
  //       const request = new RequestModel();
  //       request.requestdate = value.createdAt;
  //       request.insuranceCompany = value.company.companyName;
  //       request.product = value.product;
  //       request.basePremium = value.amountPaid;
  //       request.fees = value.totalFee;
  //       request.totalPremium = value.totalAmount;
  //       request.paymentReferenceNumber = value.paymentToken;
  //       request.vehicleRcId = value.rcBook.id;
  //       request.vehicleMake = value.rcBook.carMake;
  //       request.vehicleModel = value.rcBook.carModel;
  //       request.vehiclePlateNumber = value.rcBook.plateNumber;
  //       request.customerName = value.civilCard.ownerName;
  //       request.customerID = value.civilCard.id;
  //       request.status = value.status;

  //       this.requestList.push(request);
  //     });

  //     lMe.dataSource = new MatTableDataSource(this.requestList.reverse());
  //     setTimeout(() => {
  //       lMe.dataSource.paginator = lMe.paginator;
  //     }, 100);

  //   }, err => {
  //     if (err) {
  //       this.dialogService.errorSanckBar(err);
  //     } else {
  //       this.dialogService.errorSanckBar('Something went wrong. Please contact admin.');
  //     }
  //   });
  // }
}
