import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Helpers } from 'src/helpers/helpers';
import { RateCard } from 'src/models/rate-card';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class RateCardService extends BaseService {

  private pathAPI = environment.apiUrl;

  constructor(private http: HttpClient, helper: Helpers) {
    super(helper);
  }

  addRateCard(insurerId: string, rateCard: RateCard): Observable<any> {
    return this.http.post(this.pathAPI + 'Quote/AddInsurerRateCard/' + insurerId, rateCard, super.header()).pipe(
      catchError(super.handleError)
    );
  }
  getMasterRateCard(): Observable<RateCard> {
    return this.http.get<RateCard>(this.pathAPI + "Quote/GetMasterRateCard/", super.header()).pipe(
      catchError(super.handleError)
    )
  }

  getRateCardByInsurerId(insurerId: string | number): Observable<any> {
    return this.http.get(this.pathAPI + "Quote/GetInsurerRateCard/" + insurerId, super.header()).pipe(map((x: any) => {
      return x ? new RateCard(x.rateCardId, x) : undefined
    })).pipe(
      catchError(super.handleError)
    )
  }

  updateRateCardByInsurerId(body: RateCard, insurerId: string): Observable<any> {
    return this.http.put(this.pathAPI + 'Quote/UpdateInsurerRateCard/' + insurerId, body, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  updateMasterRateCard(rateCard: RateCard): Observable<any> {
    return this.http.put(this.pathAPI + 'Quote/UpdateMasterRateCard', rateCard, super.header()).pipe(
      catchError(super.handleError)
    );
  }
}
