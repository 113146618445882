import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../services/company.service';
import { StorageService } from 'src/services/storage.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { BlobService, UploadConfig, UploadParams } from 'angular-azure-blob-service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { DocEditXyModelComponent } from 'src/app/model-dialog/doc-edit-xy-model/doc-edit-xy-model.component';
import { TplService } from 'src/services/tpl.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DocViewComponent } from 'src/app/model-dialog/doc-view/doc-view.component';
import { Lightbox } from 'ngx-lightbox';
import { environment } from '../../../environments/environment';
import { RateCardService } from 'src/services/rate-card.service';

export class ICompany {
  id: number;
  companyName: string;
  address: string;
  primaryContact: string;
  companyTradeLicense: string;
  isPrimary: boolean;
  isSafrattiPrimary: boolean
  phone: string;
  countryCode: number;
  createdAt: string;
  updatedAt: string;
  copies: number;
  tplWidth: number;
  tplHeight: number;
  email: any[];
  companyType: string
  safratti:string
  isBroker:boolean
  safrattiPrefix:string;
}




@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.css']
})

export class CompanyDetailComponent implements OnInit {
  isSubmited = false;
  cdnUrl = environment.cdnUrl + '/company/';
  AzureConfig: UploadParams = {
    sas: '',
    storageAccount: environment.storageAccount,
    containerName: 'company'
  };
  uploadConfig: UploadConfig;
  formDisabled = false;

  public imagePath;

  logoImgURL: any;
  logoImageFile: any;

  tradeLicence: any;
  tradeLicenceFile: any;

  authorizedSignatory: any;
  authorizedSignatoryFile: any;

  civilIDofSignatory: any;
  civilIDofSignatoryFile: any;

  requestLetter: any;
  requestLetterFile: any;

  thirdPartyLiability: any;
  thirdPartyLiabilityFile: any;

  comprehensive: any;
  comprehensiveFile: any;

  safratti:any;
  safrattiFile:any;

  public message: string;
  pageTitle = '';

  companyDetail: ICompany = new ICompany();
  error: any;
  SASToken: string;
  SASTokenForUpload: string;
  addCompany: any;
  files: any = [{}, {}, {}, {}, {}, {}];
  companyId;
  isRateCardPresent: boolean;
  tplLables = [];
  safrattiLables = [];
  project: string= 'Saiyarti'

  constructor(private route: ActivatedRoute,
    private companyService: CompanyService,
    private storageService: StorageService,
    private diloag: MatDialog,
    private blobService: BlobService,
    private snackBar: MatSnackBar,
    private router: Router,
    private location: Location,
    private tplService: TplService,
    private sanitizer: DomSanitizer,
    private lightBox: Lightbox,
    private rateCardService: RateCardService) {
    this.storageService.getSASTokenForContainer('company').subscribe(token => {
      this.SASToken = token.sasToken;
      // this.AzureConfig.sas = this.SASToken;

    });
    // Token for upload file.
    this.storageService.getUploadOnlyTokenForContainer('company').subscribe(token => {
      this.SASTokenForUpload = token.sasToken;
    });
  }

  ngOnInit() {
    // this.pageTitleService.setTitle('Warba Insurance Companies');
    const state :any = this.location.getState()
    if(state && state.companyType){
      this.project = state.companyType
    }
    for (let i = 0; i < 6; i++) {
      this.files[i] = {
        isRequired: true
      };
    }

    const id = this.route.snapshot.paramMap.get('id');
    if (id === 'add') {
      this.addCompany = true;
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < 5; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      this.companyId = result;
    } else {
      this.loadCompanyDetail(+id);
      this.getTplLabels(id);
      this.getSafrattiLabels(id);
    }
    // console.log(id);
  }
  getCompanyImageUrl(company): any {
    if (company.id) {
      return this.cdnUrl + company.id + '.jpg' + this.SASToken;
    } else {
      return;
    }
  }
  enableEditCompany() {
    if (this.formDisabled === true) {
      this.formDisabled = false;
    } else {
      this.formDisabled = true;
    }
  }
  loadCompanyDetail(id: number) {
    this.companyService.getCompanyById(id).subscribe(companyDetail => {
      this.companyService.sendId(id)
      this.formDisabled = true;
      this.companyDetail = companyDetail;
      this.companyDetail.id = id;
      this.setEmailAddress();
      this.tradeLicence = companyDetail.tradeLicense;
      this.companyId = this.tradeLicence.split('_')[0];
      this.authorizedSignatory = companyDetail.authorizedSignatory;
      this.civilIDofSignatory = companyDetail.civilIDSignatory;
      this.requestLetter = companyDetail.requestLetter;
      this.thirdPartyLiability = companyDetail.tplTemplate;
      this.comprehensive = companyDetail.comprehensive;
      this.safratti = companyDetail.safratti
      for (let i = 0; i < 6; i++) {
        this.files[i] = {
          isRequired: false
        };
      }

      this.isRateCardPresent = false;
      this.rateCardService.getRateCardByInsurerId(id).subscribe(rateCardResponse => {
        if (rateCardResponse && rateCardResponse.rateCard && rateCardResponse.rateCard.parameters) {
          this.isRateCardPresent = true;
        }
      });
    }, err => {
      // this.error = err;
      // console.log(this.error);
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right'
      });
    });
  }

  setEmailAddress(): void {
    if (this.companyDetail && this.companyDetail.email && this.companyDetail.email.length > 0) {
      let emailArr = [];
      for (let email of this.companyDetail.email) {
        emailArr.push({ emailAdd: email });
      }

      this.companyDetail.email = emailArr;
    }
  }

  addEmail(): void {
    if (!this.companyDetail.email) {
      this.companyDetail.email = [{ emailAdd: '' }];
    } else {
      this.companyDetail.email.push({ emailAdd: '' });
    }
  }

  getEmailArray() {
    if (this.companyDetail.email && this.companyDetail.email.length > 0) {
      let emailArr = [];
      for (let email of this.companyDetail.email) {
        if (email && (email.emailAdd.trim()).length > 0) {
          emailArr.push(email.emailAdd);
        }
      }
      return emailArr;
    }
  }

  markDefault(): void {
    const id = this.route.snapshot.paramMap.get('id');
    const company = window.localStorage['projectType']
    this.companyService.markCompanyDefault(+id,company).subscribe(company => {
      this.snackBar.open('Company has been marked default successfully.', 'CANCEL', {
        duration: 2000
      });
    });
  }

  createCompany(form) {
    this.isSubmited = true;
    // console.log(form.controls['companyName'].status);
    const companyData = {
      companyName: form.value.companyName,
      address: form.value.address,
      primaryContact: form.value.primaryContact,
      companyTradeLicense: form.value.companyTradeLicense,
      phone: form.value.phone,
      copies: form.value.copies,
      countryCode: form.value.countryCode,
      email: this.getEmailArray(),
      comprehensive: this.comprehensive,
      tradeLicense: this.tradeLicence,
      authorizedSignatory: this.authorizedSignatory,
      civilIDSignatory: this.civilIDofSignatory,
      requestLetter: this.requestLetter,
      tPLTemplate: this.thirdPartyLiability ?? '',
      tplWidth: form.value.tplWidth,
      tplHeight: form.value.tplHeight,
      companyType: form.value.project,
      safratti:this.safratti,
      safrattiPrefix: form.value.safrattiPrefix,
      isBroker:null
    };
    this.uploadDocuments();
    this.companyService.createCompany(companyData).subscribe(compnayRes => {
      // console.log(compnayRes);
      const companyId = compnayRes.id;
      if (this.logoImageFile !== undefined) {
        this.uploadFile(this.logoImageFile, companyId + '.jpg', (x) => {
          this.snackBar.open('Company created successfully.', 'CANCEL', {
            duration: 2000,
            horizontalPosition: 'right'
          });
          this.router.navigate(['/companies']);
        });
      } else {
        this.snackBar.open('Company created successfully.', 'CANCEL', {
          duration: 2000,
          horizontalPosition: 'right'
        });
        this.router.navigate(['/companies']);
      }
    }, err => {
      // console.log(err.error.Text);
      // this.error = err;
      // this.diloag.open(AlertDialogComponent, { data: { title: 'Error', message: 'Error to create company.' } });
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right',
      });
    });

  }

  updateCompany(form): void {

    const diloagRef = this.diloag.open(ConfirmDialogComponent,
      { data: { title: 'Confirm', message: 'Are you sure you want to update company?' } }
    );
    diloagRef.afterClosed().subscribe(result => {

      if (result === 'true') {

        const id = +this.route.snapshot.paramMap.get('id');
        const companyData = {
          companyName: form.value.companyName,
          address: form.value.address,
          copies: form.value.copies,
          primaryContact: form.value.primaryContact,
          companyTradeLicense: form.value.companyTradeLicense,
          phone: form.value.phone,
          countryCode: form.value.countryCode,
          email: this.getEmailArray(),
          comprehensive: this.comprehensive,
          tradeLicense: this.tradeLicence,
          authorizedSignatory: this.authorizedSignatory,
          civilIDSignatory: this.civilIDofSignatory,
          requestLetter: this.requestLetter,
          tPLTemplate: this.thirdPartyLiability ?? '',
          tplWidth: form.value.tplWidth,
          tplHeight: form.value.tplHeight,
          companyType: form.value.project,
          safratti: this.safratti,
          safrattiPrefix: form.value.safrattiPrefix,
          isBroker:null
        };
        this.uploadLogo(id);
        this.uploadDocuments();
        this.companyService.updateCompany(id, companyData).subscribe(company => {
          // this.diloag.open(AlertDialogComponent, { data: { title: 'Success', message: 'Company updated successfully.' } });
          this.snackBar.open('Company updated successfully.', 'CANCEL', {
            duration: 2000,
            horizontalPosition: 'right'
          });
          this.loadCompanyDetail(id);
          this.isSubmited = true;
        }, err => {
          // this.diloag.open(AlertDialogComponent, { data: { title: 'Error', message: 'Error to update copany' } });
          // this.error = err;
          this.snackBar.open(err, 'CANCEL', {
            horizontalPosition: 'right'
          });
        });
      }
    });

  }

  onLogoFileSelected(files) {
    if (files.length === 0) {
      return;
    }
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (event) => {
      this.logoImgURL = reader.result;
    };
    this.logoImageFile = files[0];
    // this.uploadFile (files[0],'' + this.companyDetail.id + '.jpg');
  }

  onTradeLicence(event) {
    const file = event.target.files[0];
    this.tradeLicence = this.companyId + '_Trade_Licence_' + file.name;
    this.tradeLicenceFile = file;
    // this.uploadFile(file,this.companyDetail.id + file.name.split('.')[1]);
    // this.uploadFile(file,file.name);
  }
  onAuthorizedSignatory(event) {
    const file = event.target.files[0];
    this.authorizedSignatory = this.companyId + '_Authorized_Signatory_' + file.name;
    this.authorizedSignatoryFile = file;
  }
  onCivilIDofSignatory(event) {
    const file = event.target.files[0];
    this.civilIDofSignatory = this.companyId + '_CivilId_Signatory_' + file.name;
    this.civilIDofSignatoryFile = file;
  }
  onRequestLetter(event) {
    const file = event.target.files[0];
    this.requestLetter = this.companyId + '_Request_Letter_' + file.name;
    this.requestLetterFile = file;
  }
  onThirdPartyLiability(event) {
    const file = event.target.files[0];
    this.thirdPartyLiability = this.companyId + '_TPL_Template_' + file.name;
    this.thirdPartyLiabilityFile = file;
  }
  onComprehensive(event) {
    const file = event.target.files[0];
    this.comprehensive = this.companyId + '_Comprehensive_' + file.name;
    this.comprehensiveFile = file;
  }
  onSafratti(event){
    const file = event.target.files[0];
    this.safratti = this.companyId + '_Safratti_' + file.name;
    this.safrattiFile = file;
  }


  uploadLogo(companyId: number) {
    // Company Logo
    if (this.logoImageFile !== undefined) {
      this.uploadFile(this.logoImageFile, companyId + '.jpg', (x) => { });
    }
  }
  uploadDocuments() {

    // Trade Licence
    if (this.tradeLicenceFile !== undefined) {
      this.uploadFile(this.tradeLicenceFile, this.tradeLicence, (x) => { });
    }

    // Authorized Signatory
    if (this.authorizedSignatoryFile !== undefined) {
      this.uploadFile(this.authorizedSignatoryFile, this.authorizedSignatory, (x) => { });
    }

    // Civil ID of Signatory
    if (this.civilIDofSignatoryFile !== undefined) {
      this.uploadFile(this.civilIDofSignatoryFile, this.civilIDofSignatory, (x) => { });
    }

    // Request Letter
    if (this.requestLetterFile !== undefined) {
      this.uploadFile(this.requestLetterFile, this.requestLetter, (x) => { });
    }

    // Third Party Liability
    if (this.thirdPartyLiabilityFile !== undefined) {
      this.uploadFile(this.thirdPartyLiabilityFile, this.thirdPartyLiability, (x) => { });
    }

    // Comprehensive
    if (this.comprehensiveFile !== undefined) {
      this.uploadFile(this.comprehensiveFile, this.comprehensive, (x) => { });
    }

    // safratti
    if (this.safrattiFile !== undefined) {
      this.uploadFile(this.safrattiFile, this.safratti, (x) => { });
    }
  }

  goBack(): void {
    this.location.back();
  }

  addEditRateCard(): void {
    this.router.navigate(["companies/add-ratecard/" + this.companyDetail.id], { queryParams: { name: this.companyDetail.companyName }, queryParamsHandling: 'preserve' });
  }

  uploadFile(yourfile: any, fileName: string, cb: (error: Error) => void) {
    this.AzureConfig.sas = this.SASTokenForUpload;
    if (yourfile !== null) {
      const baseUrl = this.blobService.generateBlobUrl(this.AzureConfig, fileName);
      this.uploadConfig = {
        baseUrl,
        sasToken: this.AzureConfig.sas,
        // blockSize: 1024 * 64, // OPTIONAL, default value is 1024 * 32
        file: yourfile,
        complete: () => {
          cb(null);
        },
        error: (err) => {
          this.snackBar.open('There was an error uploading your document: ' + fileName, 'CANCEL', {
            horizontalPosition: 'right'
          });
        },
        progress: (percent) => {
          // this.percent = percent;
        }
      };
      // Upload to server
      this.blobService.upload(this.uploadConfig);
    }
  }


  editXYDoc() {
    const id = +this.route.snapshot.paramMap.get('id');
    const dialogConfig = new MatDialogConfig();
    const title = this.companyDetail.companyName + ' > ' + ' Print Configuration';
    dialogConfig.data = { title, message: 'Error to create company.', labels: this.tplLables, companyId: id, companyType:'Saiyarti'};
    dialogConfig.width = '60%';
    dialogConfig.position = {
      // 'top':'2%'
    };
    const diloagRef = this.diloag.open(DocEditXyModelComponent, dialogConfig);
  }

  editXYDocForSafratti() {
    const id = +this.route.snapshot.paramMap.get('id');
    const dialogConfig = new MatDialogConfig();
    const title = this.companyDetail.companyName + ' > ' + ' Print Configuration';
    dialogConfig.data = { title, message: 'Error to create company.', labels: this.safrattiLables, companyId: id, companyType:'Safratti'};
    dialogConfig.width = '60%';
    dialogConfig.position = {
      // 'top':'2%'
    };
    const diloagRef = this.diloag.open(DocEditXyModelComponent, dialogConfig);
  }

  getTplLabels(id: string) {

    this.tplService.getTPLLabels(id).subscribe(res => {
      this.tplLables = res;
    }, err => {
      console.log(err);
    });
  }

  getSafrattiLabels(id: string) {

    this.tplService.getSafrattiLabels(id).subscribe(res => {
      this.safrattiLables = res;
    }, err => {
      console.log(err);
    });
  }

  showPdf(filename: string) {
    const url = environment.cdnUrl + '/company/' + filename + this.SASToken + '#toolbar=0';
    const pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.diloag.open(DocViewComponent, { data: { url: pdfUrl } });
  }

  showImage(filename: string, caption: string) {
    const src = environment.cdnUrl + '/company/' + filename + this.SASToken;
    const thumb = src;
    const album = {
      src,
      caption,
      thumb
    };

    this.lightBox.open([album], 0);
  }

  showOverlay(filename: string, caption) {
    if (filename.substr(filename.length - 3, 3) === 'pdf') {
      this.showPdf(filename);
    } else {
      this.showImage(filename, caption);
    }
  }

  viewTradeLicense() {
    this.showOverlay(this.tradeLicence, ' Trade License');
  }

  viewAuthorizedSignatory() {
    this.showOverlay(this.authorizedSignatory, 'Authorized Signatory');
  }

  viewCivilIDSignatory() {
    this.showOverlay(this.civilIDofSignatory, 'Civil ID Signatory');
  }

  viewRequestLetter() {
    this.showOverlay(this.requestLetter, 'Request Letter');
  }

  viewTPL() {
    this.showOverlay(this.thirdPartyLiability, 'Third Party Liability');
  }

  viewComprehensive() {
    this.showOverlay(this.comprehensive, 'Comprehensive');
  }
  viewSafratti() {
    this.showOverlayForSafratti(this.safratti, 'Safratti');
  }
  showOverlayForSafratti(filename: string, caption){
    this.showImage(filename, caption);
  }
}
