
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';



import { catchError } from 'rxjs/operators';

import { Helpers } from '../../helpers/helpers';
import { ICountService } from '../icount.service';
import { BaseService } from '../base.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PaymentService extends BaseService implements ICountService {
    private pathAPI = environment.apiUrl;

    public errorMessage: string;

    constructor(private http: HttpClient, helper: Helpers) { super(helper); }

    getCount(body: any) {
        return this.http.post<any>(this.pathAPI + 'Account/PaymentCount', body, super.header())
            .pipe(catchError(super.handleError));
    }

    getData(body: any) {
        return this.http.post<any>(this.pathAPI + 'Account/Payment', body, super.header())
            .pipe(catchError(super.handleError));
    }
}
