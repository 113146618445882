import { Component, OnInit } from '@angular/core';
import { RenewCustomerService } from 'src/services/renewcustomer.service';
import { NewCustomer } from 'src/models/newcustomer';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SelectLanguageComponent } from '../model-dialog/select-language/select-language.component';

@Component({
  selector: 'app-renew-customer-detail',
  templateUrl: './renew-customer-detail.component.html',
  styleUrls: ['./renew-customer-detail.component.css']
})
export class RenewCustomerDetailComponent implements OnInit {

  customer = new NewCustomer();
  currentYear: number = (new Date()).getFullYear();
  formDisabled = true;

  constructor(private newCustomerService: RenewCustomerService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private location: Location,
    private dialog: MatDialog) { }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');

    this.newCustomerService.get(+id).subscribe(res => {
      this.customer = res;
    });
  }

  back() {
    this.location.back();
  }
  updateCustomer(form) {
    const id = this.route.snapshot.paramMap.get('id');

    this.newCustomerService.update(+id, this.customer).subscribe(res => {
      this.back();
    });
  }

  sendPolicyRenewalSMS() {
    const dialogRef = this.dialog.open( SelectLanguageComponent );
    
    dialogRef.afterClosed().subscribe( result => {
      let lang: string;
      if ( result != "false" ) {
        if (result === 'Arabic') {
          lang = 'ar';
        }
  
        const id = this.route.snapshot.paramMap.get('id');
        this.newCustomerService.sendPolicyRenewalSMS(+id, lang).subscribe(res => {
          this.snackBar.open('The policy renewal reminder is sent successfully.', 'CANCEL', {
            horizontalPosition: 'right',
            duration: 4000
          });
        }, err => {
          this.snackBar.open(err, 'CANCEL', {
            horizontalPosition: 'right',
            duration: 4000
          });
        });
      }
    }, error => {
      this.snackBar.open( error, 'CANCEL', {
        horizontalPosition: 'right',
        duration: 2000
      });
    });
  }
}
