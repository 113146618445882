import { Component, OnInit } from '@angular/core';

import { Location } from '@angular/common';

import { ActivatedRoute } from '@angular/router';

import { Router } from '@angular/router';

import { RequestService } from '../../../services/request.service';

import {DomSanitizer} from '@angular/platform-browser';

import { StorageService } from 'src/services/storage.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PrintLabelComponent } from 'src/app/model-dialog/print-label/print-label.component';
import { environment } from '../../../environments/environment'
import { OwlOptions } from 'ngx-owl-carousel-o';


@Component({
  selector: 'app-policy-status',
  templateUrl: './policy-status.component.html',
  styleUrls: ['./policy-status.component.css']
})
export class PolicyStatusComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
    },
    nav: false,
  };


  tplStatus = 3;
  pageTitle = 'TPL Request';
  constructor(private sanitizer: DomSanitizer, private route: ActivatedRoute,
              private requestService: RequestService, private router: Router, private location: Location,
              private storageService: StorageService, private dialog: MatDialog) { }

  step = 0;
  buttonName = 'POLICY PRINTED';
  backButtonName = 'Back';
  safePDFDocumentUrl;
  transportPDFDocumentUrl;
  invoicePDFDocumentUrl;
  purchaseDetail: any;
  error: any;
  serializedStartDate: any = '';
  serializedIssueDate: any = '';
  expiryDate: any = '';

  ngOnInit() {
    this.getPurchaseDetail();
    // this.loadImage();
    this.loadDocuments();
  }

  loadDocuments(): void {

    this.storageService.getSASToken(this.route.snapshot.paramMap.get('id') + '_background.pdf', 'policies').subscribe((token: any) => {

      const PDFDocumentUrl = environment.cdnUrl +  '/policies/'
      + this.route.snapshot.paramMap.get('id') + '_background.pdf' + token.sasToken;
      this.storageService.getImage(PDFDocumentUrl).subscribe((response) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        this.safePDFDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl)
    });})

    this.storageService.getSASToken(this.route.snapshot.paramMap.get('id')
    + '_transport_background.pdf', 'policies').subscribe((token: any) => {
      const temptransportPDFDocumentUrl = environment.cdnUrl + '/policies/'
        + this.route.snapshot.paramMap.get('id') + '_transport_background.pdf' + token.sasToken;
        this.storageService.getImage(temptransportPDFDocumentUrl).subscribe((response) => {
          const blob = new Blob([response], { type: 'application/pdf' });
          const blobUrl = URL.createObjectURL(blob);
        this.transportPDFDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl)})
    });
    
    this.storageService.getSASToken(this.route.snapshot.paramMap.get('id') + '_invoice.pdf', 'policies').subscribe((token: any) => {

      const tempinvoicePDFDocumentUrl = environment.cdnUrl + '/policies/'
        + this.route.snapshot.paramMap.get('id') + '_invoice.pdf' + token.sasToken;
        this.storageService.getImage(tempinvoicePDFDocumentUrl).subscribe((response) => {
          const blob = new Blob([response], { type: 'application/pdf' });
          const blobUrl = URL.createObjectURL(blob);
        this.invoicePDFDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl)})

    });
  }

  getPurchaseDetail(): void {
    const id = +this.route.snapshot.paramMap.get('id');

    this.requestService.getPurchaseDetail(id).subscribe((purchaseDetail: any) => {

      if (purchaseDetail === undefined || purchaseDetail.tplStatus === 'REQUESTRESENT') {
        this.router.navigate(['/dashboard']);
      }

      this.purchaseDetail = purchaseDetail;

      if (this.purchaseDetail.status === 'PENDING') {
        this.step = 0;
      }
      if (this.purchaseDetail.status === 'POLICY_PRINTED') {
        this.step = 1;
        this.backButtonName = 'Home';
        this.buttonName = 'DISPATCH POLICY';
      }

      if (this.purchaseDetail.status === 'DISPATCHED') {
        this.step = 2;
        this.buttonName = 'DELIVER POLICY';
        this.tplStatus = 2;
        this.backButtonName = 'Home';
      }

      if (this.purchaseDetail.status === 'DELIVERED') {
        this.step = 3;
        this.buttonName = 'HOME';
        this.tplStatus = 3;
        this.backButtonName = 'BACK';
      }

    }, err => {
      this.error = err;
    });
  }

  printLabel() {
    this.dialog.open(PrintLabelComponent, {data: this.purchaseDetail});
  }

  goBack(): void {
    if (this.step === 0) {
      this.location.back();
    } else {
      this.router.navigate(['/consumer-request']);
    }
  }

  updateStatus(): void {
    if (this.step === 0) {
      this.requestService.policyPrinted(this.purchaseDetail.id, this.purchaseDetail).subscribe((response: any) => {

        this.step++;
        this.buttonName = 'DISPATCH POLICY';

      }, err => {
        this.error = err;
      });

    }

    if (this.step === 1) {
      this.requestService.dispatchPolicy(this.purchaseDetail.id, this.purchaseDetail).subscribe((response: any) => {

        this.step++;
        this.buttonName = 'DELIVER POLICY';

      }, err => {
        this.error = err;
      });
    }

    if (this.step === 2) {

      this.requestService.deliverPolicy(this.purchaseDetail.id, this.purchaseDetail).subscribe((response: any) => {

        this.step++;
        this.buttonName = 'HOME';
      }, err => {
        this.error = err;
      });
    }

    if (this.step === 3) {
      this.router.navigate(['/dashboard']);
    }
  }
}
