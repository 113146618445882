import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AddAgent } from 'src/models/add-agent';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { StorePersonService } from 'src/services/storeperson.service';

@Component({
  selector: 'app-sperson-scoordinator-detail',
  templateUrl: './sperson-scoordinator-detail.component.html',
  styleUrls: ['./sperson-scoordinator-detail.component.css']
})
export class SpersonScoordinatorDetailComponent implements OnInit {

  pageTitle = 'Store Administrators';
  agentData: AddAgent = new AddAgent();
  isAddAgent = false;
  formDisabled = true;

  storeUserTypes = {
    'StoreSalesPerson': 'Sales Person',
    'StoreCoordinator': 'Store Coordinator'
  }

  constructor(private storePersonService: StorePersonService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location) {
  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    
    if (id === 'add') {
      this.isAddAgent = true;
      this.formDisabled = false;
      this.agentData.type = "StoreSalesPerson";
      this.agentData.countryCode = 91;
    } else {
      this.loadAgent(id);
    }
  }
  enableFormForEdit() {
    if (this.formDisabled === true) {
      this.formDisabled = false;
    } else {
      this.formDisabled = true;
    }
  }

  addAgent(form) {
    this.storePersonService.createStoreUser(form.value).subscribe(res => {
      this.snackBar.open('Success', 'CANCEL', {
        horizontalPosition: 'right',
        duration: 2000
      });
      this.router.navigate(['/stores-user']);
    }, err => {
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right'
      });
    });
  }
  loadAgent(id) {
    this.storePersonService.getStoreUserById(id).subscribe(res => {
      this.agentData = res;
    }, err => {
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right'
      });
    });
  }

  updateAgent() {
    const id = this.route.snapshot.paramMap.get('id');
    this.storePersonService.updateStoreUser(id, {
      email: this.agentData.email,
      phoneNumber: this.agentData.phoneNumber,
      countryCode: this.agentData.countryCode,
      firstName: this.agentData.firstName,
      lastName: this.agentData.lastName 
    }).subscribe(res => {
      this.snackBar.open('Store User update successfully.', 'CANCEL', {
        horizontalPosition: 'right',
        duration: 2000
      });
      this.router.navigate(['/stores-user']);
    }, err => {
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right'
      });
    });
  }

  back() {
    this.location.back();
  }

}
