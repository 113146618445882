import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SelectionModel } from '@angular/cdk/collections';
import { CompanyService } from 'src/services/company.service';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { CompaniesService } from 'src/services/companies.service';

@Component({
  selector: 'app-b2bcompanies',
  templateUrl: './b2bcompanies.component.html',
  styleUrls: ['./b2bcompanies.component.css']
})
export class B2BCompaniesComponent implements OnInit {

  pageTitle = 'Admins';
  companyList = [];
  displayedColumns: string[] = ['companyName', 'departmentName', 'name', 'phone', 'email', 'dateCreated', 'optionMenu'];

  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  constructor(private router: Router,
    private companiesService: CompaniesService,
    private snackBar: MatSnackBar,
    private diloag: MatDialog) {
  }

  ngOnInit() {
    this.getCompanyList();
  }

  getCompanyList() {
    this.companiesService.getCompanyList().subscribe(res => {
      this.companyList = res;
      // Assign the data to the data source for the table to render
      this.dataSource = new MatTableDataSource(this.companyList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, err => {
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right'
      });
    });
  }


  resetPassword(company) {
    this.companiesService.resetPassword(company.id).subscribe(res => {
      // Reset Password link sent successfully.
      this.snackBar.open('The new password is sent successfully.', 'CANCEL', {
        horizontalPosition: 'right',
        duration: 2000
      });
    }, err => {
      this.snackBar.open(err, 'Retry', {
        horizontalPosition: 'right'
      });
    });
  }
  deleteCompany(company) {
    const dialogRef = this.diloag.open(ConfirmDialogComponent, { data: { title: 'Confirm', message: 'Once deactivated the user will not be visible in the list. Are you sure you want deactivate?' } });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result === 'true') {
        this.companiesService.deactivateCompany(company.id).subscribe(res => {
          this.getCompanyList();
          this.snackBar.open('User deactivated successfully.', 'CANCEL', {
            horizontalPosition: 'right',
            duration: 2000
          });
        }, err => {
          this.snackBar.open(err, 'CANCEL', {
            horizontalPosition: 'right'
          });
        });
      }
    });
  }
}
