import { Component } from '@angular/core';
import { UsersService } from 'src/services/users.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { TableComponent } from 'src/app/table.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent extends TableComponent<Request> {
  pageTitle = 'All Users';
  displayedColumns: string[] = ['customerName', 'phone', 'email', 'referredBy', 'policyCount', 'vehiclesCount','product'];
  selection = new SelectionModel<any>(true, []);

  constructor(protected usersService: UsersService, private router: Router) { 
    super (usersService);
  }

  /** open customer detail */
  openCustomer(row): void {
    this.router.navigate(['/users/' + row.userId]);
  }

}
