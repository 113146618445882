import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Helpers } from 'src/helpers/helpers';
import { BaseService } from './base.service';
import { ICountService } from './icount.service';
@Injectable({
  providedIn: 'root'
})
export class ComprehensiveOrderService extends BaseService implements ICountService {

  private pathAPI = environment.apiUrl;

  constructor(private http: HttpClient, helper: Helpers) { super(helper); }

  getData(body: any): any {
    return this.http.post<any>(this.pathAPI + 'Quote/GetAllOrders', body, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getCount(body: any) {
    return this.http.post<any>(this.pathAPI + 'Quote/GetAllOrdersCount', body, super.header()).pipe(catchError(super.handleError));
  }

  getOrderById(id: string): Observable<any> {
    return this.http.get(this.pathAPI + "Quote/Order/" + id, super.header()).pipe(
      catchError(super.handleError)
    );
  }
}
