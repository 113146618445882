import { Component, OnInit } from '@angular/core';
import { NewCustomerService } from 'src/services/newcustomer.service';
import { NewCustomer } from 'src/models/newcustomer';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-new-customer-detail',
  templateUrl: './new-customer-detail.component.html',
  styleUrls: ['./new-customer-detail.component.css']
})
export class NewCustomerDetailComponent implements OnInit {

  customer = new NewCustomer();
  currentYear: number = (new Date()).getFullYear();
  formDisabled = true;

  constructor(private newCustomerService: NewCustomerService,
              private route: ActivatedRoute,
              private location: Location) { }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');

    this.newCustomerService.get(+id).subscribe(res => {
      this.customer = res;
    });
  }

  back() {
    this.location.back();
  }
  updateCustomer(form) {
    const id = this.route.snapshot.paramMap.get('id');

    this.newCustomerService.update(+id, this.customer).subscribe(res => {
      this.back();
    });
  }
}
