
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';



import { catchError, map, take } from 'rxjs/operators';

import { environment } from '../environments/environment';

import { BaseService } from './base.service';

import { ICountService } from './icount.service';

import { Helpers } from '../helpers/helpers';
import { RateCard } from 'src/models/rate-card';


@Injectable()

export class RequestService extends BaseService implements ICountService {

  private pathAPI = environment.apiUrl;

  public errorMessage: string;

  constructor(private http: HttpClient, helper: Helpers) { super(helper); }

  getData(body: any): any {

    return this.http.post(this.pathAPI + 'TPLPurchase/Filtered', body, super.header()).pipe(
      catchError(super.handleError)
    );

  }

  getPurchaseDetail(id: number): any {

    return this.http.get<any>(this.pathAPI + 'TPLPurchase/' + id, super.header()).pipe(

      catchError(super.handleError)

    );

  }

  getCount(body: any) {
    return this.http.post<any>(this.pathAPI + 'TPLPurchase/GetRequestsCount', body, super.header()).pipe(catchError(super.handleError));
  }

  updatePurchaseDetail(id: number, body: any): any {

    return this.http.post<any>(this.pathAPI + 'TPLPurchase/' + id, body, super.header()).pipe(

      catchError(super.handleError)

    );

  }

  updatePDFDocs(id: number, companyId: number): any {

    return this.http.post<any>(this.pathAPI + 'TPLPurchase/' + id + '/UpdatePDFDocs?companyId=' + companyId, {}, super.header()).pipe(

      catchError(super.handleError)

    );

  }

  sendRequestToCustomer(id: number, body: any): any {

    return this.http.post<any>(this.pathAPI + 'TPLPurchase/' + id + '/SendRequestToCustomer', body, super.header()).pipe(

      catchError(super.handleError)

    );

  }


  policyPrinted(id: number, body: any): any {

    return this.http.post<any>(this.pathAPI + 'TPLPurchase/' + id + '/PolicyPrinted', body, super.header()).pipe(

      catchError(super.handleError)

    );

  }


  dispatchPolicy(id: number, body: any): any {

    return this.http.post<any>(this.pathAPI + 'TPLPurchase/' + id + '/Dispatch', body, super.header()).pipe(

      catchError(super.handleError)

    );

  }


  deliverPolicy(id: number, body: any): any {

    return this.http.post<any>(this.pathAPI + 'TPLPurchase/' + id + '/Deliver', body, super.header()).pipe(

      catchError(super.handleError)

    );

  }

}
