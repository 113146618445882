import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { Router } from '@angular/router';

import { RequestService } from '../../../services/request.service';

import { DashboardService } from '../../../services/dashboard.service';

import { Helpers } from '../../../helpers/helpers';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import jwt_decode from 'jwt-decode';
import { AccountService } from 'src/services/account.service';
import { UsernamePasswordDialogComponent } from 'src/app/username-password-dialog/username-password-dialog.component';
import { ComprehensiveRequestsService } from 'src/services/comprehensive-requests.service';
import { QuoteStatus } from 'src/models/comprehensive-quote';
import { SafrattiRequestService } from 'src/services/safratti-request.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  pageTitle = 'Dashboard';
  type = 'TPL'
  displayedColumns: string[] = ['name', 'type', 'status', 'policyDate'];
  displayedComprehensiveColumns: string[] = ['name', 'status', 'policyDate'];
  displayedSafrattiColumns: string[] = ['name', 'status', 'policyDate'];
  requestAPColumns: string[] = ['name', 'carMake', 'insurer', 'policyDate'];
  requests = [];
  cRequests = [];
  sRequest = [];
  apRequests = [];
  referrals = [];
  displayedReferralColumns: string[] = ['referral', 'B', 'C', 'D'];
  message: string;
  error: any;
  language = 'en';
  title: string;
  TypeofData: string = 'TPL'
  TCdropdown = true
  cRequesttype: boolean
  constructor(private helper: Helpers,
    private router: Router,
    private accountService: AccountService,
    private requestService: RequestService,
    private crService: ComprehensiveRequestsService,
    private dashboardService: DashboardService,
    private safrattiService: SafrattiRequestService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog) { 
      this.receiveData(this.TypeofData)
    }

  dashboardCounts: any;
  dashboaedComprehensiveCount: any;
  dashboardSafrattiCount:any;
  ngOnInit() {
  
    const obj: any = jwt_decode(this.helper.getToken());

    if (obj.type.indexOf('OfficeAdministrator') === 0) {
      this.router.navigate(['/consumer-request']);
    }
    // this.getReferrals();
    // this.getComprehensiveReferrals();
    // this.getSafrattiReferrals();
  }
  receiveData(data: string) {
    this.TypeofData = data
  
    this.getReferrals();
    this.getComprehensiveReferrals();
    this.getSafrattiReferrals();
  }
  openPurchaseDetail(row): void {
    this.requestService.getPurchaseDetail(row.id).subscribe((purchaseDetail: any) => {

      if (purchaseDetail.status === 'PENDING' ||
        purchaseDetail.status === 'REQUESTRESENT') {
        this.router.navigate(['/consumer-request/purchase-detail/' + row.id]);
      } else {
        this.router.navigate(['/consumer-request/policy-status/' + row.id]);
      }
    });
  }

  openComprehensivePurchaseDetail(row): void {
    if ((row.status == QuoteStatus.POLICY_PRINTED || row.status == QuoteStatus.DELIVERED || row.status == QuoteStatus.DISPATCHED)) {
      this.router.navigate(['/comprehensive-consumer-request/policy-status/' + row.id]);
    } else {
      this.router.navigate(['/comprehensive-consumer-request/cr-detail/' + row.id]);
    }
  }

  getCounts(days) {
    this.dashboardService.getOverviewCount(days).subscribe(counts => {
      this.dashboardCounts = counts;
    });
  }


  getReferrals() {
    this.dashboardService.getReferrals(this.TypeofData).subscribe(referrals => {
      this.referrals = referrals;
      this.dashboardService.getOverviewCount(365).subscribe(counts => {
        this.dashboardCounts = counts;

        this.requestService.getData({ skip: 0, take: 5 }).subscribe(requests => {
          this.requests = requests;
        });
      });
    });
  }

  getComprehensiveReferrals() {
    this.dashboardService.getReferrals(this.TypeofData).subscribe(referrals => {
      this.referrals = referrals;
      this.dashboardService.getComprehensiveOverviewCount(365).subscribe(counts => {
        this.dashboaedComprehensiveCount = counts;
        
        this.crService.getData({ skip: 0, take: 5 }).subscribe(cRequests => {
          this.cRequests = cRequests;

          this.crService.getData({ skip: 0, take: 5 }).subscribe(apRequests => {
            this.apRequests = apRequests.filter(item => item.status === 'CUSTOMER_ACCEPTED')
          });
        });
      });
    });
  }

  getSafrattiReferrals() {
    this.dashboardService.getReferrals(this.TypeofData).subscribe(referrals => {
      this.referrals = referrals;
      this.dashboardService.getSafrattiOverviewCount(365).subscribe(counts => {
        this.dashboardSafrattiCount = counts;

        this.safrattiService.getData({ skip: 0, take: 5 }).subscribe(sRequest => {
          this.sRequest = sRequest;

        });
      });
    });
  }
  openUser(row) {
    this.router.navigate(['/users/' + row.id]);
  }

  sendMessage() {


    const dialogRef = this.dialog.open(UsernamePasswordDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.accountService.sendPushNotification({ message: this.message, language: this.language, title: this.title }).subscribe(res => {
          this.message = undefined;
          this.snackBar.open('The push notification was sent out successfully..', 'CANCEL', {
            duration: 2000,
            horizontalPosition: 'right'
          });
        }, err => {
          console.log(err);
          this.snackBar.open(err, 'CANCEL', {
            horizontalPosition: 'right'
          });
        });
      } else {
        // TODO : logi for cancel
      }
    });
  }

}
