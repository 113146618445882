import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Benefit } from 'src/models/benefit';
import { SafrattiAges } from 'src/models/safratti-fee-structure-age';

@Component({
  selector: 'app-safratti-fee-structure-age',
  templateUrl: './safratti-fee-structure-age.component.html',
  styleUrls: ['./safratti-fee-structure-age.component.css']
})
export class SafrattiFeeStructureAgeComponent {
  showAddAge = true;
  id: number;

  ages: SafrattiAges[] = [];
  selectedAge?: SafrattiAges = undefined;
  constructor() {}

  ageForm = new FormGroup({
    age: new FormControl<number>(null, Validators.required),
    percentage: new FormControl<number>(null, Validators.required),
    deductible: new FormControl<number>(null, Validators.required),
  });

  ngOnInit() {}

  saveAge() {
    const safrattiage: SafrattiAges = {
      uptoAge: this.ageForm.get("age").value,
      percentage: this.ageForm.get("percentage").value,
      deductible: this.ageForm.get("deductible").value,
    };
    this.ages.push(safrattiage);
    this.showAddAge = false;
  }

  deleteAge(safrattiage: SafrattiAges) {
    this.ages.splice(this.ages.indexOf(safrattiage), 1);
  }
}
