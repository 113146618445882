import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TokenService } from 'src/services/token.service';
import { Helpers } from 'src/helpers/helpers';

@Component({
  selector: 'app-password-confirm-dialog',
  templateUrl: './password-confirm-dialog.component.html',
  styleUrls: ['./password-confirm-dialog.component.css']
})
export class PasswordConfirmDialogComponent implements OnInit {

  password: string;
  constructor(private dialogRef: MatDialogRef<PasswordConfirmDialogComponent>,
    private snackBar: MatSnackBar,
    private helpers: Helpers,
    protected tokenService: TokenService) { }

  ngOnInit() {
  }


  login(): void {

    // const authValues = { email: this.email, password: this.password };

    this.tokenService.auth({ email: this.helpers.getEmail(), password: this.password }).subscribe(token => {
      this.dialogRef.close();
      // Hide self
    }, err => {
      // Show error
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right',
        duration: 2000
      });
    });
  }
}
