import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit, DoCheck } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-make-payment-dialog',
  templateUrl: './make-payment-dialog.component.html',
  styleUrls: ['./make-payment-dialog.component.css']
})
export class MakePaymentDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
        private dialog: MatDialog, 
        private payMentDialog: MatDialogRef<MakePaymentDialogComponent>,
        private snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  confirmUser(amount, notes) {
    if( amount > 0 ) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, { data: { title: 'Confirm', message: "Do you want to proceed with the payment?" } });
      confirmDialog.afterClosed().subscribe(result => {
        if (result === 'true') {
          this.payMentDialog.close({status: true, amount: amount, notes: notes});
        }
      });
    }
    else {
      this.snackBar.open('Please enter a valid amount.', 'CANCEL', {
        horizontalPosition: 'right',
        duration: 2000
      });
    }
  }

  closeDialog() {
    this.payMentDialog.close({status: false});
  }
}
