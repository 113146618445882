import { Component, OnInit } from '@angular/core';
import { RCBookService } from 'src/services/rcbook.service';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from 'src/services/storage.service';
import { Lightbox } from 'ngx-lightbox';
import { Location } from '@angular/common';
import { environment } from '../../../environments/environment';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-vehicle-detail',
  templateUrl: './vehicle-detail.component.html',
  styleUrls: ['./vehicle-detail.component.css']
})
export class VehicleDetailComponent implements OnInit {
  pageTitle = 'Vehicle details';
  rcBookImageUrl: any;
  rcBook: any = {};
  albums: any = [];

  constructor(private rcbookService: RCBookService, private route: ActivatedRoute,
    private lightbox: Lightbox, private location: Location,
    private storageService: StorageService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    const id = +this.route.snapshot.paramMap.get('id');

    this.rcbookService.getRCBook(id).subscribe(res => {
      this.rcBook = res;
      this.loadImages();
    }, err => {

    });
  }

  goBack(): void {
    this.location.back();
  }


  loadImages() {
    const rcBook = this.rcBook.id;

    this.storageService.getSASToken(rcBook, 'comprehensive-rc-book').subscribe((token: any) => {
      this.rcBookImageUrl = environment.cdnUrl + '/comprehensive-rc-book/' + rcBook + token.sasToken;
      const src = this.rcBookImageUrl;
      const caption = 'RC Book';
      const thumb = this.rcBookImageUrl;
      const album = {
        src,
        caption,
        thumb
      };
      this.albums[0] = album;
    });
  }

  open(): void {
    this.lightbox.open(this.albums, 0);
  }

}
