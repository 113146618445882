import { Component, OnInit, ViewChild } from '@angular/core';
import { PointService } from 'src/services/point.service';
import { TableComponent } from 'src/app/table.component';

@Component({
  selector: 'app-point-transactions',
  templateUrl: './point-transactions.component.html',
  styleUrls: ['./point-transactions.component.css']
})
export class PointTransactionsComponent extends TableComponent<Request> implements OnInit {
  pageTitle = 'Point Transactions';

  displayedColumns: string[] = ['userName', 'scheme', 'type', 'method', 'referenceName', 'pointValue', 'dateTime'];

  constructor(protected pointService: PointService) {
    super(pointService);
  }


  getUserPoint(data) {

  }

  // applyFilter(filterValue: string) {
  //   this.dataSource.filter = filterValue.trim().toLowerCase();
  // }

}
