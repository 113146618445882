import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Benefit } from "src/models/benefit";
import { BenefitProperty } from "src/models/benefit-property";

export class BenefitData {
  title: string;
  arabicTitle: string;
}

@Component({
  selector: "app-safratti-travel-plan-benefits-add",
  templateUrl: "./safratti-travel-plan-benefits-add.component.html",
  styleUrls: ["./safratti-travel-plan-benefits-add.component.css"],
})
export class SafrattiTravelPlanBenefitsAddComponent implements OnInit {
  showAddBenefit = true;
  showAddBenefitProperty = false;
  id: number;

  benefits: Benefit[] = [];
  selectedBenefit?: Benefit = undefined;
  constructor() {}

  benefitForm = new FormGroup({
    title: new FormControl("", Validators.required),
    arabicTitle: new FormControl("", Validators.required),
  });

  benefitPropertyForm = new FormGroup({
    title: new FormControl("", Validators.required),
    displayValue: new FormControl("", Validators.required),
    arabicTitle: new FormControl("", Validators.required),
    arabicDisplayValue: new FormControl("", Validators.required),
  });

  ngOnInit() {}

  saveBenefitProperty() {
    const benefitProperty: BenefitProperty = {
      title: this.benefitPropertyForm.get("title").value,
      displayValue: this.benefitPropertyForm.get("displayValue").value,
      arabicTitle: this.benefitPropertyForm.get("arabicTitle").value,
      arabicDisplayValue:
        this.benefitPropertyForm.get("arabicDisplayValue").value,
    };
    this.selectedBenefit?.benefitProperty.push(benefitProperty);
    this.showAddBenefitProperty = false;
  }

  saveBenefit() {
    const benefit: Benefit = {
      title: this.benefitForm.get("title").value,
      arabicTitle: this.benefitForm.get("arabicTitle").value,
      benefitProperty: [],
    };
    this.benefits.push(benefit);
    this.showAddBenefit = false;
  }

  deleteBenefitProperty(benefit: Benefit, benefitProperty: BenefitProperty) {
    benefit.benefitProperty.splice(
      benefit.benefitProperty.indexOf(benefitProperty),
      1
    );
  }

  deleteBenefit(benefit: Benefit) {
    this.benefits.splice(this.benefits.indexOf(benefit), 1);
  }
}
