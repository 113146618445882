import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "../environments/environment";
import { Helpers } from "../helpers/helpers";
import { catchError } from "rxjs/operators";
import { ICountService } from "./icount.service";
import { UpdateEnableFlagForUser } from "src/models/updateEnableFlagForUser";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CustomerService extends BaseService implements ICountService {
  private pathAPI = environment.apiUrl;

  public errorMessage: string;

  constructor(private http: HttpClient, helper: Helpers) {
    super(helper);
  }

  /**
   * To get all user/cusotmer
   */
  getData(body: any): any {
    return this.http
      .post<any>(this.pathAPI + "User/Customers", body, super.header())
      .pipe(catchError(super.handleError));
  }

  getCustomerByCivilId(id: string) {
    return this.http
      .get<any>(this.pathAPI + "User/GetCustomer/" + id, super.header())
      .pipe(catchError(super.handleError));
  }

  /**
   * To get cutomer detail by using customer id
   */

  getCustomer(id: string): any {
    return this.http
      .get<any>(this.pathAPI + "User/GetUser/" + id, super.header())
      .pipe(catchError(super.handleError));
  }

  getEnableFlagForUser(
    id: any,
    body: UpdateEnableFlagForUser
  ): Observable<any> {
    return this.http
      .put(
        this.pathAPI + `User/EnableFlagForUser?UserId=${id}`,
        body,
        super.header()
      )
      .pipe(catchError(super.handleError));
  }

  getCount(body: any): any {
    return this.http
      .post<any>(this.pathAPI + "User/GetCustomersCount", body, super.header())
      .pipe(catchError(super.handleError));
  }
}
