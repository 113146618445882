import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { TravelerDetail } from "src/models/traveler-detail";
import { GTSService } from "src/services/gts.service";
import { SafrattiRequestService } from "src/services/safratti-request.service";

@Component({
  selector: "app-safratti-policy-payment-detail",
  templateUrl: "./safratti-policy-payment-detail.component.html",
  styleUrls: ["./safratti-policy-payment-detail.component.css"],
})
export class SafrattiPolicyPaymentDetailComponent implements OnChanges {
  constructor(private _gtsService:GTSService) {}

  @Input() paymentDetail: TravelerDetail;
  @Input() updatePremium = false
  updatedPremiumPrice =0
  @Output() premiumUpdated = new EventEmitter<number>();
  paymentDetailForm = new FormGroup({
    basePremium: new FormControl<number | null>({ disabled: true, value: null }),
    amountPaid: new FormControl<string | null>({ disabled: true, value: null }),
    issueFee: new FormControl<string | null>({ disabled: true, value: null }),
    totalAmount: new FormControl<number | null>({
      disabled: true,
      value: null,
    }),
    netPremium: new FormControl<string | null>({ disabled: true, value: null }),
    paymentToken: new FormControl<string | null>(null),
    paymentId: new FormControl<string | null>(null),
    refund:new FormControl<number|null>(null),
    collection:new FormControl<number|null>(null),
    gtsPolicyNumber:new FormControl<string|null>(null)
  });

  ngOnInit(){
    this._gtsService.updatedPremiumPrice$.subscribe((price)=>{
      this.updatedPremiumPrice = price
      this.premiumUpdated.emit(this.updatedPremiumPrice)
    })
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.paymentDetailForm.patchValue(this.paymentDetail);
  }
}
