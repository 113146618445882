import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { PolicyDocumentService } from "./policy-document-abstract";
import { PolicyDetails } from "./factory/policy-document-service-factory";
import { of } from "rxjs";
import { take } from "rxjs/operators";
import { StorageService } from "./storage.service";

(pdfMake as any).addVirtualFileSystem(pdfFonts);

export class SafrattiPolicyDocumentService extends PolicyDocumentService {
  _membershipCardDetails: PolicyDetails;
  _policyId?: string

  constructor(
    membershipCardDetails: PolicyDetails,
    private _storageService: StorageService
  ) {
    super();
    this._membershipCardDetails = membershipCardDetails;
  }

  private _getMembershipCardDefinition(): any {
    return {
      images: {
        backgroundImg: {
          url: "https://saiyartiproduction.blob.core.windows.net/assets/membership-card-background.png",
        },
        safrattiLogo: {
          url: `https://saiyartiproduction.blob.core.windows.net/assets/membership-card-safratti-logo.png`,
        },
        kuwaitCompanyLogo: {
          url: `https://saiyartiproduction.blob.core.windows.net/assets/membership-kuwait-company-logo.png`,
        },
        gtsLogo: {
          url: `https://saiyartiproduction.blob.core.windows.net/assets/membership-gts-logo.png`,
        },
        medassistLogo: {
          url: `https://saiyartiproduction.blob.core.windows.net/assets/membership-medassistlogo.png`,
        },
      },
      content: [
        {
          table: {
            widths: [520],
            body: [
              [
                {
                  image: "backgroundImg",
                  fit: [520, 300],
                  margin: [15, 50, 0, 0],
                  borderColor: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
                },
              ],
            ],
          },
        },
        {
          image: "safrattiLogo",
          width: 125,
          absolutePosition: { x: 85, y: 125 },
        },

        {
          image: "kuwaitCompanyLogo",
          width: 130,
          absolutePosition: { x: 370, y: 118 },
        },
        {
          text: "Policy Number",
          absolutePosition: { x: 100, y: 215 },
          fontSize: 12,
          color: "white",
        },
        {
          text: this._membershipCardDetails.policyNumber.split("-")[0],
          absolutePosition: { x: 100, y: 230 },
          fontSize: 30,
          color: "white",
          bold: 700,
          wordSpacing: 5,
        },
        {
          text: "Inception Date",
          absolutePosition: { x: 100, y: 285 },
          fontSize: 12,
          color: "white",
        },
        {
          text: this._formatDate(this._membershipCardDetails.startDate),
          absolutePosition: { x: 100, y: 305 },
          fontSize: 12,
          color: "white",
          bold: true,
        },
        {
          text: "Expiry Date",
          absolutePosition: { x: 210, y: 285 },
          fontSize: 12,
          color: "white",
        },
        {
          text: this._formatDate(this._membershipCardDetails.endDate),
          absolutePosition: { x: 210, y: 305 },
          fontSize: 12,
          color: "white",
          bold: true,
        },
        {
          text: this._membershipCardDetails.name,
          absolutePosition: { x: 100, y: 345 },
          fontSize: 18,
          color: "white",
          bold: 700,
          wordSpacing: 5,
        },
        // second card
        {
          table: {
            widths: [520],
            body: [
              [
                {
                  image: "backgroundImg",
                  fit: [520, 300],
                  margin: [15, 50, 0, 0],
                  borderColor: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
                },
              ],
            ],
          },
        },
        {
          image: "gtsLogo",
          width: 120,
          absolutePosition: { x: 93, y: 470 },
        },
        {
          image: "medassistLogo",
          width: 110,
          absolutePosition: { x: 397, y: 470 },
        },
        {
          text: "In case of emergency or request of assistance, G-assist headquarters in France will",
          absolutePosition: { x: 95, y: 585 },
          fontSize: 11,
          color: "white",
          bold: true,
        },
        {
          text: "offer you 24 hours service and assistance.",
          absolutePosition: { x: 95, y: 600 },
          fontSize: 11,
          color: "white",
          bold: true,
        },
        {
          text: "Europe: +33 975 181 280",
          absolutePosition: { x: 95, y: 615 },
          fontSize: 11,
          color: "white",
        },
        {
          text: "Asia: +813 457 89 229",
          absolutePosition: { x: 95, y: 630 },
          fontSize: 11,
          color: "white",
        },
        {
          text: "Australia: +612 801 446 45",
          absolutePosition: { x: 95, y: 645 },
          fontSize: 11,
          color: "white",
        },
        {
          text: "USA: +646 652 0758",
          absolutePosition: { x: 95, y: 660 },
          fontSize: 11,
          color: "white",
        },
        {
          text: "Middle East & Africa: +961 1 518 456",
          absolutePosition: { x: 95, y: 675 },
          fontSize: 11,
          color: "white",
        },
        {
          text: "Mobile (WhatsApp available): +961 71 861 776",
          absolutePosition: { x: 95, y: 690 },
          fontSize: 11,
          color: "white",
        },
        {
          text: "Email: assist@globemedgroup.com",
          absolutePosition: { x: 95, y: 705 },
          fontSize: 11,
          color: "white",
        },
      ],
    };
  }

  downloadMembershipCard(cb: (blob: Blob) => void) {
    this._storageService
      .getSASTokenForSafrattiMemberShipCard(
        this._policyId
      )
      .pipe(take(1))
      .subscribe((token: { sasToken: any }) => {
        const sasToken = token.sasToken;
        this._storageService
          .downloadFile(
            `/safratti-membership-card/${this._policyId}${sasToken}`
          )
          .pipe(take(1))
          .subscribe((res) => {
            cb(res);
          });
      });
  }
  downloadPolicy(cb: (blob: Blob) => void) {
    this._storageService
      .getSASTokenForSafratti(this._policyId)
      .pipe(take(1))
      .subscribe((token: { sasToken: any }) => {
        const sasToken = token.sasToken;
        this._storageService
          .downloadFile(
            `/safratti-policy-copy/${this._policyId}${sasToken}`
          )
          .pipe(take(1))
          .subscribe((res) => {
            cb(res);
          });
      });
  }
  downloadTermsAndConditions() {
    return of({});
    // return pdfMake.createPdf({});
  }

  private _formatDate(val: any) {
    const date = new Date(val);
    if (date.toString() == "Invalid Date") {
      return (
        val.split("-")[2] + "/" + val.split("-")[1] + "/" + val.split("-")[0]
      );
    } else {
      return (
        date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
      );
    }
  }

  uploadMembershipCard(cb: () => void): void {
    pdfMake.createPdf(this._getMembershipCardDefinition()).getBlob((blob) => {
      var file = new File([blob], this._membershipCardDetails.policyId);

      return this._storageService
        .getUploadTokenForSafrattiMemberShipCard(
          this._membershipCardDetails.policyId
        )
        .pipe(take(1))
        .subscribe((token: { sasToken: any }) => {
          const sasToken = token.sasToken;
          this._storageService
            .uploadFileToAzure(
              `/safratti-membership-card/${this._membershipCardDetails.policyId}${sasToken}`,
              file,
              "application/pdf"
            )
            .pipe(take(1))
            .subscribe((x) => {
              cb();
            });
        });
    });
  }
  uploadPolicy(cb: () => void): void {
    // TODO:
    const dd = {
      content: [
        {
          image: this._membershipCardDetails.policyBkgImg,
          width: 595,
        },
        {
          text: this._membershipCardDetails.reference,
          absolutePosition: this._membershipCardDetails.referencePosition,
          bold: true,
          fontSize: 11,
        },
        {
          text: this._membershipCardDetails.date,
          absolutePosition: this._membershipCardDetails.datePosition,
          bold: true,
          fontSize: 11,
        },
        {
          text: this._membershipCardDetails.agent,
          absolutePosition: this._membershipCardDetails.agentPosition,
          bold: true,
          fontSize: 11,
        },

        {
          text: this._membershipCardDetails.fullName,
          absolutePosition: this._membershipCardDetails.fullNamePosition,
          bold: true,
          fontSize: 11,
        },
        {
          text: this._membershipCardDetails.gender,
          absolutePosition: this._membershipCardDetails.genderPosition,
          bold: true,
          fontSize: 11,
        },
        {
          text: this._membershipCardDetails.dateofBirth,
          absolutePosition: this._membershipCardDetails.dateofBirthPosition,
          bold: true,
          fontSize: 11,
        },
        {
          text: this._membershipCardDetails.passportNo,
          absolutePosition: this._membershipCardDetails.passportNoPosition,
          bold: true,
          fontSize: 11,
        },
        {
          text: this._membershipCardDetails.countryofResidence,
          absolutePosition:
            this._membershipCardDetails.countryofResidencePosition,
          bold: true,
          fontSize: 11,
        },
        {
          text: this._membershipCardDetails.phone,
          absolutePosition: this._membershipCardDetails.phonePosition,
          bold: true,
          fontSize: 11,
        },
        {
          text: this._membershipCardDetails.email,
          absolutePosition: this._membershipCardDetails.emailPosition,
          bold: true,
          fontSize: 11,
        },
        // {      //passort expiry date
        //   text: this.safrattiLables[11]?.type,
        //   absolutePosition: { x: this.safrattiLables[11]?.positionX, y: this.safrattiLables[11]?.positionY },
        //   bold: true,
        //   fontSize: 11,
        // },
        // {     // nationality
        //   text: this.safrattiLables[10]?.type,
        //   absolutePosition: { x: this.safrattiLables[10]?.positionX, y: this.safrattiLables[10]?.positionY },
        //   bold: true,
        //   fontSize: 11
        // },

        {
          text: this._membershipCardDetails.plan,
          absolutePosition: this._membershipCardDetails.planPosition,
          bold: true,
          fontSize: 11,
        },
        {
          text: this._membershipCardDetails.coverageZone,
          absolutePosition:
            this._membershipCardDetails.countryofResidencePosition,
          bold: true,
          fontSize: 11,
        },
        {
          text: this._membershipCardDetails.from,
          absolutePosition: this._membershipCardDetails.fromPosition,
          bold: true,
          fontSize: 11,
        },
        {
          text: this._membershipCardDetails.to,
          absolutePosition: this._membershipCardDetails.toPosition,
          bold: true,
          fontSize: 11,
        },
        {
          text: this._membershipCardDetails.periodofCover,
          absolutePosition: this._membershipCardDetails.periodofCoverPosition,
          bold: true,
          fontSize: 11,
        },
        {
          text: this._membershipCardDetails.sportsActivities,
          absolutePosition:
            this._membershipCardDetails.sportsActivitiesPosition,
          bold: true,
          fontSize: 11,
        },
        {
          text: this._membershipCardDetails.destination,
          absolutePosition: this._membershipCardDetails.destinationPosition,
          bold: true,
          fontSize: 11,
        },

        // {   //deductable amount
        //   text: this.safrattiLables[5]?.type,
        //   absolutePosition: { x: this.safrattiLables[5]?.positionX, y: this.safrattiLables[5]?.positionY },
        //   bold: true,
        //   fontSize: 11
        // },
      ],
    };

    pdfMake.createPdf(dd).getBlob((blob) => {
      var file = new File([blob], this._membershipCardDetails.policyId);

      return this._storageService
        .getUploadTokenForSafratti(this._membershipCardDetails.policyId)
        .pipe(take(1))
        .subscribe((token: { sasToken: any }) => {
          const sasToken = token.sasToken;
          this._storageService
            .uploadFileToAzure(
              `/safratti-policy-copy/${this._membershipCardDetails.policyId}${sasToken}`,
              file,
              "application/pdf"
            )
            .pipe(take(1))
            .subscribe((x) => {
              cb();
            });
        });
    });
  }
}
