import { AfterViewInit, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Location } from "@angular/common";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { ActivatedRoute } from '@angular/router';
import { CreatePromoCodeDto } from 'src/dto/request/create-promoCode-dto';
import { SafrattiPromoCodeService } from 'src/services/safratti-promo-code.service';
import { SafrattiPromoCode } from 'src/models/safratti-promo-code';
import { UpdateSafrattiPromoCode } from 'src/models/updateSafrattiPromoCode';

@Component({
  selector: 'app-safratti-promo-code-add',
  templateUrl: './safratti-promo-code-add.component.html',
  styleUrls: ['./safratti-promo-code-add.component.css']
})
export class SafrattiPromoCodeAddComponent implements OnInit {
  isAddPromoCode = true;
  couponCode: string;
  selectedApproval = 'Auto';
  selectedBenefits: string[] = [];
  todayDate:Date = new Date();

  constructor(
    private location: Location,
    private _snackBar: MatSnackBar,
    private _safrattiPromoCodeService: SafrattiPromoCodeService,
    private activatedRoute: ActivatedRoute
  ) {
    this.selectedApproval = 'Auto';
  }

  safrattiPromoCodeForm = new FormGroup({
    promotionName: new FormControl<string>(undefined, Validators.required),
    couponCode: new FormControl<string>(undefined, Validators.required),
    startDate: new FormControl<Date | null>(null, Validators.required),
    endDate: new FormControl<Date | null>(null,Validators.required),
    type: new FormControl<string[]>(undefined, Validators.required),
    discountAmountValue: new FormControl<number>(undefined),
    percentageValue: new FormControl<number>(undefined),
    rewardCreditValue: new FormControl<number>(undefined),
    approvalMethod: new FormControl<string | null>("Auto", Validators.required),
  });

  

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.couponCode = params["couponCode"];
      this.isAddPromoCode = !this.couponCode;
      if (this.couponCode) {
        this.fetchPromoCode();
      }
    });
    console.log(this.safrattiPromoCodeForm)
   
    
  }


  fetchPromoCode() {
      this._safrattiPromoCodeService.fetchPromoCode(this.couponCode).subscribe( res=> {

        this.selectedBenefits= res.type.split(',').map(x=>{
          return x.trim()
        })
        this.safrattiPromoCodeForm.patchValue({
          promotionName: res.promotionName,
          couponCode: res.couponCode,
          startDate: res.startDate,
          endDate: res.endDate,
          discountAmountValue: res.discountAmountValue,  
          percentageValue: res.percentageValue,
          rewardCreditValue: res.rewardCreditValue, 
          approvalMethod: res.approvalMethod,
          type: this.selectedBenefits,
        });
        this.safrattiPromoCodeForm.get('type')?.valueChanges.subscribe(() => {
          this.updateValidators();
        });
              })
  }
  

  addPromoCode() {
    const promotionName = this.safrattiPromoCodeForm.get('promotionName')?.value;
    const couponCode = this.safrattiPromoCodeForm.get('couponCode')?.value;
    const startDate = this.safrattiPromoCodeForm.get('startDate')?.value;
    const endDate = this.safrattiPromoCodeForm.get('endDate')?.value;
    const discountAmountValue = this.safrattiPromoCodeForm.get('discountAmountValue')?.value;
    const percentageValue = this.safrattiPromoCodeForm.get('percentageValue')?.value;
    const rewardCreditValue = this.safrattiPromoCodeForm.get('rewardCreditValue')?.value;
    const approvalMethod = this.safrattiPromoCodeForm.get('approvalMethod')?.value;


    const promoCodeFormDetails: CreatePromoCodeDto = {
      promotionName,
      couponCode,
      startDate,
      endDate,
      type: this.selectedBenefits.join(","),
      discountAmountValue,
      percentageValue,
      rewardCreditValue,
      approvalMethod,
      status: true,
    };

    this._safrattiPromoCodeService.createSafrattiPromoCode(promoCodeFormDetails)
    .subscribe((x) => {
      this._snackBar.open("Promo Code Created Successfully.")
      this.back();
    });
    
  }

  updatePromoCode() {
    const promotionName = this.safrattiPromoCodeForm.get('promotionName')?.value;
    const couponCode = this.safrattiPromoCodeForm.get('couponCode')?.value;
    const startDate = this.safrattiPromoCodeForm.get('startDate')?.value;
    const endDate = this.safrattiPromoCodeForm.get('endDate')?.value;
    const discountAmountValue = this.safrattiPromoCodeForm.get('discountAmountValue')?.value;
    const percentageValue = this.safrattiPromoCodeForm.get('percentageValue')?.value;
    const rewardCreditValue = this.safrattiPromoCodeForm.get('rewardCreditValue')?.value;
    const approvalMethod = this.safrattiPromoCodeForm.get('approvalMethod')?.value;

    const updatePromoCode: UpdateSafrattiPromoCode = {
      promotionName,
      couponCode,
      startDate,
      endDate,
      type: this.selectedBenefits.join(","),
      discountAmountValue,
      percentageValue,
      rewardCreditValue,
      approvalMethod,
      status: true,
    };

    this._safrattiPromoCodeService.updateSafrattiPromoCode(this.couponCode,updatePromoCode)
    .subscribe((_) => {
      this._snackBar.open("Promo Code Updated Successfully.")
      this.back();
    });
    
  }

  updateValidators() {
    const discountControl = this.safrattiPromoCodeForm.get('discountAmountValue');
    const percentageControl = this.safrattiPromoCodeForm.get('percentageValue');
    const rewardCreditControl = this.safrattiPromoCodeForm.get('rewardCreditValue');
  
    if (this.selectedBenefits.length === 0) {
      // Clear validators for all three controls if no benefits are selected
      discountControl.clearValidators();
      percentageControl.clearValidators();
      rewardCreditControl.clearValidators();
    } else {
      // Clear validators only for the control that corresponds to the unselected benefit
      if (!this.selectedBenefits.includes('DiscountAmount')) {
        discountControl.clearValidators();
      }
      if (!this.selectedBenefits.includes('Percentage')) {
        percentageControl.clearValidators();
      }
      if (!this.selectedBenefits.includes('RewardCredit')) {
        rewardCreditControl.clearValidators();
      }
  
      // Set validators only for the control that corresponds to the selected benefit
      if (this.selectedBenefits.includes('DiscountAmount')) {
        discountControl.setValidators([Validators.required]);
      }
      if (this.selectedBenefits.includes('Percentage')) {
        percentageControl.setValidators([Validators.required]);
      }
      if (this.selectedBenefits.includes('RewardCredit')) {
        rewardCreditControl.setValidators([Validators.required]);
      }
    }
  
    discountControl.updateValueAndValidity();
    percentageControl.updateValueAndValidity();
    rewardCreditControl.updateValueAndValidity();
  }


  back() {
    this.location.back();
  }
}
