import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-safratti-report',
  templateUrl: './safratti-report.component.html',
  styleUrls: ['./safratti-report.component.css']
})
export class SafrattiReportComponent implements OnInit {

  constructor() { }
  pageTitle = 'Safratti Report'
  ngOnInit(): void {
  }

}
