import { Component, AfterViewInit } from '@angular/core';
import { Helpers } from '../helpers/helpers';
import { startWith, delay } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Router, Event, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  subscription: Subscription;

  authentication: boolean;

  showLoadingIndicator = false;

  constructor(private helpers: Helpers, private router: Router) {
    /**
     * To handal loader while route change
     */
    this.router.events.subscribe((routerEvent: Event) => {
      // if(routerEvent instanceof NavigationStart){
      //   this.showLoadingIndicator = true;
      // }
      // if(routerEvent  instanceof NavigationEnd){
      //   this.showLoadingIndicator = false;
      // }

      switch (true) {
        case routerEvent instanceof NavigationStart: {
          this.showLoadingIndicator = true;
          break;
        }

        case routerEvent instanceof NavigationEnd:
        case routerEvent instanceof NavigationCancel:
        case routerEvent instanceof NavigationError: {
          var lMe = this;
          setTimeout(function () {
            lMe.showLoadingIndicator = false;
          }, 100);

          break;
        }
        default: {
          break;
        }
      }

    });
  }
  ngOnInit() {
    $(document).on('click', '[data-toggle="lightbox"]', function (event) {
      event.preventDefault();
    });
  }

  ngAfterViewInit() {

    this.subscription = this.helpers.isAuthenticationChanged().pipe(

      startWith(this.helpers.isAuthenticated()),

      delay(0)).subscribe((value) =>

        this.authentication = value

      );

  }

  title = 'Angular 5 Seed';

  ngOnDestroy() {

    this.subscription.unsubscribe();

  }

}
