import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { TableComponent } from 'src/app/table.component';
import { OrderService } from 'src/services/order.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})

export class OrdersComponent extends TableComponent<Request> {
  pageTitle = 'Orders';
  displayedColumns: string[] = [
    'id',
    'orderDate',
    'customerName',
    'userName',
    'orderType',
    'plateNo',
    'carStatus',
    'insuranceCom',
    'policyNumber',
    'basePremium',
    'totalFee',
    'totalPremium',
    'netAmount',
    'paymentReference',
    'refund',
    'collection',
    'notes'
  ];

  selection = new SelectionModel<any>(true, []);

  constructor(orderService: OrderService, private router: Router) {
    super(orderService);
  }

  openOrder(data: any) {
    this.router.navigate(['/orders/' + data.id]);
  }

}
