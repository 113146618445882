import { Component, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ComprehensiveRequestsService } from 'src/services/comprehensive-requests.service';
import { Location } from '@angular/common';
import { RateCard } from 'src/models/rate-card';

@Component({
  selector: 'app-quote-history-details',
  templateUrl: './quote-history-details.component.html',
  styleUrls: ['./quote-history-details.component.css']
})
export class QuoteHistoryDetailsComponent implements OnInit {

  quoteDetails: RateCard;
  // requestDetails: any;
  qhId: number;
  formDisabled: boolean = true;
  Object = Object;

  constructor(private location: Location,
    private requestService: ComprehensiveRequestsService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar) {
    const id = +this.route.snapshot.paramMap.get('id');
    if (this.router.getCurrentNavigation().extras.state) {
      this.quoteDetails = new RateCard(id, this.router.getCurrentNavigation().extras.state.rateCard)
    } else {

      this.requestService.getQuoteRequestByVehicleId(id).subscribe(x => {
        this.quoteDetails = x
      })
    }
  }

  ngOnInit(): void { }
  enableEditRequest() {
    this.formDisabled = !this.formDisabled;
  }

  // getAddOnValue(): any {
  //   for (const addOnKey in this.quoteDetails.addOnsMap) {
  //     const addOn = this.quoteDetails.addOnsMap[addOnKey]
  //     if (addOn.subParametersMap) {
  //       for (const subParameterKey in addOn.subParametersMap) {
  //         const subParameter = addOn.subParametersMap[subParameterKey]
  //         if (subParameter.subParametersMap) {
  //           for (const subSubParameterKey in subParameter.subParametersMap) {
  //             const subSubParameter = addOn.subParametersMap[subSubParameterKey]
  //             if (subSubParameter.isSelected) {
  //               addOn.name = addOn.name + ' (' + subParameter.name + ')';
  //               addOn.arabicName = addOn.arabicName + ' (' + subParameter.arabicName + ')';
  //             }

  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  getRateCardValue(rateCard: RateCard): any {
    let bRetVal = "";
    if (rateCard) {
      for (const key in rateCard.parametersMap) {
        var parameter = rateCard.parametersMap[key]
        if (parameter.isSelected) {
          bRetVal = parameter.name
          break;
        }
      }
    } else {
      return "Included";
    }
    return bRetVal;
  }

  goBack(): void {
    this.location.back();
  }

  updateCarDetails(updateData) {
    let updateBody: {
      ownerName?: string,
      carMake?: string,
      carModel?: number,
      variant?: string,
      modelYear?: number,
      marketValue?: number,
      amount?: number,
      purposeOfLicense?: string,
      plateNumber?: string,
      noOfPassenger?: number
    } = {
      ownerName: updateData.ownerName,
      carMake: updateData.carMake,
      carModel: updateData.carModel,
      variant: updateData.variant,
      modelYear: updateData.modelYear,
      marketValue: updateData.marketValue,
      amount: updateData.amount,
      purposeOfLicense: this.quoteDetails.vehicle.purposeOfLicense ? this.quoteDetails.vehicle.purposeOfLicense : '',
      plateNumber: this.quoteDetails.vehicle.plateNumber ? this.quoteDetails.vehicle.plateNumber : '',
      noOfPassenger: this.quoteDetails.vehicle.noOfPassenger ? this.quoteDetails.vehicle.noOfPassenger : 0
    }

    this.requestService.editCar(this.quoteDetails.vehicleId, updateBody).subscribe(() => {
      this.snackBar.open('Details are updated successfully.', 'OK', {
        horizontalPosition: 'center',
        duration: 2000
      });
      this.formDisabled = true;
    }, err => {
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right'
      });
    });
  }
  sort(x: any, y: any) {
    const aStepId = x.value.order ?? 0
    const bStepId = y.value.order ?? 0
    return aStepId > bStepId ? 1 : (bStepId > aStepId ? -1 : 0);
  }
}
