import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient } from "@angular/common/http";
import { Helpers } from "src/helpers/helpers";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError } from "rxjs/operators";
import { SafrattiDataListRows } from "src/models/safratti-data-list-rows";
import { SafrattiFee } from "src/models/safratti-fee";
import { SafrattiTravelPlan } from "src/models/safratti-travel-plan";
import { CreateTravelPlanDto } from "src/dto/request/create-travel-plan-dto";
import { UpdateTravelPlan } from "src/models/update-travel-plan";

@Injectable({
  providedIn: "root",
})
export class SafrattiTravelPlanService extends BaseService {
  constructor(private http: HttpClient, helper: Helpers) {
    super(helper);
  }

  getDataListRows(
    data: SafrattiDataListRows[]
  ): Observable<SafrattiDataListRows[]> {
    return this.http
      .post<SafrattiDataListRows[]>(
        environment.apiUrl + "Datalist/QueryDatalistRows",
        data,
        super.header()
      )
      .pipe(catchError(super.handleError));
  }

  createTravelPlans(body: CreateTravelPlanDto): Observable<void> {
    return this.http
      .post<void>(
        environment.apiUrl + "Safratti/TravelPlan",
        body,
        super.header()
      )
      .pipe(catchError(super.handleError));
  }

  getTravelPlans(): Observable<SafrattiTravelPlan[]> {
    return this.http
      .get<SafrattiTravelPlan[]>(
        environment.apiUrl + "Safratti/TravelPlan",
        super.header()
      )
      .pipe(catchError(super.handleError));
  }

  updateTravelPlan(id: number, body: UpdateTravelPlan): Observable<void> {
    return this.http.put<void>(environment.apiUrl + `Safratti/TravelPlan/${id}`,body,super.header())
    .pipe(catchError(super.handleError));
  }

  fetchTravelPlan(id: any): Observable<UpdateTravelPlan> {
    return this.http.get<UpdateTravelPlan>(environment.apiUrl + 'Safratti/TravelPlan/'+id,super.header() )
    .pipe(catchError(super.handleError))
  }

  toggleStatus(id: number): Observable<void> {
    return this.http
      .put<void>(
        environment.apiUrl + `Safratti/TravelPlan/${id}/ToggleStatus`,
        {},
        super.header()
      )
      .pipe(catchError(super.handleError));
  }
}
