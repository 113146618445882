export class Parameter {
    // splice(i: any, arg1: number) {
    //   throw new Error('Method not implemented.')
    // }

    constructor(obj: any) {

        if (obj) {
            Object.assign(this, obj)
            if (obj.subParametersMap) {
                this.subParametersMap = {}
                for (const key in obj.subParametersMap) {
                    const val = obj.subParametersMap[key]
                    this.subParametersMap[key] = new Parameter(val)
                }
            }
        }
    }

    order?: number
    higherRange?: number
    lowerRange?: number
    isMandatory?: boolean
    name?: string
    arabicName?: string
    type?: string
    valueForInsurer?: number
    percentageValue?: number
    isSelected?: boolean
    isEnabled?: boolean
    description?: string
    arabicDescription?: string
    subParametersMap?: { [key: string]: Parameter } = {}

    translatedName(lang: string):string|undefined {
    // translatedName(lang: string) {
        return lang === 'en' ? this.name : this.arabicName
    }
    translatedDescription(lang: string) :string|undefined{
    // translatedDescription(lang: string) {
        return lang === 'en' ? this.description : this.arabicDescription
    }
}