import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/services/company.service';
import { TableComponent } from 'src/app/table.component';
import { InsurerReportService } from 'src/services/reports/insurer.service';
import { Insurer } from 'src/models/insurer';
import * as moment from 'moment';

@Component({
  selector: 'app-insurer-report',
  templateUrl: './insurer-report.component.html',
  styleUrls: ['./insurer-report.component.css']
})
export class InsurerReportComponent extends TableComponent<Insurer> implements OnInit {

  startDate: Date;
  endDate: moment.Moment;
  get EndDate() {
    return this.endDate;
  }
  set EndDate(value) {
    if ( value ) {
      this.endDate = value.endOf('day');
    }
  }
  companyId;
  companyList: any[] = [];
  fileUrl;
  companyType:any

  pageTitle = 'Insurer Report';
  displayedColumns: string[] =
    [
      'companyName',
      'product',
      'createdAt',
      'issueDate',
      'startDate',
      'endDate',
      'policyNumber',
      'orderType',
      'customerName',
      'carMake',
      'carModel',
      // 'carManufacturingDate',
      'carPlateNumber',
      'carStatus',
      'basePremium',
      'fees',
      'addOn',
      'totalPremium',
      'netAmount'
    ];

  constructor(private reportService: InsurerReportService, private companyService: CompanyService) {
    super(reportService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getCompanyList();
  }

  getCompanyList() {
    this.companyService.getCompnayList('Saiyarti').subscribe(res => {
      this.companyList = res;
    });
  }

  getCount() {
    const data = {
      startDate: this.startDate,
      endDate: this.endDate,
      companyId: this.companyId,
      skip: this.paginator.pageIndex * this.paginator.pageSize,
      take: this.paginator.pageSize
    };
    return this.reportService.getCount(data);
  }

  getData(): any {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      companyId: this.companyId,
      skip: this.paginator.pageIndex * this.paginator.pageSize,
      take: this.paginator.pageSize,
      orderBy: this.sort.active,
      orderByDescending: this.sort.direction === 'desc'
    };
  }

  getDataByFilter() {
    const data = this.getData();
    this.getCount().subscribe(res => {
      this.count = res.count;
      this.loadRequests(data);
    });
  }

  downloadXLS(isXLS: boolean) {
    const data = {
      startDate: this.startDate,
      endDate: this.endDate,
      companyId: this.companyId,
      skip: 0,
      take: 2147483647,
      asXLS: isXLS
    };

    this.reportService.downloadReport(data);
  }
}
