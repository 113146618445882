import { Component, OnInit } from '@angular/core';
import { AddAgent } from 'src/models/add-agent';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CompaniesService } from 'src/services/companies.service';
import { Location } from '@angular/common';
import { CompanyService } from 'src/services/company.service';

@Component({
  selector: 'app-company-detail',
  templateUrl: './b2bcompany-detail.component.html',
  styleUrls: ['./b2bcompany-detail.component.css']
})
export class B2BCompanyDetailComponent implements OnInit {
  pageTitle = 'Company Administrators';
  agentData: AddAgent = new AddAgent();
  isAddAgent = false;
  formDisabled = true;

  companies = [];

  constructor(private companiesService: CompaniesService,
    private companyService: CompanyService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private diloag: MatDialog) {
    this.agentData.role = 'Company Admin';
  }

  ngOnInit() {

    const agentId = this.route.snapshot.paramMap.get('id');
    const id = this.route.snapshot.paramMap.get('id');
    if ( this.companies.length == 0 ) {
      this.getCompanies();
    }
    
    if (id === 'add') {
      this.isAddAgent = true;
      this.formDisabled = false;
    } else {
      this.loadAgent(id);
    }
  }
  enableFormForEdit() {
    if (this.formDisabled === true) {
      this.formDisabled = false;
    } else {
      this.formDisabled = true;
    }
  }

  getCompanies(): void {
    this.companyService.getCompnayList('Saiyarti').subscribe((companies: any) => {
      this.companies = companies;
    });
  }

  addAgent(form) {
    this.companiesService.createCompany(form.value).subscribe(res => {
      this.snackBar.open('Success', 'CANCEL', {
        horizontalPosition: 'right',
        duration: 2000
      });
      this.router.navigate(['/b2b-companies']);
    }, err => {
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right'
      });
    });
  }
  loadAgent(id) {
    this.companiesService.getCompanyById(id).subscribe(res => {
      this.agentData = res;
    }, err => {
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right'
      });
    });
  }

  updateAgent(form) {
    const id = this.route.snapshot.paramMap.get('id');
    this.companiesService.updateCompany(id, this.agentData).subscribe(res => {
      this.snackBar.open('Company update successfully.', 'CANCEL', {
        horizontalPosition: 'right',
        duration: 2000
      });
      this.router.navigate(['/b2b-companies']);
    }, err => {
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right'
      });
    });
  }

  back() {
    this.location.back();
  }
}
