import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TableComponent } from '../table.component';
import { UpdatePayment } from 'src/models/update-payment';
import { SelectionModel } from '@angular/cdk/collections';
import { PaymentService } from 'src/services/reports/payment.service';

@Component({
  selector: 'app-collectibles',
  templateUrl: './collectibles.component.html',
  styleUrls: ['./collectibles.component.css']
})
export class CollectiblesComponent extends TableComponent<UpdatePayment>  {
  pageTitle = 'Payments';
  requestsArray = [];
  error: any;
  displayedColumns: string[] =
    [
      'id',
      'phoneNumber',
      'plateNumber',
      'civilCardNumber',
      'amount',
      'status',
      'paymentId',
      'paymentToken',
    ];
  selection = new SelectionModel<any>(true, []);

  constructor(private paymentService: PaymentService, private router: Router) {
    super(paymentService);
  }
}
