import { Component, OnInit} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TokenService } from 'src/services/token.service';
import { Helpers } from 'src/helpers/helpers';

@Component({
  selector: 'app-username-password-dialog',
  templateUrl: './username-password-dialog.component.html',
  styleUrls: ['./username-password-dialog.component.css']
})
export class UsernamePasswordDialogComponent implements OnInit {
  email: string;
  password: string;

  constructor(private dialogRef: MatDialogRef<UsernamePasswordDialogComponent>,
    private snackBar: MatSnackBar,
    private helpers: Helpers,
    protected tokenService: TokenService) { }

  ngOnInit() {
  }


  login(): void {

    this.tokenService.getTokenForPushNotification({ email: this.email, password: this.password }).subscribe(token => {
      this.helpers.setPushNotificationToken(token);
      this.dialogRef.close(true);
      // Hide self
    }, err => {
      // Show error
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right',
        duration: 2000
      });
    });
  }
}
