import { SelectionModel } from "@angular/cdk/collections";
import { Component } from "@angular/core";
import { RequestService } from "../../../services/request.service";
import { Router } from "@angular/router";
import { TableComponent } from "src/app/table.component";
import { ComprehensiveRequestsService } from "src/services/comprehensive-requests.service";
import { QuoteStatus } from "src/models/comprehensive-quote";
import { RateCard } from "src/models/rate-card";

export class Customer {
  id: number;
  customerName: string;
  userName: string;
  requestType: string;
  vehicleType: string;
  date: string;
  amount: string;
  status: string;
}

@Component({
  selector: "app-comprehensive-consumer-request",
  templateUrl: "./comprehensive-consumer-request.component.html",
  styleUrls: ["./comprehensive-consumer-request.component.css"],
})
export class ComprehensiveConsumerRequestComponent extends TableComponent<RateCard> {
  pageTitle = "Request";
  error: any;
  displayedColumns: string[] = [
    "id",
    "requestDate",
    "ownerName",
    "email",
    "phoneNumber",
    "carMake",
    "insurer",
    "type",
    "requestStatus",
    "comprehensive",
    "addOn",
    "amount",
    "paymentReference",
  ];
  selection = new SelectionModel<any>(true, []);

  constructor(
    requestService: ComprehensiveRequestsService,
    private router: Router
  ) {
    super(requestService);
  }

  openPurchaseDetail(rateCard: RateCard): void {
    if (
      rateCard.status == QuoteStatus.POLICY_PRINTED ||
      rateCard.status == QuoteStatus.DELIVERED ||
      rateCard.status == QuoteStatus.DISPATCHED
    ) {
      this.router.navigate(
        [
          "/comprehensive-consumer-request/policy-status/" +
            rateCard.rateCardId,
        ],
        {
          state: {
            rateCard: rateCard,
          },
        }
      );
    } else {
      this.router.navigate(
        ["/comprehensive-consumer-request/cr-detail/" + rateCard.rateCardId],
        {
          state: {
            rateCard: rateCard,
          },
        }
      );
    }
  }
}
