import { Component, OnInit } from "@angular/core";
import { RateCardType, RCValueType } from "src/models/comprehensive-quote";
import * as $ from "jquery";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { RateCardService } from "src/services/rate-card.service";
import { Helpers } from "src/helpers/helpers";
import { RateCard } from "src/models/rate-card";
import { AddLimitDialogComponent } from "src/app/add-limit-dialog/add-limit-dialog.component";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Parameter } from "src/models/parameter";
import { filter } from "rxjs/internal/operators/filter";

@Component({
  selector: "app-master-ratecard",
  templateUrl: "./master-ratecard.component.html",
  styleUrls: ["./master-ratecard.component.css"],
})
export class MasterRatecardComponent implements OnInit {
  addCategory() {
    throw new Error("Method not implemented.");
  }
  addAddOn() {
    throw new Error("Method not implemented.");
  }

  RateCardType = RateCardType;
  RCValueType = RCValueType;
  enableEdit: boolean = true;
  otherFees: any = {};
  //parameterMap:{[key:string]:Parameter } = {}

  masterRateCard: RateCard = new RateCard(0, {});
  defaultSupervisionFee: number;
  defaultIssueFee: number;
  defaultDeliveryFee: number;
  defaultConvenienceFee: number;
  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private rateCardService: RateCardService,
    private helper: Helpers
  ) {}

  ngOnInit(): void {
    this.fetchMasterRateCard();
  }

  fetchMasterRateCard(): void {
    $(".apiLoader").show();
    this.rateCardService.getMasterRateCard().subscribe(
      (rateCard) => {
        $(".apiLoader").hide();
        this.masterRateCard = new RateCard(rateCard.rateCardId, rateCard);
      },
      (error) => {
        $(".apiLoader").hide();
        this.snackBar.open(error, "CANCEL", {
          horizontalPosition: "right",
          duration: 2000,
        });
      }
    );
  }
  sort(x: any, y: any) {
    const aStepId = x.value.order ?? 0;
    const bStepId = y.value.order ?? 0;
    return aStepId > bStepId ? 1 : bStepId > aStepId ? -1 : 0;
  }

  setParameterValue(limitSublimit, e) {
    limitSublimit.valueForInsurer = e.target.value;
  }

  // addFeeLabel(feesOption: FeesOptionModel[]): void {
  //   if ( feesOption ) {
  //     const feesDialogtRef = this.dialog.open(AddLimitDialogComponent, { disableClose: true, data: {addFee: true} });
  //     feesDialogtRef.afterClosed().subscribe(result => {
  //       if (result.name && result.name != '') {
  //         feesOption.push( new FeesOptionModel(result.name, null, true) );
  //       }
  //     });
  //   }
  // }

  addSubLimit(parameter: Parameter, type: RateCardType): void {
    const addSubLimitRef = this.dialog.open(AddLimitDialogComponent, {
      disableClose: true,
      data: { isSubLimit: true, type: type },
    });
    addSubLimitRef.afterClosed().subscribe((result) => {
      if (result.name && result.name != "") {
        parameter.subParametersMap[result.name] = new Parameter({
          name: result.name,
          arabicName: result.arabicName,
          isEnabled: true,
          isMandatory: false,
        });
      }
    });

    // if ( limit && limit.subLimit ) {
    //   const addSubLimitRef = this.dialog.open(AddLimitDialogComponent, { disableClose: true, data: {isSubLimit: true, type: RateCardType.RATE_CARD} });
    //   addSubLimitRef.afterClosed().subscribe(result => {
    //     if (result.name && result.name != '') {
    //       limit.subLimit.push( new RateCardSubLimitModel(result.name, result.arabicName ,null, false, undefined,undefined ,true) );
    //     }
    //   });
    // }
  }

  addOnLimit(): void {
    const addSubLimitRef = this.dialog.open(AddLimitDialogComponent, {
      disableClose: true,
      data: { isSubLimit: true, type: RateCardType.ADD_ONS_LIMITS },
    });
    addSubLimitRef.afterClosed().subscribe((_) => {});
    // if (limit) {
    //   const addSubLimitRef = this.dialog.open(AddLimitDialogComponent, { disableClose: true, data: { isSubLimit: true, type: RateCardType.ADD_ONS_LIMITS } });
    //   addSubLimitRef.afterClosed().subscribe(result => {
    //     if (result.name && result.name != '') {
    //       limit.limits = new Array<limitModel>();
    //       limit.limits.push(new limitModel(result.name, result.arabicName, false, null, undefined, true));
    //     }
    //   });
    // }
  }

  addOnSubLimit(subParameter: Parameter): void {
    const addSubLimitRef = this.dialog.open(AddLimitDialogComponent, {
      disableClose: true,
      data: { isSubLimit: true, type: RateCardType.ADD_ONS_LIMITS_SUBLIMITS },
    });
    addSubLimitRef.afterClosed().subscribe((_) => {});

    // if (limit) {
    //   const addSubLimitRef = this.dialog.open(AddLimitDialogComponent, { disableClose: true, data: { isSubLimit: true, type: RateCardType.ADD_ONS_LIMITS_SUBLIMITS } });
    //   addSubLimitRef.afterClosed().subscribe(result => {
    //     if (result.name && result.name != '') {
    //       limit.addOnSublimits = new Array<addOnSublimitModel>();
    //       limit.addOnSublimits.push(new addOnSublimitModel(result.name, result.arabicName, false, null, true));
    //     }
    //   });
    // }
  }

  addLimit(): void {
    // if (limit) {
    const addLimitRef = this.dialog.open(AddLimitDialogComponent, {
      disableClose: true,
      data: { isSubLimit: false, type: RateCardType.RATE_CARD },
    });
    addLimitRef.afterClosed().subscribe((result) => {
      if (
        result.name &&
        result.name != "" &&
        result.arabicName &&
        result.arabicName != ""
      ) {
        const parameter: Parameter = {
          arabicName: result.arabicName,
          name: result.name,
          type: "Calculative",
          isEnabled: true,
          isMandatory: false,
          valueForInsurer: 0,
          order: Object.keys(this.masterRateCard.parametersMap).length - 1,
          translatedDescription: () => "",
          translatedName: () => "",
          // splice: function (i: any, arg1: number): void {
          //   throw new Error('Function not implemented.');
          // }
        };
        this.masterRateCard.parametersMap[result.name] = parameter;
        // if ( type == RateCardType.RATE_CARD ) {
        //   ( <RateCardLimitModel[]>limit ).push( new RateCardLimitModel(result.name, result.arabicName ,false, new Array<RateCardSubLimitModel>(), RCValueType.CALCULATIVE, undefined, undefined ,true));
        // } else if ( type == RateCardType.ADD_ONS ) {
        //   ( <AddOnsLimitModel[]>limit ).push( new AddOnsLimitModel(result.name, result.arabicName ,false, '', RCValueType.TEXT, undefined, undefined ,true) );
        // }
      }
    });
    // }
  }

  checkValidaton(eleId) {
    const ele = $("#" + eleId);
    setTimeout(() => {
      if (ele.hasClass("ng-invalid")) {
        ele.closest(".amount-box").addClass("error");
        ele.closest(".groupInput").addClass("error");
      } else {
        ele.closest(".amount-box").removeClass("error");
        ele.closest(".groupInput").removeClass("error");
      }
    }, 100);
  }

  updateRateCard(): void {
    $(".apiLoader").show();
    this.rateCardService.updateMasterRateCard(this.masterRateCard).subscribe(
      () => {
        $(".apiLoader").hide();
        this.snackBar.open("Master rate card successfully updated.", "CANCEL", {
          horizontalPosition: "right",
          duration: 2000,
        });
      },
      (error) => {
        $(".apiLoader").hide();
        this.snackBar.open(error, "CANCEL", {
          horizontalPosition: "right",
          duration: 2000,
        });
      }
    );
  }

  removePara(key: string) {
    delete this.masterRateCard.parametersMap[key];
    //this.masterRateCard.parametersMap[key] = this.masterRateCard.parametersMap.map(x=>x!==this.masterRateCard.parametersMap[key])

    // this.masterRateCard.parametersMap.subParametersMap[key]= undefined
  }
  removeSubPara(key: string) {
    delete this.masterRateCard.parametersMap[key].subParametersMap[key];
  }
}
