import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RateCardType, RCValueType } from 'src/models/comprehensive-quote';
import { RateCardService } from 'src/services/rate-card.service';
import * as $ from 'jquery';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { RateCard } from 'src/models/rate-card';
import { RateCardFee } from 'src/models/rate-card-fee';

@Component({
  selector: 'app-add-ratecard',
  templateUrl: './add-ratecard.component.html',
  styleUrls: ['./add-ratecard.component.css']
})
export class AddRatecardComponent implements OnInit {

  RCValueType = RCValueType;
  RateCardType = RateCardType;
  paramInsurerId: string;
  isAdd: boolean;
  fee: RateCardFee = {
    name: '',
    arabicName: '',
    amount: 0
  }

  carMake?:string
  carModel?:string
  purposeOfLicence?:string

  insurerRateCard?: RateCard;
  companyName: string;

  baseInfoTop = {
    bSaiyartiPer: "Saiyarti %",
    cInsurerPer: "Insurer %",
    dLimitedPer: "Limited Comprehensive Cover %",
  }

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private rateCardService: RateCardService,
    private snackBar: MatSnackBar) {
    this.companyName = this.router.getCurrentNavigation().extras ? this.router.getCurrentNavigation().extras.queryParams?.name || 'Insurer' : 'Insurer';
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(param => {
      this.paramInsurerId = param.get("id");
      this.fetchInsurerRateCard();
    }, error => { });
  }

  sort(x: any, y: any) {
    const aStepId = x.value.order ?? 0
    const bStepId = y.value.order ?? 0
    return aStepId > bStepId ? 1 : (bStepId > aStepId ? -1 : 0);
  }

  fetchInsurerRateCard(): void {

    if (this.paramInsurerId) {
      // Fetch insurer rate card.
      this.rateCardService.getRateCardByInsurerId(this.paramInsurerId).subscribe((insurerRateCard) => {
        if (insurerRateCard) {
          this.insurerRateCard = insurerRateCard
        } else {
          // Fetch master Rate card.
          this.rateCardService.getMasterRateCard().subscribe((masterRateCard) => {
            this.insurerRateCard = masterRateCard
            this.isAdd = true
          }, error => {
            this.snackBar.open(error, "CANCEL", {
              horizontalPosition: 'right',
              duration: 2000
            });
          });
        }
      }, error => {
        console.log('Error', error);
        this.snackBar.open(error, "CANCEL", {
          horizontalPosition: 'right',
          duration: 2000
        });
      });
    }
  }

  getParameterValue(subLimit) {
    return subLimit.valueForInsurer || 0;
  }

  getParameterValueForRateCard(parameter) {
    return parameter.valueForInsurer.toString() || "0";
  }
  setParameterValue($event, subLimit) {
    if ($event.target.classList[0] != "noSublimit") {
      subLimit.valueForInsurer = parseFloat($event.target.value) || 0;
    } else {
      subLimit.valueForInsurer = $event.target.value.toString() || "0";
    }
  }

  // getRateCardValue(rateCard: RateCardLimitModel): number {
  //   let retVal = 0;
  //   if (rateCard && rateCard.subLimit) {
  //     rateCard.subLimit.forEach((subLimit: RateCardSubLimitModel) => {
  //       if (subLimit.isSelected) {
  //         retVal += (+subLimit.valueForInsurer || 0);
  //       }
  //     });
  //   }
  //   return retVal;
  // }

  getRateCardParam(insurerRateCard, key): number | string {
    let retValue: number | string;

    if (insurerRateCard) {
      if (key == this.baseInfoTop.bSaiyartiPer) {
        retValue = (insurerRateCard.saiyartiPercentage || 0);
      } else if (key == this.baseInfoTop.cInsurerPer) {
        retValue = (insurerRateCard.insurerPercentage || 0);
      } else if (key == this.baseInfoTop.dLimitedPer) {
        retValue = (insurerRateCard.limitedComprehensiveCover || 0);
      }
    }

    return retValue;
  }


  setRateCardParam(event, insurerRateCard, key): void {
    if (insurerRateCard) {
      if (key == this.baseInfoTop.bSaiyartiPer) {
        insurerRateCard.saiyartiPercentage = parseFloat(event.target.value);
      } else if (key == this.baseInfoTop.cInsurerPer) {
        insurerRateCard.insurerPercentage = parseFloat(event.target.value);
      } else if (key == this.baseInfoTop.dLimitedPer) {
        insurerRateCard.limitedComprehensiveCover = parseFloat(event.target.value);
      }
    }
  }

  checkValidaton(eleId) {
    const ele = $('#' + eleId);
    setTimeout(() => {
      if (ele.hasClass('ng-invalid')) {
        ele.closest('.amount-box').addClass('error');
        ele.closest('.groupInput').addClass('error');

      } else {
        ele.closest('.amount-box').removeClass('error');
        ele.closest('.groupInput').removeClass('error');
      }
    }, 100);

  }

  addUpdateRateCard(): void {
    if (this.isAdd) {
      this.rateCardService.addRateCard(this.paramInsurerId, this.insurerRateCard).subscribe(addResponse => {
        this.isAdd = true;
        this.snackBar.open("Rate Card successfully created.", "CANCEL", {
          horizontalPosition: 'right',
          duration: 2000
        });
      }, error => {
        this.snackBar.open(error, "CANCEL", {
          horizontalPosition: 'right',
          duration: 2000
        });
      });
    } else {
      this.rateCardService.updateRateCardByInsurerId(this.insurerRateCard, this.insurerRateCard.insurerId).subscribe(editResponse => {
        this.snackBar.open("Rate Card successfully updated.", "CANCEL", {
          horizontalPosition: 'right',
          duration: 2000
        });
      }, error => {
        this.snackBar.open(error, "CANCEL", {
          horizontalPosition: 'right',
          duration: 2000
        });
      });
    }
  }

  addFee() {
    this.insurerRateCard.fees.push(this.fee);
    this.fee = {
      name: '',
      arabicName: '',
      amount: 0
    }
    this.addUpdateRateCard()
  }

  removeFee(fee) {
    this.insurerRateCard.fees = this.insurerRateCard.fees.filter(x => x.name !== fee.name);
    this.addUpdateRateCard()
  }

  addCarMake(){
    if(this.carMake && this.carMake.trim().length >0){
    this.insurerRateCard.carMake.push(this.carMake);
    this.addUpdateRateCard()
    this.carMake=undefined
  }
  }
  removeCarMake(carMake) {
    this.insurerRateCard.carMake = this.insurerRateCard.carMake.filter(x => x !== carMake);
    this.addUpdateRateCard()
  }
  addCarModel(){
    if(this.carModel && this.carModel.trim().length >0){
    this.insurerRateCard.carModel.push(this.carModel);
    
    // this.insurerRateCard.carModel.push(this.carmodel);
    
    this.addUpdateRateCard()
    this.carModel=undefined
  }
  }
  removeCarModel(carModel) {
    this.insurerRateCard.carModel = this.insurerRateCard.carModel.filter(x => x !== carModel);
    this.addUpdateRateCard()
  }

  addPurposeOfLicence(){
    if(this.purposeOfLicence && this.purposeOfLicence.trim().length >0){
    this.insurerRateCard.purposeOfLicence.push(this.purposeOfLicence);
    this.addUpdateRateCard()
    this.purposeOfLicence=undefined
  }
  }
  removePurposeOfLicence(purposeOfLicence) {
    this.insurerRateCard.purposeOfLicence = this.insurerRateCard.purposeOfLicence.filter(x => x !== purposeOfLicence);
    this.addUpdateRateCard()
  }
}