import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SafrattiTravelPlan } from "src/models/safratti-travel-plan";
import { SafrattiTravelPlanService } from "src/services/safratti-travel-plan.service";
import { SafrattiDataListRows } from "src/models/safratti-data-list-rows";
import { SafrattiFeeService } from "src/services/safratti-fee.service";
import { SafrattiFee } from "src/models/safratti-fee";
import { CompanyService } from "src/services/company.service";
import { Company } from "src/models/company";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { CreateTravelPlanDto } from "src/dto/request/create-travel-plan-dto";
import { ActivatedRoute } from "@angular/router";
import { UpdateTravelPlan } from "src/models/update-travel-plan";
import { take } from "rxjs/operators";

@Component({
  selector: "app-safaratti-travel-plan-add",
  templateUrl: "./safaratti-travel-plan-add.component.html",
  styleUrls: ["./safaratti-travel-plan-add.component.css"],
})
export class SafarattiTravelPlanAddComponent implements OnInit {
  pageTitle = "Travel Plan Users";
  isAddTravelPlan = true;
  id: any;
  travelData: SafrattiTravelPlan = new SafrattiTravelPlan();
  travelPlans = new FormControl("");

  travelDataListRows: SafrattiDataListRows[] = [];
  safrattiFee: SafrattiFee;
  safrattiFees: SafrattiFee[] = [];

  companies: Company[] = [];

  safrattiTravelPlanForm = new FormGroup({
    travelPlanName: new FormControl<string>(undefined, Validators.required),
    code: new FormControl<string>(undefined, Validators.required),
    countryOfResidence: new FormControl<any>(undefined, Validators.required),
    coverageZone: new FormControl<any>([], Validators.required),
    destinationCountry: new FormControl<any>([], Validators.required),
    insuranceCompany: new FormControl<Company>(undefined, Validators.required),
    feeStructure: new FormControl<SafrattiFee[]>([], Validators.required),
    shouldSendInvoice: new FormControl<boolean>(false),
  });

  constructor(
    private location: Location,
    private _snackBar: MatSnackBar,
    private _safrattiTravelPlanService: SafrattiTravelPlanService,
    private _safrattiFeeService: SafrattiFeeService,
    private _companyService: CompanyService,
    private activatedRoute: ActivatedRoute
  ) {}
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.id = +params["id"];
      this.isAddTravelPlan = !(this.id > 0);
    });

    this._companyService.getCompnayList("Safratti").subscribe((x) => {
      this.companies = x;
      this._safrattiFeeService.getSafrattiFees().subscribe((fees) => {
        this.safrattiFees = fees;
        this._safrattiTravelPlanService
          .getDataListRows(this.travelDataListRows)
          .subscribe((res) => {
            this.travelDataListRows = res;
            if (!this.isAddTravelPlan) {
              this.fetchTravelPlan(this.id);
            }
          });
      });
    });
  }

  fetchTravelPlan(id: any) {
    this._safrattiTravelPlanService.fetchTravelPlan(id).subscribe({
      next: (safrattiTravelPlanData: UpdateTravelPlan) => {
        // Set default values for the form based on fetched data
        this.safrattiTravelPlanForm.patchValue(safrattiTravelPlanData);

        const countryValues = this.travelDataListRows[0].data.filter(
          (x) => +x.id === safrattiTravelPlanData.gtsCountry.id
        );
        if (countryValues.length > 0) {
          this.safrattiTravelPlanForm
            .get("countryOfResidence")
            .patchValue(countryValues[0]);
        }

        const gtsAreaOfTravelIds = safrattiTravelPlanData.gtsAreaOfTravels.map(
          (x) => x.id
        );
        const gtsAreaOfTravels = this.travelDataListRows[2].data.filter(
          (x) => gtsAreaOfTravelIds.indexOf(+x.id) >= 0
        );
        if (gtsAreaOfTravels.length > 0) {
          this.safrattiTravelPlanForm
            .get("coverageZone")
            .patchValue(gtsAreaOfTravels);
        }

        const gtsDestinationCountryIds =
          safrattiTravelPlanData.gtsDestinationCountrys.map((x) => x.id);
        const gtsDestinationCountrys = this.travelDataListRows[6].data.filter(
          (x) => gtsDestinationCountryIds.indexOf(+x.id) >= 0
        );
        if (gtsDestinationCountrys.length > 0) {
          this.safrattiTravelPlanForm
            .get("destinationCountry")
            .patchValue(gtsDestinationCountrys);
        }

        const safrattiFeeIds = safrattiTravelPlanData.safrattiFees.map(
          (x) => x.id
        );
        const safrattiFees = this.safrattiFees.filter(
          (x) => safrattiFeeIds.indexOf(+x.id) >= 0
        );
        if (safrattiFees.length > 0) {
          this.safrattiTravelPlanForm
            .get("feeStructure")
            .patchValue(safrattiFees);
        }

        const insuranceCompanys = this.companies.filter(
          (x) => +x.id === safrattiTravelPlanData.company.id
        );
        if (insuranceCompanys.length > 0) {
          this.safrattiTravelPlanForm
            .get("insuranceCompany")
            .patchValue(insuranceCompanys[0]);
        }
      },
    });
  }

  addTravelPlan() {
    const name = this.safrattiTravelPlanForm.get("travelPlanName").value;
    const code = this.safrattiTravelPlanForm.get("code").value;
    const gtsCountryId =
      this.safrattiTravelPlanForm.get("countryOfResidence").value.id;

    const gtsAreaOfTravelIds = this.safrattiTravelPlanForm
      .get("coverageZone")
      .value.map((x) => x.id);
    const gtsDestinationCountryIds = this.safrattiTravelPlanForm
      .get("destinationCountry")
      .value.map((x) => x.id);

    const companyId =
      this.safrattiTravelPlanForm.get("insuranceCompany").value.id;
    const safrattiFeeIds = this.safrattiTravelPlanForm
      .get("feeStructure")
      .value.map((x) => x.id);

    const shouldSendInvoice = this.safrattiTravelPlanForm.get("shouldSendInvoice").value;  

    const travelPlan: CreateTravelPlanDto = {
      name,
      code,
      gtsCountryId,
      gtsAreaOfTravelIds,
      gtsDestinationCountryIds,
      safrattiFeeIds,
      companyId,
      shouldSendInvoice,
      status: true,
    };
    this._safrattiTravelPlanService
      .createTravelPlans(travelPlan)
      .subscribe((x) => {
        this._snackBar.open("Travel Plan Created Successfully.");
        this.back();
      });
  }

  updateTravelPlan() {
    const name = this.safrattiTravelPlanForm.get("travelPlanName").value;
    const code = this.safrattiTravelPlanForm.get("code").value;
    const gtsCountry =
      this.safrattiTravelPlanForm.get("countryOfResidence").value;

    const gtsAreaOfTravels =
      this.safrattiTravelPlanForm.get("coverageZone").value;
    const gtsDestinationCountrys =
      this.safrattiTravelPlanForm.get("destinationCountry").value;
    const company = this.safrattiTravelPlanForm.get("insuranceCompany").value;
    const safrattiFees = this.safrattiTravelPlanForm.get("feeStructure").value;
    const shouldSendInvoice = this.safrattiTravelPlanForm.get("shouldSendInvoice").value;  

    const updateTravel: UpdateTravelPlan = {
      name,
      code,
      gtsCountry,
      gtsAreaOfTravels,
      gtsDestinationCountrys,
      safrattiFees,
      company,
      shouldSendInvoice,
      status: true,
    };

    this._safrattiTravelPlanService
      .updateTravelPlan(this.id, updateTravel)
      .pipe(take(1))
      .subscribe((_) => {
        this._snackBar.open("Travel Plan Updated Successfully.");
        this.back();
      });
  }

  back() {
    this.location.back();
  }
}
