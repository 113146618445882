
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';

import { environment } from '../environments/environment';

import { BaseService } from './base.service';

import { Helpers } from '../helpers/helpers';


@Injectable()

export class DashboardService extends BaseService {

  private pathAPI = environment.apiUrl;

  public errorMessage: string;

  constructor(private http: HttpClient, helper: Helpers) { super(helper); }

  getOverviewCount(days: number): Observable<any> {

    return this.http.get<any>(this.pathAPI + 'Dashboard/GetCountOverview?days=' + days, super.header()).pipe(

      catchError(super.handleError)

    );
  }

  getComprehensiveOverviewCount(days: number): Observable<any> {

    return this.http.get<any>(this.pathAPI + 'Dashboard/GetComprehensiveCountOverview?days=' + days, super.header()).pipe(

      catchError(super.handleError)

    );
  }

  getSafrattiOverviewCount(days: number): Observable<any>{
    return this.http.get<any>(this.pathAPI + 'Dashboard/GetCountOverviewForSafratti?days=' + days, super.header()).pipe(

      catchError(super.handleError)

    );
  }

  getReferrals(type:string): Observable<any> {
    return this.http.get<any>(this.pathAPI + 'Dashboard/GetReferrals?Type='+ type, super.header()).pipe(

      catchError(super.handleError)

    );
  }
}
