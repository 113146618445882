import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';


@Component({
  selector: 'app-confirm-reason-dialog',
  templateUrl: './confirm-reason-dialog.component.html',
  styleUrls: ['./confirm-reason-dialog.component.css']
})
export class ConfirmReasonDialogComponent implements OnInit {
  reasons = ['Paper damaged', 'Incorrect value', 'Date change', 'Incorrect Printing'];
  reason = 'Paper damaged';
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {

  }

}
