import { SelectionModel } from '@angular/cdk/collections';
import { Component } from '@angular/core';
import { RequestService } from '../../../services/request.service';
import { Router } from '@angular/router';
import { TableComponent } from 'src/app/table.component';


export class Customer {
  id: number;
  customerName: string;
  userName: string;
  requestType: string;
  vehicleType: string;
  date: string;
  amount: string;
  status: string;

}

@Component({
  selector: 'app-consumer-request',
  templateUrl: './consumer-request.component.html',
  styleUrls: ['./consumer-request.component.css']
})
export class ConsumerRequestComponent extends TableComponent<Request> {
  pageTitle = 'Request';
  requestsArray = [];
  error: any;
  displayedColumns: string[] =
    [
      'id',
      'customerName',
      'policyType',
      'requestType',
      'vehicleType',
      'date',
      'status',
      'basePremium',
      'totalFee',
      'totalPremium',
      'netAmount',
      'paymentReference',
      'refund',
      'collection',
      'notes'
    ];
  selection = new SelectionModel<any>(true, []);

  constructor(private requestService: RequestService, private router: Router) {
    super(requestService);
  }

  openPurchaseDetail(row): void {
    if (row.status === 'PAYMENT_PENDING') {
      // Do nothing
    } else {
      this.requestService.getPurchaseDetail(row.id).subscribe((purchaseDetail: any) => {

        if (purchaseDetail.status === 'PENDING' ||
          purchaseDetail.status === 'REQUESTRESENT') {
          this.router.navigate(['/consumer-request/purchase-detail/' + row.id]);
        } else if (purchaseDetail.status === 'PAYMENT_PENDING') {
          // Do nothing
        } else {
          this.router.navigate(['/consumer-request/policy-status/' + row.id]);
        }
      });
    }
  }


}


