import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { TableComponent } from 'src/app/table.component';
import { ComprehensiveOrderService } from 'src/services/comprehensive-order.service';

@Component({
  selector: 'app-comprehensive-order',
  templateUrl: './comprehensive-order.component.html',
  styleUrls: ['./comprehensive-order.component.css']
})
export class ComprehensiveOrderComponent extends TableComponent<Request> {
  pageTitle = 'Orders';
  displayedColumns: string[] = [
    'id',
    'issueDate',
    'orderDate',
    'ownerName',
    'type',
    'plateNo',
    'insurer',
    'policyNumber',
    'addOn',
    'amount',
    'paymentToken',
    'status'
  ];

  selection = new SelectionModel<any>(true, []);

  constructor(orderService: ComprehensiveOrderService, private router: Router) {
    super(orderService);
  }

  openOrder(data: any) {
    this.router.navigate(['/comprehensive-orders/' + data.rateCardId]);
  }
}
