import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from 'src/services/storage.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { ComprehensiveOrderService } from 'src/services/comprehensive-order.service';
import { ComprehensiveRequestsService } from 'src/services/comprehensive-requests.service';
import { Lightbox } from 'ngx-lightbox';
import { RateCard } from 'src/models/rate-card';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-comprehensive-order-detail',
  templateUrl: './comprehensive-order-detail.component.html',
  styleUrls: ['./comprehensive-order-detail.component.css']
})
export class ComprehensiveOrderDetailComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
    },
    nav: false,
  };

  carReadToken: any;
  carImagesList: any;
  constructor(private lightbox: Lightbox, private route: ActivatedRoute, private _quoteService: ComprehensiveRequestsService, private requestService: ComprehensiveRequestsService,
    private storageService: StorageService, private sanitizer: DomSanitizer) { }

  public slideIndex = 1;
  order?: RateCard;
  policyDocumentUrl;
  moroorDocumentUrl;
  invoiceDocumentUrl;

  carImageUrl: any;
  rcBookImageUrl: any;
  civilFrontImageUrl: any;
  civilBackImageUrl: any;
  docContainerName: string = 'comprehensive-policy-book';

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.getOrder(id);
  }

  setCarImages(carImagesList): void {
    let tempCarImages: any = [];
    for (var carImage of carImagesList) {
      let image: any = {};
      let carImageIndex = +(carImage.name.split("_")[1]);
      let carImageUrl = environment.cdnUrl + '/comprehensive-car-book/' + carImage.name + this.carReadToken;
      image.src = carImageUrl;
      image.caption = carImage.name;
      image.carIndex = carImageIndex;

      tempCarImages.push(image);
    }
    // if ( carImagesList.length < 4 ) {
    //   let image: any = {};
    //   image.src = null;
    //   image.caption = 'Add car image';
    //   image.carIndex = carImagesList.length + 1;

    //   tempCarImages.push(image);
    // }

    this.carImagesList = Object.assign([], tempCarImages);
  }

  openCarImage(car): void {
    const src = car.src;
    const caption = car.caption;
    const thumb = car.src;
    const album = {
      src,
      caption,
      thumb
    };
    this.lightbox.open([album], 0);
  }

  getOrder(id: any) {
    this._quoteService.getQuoteRequestByVehicleId(id).subscribe(res => {
      // console.log(res);
      this.order = res;
      this.loadDocuments();
      this.loadImages();
      this.onCarImages();
    }, err => {
      console.log(err);
    });
  }

  loadDocuments(): void {
    this.storageService.getSASToken(this.route.snapshot.paramMap.get('id') + '_policy', this.docContainerName).subscribe((token: any) => {
      const encodedSasToken = encodeURIComponent(token.sasToken);
      let url = environment.cdnUrl + '/' + this.docContainerName + '/'
        + this.route.snapshot.paramMap.get('id') + '_policy' + encodedSasToken;
      this.policyDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });

    this.storageService.getSASToken(this.route.snapshot.paramMap.get('id')
      + '_moroor', this.docContainerName).subscribe((token: any) => {
        const encodedSasToken = encodeURIComponent(token.sasToken);
        let url = environment.cdnUrl + '/' + this.docContainerName + '/'
          + this.route.snapshot.paramMap.get('id') + '_moroor' + encodedSasToken;
        this.moroorDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      });

    this.storageService.getSASToken(this.route.snapshot.paramMap.get('id') + '_invoice', this.docContainerName).subscribe((token: any) => {
      const encodedSasToken = encodeURIComponent(token.sasToken);
      let url = environment.cdnUrl + '/' + this.docContainerName + '/'
        + this.route.snapshot.paramMap.get('id') + '_invoice' + encodedSasToken;
      this.invoiceDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });
  }

  loadImages() {
    const rcBook = this.order.vehicleId;
    const civilFront = this.order.vehicleId + '_front';
    const civilBack = this.order.vehicleId + '_back';


    this.storageService.getSASToken(rcBook.toString(), 'comprehensive-rc-book').subscribe((token: any) => {
      this.rcBookImageUrl = environment.cdnUrl + '/comprehensive-rc-book/' + rcBook + token.sasToken;
    });

    this.storageService.getSASToken(civilFront, 'comprehensive-civilcard-book').subscribe((token: any) => {
      this.civilFrontImageUrl = environment.cdnUrl + '/comprehensive-civilcard-book/' + civilFront + token.sasToken;
    });

    this.storageService.getSASToken(civilBack, 'comprehensive-civilcard-book').subscribe((token: any) => {
      this.civilBackImageUrl = environment.cdnUrl + '/comprehensive-civilcard-book/' + civilBack + token.sasToken;
    });
  }

  onCarImages() {
    this.storageService.getSASTokenForContainer("comprehensive-car-book").subscribe(carReadToken => {
      this.carReadToken = carReadToken.sasToken;
      this.requestService.getCarImagesByVehicleId(this.order.vehicleId).subscribe(carImagesList => {
        this.setCarImages(carImagesList);
      });
    });
  }

  public onSlide(slideData) {
    console.log('SLIDE:', slideData);
    if (slideData.direction === 'left') {
      if (this.slideIndex < 3) {
        this.slideIndex++;
      } else {
        this.slideIndex = 1;
      }
    } else if (slideData.direction === 'right') {
      if (this.slideIndex > 1) {
        this.slideIndex--;
      } else {
        this.slideIndex = 3;
      }
    }

    switch (this.slideIndex) {

      case 1: {
        if (this.policyDocumentUrl === undefined) {
          this.storageService.getSASToken(this.route.snapshot.paramMap.get('id') + '_policy', this.docContainerName).subscribe((token: any) => {
            const encodedSasToken = encodeURIComponent(token.sasToken);
            let url = environment.cdnUrl + '/' + this.docContainerName + '/'
              + this.route.snapshot.paramMap.get('id') + '_policy' + encodedSasToken;
            this.policyDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
          });
        }
        break;
      }

      case 2: {
        if (this.moroorDocumentUrl === undefined) {
          this.storageService.getSASToken(this.route.snapshot.paramMap.get('id')
            + '_moroor', this.docContainerName).subscribe((token: any) => {
              const encodedSasToken = encodeURIComponent(token.sasToken);
              let url = environment.cdnUrl + '/' + this.docContainerName + '/'
                + this.route.snapshot.paramMap.get('id') + '_moroor' + encodedSasToken;
              this.moroorDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
            });
        }
        break;
      }


      case 3: {
        if (this.invoiceDocumentUrl === undefined) {
          this.storageService.getSASToken(this.route.snapshot.paramMap.get('id') + '_invoice', this.docContainerName).subscribe((token: any) => {
            const encodedSasToken = encodeURIComponent(token.sasToken);
            let url = environment.cdnUrl + '/' + this.docContainerName + '/'
              + this.route.snapshot.paramMap.get('id') + '_invoice' + encodedSasToken;
            this.invoiceDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
          });
        }
      }
    }
    // your JS here
  }
}
