import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';

import { Router } from '@angular/router';

import { RequestService } from '../../../services/request.service';

import { Location } from '@angular/common';


import { StorageService } from 'src/services/storage.service';

import { Lightbox } from 'ngx-lightbox';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TplService } from 'src/services/tpl.service';

import { environment } from '../../../environments/environment';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-purchase-detail',
  templateUrl: './purchase-detail.component.html',
  styleUrls: ['./purchase-detail.component.css']
})
export class PurchaseDetailComponent implements OnInit {

  tplStatus = 0;
  tplOwner: string;
  pageTitle = 'TPL Request';
  albums: any = [];
  currentYear: number = (new Date()).getFullYear();
  minDate: Date;
  feeData: any;
  purposeOfLicenseList =
    ['حصوصي', 'تاكسي', 'ونيت ياباني', 'ونيت أمريكي', 'نصف شاحنة', 'تنكر مياة', 'تريللا', 'رافعة شوكية', 'خلاط', 'اسعاف', 'دراجة نارية', 'كرين', 'باص خاص'];

  purposeOfLicenseListEn =
    ['Private', 'Taxi', 'Japanese pickup', 'American pickup', 'Half lorry', 'Water tank', 'Tipper', 'Fork cliff', 'Mixer', 'Ambulance', 'Motorcycle', 'Crane', 'Bus'];
  passangerList = [];

  
  constructor(private lightbox: Lightbox, private route: ActivatedRoute,
    private router: Router, private storageService: StorageService, private requestService: RequestService,
    private location: Location, private snackBar: MatSnackBar,
    private tplService: TplService, private dialogRef: MatDialogRef<PurchaseDetailComponent>

  ) {
    this.minDate = new Date();
  }

  purchaseDetail: any;
  error: any;
  isCivilIDImageClear = 'true';
  isRCImageClear = 'true';
  isCivilNumberMatch = 'true';
  thumbRCBookImageUrl: any;
  thumbCivilFrontImageUrl: any;
  thumbCivilBackImageUrl: any;
  civilFront: string;
  civilBack: string;
  rcBook: string;
  selectedDepartment:any;
  account:any;
  civilFrontFile: any;
  civilBackFile:any;
  rcBookFile:any;


  ngOnInit() {
    this.getTplFee();
  }



  getPurchaseDetail(): void {
    const id = +this.route.snapshot.paramMap.get('id');

    this.requestService.getPurchaseDetail(id).subscribe((purchaseDetail: any) => {
      this.isCivilIDImageClear = purchaseDetail.civilIdClarity ? 'true' : 'false';
      this.isRCImageClear = purchaseDetail.rcBookClarity ? 'true' : 'false';
      this.isCivilNumberMatch = purchaseDetail.rcBookCivilMatch ? 'true' : 'false';
      this.account = purchaseDetail

      if (purchaseDetail === undefined || purchaseDetail.tplStatus === 'REQUESTRESENT') {
        this.router.navigate(['/dashboard']);
      }

      if (purchaseDetail.rcBook.vehicleStatus !== 'جديد' && purchaseDetail.rcBook.vehicleStatus !== 'مُستعمل') {
        purchaseDetail.rcBook.vehicleStatus = 'جديد';
      }

      if (purchaseDetail.rcBook.fuelType !== 'الوقود' && purchaseDetail.rcBook.fuelType !== 'ديزل (وقود)') {
        purchaseDetail.rcBook.fuelType = 'الوقود';
      }

      console.log('Purpose of License');
      console.log(purchaseDetail.rcBook.purposeOfLicense);

      let index = this.purposeOfLicenseList.indexOf(purchaseDetail.rcBook.purposeOfLicense);
      if (index === -1) {
        console.log('Index AR');
        console.log(index);
        index = this.purposeOfLicenseListEn.indexOf(purchaseDetail.rcBook.purposeOfLicense);
        if (index === -1) {
          console.log('Index EN');
          console.log(index);
          index = 0;
        }
      }
      console.log(index);
      purchaseDetail.rcBook.purposeOfLicense = this.purposeOfLicenseList[index];

      this.purchaseDetail = purchaseDetail;
      this.purchaseDetail.civilCard.expiry = new Date(purchaseDetail.civilCard.expiry);
      this.purchaseDetail.rcBook.expiryDate = new Date(purchaseDetail.rcBook.expiryDate);

      this.civilFront = purchaseDetail.civilCard.id + '_front.jpg';
      this.civilBack = purchaseDetail.civilCard.id + '_back.jpg';
      this.rcBook = purchaseDetail.rcBook.id + '.jpg';

      this.storageService.getSASToken(this.civilFront, 'civil-card').subscribe((token: any) => {
          const civilFrontImageUrl = environment.cdnUrl + '/civil-card/' + this.civilFront + token.sasToken;
          this.thumbCivilFrontImageUrl = civilFrontImageUrl
          const src = civilFrontImageUrl;
          const caption = 'Civil Card Front';
          const thumb = this.thumbCivilFrontImageUrl;
          const album = {
            src,
            caption,
            thumb
          };
          this.albums[0] = album;
      });

      this.storageService.getSASToken(this.civilBack, 'civil-card').subscribe((token: any) => {
          const civilBackImageUrl = environment.cdnUrl + '/civil-card/' + this.civilBack + token.sasToken;
          this.thumbCivilBackImageUrl = civilBackImageUrl
          const src = civilBackImageUrl;
          const caption = 'Civil Card Back';
          const thumb = this.thumbCivilBackImageUrl;
          const album = {
            src,
            caption,
            thumb
          };
          this.albums[1] = album;
      });

      this.storageService.getSASToken(this.rcBook, 'rc-book').subscribe((token: any) => {
        const rcBookImageUrl = environment.cdnUrl + '/rc-book/' + this.rcBook + token.sasToken;
         this.thumbRCBookImageUrl = rcBookImageUrl
          const src = rcBookImageUrl;
          const caption = 'RC Book';
          const thumb = src;
          const album = {
            src,
            caption,
            thumb
          };
          this.albums[2] = album;
          
      });
    }, err => {
      this.snackBar.open(err, 'CANCLE', {
        horizontalPosition: 'right'
      });
      // this.error = err
    });
  }

  open(index: number): void {
    this.lightbox.open(this.albums, index);
  }

  goBack(): void {
    this.location.back();
  }

  sendRequest(): void {
    if (this.isCivilIDImageClear === undefined || this.isRCImageClear === undefined || this.isCivilNumberMatch === undefined) {
      this.error = 'Please select an option';
      this.snackBar.open(this.error, 'CANCEL', {
        horizontalPosition: 'right',
      });
      return;
    }

    if (this.isCivilIDImageClear === 'true' && this.isRCImageClear === 'true' && this.isCivilNumberMatch === 'true') {
      this.error = 'Select atleast one reason to send a request';
      this.snackBar.open(this.error, 'CANCEL', {
        horizontalPosition: 'right',
      });
      return;
    }

    const body: any = {};
    body.isCivilIDImageClear = this.isCivilIDImageClear === 'true';
    body.isRCBookImageClear = this.isRCImageClear === 'true';
    body.isCivilNumberMatch = this.isCivilNumberMatch === 'true';
    this.requestService.sendRequestToCustomer(this.purchaseDetail.id, body).subscribe(() => {

      this.goBack();

    }, err => {
      this.error = err;
      this.snackBar.open(this.error, 'CANCEL', {
        horizontalPosition: 'right',
      });
    });
  }


  updatePurchaseDetail() {

    this.purchaseDetail.notes = undefined;
    this.purchaseDetail.refund = undefined;
    this.purchaseDetail.collection = undefined;

    this.requestService.updatePurchaseDetail(this.purchaseDetail.id, this.purchaseDetail).subscribe(() => {
      this.snackBar.open('Details are updated successfully.', 'OK', {
        horizontalPosition: 'center',
        duration: 2000
      });
      this.router.navigate(['/consumer-request/policy-info/' + this.purchaseDetail.id]);

    }, err => {
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right'
      });
    });
  }


  getTplFee(): any {

    return this.tplService.getFees().subscribe(res => {
      this.feeData = res;

      this.getPurchaseDetail()

    }, () => { });
  }

  getPessangerList() {

    this.passangerList = [];
    if (this.purchaseDetail.rcBook !== undefined) {
      const index = this.purposeOfLicenseList.indexOf(this.purchaseDetail.rcBook.purposeOfLicense);
      // console.log(this.purchaseDetail.rcBook.purposeOfLicense + ", index=>  " + index);
      // this.purchaseDetail.rcBook.passengers = 0;
      if (index < 6) {
        this.feeData.forEach(fee => {
          if (fee.vehicleType === index) {
            this.passangerList.push(fee.passengers);
          }
        });
        this.passangerList = Array.from(new Set(this.passangerList));
        console.log(this.passangerList);
      }
    }
  }

  uploadDoc(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    const d = new Date();
    if (file !== undefined) {
      if (event.srcElement.id === 'civilCardFront' || event.srcElement.id === 'civilCardBack') {
        if (event.srcElement.id === 'civilCardFront') {
          this.civilFrontFile = file
          this.storageService
          .getUploadOnlyTokenForContainer("civil-card")
          .subscribe((token) => {
            this.storageService
              .uploadFileToAzure(
                `/civil-card/${
                  this.account.civilCard.id +
                  "_front.jpg"
                }${token.sasToken}`,
                this.civilFrontFile,
                "image/jpg"
              )
              .subscribe({
                next: (res) => {
                  this.dialogRef.close();
                  this.snackBar.open(
                    "The file has been uploaded. An email will be sent to your account as soon as processed.",
                    "CANCEL",
                    {
                      horizontalPosition: "right",
                      duration: 4000,
                    }
                  );
                },
              });
          });
          reader.onload = (e: any) => {
            this.thumbCivilFrontImageUrl = e.target.result;
          };
        } else {
          this.storageService
          .getUploadOnlyTokenForContainer("civil-card")
          .subscribe((token) => {
            this.civilBackFile = file
            this.storageService
              .uploadFileToAzure(
                `/civil-card/${
                  this.account.civilCard.id +
                  "_back.jpg"
                }${token.sasToken}`,
                this.civilBackFile,
                "image/jpg"
              )
              .subscribe({
                next: (res) => {
                  this.dialogRef.close();
                  this.snackBar.open(
                    "The file has been uploaded. An email will be sent to your account as soon as processed.",
                    "CANCEL",
                    {
                      horizontalPosition: "right",
                      duration: 4000,
                    }
                  );
                },
              });
          });
          reader.onload = (e: any) => {
            this.thumbCivilBackImageUrl = e.target.result;
          };

        }
      } else if (event.srcElement.id === 'rcBook') {
        const d = new Date();
        this.rcBookFile = file
        this.storageService
          .getUploadOnlyTokenForContainer("rc-book")
          .subscribe((token) => {
            this.storageService
              .uploadFileToAzure(
                `/rc-book/${
                  this.account.rcBook.id +
                  ".jpg"
                }${token.sasToken}`,
                this.rcBookFile,
                "image/jpg"
              )
              .subscribe({
                next: (res) => {
                  this.dialogRef.close();
                  this.snackBar.open(
                    "The file has been uploaded. An email will be sent to your account as soon as processed.",
                    "CANCEL",
                    {
                      horizontalPosition: "right",
                      duration: 4000,
                    }
                  );
                },
              });
          });
          reader.onload = (e: any) => {
            this.thumbRCBookImageUrl = e.target.result;
          };
      }
      reader.readAsDataURL(file);
     
    }
  }

}
