
import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';



import { catchError } from 'rxjs/operators';

import { environment } from '../environments/environment';

import { BaseService } from './base.service';

import { Helpers } from '../helpers/helpers';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

export abstract class ReportService extends BaseService {

  protected pathAPI = environment.apiUrl;

  public errorMessage: string;

  constructor(protected http: HttpClient, helper: Helpers, protected snackBar: MatSnackBar) { super(helper); }

  downloadReport(reportName: string, body: any) {
    const headers = super.header().headers;
    return this.http.post(this.pathAPI + 'reports/' + reportName, body, { headers, observe: 'response', responseType: 'blob' })
      .pipe(catchError(super.handleError))
      .subscribe(response => {
        this.downLoadFile(response);
      }, err => {
        this.snackBar.open(err, 'CANCEL', {
          horizontalPosition: 'right',
          duration: 2000
        });
      });
  }
  downloadSafrattiReport(reportName: string, body: any) {
    const headers = super.header().headers;
    return this.http.post(this.pathAPI + 'Reports/' + reportName, body, { headers, observe: 'response', responseType: 'blob' })
      .pipe(catchError(super.handleError))
      .subscribe(response => {
        this.downLoadFile(response);
      }, err => {
        this.snackBar.open(err, 'CANCEL', {
          horizontalPosition: 'right',
          duration: 2000
        });
      });
  }

  downLoadFile(response: any) {

    const contentType: string = response.headers.get('Content-Type');
    const contentDisposition = response.headers.get('Content-Disposition');
    const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();

    const blob = new Blob([response.body], { type: contentType });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.download = fileName;
    anchor.href = url;
    anchor.click();
  }
}
