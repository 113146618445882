import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { TableComponent } from 'src/app/table.component';
import { PoliciesService } from 'src/services/policies.service';
import { Policy } from 'src/models/policy';
import { Router } from '@angular/router';
import { Helpers } from 'src/helpers/helpers';
import jwt_decode from 'jwt-decode';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmReasonDialogComponent } from '../confirm-reason-dialog/confirm-reason-dialog.component';
import { B2BBulkUploadDialogComponent } from '../b2b-bulk-upload-dialog/b2b-bulk-upload-dialog.component';
import { VehicleAccordion, VehiclePolicy } from 'src/models/vehicle-accordion';

@Component({
  selector: 'app-policies',
  templateUrl: './policies.component.html',
  styleUrls: ['./policies.component.css']
})
export class PoliciesComponent extends TableComponent<Policy> implements OnInit {

  pageTitle = 'Policies';
  requestsArray = [];
  error: any;
  counts: any = {
    totalRequests: 0,
    supervisionFee: 0,
    issueFee: 0,
    premiumFee: 0,
    totalFee: 0,
    totalRejectedPrinted: 0,
    totalApprovedPrinted: 0,
    newPolicies: 0,
    renewals: 0,
    totalPending: 0
  };
  name: string;
  displayedColumns: string[] =
    [
      'id',
      'customerName',
      'carMake',
      'carModel',
      'plateNumber',
      'groupName',
      'departmentName',
      'policyNumber',
      'requestType',
      'date',
      'issueDate',
      'tag',
      'status',
      'policyStatus',
      'printedDate',
      'printedBy',
      'reason',
      'action'
    ];
  selection = new SelectionModel<any>(true, []);
  obj: any;
  vehicleAccordionArray: Array<VehicleAccordion> = [];
  accordionTableColumns: string[] = ["expiryDate", "plateNumber", "groupName", "departmentName"];
  months: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  constructor(private policiesService: PoliciesService,
    private router: Router,
    private dialog: MatDialog,
    private helpers: Helpers) {
    super(policiesService);
    this.obj = this.getDecodedAccessToken(this.helpers.getToken());
    this.name = this.helpers.getName();
  }

  ngOnInit() {
    super.ngOnInit();
    this.policiesService.counts().subscribe(res => {
      this.counts = res;
      if (this.counts.totalRequests === undefined) {
        this.counts.totalRequests = 0;
      }
      if (this.counts.supervisionFee === undefined) {
        this.counts.supervisionFee = 0;
      }
      if (this.counts.issueFee === undefined) {
        this.counts.issueFee = 0;
      }
      if (this.counts.premiumFee === undefined) {
        this.counts.premiumFee = 0;
      }
      if (this.counts.totalFee === undefined) {
        this.counts.totalFee = 0;
      }

      if (this.counts.totalRejectedPrinted === undefined) {
        this.counts.totalRejectedPrinted = 0;
      }

      if (this.counts.totalApprovedPrinted === undefined) {
        this.counts.totalApprovedPrinted = 0;
      }
    });

    this.policiesService.getPolicyByMonth()
      .subscribe(
        res => {
          this.createAccordion(res);
        }, error => {
          let startDate = new Date();
          this.handleErrorForAccodionData(error, startDate.getMonth(), startDate.getUTCFullYear())
        }
      );
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  reject(ele) {
    const dialogRef = this.dialog.open(ConfirmReasonDialogComponent, {
      data: {
        title: 'Confirm', message: 'Are you sure you want reject the policy?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'false') {
        this.policiesService.reject(ele.id, { reason: result }).subscribe(res => {
          this.ngOnInit();
        });
      }
    });
  }

  rejectAndClone(ele) {
    const dialogRef = this.dialog.open(ConfirmReasonDialogComponent, {
      data: {
        title: 'Confirm', message: 'Are you sure you want reject the policy?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'false') {
        this.policiesService.rejectAndClone(ele.id, { reason: result }).subscribe(res => {
          this.ngOnInit();
        });
      }
    });
  }

  clone(ele) {
    this.policiesService.clone(ele.id).subscribe(res => {
      this.ngOnInit();
    });
  }

  viewDetail(ele) {
    this.router.navigate(['/policies/' + ele.id]);
  }

  logout() {
    this.helpers.logout();
    if (this.obj.type === 'CompanyAdministrator') {
      this.router.navigate(['/company-login']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  bulkUpload(ele) {
    const dialogRef = this.dialog.open(B2BBulkUploadDialogComponent, {
      data: {
        title: 'Bulk Upload', message: 'Upload your csv of policy details.'
      }
    });
    dialogRef.afterClosed().subscribe(result => { });
  }

  createAccordion(accordionTitle): void {
    accordionTitle.forEach(accTitle => {
      let monthYear: string[] = accTitle.month.split(" ");
      this.vehicleAccordionArray.push(new VehicleAccordion(accTitle.month, new Array<VehiclePolicy>(), monthYear ? this.months.indexOf(monthYear[0]) : 0, monthYear ? +monthYear[1] : 0, accTitle.count));
    });
  }

  createAccordionData(accordionArray: Array<any>, vehicleAccordion: VehicleAccordion): void {
    accordionArray.forEach(accordion => {
      let expiryDate: any = accordion.expiryDate,
        plateNumber: string = accordion.plateNumber || "-",
        groupName: string = accordion.groupName || "-",
        departmentName: string = accordion.departmentName || "-";

      vehicleAccordion.policies.push(new VehiclePolicy(expiryDate, plateNumber, groupName, departmentName))
    });
    if (accordionArray && accordionArray.length != vehicleAccordion.policyCount) {
      vehicleAccordion.policyCount = accordionArray.length;
    }
  }

  getPolicyForMonth(vehicleAccordion: VehicleAccordion): void {
    let startDate = new Date(+vehicleAccordion.year, +vehicleAccordion.month, 1);
    let endDate = new Date(+vehicleAccordion.year, +vehicleAccordion.month + 1, 1);
    let startDateParam = (startDate.getMonth() + 1) + "%2F" + startDate.getDate() + "%2F" + startDate.getFullYear();
    let endDateParam = (endDate.getMonth() + 1) + "%2F" + endDate.getDate() + "%2F" + endDate.getFullYear();

    this.policiesService.getPolicyByDate(startDateParam, endDateParam)
      .subscribe(
        res => {
          vehicleAccordion.isExpanded = true;
          vehicleAccordion.policies = new Array<VehiclePolicy>();
          this.createAccordionData(res, vehicleAccordion);
        },
        error => { }
      );
  }

  handleErrorForAccodionData(error, startMonth: number, startYear: number) {
    for (let counter = 0; counter < 12; counter++) {
      let vehiclePolicyArray: Array<VehiclePolicy> = [];
      let title: string = this.months[startMonth] + " " + startYear;
      this.vehicleAccordionArray.push(new VehicleAccordion(title, vehiclePolicyArray, startMonth, startYear));
      (startMonth + 1 > 11) ? (startMonth = 0, startYear += 1) : (startMonth += 1);
    }
  }
}
