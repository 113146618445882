import { Component, OnInit, ViewChild } from '@angular/core';
import { TableComponent } from '../table.component';
import { IssuanceModel } from 'src/models/issuance';
import { IssuanceReportService } from 'src/services/reports/issuance.service';
import { SafrattiIssuanceReportService } from 'src/services/reports/safratti-report.service';
import { SafrattiIssuance } from 'src/models/safratti-issuance';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';

@Component({
  selector: "app-safratti-issuance-report",
  templateUrl: "./safratti-issuance-report.component.html",
  styleUrls: ["./safratti-issuance-report.component.css"],
})
export class SafrattiIssuanceReportComponent
  extends TableComponent<SafrattiIssuance>
  implements OnInit
{
  pageTitle = "Issuance Report";
  issueDate: string;
  policyNumber: string;
  username: string;
  passportNumber: string;
  startDate: string;
  endDate: string;

  displayedColumns: string[] = [
    "type",
    "creationDate",
    "issueDate",
    "status",
    "policyNumber",
    "insuranceCompany",
    "passportNumber",
    "referralName",
    "referralEmailID",
    "firstName",
    "lastName",
    "policyHolderNames",
    "dateOfBirth",
    "gender",
    "email",
    "phoneNumber",
    "numberOfMembers",
    "countryOfResidence",
    "plan",
    "travelperiodStartDate",
    "travelperiodEndDate",
    "duration",
    "coverageZone",
    "destination",
    "sportsActivity",
    "emergencyContactName",
    "emergencyPhoneNumber",
    "premiumAmount",
    "policyFee",
    "issueFee",
    "additionalFees",
    "sportsActivityLoading",
    "totalAmount",
    "convenienceFee",
    "netAmount",
    "discountAmount",
    "promotionName",
    "source",
    "transactionID",
    "paymentReferenceID",
  ];

  constructor(private reportServiceSafratti: SafrattiIssuanceReportService) {
    super(reportServiceSafratti);
  }
  getPolicyHolderNames(policyHolders: any[]): string {
    return policyHolders.map((holder) => holder.name).join(", ");
  }

  formatDateForFilter(val: string, type: string) {
    const date = new Date(val);
    let localTime;
    if (type == "startDate") {
      localTime = "00:00:00";
    } else {
      localTime = "23:59:59";
    }
    const splitDate = date.toISOString().split("T");

    const formatedDate = `${splitDate[0]}T${localTime}`;

    const utcDate = new Date(formatedDate).toISOString();

    return utcDate;
  }

  getCount(): any {
    const data = {
      issueDate: this.issueDate ? this.issueDate : null,
      startDate: this.startDate
        ? this.formatDateForFilter(this.startDate, "startDate")
        : null,
      endDate: this.endDate
        ? this.formatDateForFilter(this.endDate, "endDate")
        : null,
      policyNumber: this.policyNumber ? this.policyNumber : null,
      username: this.username ? this.username : null,
      passportNumber: this.passportNumber ? this.passportNumber : null,
      skip: this.paginator.pageIndex * this.paginator.pageSize,
      take: this.paginator.pageSize,
    };
    return this.reportServiceSafratti.getCount(data);
  }

  getData(): any {
    return {
      issueDate: this.issueDate ? this.issueDate : null,
      startDate: this.startDate
        ? this.formatDateForFilter(this.startDate, "startDate")
        : null,
      endDate: this.endDate
        ? this.formatDateForFilter(this.endDate, "endDate")
        : null,
      policyNumber: this.policyNumber ? this.policyNumber : null,
      username: this.username ? this.username : null,
      passportNumber: this.passportNumber ? this.passportNumber : null,
      skip: this.paginator.pageIndex * this.paginator.pageSize,
      take: this.paginator.pageSize,
      orderBy: this.sort.active,
      orderByDescending: this.sort.direction === "desc",
    };
  }

  getDataByFilter() {
    const data = this.getData();
    this.getCount().subscribe((res) => {
      this.count = res.count;
      this.loadRequests(data);
    });
  }

  downloadXLS(isXLS: boolean) {
    const data = {
      issueDate: this.issueDate ? this.issueDate : null,
      startDate: this.startDate
        ? this.formatDateForFilter(this.startDate, "startDate")
        : null,
      endDate: this.endDate
        ? this.formatDateForFilter(this.endDate, "endDate")
        : null,
      policyNumber: this.policyNumber ? this.policyNumber : null,
      username: this.username ? this.username : null,
      passportNumber: this.passportNumber ? this.passportNumber : null,
      skip: 0,
      take: 2147483647,
      asXLS: isXLS,
    };

    this.reportServiceSafratti.downloadReport(data);
  }

  formatDate(val: any) {
    const [datePart, timePart] = val.split("T");

    const utcDate = new Date(`${datePart}T${timePart}Z`);

    const kuwaitOffset = 3 * 60 * 60 * 1000; // Kuwait is UTC+3 hours
    const kuwaitDate = new Date(utcDate.getTime() + kuwaitOffset);

    const hours = String(kuwaitDate.getUTCHours()).padStart(2, "0");
    const minutes = String(kuwaitDate.getUTCMinutes()).padStart(2, "0");
    const seconds = String(kuwaitDate.getUTCSeconds()).padStart(2, "0");
    const kuwaitTimeString = `${hours}:${minutes}:${seconds}`;

    const kuwaitDateFormatted = `${String(kuwaitDate.getUTCDate()).padStart(
      2,
      "0"
    )}/${String(kuwaitDate.getUTCMonth() + 1).padStart(
      2,
      "0"
    )}/${kuwaitDate.getUTCFullYear()}`;
    return `${kuwaitDateFormatted} ${kuwaitTimeString}`;
  }
}
