import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { RateCardType } from 'src/models/comprehensive-quote';
import { PasswordConfirmDialogComponent } from '../password-confirm-dialog/password-confirm-dialog.component';

@Component({
  selector: 'app-add-limit-dialog',
  templateUrl: './add-limit-dialog.component.html',
  styleUrls: ['./add-limit-dialog.component.css']
})
export class AddLimitDialogComponent implements OnInit {

  limitName: string;
  arabicLimitNmae: string;
  RateCardType = RateCardType;
  constructor(private dialogRef: MatDialogRef<PasswordConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {isSubLimit?: boolean, type?: RateCardType, addFee?: boolean},
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  addLimit(): void {
    if ( this.limitName && this.limitName != '' ) {
      this.dialogRef.close({name: this.limitName , arabicName: this.arabicLimitNmae});
    } else {
      this.snackBar.open("Limit should not be empty.", 'CANCEL', {
        horizontalPosition: 'right',
        duration: 2000
      });
    }
  }

}
