import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { TravelerDetail } from "src/models/traveler-detail";

@Component({
  selector: "app-safratti-policy-contact-detail",
  templateUrl: "./safratti-policy-contact-detail.component.html",
  styleUrls: ["./safratti-policy-contact-detail.component.css"],
})
export class SafrattiPolicyContactDetailComponent implements OnChanges {
  

  @Input() contactDetail: TravelerDetail;
  contactDetailForm = new FormGroup({
    email: new FormControl<string | null>(null),
    phoneNumber: new FormControl<string | null>(null),
    emergencyContactName: new FormControl<string | null>(null),
    emergencyContactNumber: new FormControl<string | null>(null),
  });

  ngOnChanges(changes: SimpleChanges): void {
    this.contactDetailForm.patchValue(this.contactDetail);
  }
}
