import { Component, OnInit } from '@angular/core';
import { TplService } from 'src/services/tpl.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import * as $ from 'jquery';
import { FeeService } from 'src/services/fee.service';
import { PasswordConfirmDialogComponent } from 'src/app/password-confirm-dialog/password-confirm-dialog.component';
import { DialogService } from 'src/services/dialog.service';

// Vehicle Type 0 = Private
// Vehicle Type 1 = Taxi
// Vehicle Type 2 = Japanese Pick Up
// Vehicle Type 3 =  American Pick Up
// Vehicle Type 4 = Water Tank
// Vehicle Type 5 = Ambulance
// Vehicle Type 6 = MotorCycle
// Vehicle Type 7 = Crane
// Vehicle Type 8 = Bus

export class TplFeeModel {
  vehicalType: string;
  vehicalCode: string;
  fees = [];
}

@Component({
  selector: 'app-fee-setup',
  templateUrl: './fee-setup.component.html',
  styleUrls: ['./fee-setup.component.css']
})
export class FeeSetupComponent implements OnInit {
  feeData: any;
  formDataMappedList = [];
  otherFees: any = {};

  constructor(private tplService: TplService,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private feeService: FeeService) { }


  ngOnInit() {
    const dialogRef = this.dialog.open(PasswordConfirmDialogComponent, { disableClose: true });

    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined) {
        this.getTplFee();
        this.getOtherFees();
      }
    });
  }
  getTplFee(): any {
    return this.tplService.getFees().subscribe(res => {
      this.feeData = res;
      this.formDataMappedList = [];
      this.mapFormModel();
    }, () => { });
  }

  getOtherFees(): any {
    return this.feeService.getFees().subscribe(res => {
      this.otherFees = res;
    }, () => { });
  }

  mapFormModel() {
    const pc4p = new TplFeeModel();
    pc4p.vehicalCode = '0-4';
    pc4p.vehicalType = 'Private car 4 Passengers',
      pc4p.fees = this.mapFormDataWithFee(pc4p.vehicalCode);
    this.formDataMappedList.push(pc4p);

    const pc5p = new TplFeeModel();
    pc5p.vehicalCode = '0-5';
    pc5p.vehicalType = 'Private car 5 Passengers',
      pc5p.fees = this.mapFormDataWithFee(pc5p.vehicalCode);
    this.formDataMappedList.push(pc5p);

    const pc6p = new TplFeeModel();
    pc6p.vehicalCode = '0-6';
    pc6p.vehicalType = 'Private car 6 Passengers',
      pc6p.fees = this.mapFormDataWithFee(pc6p.vehicalCode);
    this.formDataMappedList.push(pc6p);

    const pc7p = new TplFeeModel();
    pc7p.vehicalCode = '0-7';
    pc7p.vehicalType = 'Private car 7 Passengers',
      pc7p.fees = this.mapFormDataWithFee(pc7p.vehicalCode);
    this.formDataMappedList.push(pc7p);

    const pc8p = new TplFeeModel();
    pc8p.vehicalCode = '0-8';
    pc8p.vehicalType = 'Private car 8 Passengers',
      pc8p.fees = this.mapFormDataWithFee(pc8p.vehicalCode);
    this.formDataMappedList.push(pc8p);

    const tx4p = new TplFeeModel();
    tx4p.vehicalCode = '1-4';
    tx4p.vehicalType = 'Taxi 4 Passengers',
      tx4p.fees = this.mapFormDataWithFee(tx4p.vehicalCode);
    this.formDataMappedList.push(tx4p);

    const tx5p = new TplFeeModel();
    tx5p.vehicalCode = '1-5';
    tx5p.vehicalType = 'Taxi 5 Passengers',
      tx5p.fees = this.mapFormDataWithFee(tx5p.vehicalCode);
    this.formDataMappedList.push(tx5p);

    const tx6p = new TplFeeModel();
    tx6p.vehicalCode = '1-6';
    tx6p.vehicalType = 'Taxi 6 Passengers',
      tx6p.fees = this.mapFormDataWithFee(tx6p.vehicalCode);
    this.formDataMappedList.push(tx6p);

    const tx7p = new TplFeeModel();
    tx7p.vehicalCode = '1-7';
    tx7p.vehicalType = 'Taxi 7 Passengers',
      tx7p.fees = this.mapFormDataWithFee(tx7p.vehicalCode);
    this.formDataMappedList.push(tx7p);

    const jp2p = new TplFeeModel();
    jp2p.vehicalCode = '2-2';
    jp2p.vehicalType = 'Japanese Pickup 2 Passengers',
      jp2p.fees = this.mapFormDataWithFee(jp2p.vehicalCode);
    this.formDataMappedList.push(jp2p);

    const jp4p = new TplFeeModel();
    jp4p.vehicalCode = '2-4';
    jp4p.vehicalType = 'Japanese Pickup 4 Passengers',
      jp4p.fees = this.mapFormDataWithFee(jp4p.vehicalCode);
    this.formDataMappedList.push(jp4p);

    const ap2p = new TplFeeModel();
    ap2p.vehicalCode = '3-2';
    ap2p.vehicalType = 'American Pickup 2 Passengers',
      ap2p.fees = this.mapFormDataWithFee(ap2p.vehicalCode);
    this.formDataMappedList.push(ap2p);

    const ap4p = new TplFeeModel();
    ap4p.vehicalCode = '3-4';
    ap4p.vehicalType = 'American Pickup 4 Passengers',
      ap4p.fees = this.mapFormDataWithFee(ap4p.vehicalCode);
    this.formDataMappedList.push(ap4p);

    const ap5p = new TplFeeModel();
    ap5p.vehicalCode = '3-5';
    ap5p.vehicalType = 'American Pickup 5 Passengers',
      ap5p.fees = this.mapFormDataWithFee(ap5p.vehicalCode);
    this.formDataMappedList.push(ap5p);

    const waterTank = new TplFeeModel();
    waterTank.vehicalCode = '4-2';
    waterTank.vehicalType = 'Water Tank';
    waterTank.fees = this.mapFormDataWithFee(waterTank.vehicalCode);
    this.formDataMappedList.push(waterTank);

    const نساف = new TplFeeModel();
    نساف.vehicalCode = '9-2';
    نساف.vehicalType = 'نساف';
    نساف.fees = this.mapFormDataWithFee(نساف.vehicalCode);
    this.formDataMappedList.push(نساف);

    const تريله = new TplFeeModel();
    تريله.vehicalCode = '10-2';
    تريله.vehicalType = 'تريله';
    تريله.fees = this.mapFormDataWithFee(تريله.vehicalCode);
    this.formDataMappedList.push(تريله);

    const سيارات = new TplFeeModel();
    سيارات.vehicalCode = '11-2';
    سيارات.vehicalType = 'سيارات';
    سيارات.fees = this.mapFormDataWithFee(سيارات.vehicalCode);
    this.formDataMappedList.push(سيارات);

    const Mixer = new TplFeeModel();
    Mixer.vehicalCode = '12-2';
    Mixer.vehicalType = 'Mixer';
    Mixer.fees = this.mapFormDataWithFee(Mixer.vehicalCode);
    this.formDataMappedList.push(Mixer);

    const forkCliff = new TplFeeModel();
    forkCliff.vehicalCode = '13-2';
    forkCliff.vehicalType = 'Fork Cliff';
    forkCliff.fees = this.mapFormDataWithFee(forkCliff.vehicalCode);
    this.formDataMappedList.push(forkCliff);

    const نقل = new TplFeeModel();
    نقل.vehicalCode = '14-2';
    نقل.vehicalType = 'نقل';
    نقل.fees = this.mapFormDataWithFee(نقل.vehicalCode);
    this.formDataMappedList.push(نقل);

    const tipper = new TplFeeModel();
    tipper.vehicalCode = '15-2';
    tipper.vehicalType = 'Tipper';
    tipper.fees = this.mapFormDataWithFee(tipper.vehicalCode);
    this.formDataMappedList.push(tipper);

    const نصف = new TplFeeModel();
    نصف.vehicalCode = '16-2';
    نصف.vehicalType = 'نصف';
    نصف.fees = this.mapFormDataWithFee(نصف.vehicalCode);
    this.formDataMappedList.push(نصف);

    const خصوصي = new TplFeeModel();
    خصوصي.vehicalCode = '17-2';
    خصوصي.vehicalType = 'خصوصي';
    خصوصي.fees = this.mapFormDataWithFee(خصوصي.vehicalCode);
    this.formDataMappedList.push(خصوصي);

    const halfLorry = new TplFeeModel();
    halfLorry.vehicalCode = '18-2';
    halfLorry.vehicalType = 'Half Lorry';
    halfLorry.fees = this.mapFormDataWithFee(halfLorry.vehicalCode);
    this.formDataMappedList.push(halfLorry);

    const ambulance = new TplFeeModel();
    ambulance.vehicalCode = '5-2';
    ambulance.vehicalType = 'Ambulance',
      ambulance.fees = this.mapFormDataWithFee(ambulance.vehicalCode);
    this.formDataMappedList.push(ambulance);

    const motorCycle = new TplFeeModel();
    motorCycle.vehicalCode = '6-2';
    motorCycle.vehicalType = 'MotorCycle',
      motorCycle.fees = this.mapFormDataWithFee(motorCycle.vehicalCode);
    this.formDataMappedList.push(motorCycle);

    const crane = new TplFeeModel();
    crane.vehicalCode = '7-2';
    crane.vehicalType = 'Crane',
      crane.fees = this.mapFormDataWithFee(crane.vehicalCode);
    this.formDataMappedList.push(crane);

    const bus = new TplFeeModel();
    bus.vehicalCode = '8-2';
    bus.vehicalType = 'Bus',
      bus.fees = this.mapFormDataWithFee(bus.vehicalCode);

    this.formDataMappedList.push(bus);

    console.log(this.formDataMappedList);
  }

  mapFormDataWithFee(code): any {
    const vehicleType = parseInt(code.split('-')[0], 10);
    const passengers = parseInt(code.split('-')[1], 10);
    const dataList = this.feeData;
    const feeList = [];
    for (const lData of dataList) {
      if (lData.vehicleType === vehicleType && lData.passengers === passengers) {
        if (lData.period === 1) {
          feeList[0] = lData;
        }
        if (lData.period === 2) {
          feeList[1] = lData;
        }
        if (lData.period === 3) {
          feeList[2] = lData;
        }
        // feeList.push(lData);
      }
    }
    return feeList;
  }

  updateFees() {

    $('.apiLoader').show();
    const feeDataList = [];
    for (const mappedData of this.formDataMappedList) {
      for (const feeData of mappedData.fees) {
        feeDataList.push(feeData);
      }
    }

    this.tplService.updateFee(feeDataList).subscribe(() => {
      this.feeService.updateFee(this.otherFees).subscribe(() => {
        $('.apiLoader').hide();
        this.dialogService.successSnackBar('Success');
        this.getTplFee();
        this.getOtherFees();
      });
    }, err => {
      $('.apiLoader').hide();
      this.dialogService.errorSanckBar(err);
    });
  }

  checkValidaton(eleId) {
    const ele = $('#' + eleId);
    setTimeout(() => {
      if (ele.hasClass('ng-invalid')) {
        ele.closest('.amount-box').addClass('error');
        ele.closest('.groupInput').addClass('error');

      } else {
        ele.closest('.amount-box').removeClass('error');
        ele.closest('.groupInput').removeClass('error');
      }
    }, 100);

  }

}
