
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';



import { catchError } from 'rxjs/operators';

import { environment } from '../environments/environment';

import { BaseService } from './base.service';

import { ICountService } from './icount.service';

import { Helpers } from '../helpers/helpers';


@Injectable()

export class PoliciesService extends BaseService implements ICountService {

  private pathAPI = environment.apiUrl;

  public errorMessage: string;

  constructor(private http: HttpClient, helper: Helpers) { super(helper); }

  getData(body: any): any {

    return this.http.post<any>(this.pathAPI + 'Policy/GetPolicies', body, super.header()).pipe(

      catchError(super.handleError)

    );

  }

  getPolicyDetail(id: number): any {

    return this.http.get<any>(this.pathAPI + 'Policy/' + id, super.header()).pipe(

      catchError(super.handleError)

    );

  }

  getCount(body: any) {
    return this.http.post<any>(this.pathAPI + 'Policy/GetPolicyCount', body, super.header()).pipe(catchError(super.handleError));
  }

  createPolicy(body: any): any {

    return this.http.post<any>(this.pathAPI + 'Policy', body, super.header()).pipe(

      catchError(super.handleError)

    );

  }

  updatePolicy(id: number, body: any): any {

    return this.http.put<any>(this.pathAPI + 'Policy/' + id, body, super.header()).pipe(

      catchError(super.handleError)

    );

  }

  policyPrinted(id: number): any {

    return this.http.put<any>(this.pathAPI + 'Policy/' + id + '/Printed', {}, super.header()).pipe(

      catchError(super.handleError)

    );
  }

  counts(): any {

    return this.http.get<any>(this.pathAPI + 'Policy/GetCounts', super.header()).pipe(

      catchError(super.handleError)

    );
  }

  reject(id: number, body: any): any {
    return this.http.put<any>(this.pathAPI + 'Policy/Reject/' + id, body, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  rejectAndClone(id: number, body: any): any {
    return this.http.post<any>(this.pathAPI + 'Policy/RejectAndCopy/' + id, body, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  clone(id: number): any {
    return this.http.post<any>(this.pathAPI + 'Policy/Copy/' + id, {}, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getDocuments(id: number): any {
    return this.http.get<any>(this.pathAPI + 'Policy/Documents/' + id, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  addDocuments(id: number, body: [string]): any {
    return this.http.post<any>(this.pathAPI + 'Policy/Documents/' + id, body, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getPolicyByDate(startDate: string, endDate: string): any {
    return this.http.get<any>(this.pathAPI + 'Policy/PolicyByDate?StartDate=' + startDate + "&EndDate=" + endDate, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getPolicyByMonth(): any {
    return this.http.get<any>(this.pathAPI + 'Policy/PolicyByMonth', super.header()).pipe(
      catchError(super.handleError)
    );
  }
}
