import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient } from "@angular/common/http";
import { Helpers } from "src/helpers/helpers";
import { environment } from "src/environments/environment";
import { catchError } from "rxjs/operators";
import { Observable } from "rxjs";
import { SafrattiFee } from "src/models/safratti-fee";
import { CreateFeeDto } from "src/dto/request/create-fee-dto";
import { UpdateSafrattiFee } from "src/models/updateSafrattiFee";
import { FetchSafrattiFee } from "src/models/fetchSafrattiFee";

@Injectable({
  providedIn: "root",
})
export class SafrattiFeeService extends BaseService {
  constructor(private http: HttpClient, helper: Helpers) {
    super(helper);
  }

  getSafrattiFees(): Observable<SafrattiFee[]> {
    return this.http
      .get<SafrattiFee[]>(environment.apiUrl + "Safratti/Fee", super.header())
      .pipe(catchError(super.handleError));
  }

  createSafrattiFee(body: CreateFeeDto): Observable<void> {
    return this.http
      .post<void>(environment.apiUrl + "Safratti/Fee", body, super.header())
      .pipe(catchError(super.handleError));
  }

  fetchSafrattiFee(id: any): Observable<FetchSafrattiFee> {
    return this.http
      .get<FetchSafrattiFee>(
        environment.apiUrl + "Safratti/Fee/" + id,
        super.header()
      )
      .pipe(catchError(super.handleError));
  }

  updateSafrattiFee(id: number, body: UpdateSafrattiFee): Observable<void> {
    return this.http
      .put<void>(
        environment.apiUrl + "Safratti/Fee/" + id,
        body,
        super.header()
      )
      .pipe(catchError(super.handleError));
  }
}
