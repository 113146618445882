import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Helpers } from 'src/helpers/helpers';
import { AuthRequest } from '../dto/request/auth-request';
import { AuthResponse } from '../dto/response/auth-response';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, map, take } from 'rxjs/operators';
import { SafrattiPolicy } from 'src/models/sendPolicyEmail';
import { GetOfferRequest } from 'src/models/get-offer-request';
import { GetOfferResponse } from 'src/models/get-offer-response';
import { AccountResponse } from 'src/models/account-response';
import { DataListRowRequest } from 'src/models/data-list-row-request';
import { DataListResponse } from 'src/models/data-list-response';


@Injectable({
  providedIn: 'root'
})
export class GTSService extends BaseService {

  constructor(helper: Helpers,
    private http: HttpClient) {
    super(helper);
  }

  authenticate(body: AuthRequest): Observable<AuthResponse> {
    return this.http.post<AuthResponse>(environment.gtsUrl + 'identity/GetAccessToken', body, super.header())
      .pipe(
        map((res) => { return this.parseResponse(res); })
      );
  }

  getOffers(body: GetOfferRequest): Observable<GetOfferResponse> {
    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    header = header.append('apiKey', 'a');
    header = header.append('apiPassword', 'b');
    header = header.append('Authorization', 'Bearer ' + this.helper.getToken());

    return this.http
      .post<GetOfferResponse>(
        environment.apiUrl + 'Rating/GetOffers',
        body,
        { headers:header}
      )
      .pipe(
        map((res) => {
          return this.parseResponse(res);
        }),
        catchError(this.handleError)
      );
  }

  getAccount(accountNumber: number): Observable<AccountResponse> {
    return this.http
      .get<AccountResponse>(
        environment.gtsCrmUrl + `Account/Get?accountNumber=${accountNumber}`,
        super.header()
      )
      .pipe(
        map((res) => {
          return this.parseResponse(res).account;
        })
      );
  }

  getPlans(name: string, entityId: string): Observable<DataListResponse> {
    return this.http
      .get<DataListResponse>(
        environment.apiUrl + `datalist/Get?name=${name}&entityId=${entityId}`,
        super.header()
      )
      .pipe(
        map((res) => {
          return this.parseResponse(res);
        })
      );
  }

  queryDataListRows(
    body: DataListRowRequest[]
  ): Observable<DataListResponse[]> {
    return this.http
      .post<DataListResponse[]>(
        environment.apiUrl + 'datalist/QueryDataListRows',
        body,
        super.header()
      )
      .pipe(
        map((res) => {
          return this.parseResponse(res);
        })
      );
  }
  

  downloadTermsAndConditions(policyId: string , token: string): Observable<HttpResponse<Blob>> {

    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    header.append('Authorization', 'Bearer ' + token);
    
    return this.http.get<Blob>(environment.gtsUrl + `printout/termsandconditions?id=${policyId}`, { headers: header, observe: 'response', responseType: 'blob' as 'json' })
      .pipe(
        take(1)
      );



  }

  downloadCertificate(policyId: string , token: string): Observable<HttpResponse<Blob>>
   {

    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    header.append('Authorization', 'Bearer ' + token);

     return this.http.get<Blob>(environment.gtsUrl + `printout/certificate?id=${policyId}&withoutHeader=false&separateCertificates=false&voucherIncluded=true`, { headers: header, observe: 'response', responseType: 'blob' as 'json' })
      .pipe(
        take(1)
      )
      
  }
  
  downloadReciept(policyId: string, token: string): Observable<HttpResponse<Blob>> {

    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    header.append('Authorization', 'Bearer ' + token);

    return this.http.get<Blob>(environment.gtsUrl + `printout/receipt?id=${policyId}`, { headers: header, observe: 'response', responseType: 'blob' as 'json' })
      .pipe(
        take(1)
      )
  }

  sendEmail(body:SafrattiPolicy):Observable<void>{
    return this.http.post<void>(environment.apiUrl + 'Email',body, super.header()).pipe(catchError(super.handleError));
  }


  getQueryListRequestBody(
    companyPrefix: string,
    companyCode: number
  ): DataListRowRequest[] {
    return [
      {
        name: companyPrefix+'GTSCountry',  
        language: 1033,
        entityId: companyCode,
      },

      {
        name: companyPrefix+`GTSGender`,
        language: 1033,
        entityId: companyCode,
      },
      {
        name: companyPrefix+`GTSAreaOfTravel`,
        language: 1033,
        entityId: companyCode,
      },
      {
        name: companyPrefix+`GTSRelation`,
        language: 1033,
        entityId: companyCode,
      },
      {
        name: companyPrefix+`GTSDuration`,
        language: 1033,
        entityId: companyCode,
      },
      {
        name: companyPrefix+`GTSYesNo`,
        language: 1033,
        entityId: companyCode,
      },
      {
        name: companyPrefix+`GTSDestinationCountry`,
        language: 1033,
        entityId: companyCode,
      },
    ];
  }

  private _updatedPremiumPrice = new BehaviorSubject<number>(0);

  updatedPremiumPrice$ = this._updatedPremiumPrice.asObservable();

  updatePremiumPrice(price: number) {
    this._updatedPremiumPrice.next(price);
  }
}
