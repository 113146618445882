import { Injectable } from "@angular/core";

import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable } from "rxjs";

import { catchError } from "rxjs/operators";

import { environment } from "../environments/environment";

import { BaseService } from "./base.service";

import { Helpers } from "../helpers/helpers";

@Injectable()
export class StorageService extends BaseService {
  private pathAPI = environment.apiUrl;

  public errorMessage: string;

  constructor(private http: HttpClient, helper: Helpers) {
    super(helper);
  }

  getSASToken(blobName: string, containerName: string): any {
    return this.http
      .post<any>(
        this.pathAPI + "Storage/GetReadOnlyToken",
        { blobName, containerName },
        super.header()
      )
      .pipe(catchError(super.handleError));
  }

  getSASTokenForContainer(containerName: string): any {
    return this.http
      .post<any>(
        this.pathAPI + "Storage/GetReadOnlyTokenForContainer",
        { containerName },
        super.header()
      )
      .pipe(catchError(super.handleError));
  }

  getUploadOnlyTokenForContainer(containerName: string): any {
    return this.http
      .post<any>(
        this.pathAPI + "Storage/GetUploadOnlyTokenForContainer",
        { containerName },
        super.header()
      )
      .pipe(catchError(super.handleError));
  }

  getLastFileModifiedForContainer(containerName: string): any {
    return this.http
      .post<any>(
        this.pathAPI + "Storage/GetLatestBlobFileName",
        { containerName },
        super.header()
      )
      .pipe(catchError(super.handleError));
  }

  getImage(imageUrl: string): Observable<Blob> {
    return this.http
      .get(imageUrl, { responseType: "blob" })
      .pipe(catchError(super.handleError));
  }
  getSASTokenForSafratti(id: string): any {
    return this.http
      .post<any>(
        this.pathAPI +
          "Storage/GetReadTokenForSafrattiPolicyCopy?PolicyId=" +
          id,
        {},
        super.header()
      )
      .pipe(catchError(super.handleError));
  }
  getUploadTokenForSafratti(id: string): any {
    return this.http
      .post<any>(
        this.pathAPI +
          "Storage/GetUploadTokenForSafrattiPolicyCopy?PolicyId=" +
          id,
        {},
        super.header()
      )
      .pipe(catchError(super.handleError));
  }

  getSASTokenForSafrattiTermsCondition(id: string): any {
    return this.http
      .post<any>(
        this.pathAPI +
          "Storage/GetReadTokenForSafrattiTermAndCondition?PolicyId=" +
          id,
        {},
        super.header()
      )
      .pipe(catchError(super.handleError));
  }
  getUploadTokenForSafrattiTermsCondition(id: string): any {
    return this.http
      .post<any>(
        this.pathAPI +
          "Storage/GetUploadTokenForSafrattiTermAndCondition?PolicyId=" +
          id,
        {},
        super.header()
      )
      .pipe(catchError(super.handleError));
  }

  getSASTokenForSafrattiMemberShipCard(id: string): any {
    return this.http
      .post<any>(
        this.pathAPI +
          "Storage/GetReadTokenForSafrattiMemberShipCard?PolicyId=" +
          id,
        {},
        super.header()
      )
      .pipe(catchError(super.handleError));
  }
  getUploadTokenForSafrattiMemberShipCard(id: string): any {
    return this.http
      .post<any>(
        this.pathAPI +
          "Storage/GetUploadTokenForSafrattiMemberShipCard?PolicyId=" +
          id,
        {},
        super.header()
      )
      .pipe(catchError(super.handleError));
  }

  getSASTokenForSafrattiInvoice(id: string): any {
    return this.http
      .post<any>(
        this.pathAPI + "Storage/GetReadTokenForSafrattiInvoice?PolicyId=" + id,
        {},
        super.header()
      )
      .pipe(catchError(super.handleError));
  }
  getUploadTokenForSafrattiInvoice(id: string): any {
    return this.http
      .post<any>(
        this.pathAPI +
          "Storage/GetUploadTokenForSafrattiInvoice?PolicyId=" +
          id,
        {},
        super.header()
      )
      .pipe(catchError(super.handleError));
  }

  uploadFileToAzure(fileName: string, file: File, format: string) {
    let headers = new HttpHeaders({
      "Content-Type": format,
      "x-ms-blob-type": "BlockBlob",
    });
    const fileUrl = `${environment.cdnUrl}${fileName}`;
    return this.http.put<void>(fileUrl, file, { headers });
  }

  downloadFile(fileName: string): Observable<Blob> {
    return this.http.get(`${environment.cdnUrl}${fileName}`, {
      responseType: 'blob',
    });
  }
}
