import { Component, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../services/company.service';
import { StorageService } from '../../../services/storage.service';
import { environment } from '../../../environments/environment';




@Component({
  selector: 'app-brokers',
  templateUrl: './brokers.component.html',
  styleUrls: ['./brokers.component.css']
})


export class BrokersComponent implements OnInit {
  brokerList: any;
  pageTitle = 'All Brokers';
  error: any;
  SASToken: any;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private companyService: CompanyService,
              private storageService: StorageService,
              private snackBar: MatSnackBar) {
    // this.brokerList = COMPANY_LIST;
    this.storageService.getSASTokenForContainer('company').subscribe(token => {
      this.SASToken = token.sasToken;
    });
  }

  ngOnInit() {
    this.getBrokerList();
  }


  getBrokerList(): void {
    this.companyService.getBrokerList().subscribe(brokerList => {
      this.brokerList = brokerList;
    }, err => {
      this.error = err;
    });
  }

  /** open customer detail */
  openBroker(broker): void {
    this.router.navigate(['/brokers/' + broker.id]);
  }

  markDefault(broker): void {
    this.companyService.markCompanyDefault(broker.id,'Saiyarti').subscribe(broker => {
      this.getBrokerList();
      this.snackBar.open('Broker has been marked default successfully.', 'CANCEL', {
        duration: 2000
      });
    });
  }

  // getSasToken():void{
  //   this.storageService.getSASToken(brokerImage, 'civil-card').subscribe((token: any) => {
  //     this.sasToken = token.sasToken;
  //  });
  // }

  getBrokerImageUrl(broker): any {
    return environment.cdnUrl + '/company/' + broker.id + '.jpg' + this.SASToken;
  }

}
