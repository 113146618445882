import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Helpers } from '../helpers/helpers';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService extends BaseService {

  constructor(private http: HttpClient, helper: Helpers) {
    super(helper);
  }

  private pathAPI = environment.apiUrl;

  createCompany(data: any): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'Companies', data, super.header()).pipe(catchError(super.handleError));
  }
  getCompanyList(): any {
    return this.http.get<any>(this.pathAPI + 'Companies', super.header()).pipe(catchError(super.handleError));
  }

  getCompanyById(id: any): any {
    return this.http.get<any>(this.pathAPI + 'Companies/' + id, super.header()).pipe(catchError(super.handleError));
  }

  updateCompany(id: any, data: any): any {
    return this.http.put<any>(this.pathAPI + 'Companies/' + id, data, super.header()).pipe(catchError(super.handleError));
  }

  deactivateCompany(id: string): any {
    return this.http.delete<any>(this.pathAPI + 'Companies/' + id, super.header()).pipe(catchError(super.handleError));
  }

  resetPassword(id: any): any {
    return this.http.put<any>(this.pathAPI + 'Companies/' + id + '/ResetPassword', {}, super.header()).pipe(catchError(super.handleError));
  }
}
