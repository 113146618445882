import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Helpers } from '../helpers/helpers';
import { catchError } from 'rxjs/operators';
import { ICountService } from './icount.service';
import { NewCustomer } from 'src/models/newcustomer';

@Injectable({
  providedIn: 'root'
})
export class NewCustomerService extends BaseService implements ICountService {

  private pathAPI = environment.apiUrl;

  public errorMessage: string;

  constructor(private http: HttpClient, helper: Helpers) { super(helper); }


  /**
   * To get all user/cusotmer
   */
  getData(body: any): any {

    return this.http.post<any>(this.pathAPI + 'Account/GetNewCustomers', body, super.header()).pipe(
      catchError(super.handleError)
    );
  }


  getCount(body: any): any {
    return this.http.post<any>(this.pathAPI + 'Account/GetNewCustomersCount', body, super.header()).pipe(catchError(super.handleError));
  }

  get(id: number): any {
    return this.http.get<NewCustomer>(this.pathAPI + 'Account/GetNewCustomer/' + id, super.header()).pipe(catchError(super.handleError));
  }


  update(id: number, customer: NewCustomer): any {
    return this.http.post<any>(this.pathAPI + 'Account/NewCustomer/' + id, customer, super.header()).pipe(catchError(super.handleError));
  }
}
