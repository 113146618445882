import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Helpers } from 'src/helpers/helpers';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ICountService } from './icount.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService implements ICountService {
  public id: string;

  constructor(private http: HttpClient, helper: Helpers, protected snackBar: MatSnackBar) {
    super(helper);
  }

  private pathAPI = environment.apiUrl;

  getData(body: any): any {
    return this.http.post<any>(this.pathAPI + 'User/Daily/' + this.id, body, super.header()).pipe(catchError(super.handleError));
  }

  getCount(body: any) {
    return this.http.post<any>(this.pathAPI + 'User/DailyCount/' + this.id, body, super.header()).pipe(catchError(super.handleError));
  }

  getDashboardCounts(id: string): any {
    return this.http.get<any>(this.pathAPI + 'User/DashboardCounts/' + id, super.header()).pipe(catchError(super.handleError));
  }

  downloadReport(body: any) {
    const headers = super.header().headers;
    return this.http.post(this.pathAPI + 'User/DownloadReportForStoreUser', body, { headers, observe: 'response', responseType: 'blob' })
      .pipe(catchError(super.handleError))
      .subscribe(response => {
        this.downLoadFile(response);
      }, err => {
        this.snackBar.open(err, 'CANCEL', {
          horizontalPosition: 'right',
          duration: 2000
        });
      });
  }

  downLoadFile(response: any) {

    const contentType: string = response.headers.get('Content-Type');
    const contentDisposition = response.headers.get('Content-Disposition');
    const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();

    const blob = new Blob([response.body], { type: contentType });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.download = fileName;
    anchor.href = url;
    anchor.click();
  }
}
