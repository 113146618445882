import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { SelectionModel } from "@angular/cdk/collections";
import { AgentService } from "src/services/agent.service";
import { ConfirmDialogComponent } from "src/app/confirm-dialog/confirm-dialog.component";
import { SafrattiTravelPlanService } from "src/services/safratti-travel-plan.service";
import { SafrattiTravelPlan } from "src/models/safratti-travel-plan";
import { take } from "rxjs/operators";

@Component({
  selector: "app-safaratti-travel-plan-list",
  templateUrl: "./safaratti-travel-plan-list.component.html",
  styleUrls: ["./safaratti-travel-plan-list.component.css"],
})
export class SafarattiTravelPlanListComponent {
  displayedColumns: string[] = [
    "travelPlanName",
    "code",
    "countryResidence",
    "insuranceCompany",
    "status",
    "optionMenu",
  ];

  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  plans: any;

  constructor(
    private _travelPlanService: SafrattiTravelPlanService,
    private _snackBar: MatSnackBar,
    private router: Router
  ) {
    this.loadTravelPlans();
  }

  toggleStatus(travelPlan: SafrattiTravelPlan) {
    this._travelPlanService.toggleStatus(travelPlan.id).subscribe((x) => {
      this._snackBar.open("Updated Successfully", "OK", { duration: 3000 });
      this.loadTravelPlans();
    });
  }

  loadTravelPlans() {
    this._travelPlanService
      .getTravelPlans()
      .pipe(take(1))
      .subscribe((x) => (this.dataSource = new MatTableDataSource(x)));
  }

  editTravelPlan(data:any,edit:boolean) {
    this.router.navigate(['/setup/safratti-travel-plan-list/add'], {queryParams:{id:data.id, edit:edit?'false':'true'}})
    
  }
}
