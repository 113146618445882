import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { StorageService } from 'src/services/storage.service';
import { environment } from '../../environments/environment';
import { PoliciesService } from 'src/services/policies.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Helpers } from 'src/helpers/helpers';
import jwt_decode from 'jwt-decode';
import { Policy } from 'src/models/policy';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-b2b-policy-print',
  templateUrl: './b2b-policy-print.component.html',
  styleUrls: ['./b2b-policy-print.component.css']
})
export class B2bPolicyPrintComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
    },
    nav: false,
  };

  public slideIndex = "1";

  tplStatus = 2;
  pageTitle = 'Print Policy';
  safePDFDocumentUrl:SafeResourceUrl;
  // transportPDFDocumentUrl = 'https://saiyartiproduction.blob.core.windows.net/b2b-policies/19437_invoice.pdf?sv=2018-03-28&sr=b&sig=1W8%2BDco6OAogi99AuSPZoIdiqJSgCv8KW5COb8CpMXw%3D&se=2023-04-06T14%3A41%3A38Z&sp=r';
  transportPDFDocumentUrl:SafeResourceUrl;
  invoicePDFDocumentUrl:SafeResourceUrl;
  obj: any;
  request: Policy;
  name: string;

  constructor(private location: Location,
    private router: Router,
    private policiesSerivce: PoliciesService,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private policyService: PoliciesService,
    private dialog: MatDialog,
    private helpers: Helpers,
    private sanitizer:DomSanitizer,
    protected _sanitizer: DomSanitizer,
    private snackBar: MatSnackBar) {
    this.name = this.helpers.getName();
  }

  ngOnInit() {
    this.loadImage();
    const id = +this.route.snapshot.paramMap.get('id');
    this.policiesSerivce.getPolicyDetail(id).subscribe((policy: any) => {
      this.request = policy;
    });
    this.obj = this.getDecodedAccessToken(this.helpers.getToken());
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  logout() {
    this.helpers.logout();
    if (this.obj.type === 'CompanyAdministrator') {
      this.router.navigate(['/company-login']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  loadImage(): void {
    this.storageService.getSASToken(this.route.snapshot.paramMap.get('id') + '_background.pdf', 'b2b-policies').subscribe((token: any) => {
      const PDFDocumentUrl = environment.cdnUrl +  '/b2b-policies/'
      + this.route.snapshot.paramMap.get('id') + '_background.pdf' + token.sasToken;
      this.storageService.getImage(PDFDocumentUrl).subscribe((response) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        this.safePDFDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl)
        console.log(this.safePDFDocumentUrl)
    });})

    this.storageService.getSASToken(this.route.snapshot.paramMap.get('id')
          + '_transport_background.pdf', 'b2b-policies').subscribe((token: any) => {
            const temptransportPDFDocumentUrl = environment.cdnUrl + '/b2b-policies/'
              + this.route.snapshot.paramMap.get('id') + '_transport_background.pdf' + token.sasToken;
              this.storageService.getImage(temptransportPDFDocumentUrl).subscribe((response) => {
                const blob = new Blob([response], { type: 'application/pdf' });
                const blobUrl = URL.createObjectURL(blob);
              this.transportPDFDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl)})
          });

      this.storageService.getSASToken(this.route.snapshot.paramMap.get('id') + '_invoice.pdf', 'b2b-policies').subscribe((token: any) => {
            const tempinvoicePDFDocumentUrl = environment.cdnUrl + '/b2b-policies/'
              + this.route.snapshot.paramMap.get('id') + '_invoice.pdf' + token.sasToken;
              this.storageService.getImage(tempinvoicePDFDocumentUrl).subscribe((response) => {
                const blob = new Blob([response], { type: 'application/pdf' });
                const blobUrl = URL.createObjectURL(blob);
              this.invoicePDFDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl)})
          });

  }

  goBack(): void {
    this.router.navigate(['/policies/' + this.route.snapshot.paramMap.get('id')]);
  }

  print(indexToPrint) {
    switch (indexToPrint) {
      case "1": {
        this.storageService.getSASToken(this.route.snapshot.paramMap.get('id') + '.pdf', 'b2b-policies').subscribe((token: any) => {
          const url = environment.cdnUrl + '/b2b-policies/'
            + this.route.snapshot.paramMap.get('id') + '.pdf' + token.sasToken;
          this.storageService.getImage(url).subscribe((response) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            const blobUrl = URL.createObjectURL(blob);
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = blobUrl;
            document.body.appendChild(iframe);
            iframe.contentWindow.print();

          });
        });
        break;
      }
      case "2": {
        console.log(this.slideIndex);
        this.storageService.getSASToken(this.route.snapshot.paramMap.get('id') + '_transport.pdf', 'b2b-policies').subscribe((token: any) => {
          const url = environment.cdnUrl + '/b2b-policies/'
            + this.route.snapshot.paramMap.get('id') + '_transport.pdf' + token.sasToken;
          this.storageService.getImage(url).subscribe((response) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            const blobUrl = URL.createObjectURL(blob);
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = blobUrl;
            document.body.appendChild(iframe);
            iframe.contentWindow.print();

          });
        });
        break;
      }
      case "3": {
        console.log(this.slideIndex);
        this.storageService.getSASToken(this.route.snapshot.paramMap.get('id') + '_invoice.pdf', 'b2b-policies').subscribe((token: any) => {
          const url = environment.cdnUrl + '/b2b-policies/'
            + this.route.snapshot.paramMap.get('id') + '_invoice.pdf' + token.sasToken;
          this.storageService.getImage(url).subscribe((response) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            const blobUrl = URL.createObjectURL(blob);
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = blobUrl;
            document.body.appendChild(iframe);
            iframe.contentWindow.print();

          });
        });
        break;
      }
    }

  }
  done() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm', message: 'Marking a policy as printed will not allow you to edit the policy details. Are you sure?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true') {
        // logic for ok
        const id = +this.route.snapshot.paramMap.get('id');
        this.policyService.policyPrinted(id).subscribe(res => {
          this.snackBar.open('Your policy is now marked as printed.', 'CANCEL', {
            horizontalPosition: 'right',
            duration: 2000
          });
          this.router.navigate(['/policies']);
        }, err => {
          this.snackBar.open(err, 'CANCEL', {
            horizontalPosition: 'right'
          });
        });
      } else {
        // logic for cancel
      }
    });
  }



  onSlide(slideData) {
    this.slideIndex = slideData.slides[0].id  

    switch (this.slideIndex) {

      case "1": {
        if (this.safePDFDocumentUrl === undefined) {
          this.storageService.getSASToken(this.route.snapshot.paramMap.get('id') + '_background.pdf', 'b2b-policies').subscribe((token: any) => {
            const unsafePDFDocumentUrl = environment.cdnUrl + '/b2b-policies/'
              + this.route.snapshot.paramMap.get('id') + '_background.pdf' + token.sasToken;
              this.storageService.getImage(unsafePDFDocumentUrl).subscribe((response) => {
                const blob = new Blob([response], { type: 'application/pdf' });
                const blobUrl = URL.createObjectURL(blob);
              this.safePDFDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl)})
          });
        }
        break;
      }

      case "2": {
        if (this.transportPDFDocumentUrl === undefined) {
          this.storageService.getSASToken(this.route.snapshot.paramMap.get('id')
            + '_transport_background.pdf', 'b2b-policies').subscribe((token: any) => {
              const temptransportPDFDocumentUrl = environment.cdnUrl + '/b2b-policies/'
                + this.route.snapshot.paramMap.get('id') + '_transport_background.pdf' + token.sasToken;
                this.storageService.getImage(temptransportPDFDocumentUrl).subscribe((response) => {
                  const blob = new Blob([response], { type: 'application/pdf' });
                  const blobUrl = URL.createObjectURL(blob);
                this.transportPDFDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl)})
            });
        }
        break;
      }


      case "3": {
        if (this.invoicePDFDocumentUrl === undefined) {
          this.storageService.getSASToken(this.route.snapshot.paramMap.get('id') + '_invoice.pdf', 'b2b-policies').subscribe((token: any) => {
            const tempinvoicePDFDocumentUrl = environment.cdnUrl + '/b2b-policies/'
              + this.route.snapshot.paramMap.get('id') + '_invoice.pdf' + token.sasToken;
              this.storageService.getImage(tempinvoicePDFDocumentUrl).subscribe((response) => {
                const blob = new Blob([response], { type: 'application/pdf' });
                const blobUrl = URL.createObjectURL(blob);
              this.invoicePDFDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl)})
          });
        }
      }
    }
  
  }

}
