import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../../services/customer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { StorageService } from 'src/services/storage.service';
import { Lightbox } from 'ngx-lightbox';
import { UsersService } from 'src/services/users.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DocViewComponent } from 'src/app/model-dialog/doc-view/doc-view.component';
import { environment } from '../../../environments/environment';

// userData : {
//   "phoneNumber": "8758400543",
//   "countryCode": 91,
//   "birthDate": "2020-05-14T09:25:45",
//   "gender": "Female",
//   "firstName": "Satish",
//   "lastName": "Sharma",
//   "createdAt": "2019-05-07T04:17:14",
//   "sendNotification": false,
//   "id": "019a362e-60c3-41a9-921d-efb003c84532",
//   "userName": "admin@saiyarti.com",
//   "normalizedUserName": "ADMIN@SAIYARTI.COM",
//   "email": "admin@saiyarti.com",
//   "normalizedEmail": "ADMIN@SAIYARTI.COM",
//   "emailConfirmed": true,
//   "passwordHash": "AQAAAAEAACcQAAAAEPjLhDCboGX8TMAkEYVxgrZPmKWT436lgwNrU1cObVADIGRBaRiiuVNhcAVyZrKh6g==",
//   "securityStamp": "GUJUIHTLW2UR3DNXXSNNSTG7OGPR7CFH",
//   "concurrencyStamp": "6238d722-0f21-4d68-98db-27b5f9702396",
//   "phoneNumberConfirmed": true,
//   "twoFactorEnabled": false,
//   "lockoutEnabled": true,
//   "accessFailedCount": 0
// }

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.css']
})
export class CustomerDetailComponent implements OnInit {
  imgIndex = 2;
  pageTitle = '';
  albums: any = [];
  userData: any;
  error: any;
  pdfList = [];
  selectType:string

  serializedDOB: any = '';
  message: string;
  imagePath: any;
  profileURL: string | ArrayBuffer;
  profileThumbnailUrl: string;

  policyColumns: string[] = ['type', 'policyNumber', 'platNumber', 'expiry', 'viewPolicy'];
  policyDataSource: any[];

  documentColumns: string[] = ['type', 'policyNumber', 'expiry', 'viewPolicy'];
  documentDataSource: any[];


  ordersColumns: string[] = ['orderNo', 'plateNumber', 'type', 'policyNumber', 'status'];
  ordersDataSource: any[];

  thumbCivilFrontImageUrl: any;

  constructor(private customer: CustomerService, private route: ActivatedRoute, private diloag: MatDialog,
    private storageService: StorageService, private lightBox: Lightbox, private userService: UsersService,
    private router: Router,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {

    const id = this.route.snapshot.paramMap.get('id');
    this.customer.getCustomerByCivilId(id).subscribe((userData: any) => {
      this.userData = userData;
      this.userData.documents.forEach((value, index) => {
        if (value.type === 'Civil Card') {
          this.userData.civilNumber = value.number;
          this.userData.expiryDate = value.expiryDate;
          this.userData.civilCard = {id: value.id};
        }

        if (this.userData.documents[index].type === 'RCBook') {
          this.userData.documents[index].imageIndex = 0;
        }
      });

      this.pageTitle = userData.firstName + ' ' + userData.lastName;

      this.serializedDOB = new UntypedFormControl(new Date(this.userData.birthDate));
      this.serializedDOB.disable();

      this.ordersDataSource = this.userData.orders;
      this.documentDataSource = this.userData.documents;

      this.loadCivilImage();

      this.selectType=window.localStorage['type']
      console.log(this.selectType)


    }, err => {
      this.error = err;
    });

    /**
     * To call list of purchase.
     */
    // this.getPurchaseList(id, 0, 100);
  }

  loadCivilImage() {
    const preName = this.userData.civilCard.id;
    const civilFront = preName + '_front.jpg';
    const civilBack = preName + '_back.jpg';

    this.storageService.getSASToken(civilFront, 'civil-card').subscribe((token: any) => {
      this.storageService.getSASToken(civilFront, 'thumbnail-civil-card').subscribe((thumbToken: any) => {
        const civilFrontImageUrl = environment.cdnUrl + '/civil-card/' + civilFront + token.sasToken;
        this.thumbCivilFrontImageUrl = environment.cdnUrl + '/thumbnail-civil-card/'
          + civilFront + thumbToken.sasToken;
        const src = civilFrontImageUrl;
        const caption = 'Civil Card Front';
        const thumb = this.thumbCivilFrontImageUrl;
        const album = {
          src,
          caption,
          thumb
        };
        this.albums[0] = album;
      });
    });

    /**
     * Civil Back Image
     */
    this.storageService.getSASToken(civilBack, 'civil-card').subscribe((token: any) => {
      const civilBackImageUrl = environment.cdnUrl + '/civil-card/' + civilBack + token.sasToken;
      const src = civilBackImageUrl;
      const caption = 'Civil Card Back';
      // const thumb = this.thumbCivilFrontImageUrl;
      const album = {
        src,
        caption
      };
      this.albums[1] = album;
    });

    this.loadRCBookImages();
  }

  loadRCBookImages() {

    this.documentDataSource.forEach((doc, index) => {
     // console.log(doc);
      if (doc.type === 'RCBook') {
        const rcBook = doc.id + '.jpg';
        this.storageService.getSASToken(rcBook, 'rc-book').subscribe((token: any) => {

          const rcBookImg = environment.cdnUrl + '/rc-book/' + rcBook + token.sasToken;
          const src = rcBookImg;
          const caption = 'RC BOOK';
          // const thumb = this.thumbCivilFrontImageUrl;
          const album = {
            src,
            caption
          };

          this.documentDataSource[index].imageIndex = this.albums.length;
          this.albums[this.albums.length] = album;
        });
      }
    });


  }

  openImage(index: number): void {
    console.log(index);
    this.lightBox.open(this.albums, index);
  }
  openRcBook(index: number): void {
    this.lightBox.open(this.albums, index);
  }

  onProfileFileSelected(files) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }

    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      // this.profileThumbnailUrl = reader.result;
    };
  }

  blockCustomer() {
    const dialogRef = this.diloag
      .open(ConfirmDialogComponent, { data: { title: 'Confirm', message: 'Are you sure want to block customer?' } });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // TODO : logic for ok
      } else {
        // TODO : logi for cancel
      }
    });
  }

  getPurchaseList(userId: string, skip: number, limit: number) {
    this.userService.getPurchases(userId, skip, limit).subscribe(res => {
      console.log(res);
      this.policyDataSource = res;
    }, err => {

    });
  }

  getPolicyPdf(data) {

    this.storageService.getSASToken(data.id + '_background.pdf', 'policies').subscribe((token: any) => {
      const url = environment.cdnUrl + '/policies/' + data.id + '_background.pdf' + token.sasToken + '#toolbar=0';
      const pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

      this.diloag.open(DocViewComponent, { data: { url: pdfUrl } });
    }, err => {

    });

  }
  openUser(): void {
    this.router.navigate(['/users/' + this.userData.id]);
  }

  openDocument(row): void {
    if (row.type === 'RCBook') {
      this.router.navigate(['/rcbook/' + row.id]);
    }
  }

  openPurchaseDetail(row): void {
    if (row.status === 'PENDING' ||
      row.status === 'REQUESTRESENT') {
      this.router.navigate(['/consumer-request/purchase-detail/' + row.id]);
    } else if (row.status === 'DISPATCHED' || row.status === 'DELIVERED') {
      this.router.navigate(['/orders/' + row.id]);
    } else {
      this.router.navigate(['/consumer-request/policy-status/' + row.id]);
    }
  }
}
