import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-add-year-dialog',
  templateUrl: './add-year-dialog.component.html',
  styleUrls: ['./add-year-dialog.component.css']
})
export class AddYearDialogComponent implements OnInit {

  yearName: string;
  constructor(private dialogRef: MatDialogRef<AddYearDialogComponent>,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  addYear(): void {
    if ( this.yearName && this.yearName != '' ) {
      this.dialogRef.close({year: this.yearName});
    } else {
      this.snackBar.open("Year value should not be empty.", 'CANCEL', {
        horizontalPosition: 'right',
        duration: 2000
      });
    }
  }

}
