export class Policy {
    companyId: number
    type: string;
    policyStatus: string;
    policyNumber: string;
    insuranceCompanyName: string;
    expiryDate: Date;
    issueDate: Date;
    startDate: Date;
    ownerOccupation: string;
    policyPeriod: string;
    companyName: string;
    departmentName: string;
    civilId: string;
    nationality: string;
    city: string;
    area: string;
    block: string;
    street: string;
    building: string;
    authorizedPersonName: string;
    authorizedPersonPhoneNumber: string;
    authorizedpersonNationality: string;
    authorizedPersonOccupation: string;
    authorizedPersonCivilID: string;
    plateNumber: string;
    carMake: string;
    carModel: string;
    carModelYear: string;
    noOfPassenger: string;
    chassisNumber: string;
    shape: string;
    color: string;
    vehicalStatus: string;
    premiumFee: number;
    issueFee: number;
    supervisionFee: number;
    totalFee: number;
    certificateNumber: number;
    certificateDate: Date;
    weight: number;
    sellerName: string;
    sellerCivilId: string;
    status: string;
    tag: string;
    groupId: string;
}
