import { Company } from "./company";
import { GTSCountry } from "./gts-country";

export class SafrattiTravelPlan {
    id: number;
    travelPlanName: string;
    gtsCountry: GTSCountry;
    countryResidence: string;
    company: Company;
    status: string;
}