import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import jwt_decode from 'jwt-decode';

@Injectable()

export class Helpers {

    private authenticationChanged = new Subject<boolean>();

    constructor() {



    }

    getDecodedAccessToken(token: string): any {
        try {
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }

    public isAuthenticated(): boolean {

        return (!(window.localStorage.token === undefined ||

            window.localStorage.token === null ||

            window.localStorage.token === 'null' ||

            window.localStorage.token === 'undefined' ||

            window.localStorage.token === ''));

    }

    public isAuthenticationChanged(): any {

        return this.authenticationChanged.asObservable();

    }

    public getToken(): any {

        if (window.localStorage.token === undefined ||

            window.localStorage.token === null ||

            window.localStorage.token === 'null' ||

            window.localStorage.token === 'undefined' ||

            window.localStorage.token === '') {

            return '';
        }

        const obj = JSON.parse(window.localStorage.token);

        return obj.token;

    }

    public setPushNotificationToken(data: any): void {
        window.localStorage.pushToken = JSON.stringify(data);
    }

    public getPushNotificationToken(): any {

        if (window.localStorage.pushToken === undefined ||

            window.localStorage.pushToken === null ||

            window.localStorage.pushToken === 'null' ||

            window.localStorage.pushToken === 'undefined' ||

            window.localStorage.pushToken === '') {

            return '';
        }

        const obj = JSON.parse(window.localStorage.pushToken);

        return obj.token;

    }

    public setToken(data: any): void {

        this.setStorageToken(JSON.stringify(data));

    }

    public failToken(): void {

        this.setStorageToken(undefined);

    }

    public logout(): void {
        window.localStorage.email = undefined;
        this.setStorageToken(undefined);

    }

    private setStorageToken(value: any): void {

        window.localStorage.token = value;

        this.authenticationChanged.next(this.isAuthenticated());

    }

    public setEmail(value: any): void {

        window.localStorage.email = value;

    }

    public getEmail(): string {

        return window.localStorage.email;

    }

    public setName(value: any): void {

        window.localStorage.name = value;

    }

    public getName(): string {

        return window.localStorage.name;

    }

    public setCompanyId(value: number): void {
        window.localStorage.companyId = value;
    }

    public getCompanyId(): number {
        return window.localStorage.companyId;
    }

    public setId(value: any): void {
        window.localStorage.id = value;
    }

    public getId(): string {
        return window.localStorage.id;
    }

}