import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.css']
})
export class ReceiptComponent implements OnInit {

  Amount: number;
  ConvenienceFee: number;
  DeliveryFee: number;
  SupervisionFee: number;
  IssueFee: number;
  Premium: number;
  CarMake: string;
  CarModel: string;
  OwnerName: string;
  Address: string;
  CivilCardNumber: string;
  Name: string;
  ReferenceNumber: number;
  IssueDate: string;
  ReceiptNumber: string;
  PlateNumber: string;
  cdnUrl = environment.cdnUrl;

  constructor() { }

  ngOnInit() {
  }

}
