import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Helpers } from 'src/helpers/helpers';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ICountService } from './icount.service';

@Injectable({
  providedIn: 'root'
})
export class StoreService extends BaseService implements ICountService {

  constructor(private http: HttpClient, helper: Helpers) {
    super(helper);
  }

  private pathAPI = environment.apiUrl;

  getData(body: any): any {
    return this.http.post<any>(this.pathAPI + 'Store/Stores', body, super.header()).pipe(catchError(super.handleError));
  }

  getCount(body: any) {
    return this.http.post<any>(this.pathAPI + 'Store/StoreCount', body, super.header()).pipe(catchError(super.handleError));
  }

  createStore(data: any): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'Store/Register', data, super.header()).pipe(catchError(super.handleError));
  }

  getStoreById(id: any): any {
    return this.http.get<any>(this.pathAPI + 'Store/' + id, super.header()).pipe(catchError(super.handleError));
  }

  updateStore(id: any, data: any): any {
    return this.http.put<any>(this.pathAPI + 'Store/' + id, data, super.header()).pipe(catchError(super.handleError));
  }

  updateStoreStatus(id: any, value: boolean): any {
    return this.http.put<any>(this.pathAPI + 'Store/' + ( value ? 'A' : 'Dea' ) + 'ctivate/' + id, null, super.header()).pipe(catchError(super.handleError));
  }

  getPaymentHistory(): any {
    return this.http.get<any>(this.pathAPI + 'Store/Payment' , super.header()).pipe(catchError(super.handleError));
  }

  getPaymentHistoryForStore(id: string): any {
    return this.http.get<any>(this.pathAPI + 'Store/Payment/' + id , super.header()).pipe(catchError(super.handleError));
  }

  addPayment(id: any, data: any): any {
    return this.http.post<any>(this.pathAPI + 'Store/Payment/' + id, data, super.header()).pipe(catchError(super.handleError));
  }

  getDocuments(id): any {
    return this.http.get<any>(this.pathAPI + 'Store/Documents/' + id, super.header()).pipe(catchError(super.handleError));
  }

  addDocuments(id: any, data: any): any {
    return this.http.post<any>(this.pathAPI + 'Store/Documents/' + id, data, super.header()).pipe(catchError(super.handleError));
  }

  getStoresDetail(): any {
    return this.http.get<any>(this.pathAPI + 'Store/StoreDetail', super.header()).pipe(catchError(super.handleError));
  }

  getStoreDetailById(id): any {
    return this.http.get<any>(this.pathAPI + 'Store/StoreDetail/' + id, super.header()).pipe(catchError(super.handleError));
  }

  protected storeheader() {
    return { headers: super.header().headers.append( 'accept-language', 'en' ) };
  }
}
