import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PoliciesService } from 'src/services/policies.service';
import { Policy } from 'src/models/policy';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { CompanyService } from 'src/services/company.service';
import { Helpers } from 'src/helpers/helpers';
import jwt_decode from 'jwt-decode';
import { environment } from 'src/environments/environment';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { StorageService } from 'src/services/storage.service';
import { CompaniesService } from 'src/services/companies.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-policy-detail',
  templateUrl: './policy-detail.component.html',
  styleUrls: ['./policy-detail.component.css']
})
export class PolicyDetailComponent implements OnInit {
  policyDetailAttech = false;
  policyDetailAttechName: string;
  request = new Policy();
  SASToken: string;
  SASTokenForUpload: string;
  attachments = [];
  policyAttachment: File;
  policyAttachmentName: string;
  currentYear: number = (new Date()).getFullYear() + 4;
  companies = [];
  selectedCompany: any;

  groupNames = [];
  groupedNames = [];
  selectedGroupName: string;
  selectedDepartment: any;


  id: string;
  formDisabled = false;
  pageTitle = 'Policy Detail';
  nextButton = 'SAVE';
  obj: any;
  name: string;
  account: any;
  policiesFile: any
  fileName: string

  get StartDate() {
    return this.request.startDate;
  }
  set StartDate(value) {
    this.request.startDate = value;
    if (value) {
      this.calculateExpiryDate();
    }
  }

  get PolicyPeriod() {
    return this.request.policyPeriod;
  }
  set PolicyPeriod(value) {
    this.request.policyPeriod = value;
    if (value) {
      this.calculateExpiryDate();
    }
  }

  constructor(private route: ActivatedRoute,
    private policiesSerivce: PoliciesService,
    private companyService: CompanyService,
    private companiesService: CompaniesService,
    private storageService: StorageService,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private helpers: Helpers,
    private router: Router,
    private dialogRef: MatDialogRef<PolicyDetailComponent>,) {

    this.findMatch = this.findMatch.bind(this);

    this.storageService.getSASTokenForContainer('b2b-policies').subscribe(token => {
      this.SASToken = token.sasToken;
    });
    this.storageService.getUploadOnlyTokenForContainer('b2b-policies').subscribe(token => {
      this.SASTokenForUpload = token.sasToken;
    });
    this.name = this.helpers.getName();
  }

  ngOnInit() {
    this.request.type = 'New';
    this.id = this.route.snapshot.paramMap.get('id');
    this.getCompanies();

    if (this.id !== 'add') {
      this.nextButton = 'UPDATE';
      this.policiesSerivce.getPolicyDetail(+this.id).subscribe((policy: any) => {
        this.request = policy;
        if (this.companies.length > 0) {
          const filteredCompanies = this.companies.filter(x => x.id === this.request.companyId)
          if (filteredCompanies.length > 0) {
            this.selectedCompany = filteredCompanies[0];
          }
        }
        if (this.request.policyPeriod === undefined) {
          this.calculatePolicyPeriod();
        }
        this.selectedGroupName = this.request.companyName;
        if (this.groupNames.length > 0) {
          this.selectedDepartment = this.groupNames.find(x => x.id === this.request.groupId);
          console.log('Found Dept1');
          this.selectedGroupName = this.selectedDepartment.companyName;
        }
        // if (this.request.status === 'Policy Printed') {
        //   this.formDisabled = true;
        // }
      });
      this.policiesSerivce.getDocuments(+this.id).subscribe(res => {
        this.attachments = res;
      });
    }
    this.obj = this.getDecodedAccessToken(this.helpers.getToken());

    if (this.obj.type === 'B2BPolicyAdministrator') {
      this.companyService.getGroups().subscribe(res => {
        this.groupNames = res;
        if (this.selectedGroupName === undefined) {
          this.selectedGroupName = this.groupNames[0].companyName;
        }
        if (this.request !== undefined && this.request.groupId !== undefined) {
          this.selectedDepartment = this.groupNames.find(x => x.id === this.request.groupId);
          this.selectedGroupName = this.selectedDepartment.companyName;
          console.log('Found Dept2');
        }
        this.groupedNames = Array.from(new Set(this.groupNames.map(item => item.companyName)));
      });
    }
    // this.selectedGroupName = this.groupNames[0].companyName;
    // this.groupedNames = Array.from(new Set(this.groupNames.map(item => item.companyName)));
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  logout() {
    this.helpers.logout();
    if (this.obj.type === 'CompanyAdministrator') {
      this.router.navigate(['/company-login']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  getCompanies(): void {
    this.companyService.getCompnayList('Saiyarti').subscribe((companies: any) => {
      this.companies = companies;
      this.selectedCompany = companies[0];
      if (this.request.companyId) {
        this.selectedCompany = this.companies.filter(x => x.id === this.request.companyId)
      }
    });
  }
  savePolicy(form) {
    if (this.obj.type === 'B2BPolicyAdministrator') {
      form.value.companyId = +this.selectedCompany.id;
    } else {
      form.value.companyId = +this.helpers.getCompanyId();
    }
    const id = this.route.snapshot.paramMap.get('id');
    if (this.formDisabled) {
      this.router.navigate(['/policies/policy-print/' + id]);
    } else if (id && +id > 0) {

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirm', message: 'Please ensure that the Policy number is updated correctly. Do you want to continue?'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'true') {
          this.updatePolicy(form);
        }
      });
    } else {
      this.createPolicy(form);
    }
  }

  createPolicy(form) {
    if (this.selectedDepartment !== undefined) {
      form.value.groupId = this.selectedDepartment.id;
    }
    form.value.insuranceCompanyName = undefined;
    form.value.totalFee = this.getTotalFee();
    this.nextButton = 'Generating Documents...';
    this.policiesSerivce.createPolicy(form.value).subscribe(res => {
      this.policiesSerivce.addDocuments(res.id, this.attachments.map(x => x.fileName) as [string]).subscribe(response => {
        this.router.navigate(['/policies/policy-print/' + res.id]);
        this.nextButton = 'SAVE';
      });

    }, err => {
      this.snackBar.open(err);
      this.nextButton = 'SAVE';
    });
  }
  goBack() {
    this.router.navigate(['/policies']);
  }

  updatePolicy(form) {
    this.nextButton = 'Generating Documents...';
    if (this.selectedDepartment !== undefined) {
      form.value.groupId = this.selectedDepartment.id;
    }
    form.value.insuranceCompanyName = undefined;
    form.value.totalFee = this.getTotalFee();
    this.policiesSerivce.updatePolicy(+this.id, form.value).subscribe(res => {
      this.router.navigate(['/policies/policy-print/' + +this.id]);
      this.nextButton = 'UPDATE';
    }, err => {
      this.snackBar.open(err);
      this.nextButton = 'UPDATE';
    });
  }


  showImage(fileName: string) {
    const src = environment.cdnUrl + '/b2b-policies/' + fileName + this.SASToken;
    window.open(src);
  }


  selectDoc(event) {
    const file = event.target.files[0];
    this.policyAttachment = file;
  }

  uploadDoc() {
    if (this.policyAttachment !== undefined) {
      const d = new Date();
      this.storageService
        .getUploadOnlyTokenForContainer("b2b-policies")
        .subscribe((token) => {
          this.storageService
            .uploadFileToAzure(
              `/b2b-policies/${this.selectedDepartment.id +
              "__" +
              this.account.id +
              "__" +
              d.getTime().toString() +
              ".csv"
              }${token.sasToken}`,
              this.policiesFile,
              "image/jpg"
            )
            .subscribe({
              next: (res) => {
                this.dialogRef.close();
                this.snackBar.open(
                  "The file has been uploaded. An email will be sent to your account as soon as processed.",
                  "CANCEL",
                  {
                    horizontalPosition: "right",
                    duration: 4000,
                  }
                );
                const id = +this.activatedRoute.snapshot.paramMap.get('id');
                if (id > 0) {
                  this.policiesSerivce.addDocuments(id, [this.fileName]).subscribe(res => {
                    this.policyAttachment = undefined;
                    this.attachments.push({ name: this.fileName, createdAt: new Date() });
                  });
                } else {
                  this.policyAttachment = undefined;
                  this.attachments.push({ name: this.fileName, createdAt: new Date() });
                }
              },
            });
        });
    }
  }
  findMatch(groupName) {
    return this && groupName.companyName === this.selectedGroupName;
  }

  getTotalFee(): number {
    if (this.request) {
      return (
        (this.request.premiumFee ? (+this.request.premiumFee || 0) : 0) +
        (this.request.issueFee ? (+this.request.issueFee || 0) : 0) +
        (this.request.supervisionFee ? (+this.request.supervisionFee || 0) : 0));
    }
    return 0;
  }

  calculateExpiryDate(): void {
    if (this.request && this.request.startDate && this.request.policyPeriod) {
      let startDate = new Date(this.request.startDate);
      let expiryDate = new Date(startDate.getFullYear() + +this.request.policyPeriod, startDate.getMonth(), startDate.getDate());

      if (expiryDate) {
        this.request.expiryDate = expiryDate;
      }
    }
  }

  calculatePolicyPeriod(): void {
    if (this.request && this.request.startDate && this.request.expiryDate) {
      let startDate = new Date(this.request.startDate), expiryDate = new Date(this.request.expiryDate);
      let diff = (expiryDate.getMonth() + (12 * (expiryDate.getUTCFullYear() - startDate.getUTCFullYear()))) - startDate.getMonth();
      if (diff >= 11 && diff <= 13) {
        this.request.policyPeriod = "1";
      } else if (diff >= 23 && diff <= 25) {
        this.request.policyPeriod = "2";
      } else if (diff >= 35 && diff <= 37) {
        this.request.policyPeriod = "3";
      }
    }
  }
}
