import { Component, OnInit, Inject } from "@angular/core";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from "@angular/material/legacy-dialog";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { environment } from "src/environments/environment";
import { StorageService } from "src/services/storage.service";
import { Router } from "@angular/router";
import { CompanyService } from "src/services/company.service";
import { AccountService } from "src/services/account.service";

@Component({
  selector: "app-b2b-bulk-upload-dialog",
  templateUrl: "./b2b-bulk-upload-dialog.component.html",
  styleUrls: ["./b2b-bulk-upload-dialog.component.css"],
})
export class B2BBulkUploadDialogComponent implements OnInit {
  groupNames = [];
  groupedNames = [];
  selectedGroupName: string;
  selectedDepartment: any;
  processing = false;
  policiesFile: any;
  policiesEle: string;
  account: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<B2BBulkUploadDialogComponent>,
    private storageService: StorageService,
    private snackBar: MatSnackBar,
    private companyService: CompanyService,
    private accountService: AccountService,
    private router: Router
  ) {
    this.accountService.getAccount().subscribe((res) => {
      this.account = res;
      console.log(this.account.id);
    });
  }

  ngOnInit() {
    this.findMatch = this.findMatch.bind(this);
    this.companyService.getGroups().subscribe((res) => {
      this.groupNames = res;
      this.selectedGroupName = this.groupNames[0].companyName;
      this.groupedNames = Array.from(
        new Set(this.groupNames.map((item) => item.companyName))
      );
    });
  }

  onPolicies(event) {
    const file = event.target.files[0];
    this.policiesEle = file.name;
    this.policiesFile = file;
  }

  uploadCSV() {
    if (this.policiesFile !== undefined) {
      const d = new Date();

      this.storageService
        .getUploadOnlyTokenForContainer("b2b-policies-csv")
        .subscribe((token) => {
          this.storageService
            .uploadFileToAzure(
              `/b2b-policies-csv/${
                this.selectedDepartment.id +
                "__" +
                this.account.id +
                "__" +
                d.getTime().toString() +
                ".csv"
              }${token.sasToken}`,
              this.policiesFile,
              "image/jpg"
            )
            .subscribe({
              next: (res) => {
                this.dialogRef.close();
                this.snackBar.open(
                  "The file has been uploaded. An email will be sent to your account as soon as processed.",
                  "CANCEL",
                  {
                    horizontalPosition: "right",
                    duration: 4000,
                  }
                );
              },
            });
        });
    }
  }

  findMatch(groupName) {
    return this && groupName.companyName === this.selectedGroupName;
  }
}
