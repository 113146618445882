import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-tpl-request-stepper',
  templateUrl: './tpl-request-stepper.component.html',
  styleUrls: ['./tpl-request-stepper.component.css']
})
export class TplRequestStepperComponent implements OnInit {
  @Input() tplRequestStatus : number;
  @Input() tplRequestOwnerName : string;
  constructor() { }

  ngOnInit() {
  }

}
