export class AddAgent {

    constructor() { }

    firstName: string;
    lastName: string;
    region: string;
    phoneNumber: string;
    countryCode: number;
    email: string;
    position: string;
    role: string;
    companyName: string;
    departmentName: string;
    defaultCompanyId?: string;
    type: string
}
