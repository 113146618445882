import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import { TokenService } from '../../services/token.service';

import { Helpers } from '../../helpers/helpers';

import jwt_decode from 'jwt-decode';
import { AccountService } from 'src/services/account.service';


@Component({
  selector: 'app-company-login',
  templateUrl: './company-login.component.html',
  styleUrls: ['./company-login.component.css']
})
export class CompanyLoginComponent implements OnInit {
  hide = true;
  constructor(private helpers: Helpers, private router: Router, private tokenService: TokenService, private accountService: AccountService) { }

  email: string;
  password: string;
  error: any;
  ngOnInit() {

    if (this.helpers.isAuthenticated()) {
      this.router.navigate(['/policies']);
    } else {
      this.helpers.logout();
    }

  }

  login(): void {

    const authValues = { email: this.email, password: this.password };

    this.tokenService.loginForCompanyPortal(authValues).subscribe(token => {
      this.helpers.setName(token.name);
      this.helpers.setToken(token);
      this.accountService.getAccount().subscribe(account => this.storeAccountDetails(account), err => { this.error = err });
      this.helpers.setEmail(this.email);
    }, err => {
      this.error = err;
    });

  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  storeAccountDetails(account): void {
    if (account) {
      this.helpers.setCompanyId(account.defaultCompanyId ? account.defaultCompanyId : 0);
    }
    this.router.navigate(['/policies']);
  }

}
