import { Component, OnInit, AfterContentInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Helpers } from 'src/helpers/helpers';
import { TableComponent } from '../table.component';
import jwt_decode from 'jwt-decode';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Policy } from 'src/models/policy';
import { UserService } from 'src/services/user.service';
import { CustomerService } from 'src/services/customer.service';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css']
})
export class UserDashboardComponent extends TableComponent<Policy> implements OnInit, AfterContentInit {

  pageTitle = 'Referral Report';
  counts: any = { installs: 0, registration: 0, carsAdded: 0, conversionCounts: 0, totalNumberOfCars: 0, totalNumberOfSales: 0, totalFees: 0.000, supervisionFee: 0.000, issueFee: 0.000, premiumFees: 0.000, };
  name: string;
  displayedColumns: string[] =
    [
      'date',
      'installs',
      'registrations',
      'carsAdded',
      'conversionCounts',
      'totalNumberOfSales',
      'totalNumberOfCars',
      'supervisionFee',
      'issueFee',
      'premiumFees'
    ];
  user: any;
  isStoreCoordinator: boolean;
  id: string;
  backLink: string;
  userData: any;

  constructor(private userService: UserService,
    private router: Router,
    private confirmDialog: MatDialog,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private customer: CustomerService,
    private helpers: Helpers) {
    super(userService);
    this.id = this.route.snapshot.paramMap.get('id');
    this.backLink = '/users/' + this.id;
    this.userService.id = this.id;
    this.user = this.getDecodedAccessToken(this.helpers.getToken());
  }

  ngOnInit() {
    super.ngOnInit();

    this.customer.getCustomer(this.id).subscribe((userData: any) => {
      this.userData = userData;
    });

    this.userService.getDashboardCounts(this.id).subscribe(
      response => { this.counts = response; },
      error => {
        this.snackBar.open(error, 'CANCEL', {
          horizontalPosition: 'right'
        });
      }
    );
  }

  ngAfterContentInit() {
    this.name = this.helpers.getName();
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  downloadXLS(isXLS: boolean) {
    const data = {
      userId: this.id,
      asXLS: isXLS,
      skip: 0,
      take: 2147483647,
      orderByDescending: true,
      orderBy: "string"
    };

    this.userService.downloadReport(data);
  }

  // updateUserActivateStatus(store) {
  //   const dialogMsg = store.activated ? 'Once deactivated the store will not be visible in the list. Are you sure you want deactivate?' :
  //     'Once activated the store will be visible in the list. Are you sure you want activate?';
  //   const dialogRef = this.confirmDialog.open(ConfirmDialogComponent, { data: { title: 'Confirm', message: dialogMsg } });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result === 'true') {
  //       this.storeService.updateStoreStatus(store.id, !store.activated).subscribe(res => {
  //         this.loadRequests(this.getData());
  //         this.snackBar.open('Store ' + (store.activated ? 'de' : '') + 'activated successfully.', 'CANCEL', {
  //           horizontalPosition: 'right',
  //           duration: 2000
  //         });
  //       }, err => {
  //         this.snackBar.open(err, 'CANCEL', {
  //           horizontalPosition: 'right'
  //         });
  //       });
  //     }
  //   });
  // }

  logout() {
    this.helpers.logout();
    this.router.navigate(['/user-login']);
  }

}
