import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Helpers } from '../helpers/helpers';
import { catchError } from 'rxjs/operators';
import { ICountService } from './icount.service';
import { NewCustomer } from 'src/models/newcustomer';

@Injectable({
  providedIn: 'root'
})
export class RenewCustomerService extends BaseService implements ICountService {

  private pathAPI = environment.apiUrl;

  public errorMessage: string;

  constructor(private http: HttpClient, helper: Helpers) { super(helper); }


  /**
   * To get all user/cusotmer
   */
  getData(body: any): any {

    return this.http.post<any>(this.pathAPI + 'Account/GetRenewCustomers', body, super.header()).pipe(
      catchError(super.handleError)
    );
  }


  getCount(body: any): any {
    return this.http.post<any>(this.pathAPI + 'Account/GetRenewCustomersCount', body, super.header()).pipe(catchError(super.handleError));
  }

  get(id: number): any {
    return this.http.get<NewCustomer>(this.pathAPI + 'Account/GetRenewCustomer/' + id, super.header()).pipe(catchError(super.handleError));
  }


  update(id: number, customer: NewCustomer): any {
    return this.http.post<any>(this.pathAPI + 'Account/RenewCustomer/' + id, customer, super.header()).pipe(catchError(super.handleError));
  }

  sendPolicyRenewalSMS(id: number, lang?: string): any {
    return this.http.post<any>(this.pathAPI + 'Account/SendPolicyRenewalSMS?RenewCustomerId=' + id, {}, super.header(null, lang)).pipe(catchError(super.handleError));
  }
}
