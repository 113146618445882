import { Component, OnInit, ViewChild, AfterContentInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Helpers } from 'src/helpers/helpers';
import jwt_decode from 'jwt-decode';
import { Store } from 'src/models/store';
import { StoreService } from 'src/services/store.service';
import { MakePaymentDialogComponent } from '../make-payment-dialog/make-payment-dialog.component';
import { StorageService } from 'src/services/storage.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-store-detail',
  templateUrl: './store-detail.component.html',
  styleUrls: ['./store-detail.component.css']
})
export class StoreDetailComponent implements OnInit, AfterContentInit {

  id: string;
  formDisabled: boolean = false;
  isStoreLoaded: boolean = false;
  isStoreCoordinator: boolean = false;
  pageTitle = 'Store Detail';
  nextButton = 'Add';
  user: any;
  name: string;
  counts: any;
  selectedDepartment:any;
  account:any;
  fileName:string;

  storeData: Store = new Store();
  networkType: any = {
    Physical: 'Physical',
    Virtual: 'Virtual'
  };

  attachments = [];
  storeAttachment: File;
 
  paymentHistoryColumns: string[] = ['storeName', 'amount', 'notes', 'dateCreated'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  constructor(private activatedRoute: ActivatedRoute,
    private storeService: StoreService,
    private storageService: StorageService,
    private snackBar: MatSnackBar,
    private helpers: Helpers,
    private dialog: MatDialog,
    private router: Router,private dialogRef: MatDialogRef<StoreDetailComponent>) {

    this.user = this.getDecodedAccessToken(this.helpers.getToken());


  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.isStoreCoordinator = this.user.type.indexOf('StoreCoordinator') === 0;

    this.storeService.getStoreDetailById(this.id).subscribe(
      response => { this.counts = response; },
      error => {
        this.snackBar.open(error, 'CANCEL', {
          horizontalPosition: 'right'
        });
      }
    );

    if (this.id !== 'add') {
      this.nextButton = 'UPDATE';
      this.formDisabled = true;
      this.isStoreLoaded = true;
      this.storeService.getStoreById(this.id).subscribe((store: Store) => {
        this.storeData = store;
      });
      this.getPaymentHistory(this.id);
      this.getStoreDocuments(this.id);
    }
    else {
      this.isStoreLoaded = false;
      this.formDisabled = false;
      this.storeData.type = this.isStoreCoordinator ? 'Virtual' : 'Physical';
    }
  }

  ngAfterContentInit() {
    this.name = this.helpers.getName();
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  enableFormForEdit() {
    if (this.formDisabled === true) {
      this.formDisabled = false;
    } else {
      this.formDisabled = true;
    }
    return false;
  }

  saveStoreData() {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    if (id !== 'add') {
      this.updateStore();
    } else {
      this.createStore();
    }
  }

  createStore() {
    this.storeService.createStore(this.storeData).subscribe(res => {
      if (this.attachments.length > 0) {
        this.storeService.addDocuments(res.id, this.attachments.map(x => x.fileName) as [string]).subscribe(response => {
          this.snackBar.open('Store added successfully.', 'CANCEL', {
            horizontalPosition: 'right',
            duration: 2000
          });
          this.router.navigate(['/store']);
        }, err => {
          this.snackBar.open(err);
        });
      }
      else {
        this.snackBar.open('Store added successfully.', 'CANCEL', {
          horizontalPosition: 'right',
          duration: 2000
        });
        this.router.navigate(['/store']);
      }
    }, err => {
      this.snackBar.open(err);
    });
  }

  updateStore() {
    this.storeService.updateStore(this.id, this.storeData).subscribe(res => {
      this.snackBar.open('Store update successfully.', 'CANCEL', {
        horizontalPosition: 'right',
        duration: 2000
      });
      this.storeService.getStoreById(this.id).subscribe((store: Store) => {
        this.storeData = store;
      });
      this.enableFormForEdit();
    }, err => {
      this.snackBar.open(err);
    });
  }

  getPaymentHistory(id: string) {
    this.storeService.getPaymentHistoryForStore(id).subscribe((history: any) => {
      this.dataSource = new MatTableDataSource(history);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  makePayment() {
    const dialogRef = this.dialog.open(MakePaymentDialogComponent, { data: { title: 'Make a payment' } });
    dialogRef.afterClosed().subscribe(result => {
      if (result.status) {
        this.storeService.addPayment(this.storeData.id, { amount: result.amount, notes: result.notes }).subscribe(
          res => {
            this.snackBar.open('Payment added successfully.', 'CANCEL', {
              horizontalPosition: 'right',
              duration: 2000
            });
            this.getPaymentHistory(this.id);
          },
          err => {
            this.snackBar.open(err);
          }
        )
      }
    });
  }

  selectDoc(event) {
    const file = event.target.files[0];
    this.storeAttachment = file;
  }

  showImage(fileName: string) {
    const src = environment.cdnUrl + '/' + 'b2b-policies' + '/' + fileName + this.storageService.getUploadOnlyTokenForContainer('b2b-policies');
    window.open(src);
  }

  uploadDoc() {
    if (this.storeAttachment !== undefined) {
      const d = new Date();
      this.storageService
        .getUploadOnlyTokenForContainer("b2b-policies")
        .subscribe((token) => {
          this.storageService
            .uploadFileToAzure(
              `/b2b-policies/${
                this.selectedDepartment.id +
                "__" +
                this.account.id +
                "__" +
                d.getTime().toString() +
                ".csv"
              }${token.sasToken}`,
              this.storeAttachment,
              "image/jpg"
            )
            .subscribe({
              next: (res) => {
                this.dialogRef.close();
                this.snackBar.open(
                  "The file has been uploaded. An email will be sent to your account as soon as processed.",
                  "CANCEL",
                  {
                    horizontalPosition: "right",
                    duration: 4000,
                  }
                );
                let id = this.activatedRoute.snapshot.paramMap.get('id');
                if (id != "add") {
                  this.storeService.addDocuments(id, [this.fileName]).subscribe(res => {
                    this.storeAttachment = undefined;
                    this.attachments.push({ name:this.fileName, createdAt: new Date() });
                  });
                } else {
                  this.storeAttachment = undefined;
                  this.attachments.push({ name:this.fileName, createdAt: new Date() });
                }
    
              },
            });
        });
    }
  }

  getStoreDocuments(storeId) {
    this.storeService.getDocuments(storeId).subscribe(res => {
      this.attachments = res;
    });
  }

  goBack() {
    this.router.navigate(['/store']);
  }

  logout() {
    this.helpers.logout();
    this.router.navigate(['/login']);
  }
}
