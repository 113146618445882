import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TransportDocXyModelComponent } from 'src/app/model-dialog/transport-doc-xy-model/transport-doc-xy-model.component';
import { StorageService } from 'src/services/storage.service';
import { Lightbox } from 'ngx-lightbox';
import { environment } from '../../../../environments/environment';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-transport-department-doc',
  templateUrl: './transport-department-doc.component.html',
  styleUrls: ['./transport-department-doc.component.css']
})
export class TransportDepartmentDocComponent implements OnInit {
  
  tdEle: any;
  tdFile: any;

  docType: any;
  docTypeFile: any;
  selectedDepartment:any;
  account:any;

  constructor(private diloag: MatDialog, private storageService: StorageService,
    private snackBar: MatSnackBar, 
    private lightBox: Lightbox,private dialogRef: MatDialogRef<TransportDepartmentDocComponent>) {

   
  }

  ngOnInit() {
  }

  uploadDoc() {
    if (this.tdFile !== undefined) {
      const d = new Date();
      this.storageService
        .getUploadOnlyTokenForContainer("transport")
        .subscribe((token) => {
          this.storageService
            .uploadFileToAzure(
              `/transport/${
                this.selectedDepartment.id +
                "__" +
                this.account.id +
                "__" +
                d.getTime().toString() +
                ".csv"
              }${token.sasToken}`,
              this.tdFile,
              "image/jpg"
            )
            .subscribe({
              next: (res) => {
                this.dialogRef.close();
                this.snackBar.open(
                  "The file has been uploaded. An email will be sent to your account as soon as processed.",
                  "CANCEL",
                  {
                    horizontalPosition: "right",
                    duration: 4000,
                  }
                );
              },
            });
        });
    }
  }

  onTrafficDepartment(event) {
    const file = event.target.files[0];
    this.tdEle = file.name;
    this.tdFile = file;
  }

  onDocType(event) {
    const file = event.target.files[0];
    this.docType = file.name;
    this.docTypeFile = file;
  }

  editXYDoc() {
    const dialogConfig = new MatDialogConfig();
    const title = ' Print Configuration';
    dialogConfig.data = { title, message: 'Error to create company.' };
    dialogConfig.width = '60%';
    dialogConfig.position = {
      // 'top':'5%'
    };
    const diloagRef = this.diloag.open(TransportDocXyModelComponent, dialogConfig);
  }

  save(form) {

  }

  showImage(filename: string, caption: string) {
    const src = environment.cdnUrl + '/transport/' + filename + this.storageService.getUploadOnlyTokenForContainer('transport');
    const thumb = src;
    const album = {
      src,
      caption,
      thumb
    };

    this.lightBox.open([album], 0);
  }

  showOverlay(filename: string, caption) {
      this.showImage(filename, caption);
  }

  viewTransportDocument() {
    this.showOverlay('template.jpg', ' Transport Document');
  }

}
