import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomerService } from 'src/services/customer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PointService } from 'src/services/point.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { NgModel } from '@angular/forms';

export class _redeem {
  method = 'Cash';
  pointAmount: number;
}

@Component({
  selector: 'app-redeem',
  templateUrl: './redeem.component.html',
  styleUrls: ['./redeem.component.css']
})
export class RedeemComponent implements OnInit {
  userData: any;
  pageTitle = ''
  redeem: _redeem;
  remainingPoint = 0;
  kdAmount = 0.00;

  @ViewChild('points') pwConfirmModel: NgModel;

  constructor(private customer: CustomerService, private route: ActivatedRoute, private pointService: PointService,
    private snackBar: MatSnackBar, private router: Router) { }

  ngOnInit() {
    this.redeem = new _redeem();

    this.loadCustomer();
  }

  calculateRemainingPoint() {
    this.remainingPoint = (this.userData.pointBalance - this.redeem.pointAmount);
    if (isNaN(this.remainingPoint)) {
      this.remainingPoint = 0;
    }

    if (!isNaN(this.redeem.pointAmount)) {
      this.kdAmount = (this.redeem.pointAmount / 20);
      if (this.remainingPoint < 0) {
        this.pwConfirmModel.control.setErrors({ remaining: 'The value' });
      }
    } else {
      this.pwConfirmModel.control.setErrors({ error: 'Required' });
    }
  }

  loadCustomer() {
    const id = this.route.snapshot.paramMap.get('id');
    this.customer.getCustomer(id).subscribe((userData: any) => {
      this.userData = userData;
      this.pageTitle = userData.firstName + ' ' + userData.lastName;

      this.calculateRemainingPoint();
    }, () => {

    });
  }

  redeemPoint() {
    const data = {
      userId: this.route.snapshot.paramMap.get('id'),
      amount: this.redeem.pointAmount,
      method: this.redeem.method
    };
    this.pointService.redeemPoints(data).subscribe(() => {
      this.router.navigate(['/users/' + this.route.snapshot.paramMap.get('id')]);
    }, err => {
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right'
      });
    });
  }

}
