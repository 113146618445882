import { Component, OnInit } from '@angular/core';
import { ReferralService } from 'src/services/referral.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-referral-add',
  templateUrl: './referral-add.component.html',
  styleUrls: ['./referral-add.component.css', '../fee-setup/fee-setup.component.css']
})
export class ReferralAddComponent implements OnInit {

  name: string;
  type: string = 'TPL';
  startDate: Date;
  endDate: Date;
  worthA: number;
  worthB: number;
  worthC: number;

  constructor(private referralService: ReferralService,
    private snackBar: MatSnackBar,
    private router: Router,
    private location: Location) { }

  ngOnInit() {
  }

  createReferralScheme(form) {
    this.referralService.addReferralScheme(form.value).subscribe(res => {
      this.snackBar.open('Referral Scheme created successfully.', 'CANCEL', {
        horizontalPosition: 'right',
        duration: 2000
      });
      this.router.navigate(['/setup/referral']);
    }, err => {
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right'
      });
    });
  }

  back() {
    this.location.back();
  }

}
