import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Helpers } from 'src/helpers/helpers';
import { catchError } from 'rxjs/operators';
import { ICountService } from './icount.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends BaseService implements ICountService {
  constructor(private http: HttpClient, helper: Helpers) {
    super(helper);
  }

  private pathAPI = environment.apiUrl;

  getData(body: any): any {
    return this.http.post<any>(this.pathAPI + 'User/Users', body, super.header())
      .pipe(catchError(super.handleError));
  }

  getPurchases(userId: string, skip: number, limit: number): any {
    return this.http.get<any>(this.pathAPI + 'User/Purchases/' + userId + '?skip=' + skip + '&limit=' + limit, super.header())
      .pipe(catchError(super.handleError));
  }
  getUser(userId: string): any {
    return this.http.get<any>(this.pathAPI + 'User/' + userId, super.header()).pipe(catchError(super.handleError));
  }

  getCount(body: any): any {
    return this.http.post<any>(this.pathAPI + 'User/GetUsersCount', body, super.header()).pipe(catchError(super.handleError));
  }
}
