import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.css']
})
export class SelectLanguageComponent implements OnInit {

  languages: string[] = [
    'Arabic',
    'English'
  ];
  selectedLanguage: string = 'English';

  constructor() { }

  ngOnInit(): void {
  }

}
