import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-setup",
  templateUrl: "./setup.component.html",
  styleUrls: ["./setup.component.css"],
})
export class SetupComponent implements OnInit {
  pageTitle = "Setup";
  typeOfProject: string = "Saiyarti";
  projectSelected = true;
  setupList: any;
  constructor() {}

  ngOnInit() {}

  receiveData(data: string) {
    this.typeOfProject = data;
  }
}
