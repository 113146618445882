
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';



import { catchError } from 'rxjs/operators';

import { Helpers } from '../../helpers/helpers';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { ReportService } from '../report.service';

import { ICountService } from '../icount.service';

@Injectable({
    providedIn: 'root'
})
export class ReferralReportService extends ReportService implements ICountService {
    constructor(protected http: HttpClient, helper: Helpers, protected snackBar: MatSnackBar) { super(http, helper, snackBar); }

    getCount(body: any) {
        return this.http.post<any>(this.pathAPI + 'reports/GetReferralReportCount', body, super.header())
        .pipe(catchError(super.handleError));
    }

    getData(body: any) {
        return this.http.post<any>(this.pathAPI + 'reports/GetReferralReport', body, super.header())
            .pipe(catchError(super.handleError));
    }


    downloadReport(body: any) {
        return super.downloadReport('DownloadReferralReport', body);
    }
}
