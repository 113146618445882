import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Helpers } from 'src/helpers/helpers';
import jwt_decode from 'jwt-decode';
import { AccountService } from 'src/services/account.service';
import { FlatTreeControl, NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';

interface MenuItem {
  name: string;
  children?: MenuItem[];
}

const RequestTab: MenuItem[] = [{
  name: 'Requests',
  children: [
    { name: 'TPL' },
    { name: 'Comprehensive' },
    { name: 'Safratti'}
  ]
}];

const OrderTab: MenuItem[] = [{
  name: 'Orders',
  children: [
    { name: 'TPL' },
    { name: 'Comprehensive' },
    { name: 'Safratti'}
  ]
}];

const ReportTab: MenuItem[] = [{
  name: 'Reports',
  children: [
    { name: 'Saiyarti' },
    { name: 'Safratti' }
  ]
}];

interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.css']
})
export class LeftMenuComponent implements OnInit {
  pageTitle: string;
  userFullName = '';
  userPosition = '';
  userAddress = '';
  userType = '';
  obj: any;
  accountObj: any;
  showLeftMenu: boolean;
  isRequestExpanded: boolean;

  private _transformer = (node: MenuItem, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
    };
  }

  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level, node => node.expandable);

  orderTreeControl = new FlatTreeControl<FlatNode>(
    node => node.level, node => node.expandable);

  reportTreeControl = new FlatTreeControl<FlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  requestDS = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  orderDS = new MatTreeFlatDataSource(this.orderTreeControl, this.treeFlattener);
  reportDS = new MatTreeFlatDataSource(this.reportTreeControl, this.treeFlattener)

  constructor(private helper: Helpers, private account: AccountService, private router: Router) {
    this.requestDS.data = RequestTab;
    this.orderDS.data = OrderTab;
    this.reportDS.data = ReportTab
  }

  ngOnInit() {
    if (this.router.url == '/consumer-request' || this.router.url == '/comprehensive-consumer-request' ||this.router.url == '/consumer-safratti-request' || this.router.url.indexOf("/consumer-request/") > -1 || this.router.url.indexOf("/comprehensive-consumer-request/") > -1 || this.router.url.indexOf("/consumer-safratti-request/") > -1) {
      this.treeControl.expand(this.treeControl.dataNodes[0]);
    }

    if (this.router.url == '/orders' || this.router.url == '/comprehensive-orders' || this.router.url.indexOf("/orders/") > -1 || this.router.url.indexOf("/comprehensive-orders/") > -1) {
      this.orderTreeControl.expand(this.orderTreeControl.dataNodes[0]);
    }

    if (this.router.url == '/reports' ||this.router.url == '/safratti-report' || this.router.url.indexOf("/reports") > -1 || this.router.url.indexOf("/safratti-report") > -1 ) {
      this.reportTreeControl.expand(this.reportTreeControl.dataNodes[0]);
    }
    this.obj = this.getDecodedAccessToken(this.helper.getToken());
    // console.log(this.obj);
    this.userType = this.obj.type;
    this.showLeftMenu = !(this.obj.type.indexOf('StoreSalesPerson') === 0 || this.obj.type.indexOf('StoreCoordinator') === 0);
    this.getAccount();
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;

  getAccount(): any {
    return this.account.getAccount().subscribe(res => {
      this.accountObj = res;
      this.userFullName = this.accountObj.firstName + ' ' + this.accountObj.lastName;
      if (this.obj.type.indexOf('StoreSalesPerson') === 0 || this.obj.type.indexOf('StoreCoordinator') === 0) {
        this.helper.setName(this.userFullName);
      }
    }, err => {

    });
  }
  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }
}
