import { Component, OnInit } from "@angular/core";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { ActivatedRoute } from "@angular/router";
import {
  BlobService,
  UploadConfig,
  UploadParams,
} from "angular-azure-blob-service";
import { Lightbox } from "ngx-lightbox";
import { environment } from "src/environments/environment";
import { StorageService } from "src/services/storage.service";
import { Location } from "@angular/common";
import { OrderService } from "src/services/order.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { OwlOptions } from "ngx-owl-carousel-o";
import {
  PolicyDetails,
  PolicyDocumentServiceFactory,
} from "src/services/factory/policy-document-service-factory";
import { GTSService } from "src/services/gts.service";
import { CompanyService } from "src/services/company.service";
import { SafrattiOrderDetail } from "src/models/safratti-order-detail";
import { TplService } from "src/services/tpl.service";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-safratti-order-detail",
  templateUrl: "./safratti-order-detail.component.html",
  styleUrls: ["./safratti-order-detail.component.css"],
})
export class SafrattiOrderDetailComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
    },
    nav: false,
  };

  formDisabled: boolean = true;
  albums: any = [];
  policyCopy: any;
  thumbInvoiceImageUrl: any;
  invoice: string;
  policyFront: any;
  SASTokenForUploadPolicyCopy: string;
  AzureConfig: UploadParams = {
    sas: "",
    storageAccount: environment.storageAccount,
    containerName: "civil-card",
  };
  uploadConfig: UploadConfig;
  safrattiPolicyId: string;
  orderDetail: SafrattiOrderDetail;
  carImagesList: any;
  public slideIndex = 1;
  policycopyDocumentUrl;
  termsconditionDocumentUrl;
  membershipcardDocumentUrl;
  invoiceDocumentUrl;
  membershipUrl: SafeResourceUrl;
  docContainerName: string = "safratti-policy-book";
  companies = [];
  safrattiLables = [];
  convertToBase64: string;
  travelerDetailDataSource: MatTableDataSource<any>;
  travelerHistory: any;
  tDDisplayedColumns = ["relation", "firstName", "middleName","lastName","gender","passportNo","dateOfBirth"];

  constructor(
    private lightbox: Lightbox,
    private order: OrderService,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private _policyDocumentServiceFactory: PolicyDocumentServiceFactory,
    private snackBar: MatSnackBar,
    private location: Location,
    private sanitizer: DomSanitizer,
    private _gtsService: GTSService,
    private _companyService: CompanyService,
    private tplService: TplService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    const id = +this.route.snapshot.paramMap.get("id");
    const state: any = this.location.getState();
    if (state && state.policyIssuer) {
      const policyIssuer = state.policyIssuer;
    this.order.getOrderDetail(id).subscribe(async (orderDetail) => {
      this.orderDetail = orderDetail;
      this.safrattiPolicyId = orderDetail.policyId;
      this.travelerHistory = orderDetail.travelersDetails
      this.travelerDetailDataSource = new MatTableDataSource(this.travelerHistory)
      this.tplService.getSafrattiLabels(orderDetail.companyId).subscribe(
        (res) => {
          this.safrattiLables = res;
        },
        (err) => {
          console.log(err);
        }
      );
      this._companyService
        .getCompanyById(orderDetail.companyId)
        .subscribe((res) => {
          const safratti = res.safratti;
          const pdfUrl = environment.cdnUrl + "/company/" + safratti;
          this.convertImageToBase64(pdfUrl).subscribe((converted64) => {
            this.convertToBase64 = converted64;
          });
        });
      const body = environment.authenticate;
      //
      this._gtsService.authenticate(body).subscribe((res) => {
        this.loadDocuments(res.token,policyIssuer);
      });
    });
  }
}

  convertImageToBase64(imageUrl: string): Observable<string> {
    return this.http
      .get(imageUrl, { responseType: "blob" })
      .pipe(switchMap((blob: Blob) => this.convertBlobToBase64(blob)));
  }

  convertBlobToBase64(blob: Blob): Observable<string> {
    const reader = new FileReader();

    return new Observable<string>((observer) => {
      reader.onloadend = () => {
        const base64String = reader.result as string;
        observer.next(base64String);
        observer.complete();
      };

      reader.readAsDataURL(blob);
    });
  }
  dateformate(val: any) {
    const date = new Date(val);
    if (date.toString() == "Invalid Date") {
      return (
        val.split("-")[2] + "/" + val.split("-")[1] + "/" + val.split("-")[0]
      );
    } else {
      return (
        date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
      );
    }
  }
  onChangeEvent(t: any) {}
  goBack(): void {
    this.location.back();
  }

  open(index: number): void {
    this.lightbox.open(this.albums, index);
  }

  getPessangerList() {}

  loadDocuments(token: string,policyIssuer:string): void {
    const policyDocumentService =
      this._policyDocumentServiceFactory.getPolicyDocumentServiceWithPolicyId(
        this.orderDetail.gtsPolicyId,policyIssuer
      );
    policyDocumentService.downloadPolicy((blob) => {
      const url = URL.createObjectURL(blob);
      this.policycopyDocumentUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });
    policyDocumentService.downloadMembershipCard((blob: Blob) => {
      const url = URL.createObjectURL(blob);
      this.membershipUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });

    policyDocumentService.downloadTermsAndConditions().subscribe((res) => {
      const file = new Blob([res.body], { type: "application/pdf" });
      const url = URL.createObjectURL(file);
      this.termsconditionDocumentUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });
  }
  
  formatOrderDate(val: any) {
    const [datePart, timePart] = val.split("T");

    const utcDate = new Date(`${datePart}T${timePart}Z`);

    const kuwaitOffset = 3 * 60 * 60 * 1000; // Kuwait is UTC+3 hours
    const kuwaitDate = new Date(utcDate.getTime() + kuwaitOffset);

    const hours = String(kuwaitDate.getUTCHours()).padStart(2, "0");
    const minutes = String(kuwaitDate.getUTCMinutes()).padStart(2, "0");
    const seconds = String(kuwaitDate.getUTCSeconds()).padStart(2, "0");
    const kuwaitTimeString = `${hours}:${minutes}:${seconds}`;

    const kuwaitDateFormatted = `${String(kuwaitDate.getUTCDate()).padStart(2, "0")}/${String(kuwaitDate.getUTCMonth() + 1).padStart(2, "0")}/${kuwaitDate.getUTCFullYear()}`;
    return `${kuwaitDateFormatted} ${kuwaitTimeString}`;

  }
}
