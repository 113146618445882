
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';



import { catchError } from 'rxjs/operators';

import { environment } from '../environments/environment';

import { BaseService } from './base.service';

import { ICountService } from './icount.service';

import { Helpers } from '../helpers/helpers';


@Injectable()

export class OrderService extends BaseService implements ICountService {

  private pathAPI = environment.apiUrl;

  public errorMessage: string;

  constructor(private http: HttpClient, helper: Helpers) { super(helper); }

  getCount(body: any) {
    return this.http.post<any>(this.pathAPI + 'TPLPurchase/GetOrdersCount', body, super.header()).pipe(catchError(super.handleError));
  }

  getData(body: any): any {
    return this.http.post<any>(this.pathAPI + 'TPLPurchase/GetOrders', body, super.header())
      .pipe(catchError(super.handleError));
  }
  getOrderDetail(id:number){
    return this.http.get<any>(this.pathAPI + 'Safratti/'+ id,super.header())
  }
}
