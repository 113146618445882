import { HttpParams } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Analytics, logEvent } from "@angular/fire/analytics";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-safratti-redirect",
  templateUrl: "./safratti-redirect.component.html",
  styleUrls: ["./safratti-redirect.component.css"],
})
export class SafrattiRedirectComponent implements OnInit {
  constructor(private route: ActivatedRoute, analytics: Analytics) {
    const params = this.route.snapshot.queryParams;
    const referrerId = params["id"];
    let httpParams = new HttpParams();
    const entries = Object.entries(params);
    let index = 0;

    while (index < entries.length) {
        const [key, value] = entries[index];
        
        if (key === 'id') {
            httpParams = httpParams.append('referrer', value);
        } else {
            httpParams = httpParams.append(key, value);
        }
        index++;
    }
    
    const url = `${environment.safrattiURL}?${httpParams.toString()}`;
    logEvent(analytics, "redirect", { referrerId });
    window.location.href = url;
  }

  ngOnInit(): void {}
}
