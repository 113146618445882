import { Component, Input, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../services/company.service';
import { StorageService } from '../../../services/storage.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css']
})


export class CompaniesComponent implements OnInit {
  companyList : any;
  pageTitle = 'All Companies';
  error: any;
  SASToken: any;
  typeOfProject:string ='Saiyarti'
  projectSelected= true
  companyType:string

  constructor(private router: Router,
              private route: ActivatedRoute,
              private company: CompanyService,
              private storageService: StorageService,
              private snackBar: MatSnackBar) { 
    //this.companyList = COMPANY_LIST;
    this.storageService.getSASTokenForContainer('company').subscribe(token=>{
      this.SASToken = token.sasToken;
    });
  }

  ngOnInit() {
    this.companyType=window.localStorage['projectType']
    this.getCompanyList();
  }

  receiveData(data: string){
    this.typeOfProject=data
    console.log(this.typeOfProject)
    this.getCompanyList()
  }
  
  getCompanyList(): void{
    this.company.getCompnayList(this.typeOfProject).subscribe(companyList => {
      this.companyList = companyList
    },err =>{
      this.error = err;
    })
  }

  /** open customer detail */
  openCompany(company): void {
    this.router.navigate(['/companies/' + company.id],{state:{ companyType:this.typeOfProject}});
  }

  markDefault(company): void{
    this.company.markCompanyDefault(company.id,this.typeOfProject).subscribe(company =>{
      this.getCompanyList();
      this.snackBar.open('Company has been marked default successfully.', 'CANCEL', {
        duration: 2000
      });
    });
  }

  // getSasToken():void{
  //   this.storageService.getSASToken(companyImage, 'civil-card').subscribe((token: any) => {
  //     this.sasToken = token.sasToken;
  //  });
  // }

  getCompanyImageUrl(company): any{
    return environment.cdnUrl + '/company/' + company.id + '.jpg' + this.SASToken;
  }

}
