import { Component, OnInit, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
import { SafrattiFee } from "src/models/safratti-fee";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SafrattiTravelPlanService } from "src/services/safratti-travel-plan.service";
import { SafrattiDataListRows } from "src/models/safratti-data-list-rows";
import { SafrattiTravelPlanBenefitsAddComponent } from "src/app/safratti-travel-plan-benefits-add/safratti-travel-plan-benefits-add.component";
import { SafrattiFeeService } from "src/services/safratti-fee.service";
import { take } from "rxjs/operators";
import { CreateFeeDto } from "src/dto/request/create-fee-dto";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { ActivatedRoute } from "@angular/router";
import { FetchSafrattiFee } from "src/models/fetchSafrattiFee";
import { UpdateSafrattiFee } from "src/models/updateSafrattiFee";
import { SafrattiFeeStructureAgeComponent } from "../safratti-fee-structure-age/safratti-fee-structure-age.component";

@Component({
  selector: "app-safratti-fee-structure-add",
  templateUrl: "./safratti-fee-structure-add.component.html",
  styleUrls: ["./safratti-fee-structure-add.component.css"],
})
export class SafrattiFeeStructureAddComponent implements OnInit {
  pageTitle = "Fee Structure";
  formDisabled = true;
  isAddFee = true;
  id: any;
  feesData: SafrattiFee = new SafrattiFee();
  travelDataListRows: SafrattiDataListRows[] = [];

  @ViewChild(SafrattiTravelPlanBenefitsAddComponent)  
  benefitsComponent!: SafrattiTravelPlanBenefitsAddComponent;
  @ViewChild(SafrattiFeeStructureAgeComponent)
  uptoAgeComponent!: SafrattiFeeStructureAgeComponent;

  safrattiFeeForm = new FormGroup({
    name: new FormControl("", Validators.required),
    travelDays: new FormControl<any>(undefined, Validators.required),
    basicPremium: new FormControl<number>(undefined, Validators.required),
    sportsActivity: new FormControl<boolean>(false),
    policyFee: new FormControl<number>(undefined, Validators.required),
    issueFee: new FormControl<number>(undefined, Validators.required),
    otherFee: new FormControl<number>(undefined, Validators.required),
  });

  constructor(
    private _snackBar: MatSnackBar,
    private location: Location,
    private _safrattiTravelPlanService: SafrattiTravelPlanService,
    private _safrattiFeeService: SafrattiFeeService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.id = +params["id"];
      this.isAddFee = !(this.id > 0);
    });

    this._safrattiTravelPlanService
      .getDataListRows(this.travelDataListRows)
      .subscribe((res) => {
        this.travelDataListRows = res;
        if (!this.isAddFee) {
          this.fetchFee(this.id);
        }
      });
  }

  fetchFee(id: any) {
    this._safrattiFeeService
      .fetchSafrattiFee(id)
      .pipe()
      .subscribe({
        next: (safrattiFeeData: FetchSafrattiFee) => {
          this.safrattiFeeForm.patchValue(safrattiFeeData);

          const values = this.travelDataListRows[4].data.filter(
            (x) => x.id == safrattiFeeData.gtsDuration.id
          );
          if (values.length > 0) {
            this.safrattiFeeForm.get("travelDays").setValue(values[0]);
          }

          this.benefitsComponent.benefits = safrattiFeeData.benefits;
          this.uptoAgeComponent.ages = safrattiFeeData.safrattiAges;
        },
      });
  }

  addFee() {
    const name = this.safrattiFeeForm.get("name").value;
    const gtsDurationId = this.safrattiFeeForm.get("travelDays").value.id;
    const basicPremium = this.safrattiFeeForm.get("basicPremium").value;
    const sportsActivity = this.safrattiFeeForm.get("sportsActivity").value;
    const policyFee = this.safrattiFeeForm.get("policyFee").value;
    const issueFee = this.safrattiFeeForm.get("issueFee").value;
    const otherFee = this.safrattiFeeForm.get("otherFee").value;
    const safrattiFee: CreateFeeDto = {
      name,
      gtsDurationId,
      basicPremium,
      sportsActivity,
      policyFee,
      issueFee,
      otherFee,
      benefits: this.benefitsComponent.benefits,
      safrattiAges: this.uptoAgeComponent.ages
    };
    this._safrattiFeeService
      .createSafrattiFee(safrattiFee)
      .pipe(take(1))
      .subscribe((x) => {
        this._snackBar.open("Fee Structure Created Successfully.");
      });
  }

  updateFee() {
    const name = this.safrattiFeeForm.get("name").value;
    const gtsDurationId = this.safrattiFeeForm.get("travelDays").value.id;
    const basicPremium = this.safrattiFeeForm.get("basicPremium").value;
    const sportsActivity = this.safrattiFeeForm.get("sportsActivity").value;
    const policyFee = this.safrattiFeeForm.get("policyFee").value;
    const issueFee = this.safrattiFeeForm.get("issueFee").value;
    const otherFee = this.safrattiFeeForm.get("otherFee").value;

    const updateSafrattiFee: UpdateSafrattiFee = {
      name,
      gtsDurationId,
      basicPremium,
      sportsActivity,
      policyFee,
      issueFee,
      otherFee,
      benefits: this.benefitsComponent.benefits,
      safrattiAges: this.uptoAgeComponent.ages,
    };

    this._safrattiFeeService
      .updateSafrattiFee(this.id, updateSafrattiFee)
      .pipe(take(1))
      .subscribe((_) => {
        this._snackBar.open("Fee Structure Updated Successfully.");
        this.back();
      });
  }

  back() {
    this.location.back();
  }
}
