import { Component } from '@angular/core';
import { StorageService } from 'src/services/storage.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { TableComponent } from 'src/app/table.component';
import { SelectionModel } from '@angular/cdk/collections';
import { RenewCustomerService } from 'src/services/renewcustomer.service';
import { NewCustomer } from 'src/models/newcustomer';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-renew-customers',
  templateUrl: './renew-customers.component.html',
  styleUrls: ['./renew-customers.component.css']
})
export class RenewCustomersComponent extends TableComponent<NewCustomer> {

  newCustomersFile: any;
  newCustomersEle: string;
  startExpiryDate: Date;
  endExpiryDate: Date;
  account:any;
  selectedDepartment:any;

  processing = false;

  displayedColumns: string[] =
    [
      'id',
      'hasPurchased',
      'ownerName',
      'phoneNumber1',
      'phoneNumber2',
      'phoneNumber3',
      'phoneNumber4',
      'plateNumber',
      'carMake',
      'carModel',
      'modelYear',
      'nationality',
      'chassisNumber',
      'color',
      'expiryDate',
      'gender',
      'dateOfBirth',
      'email',
      'refferByName'
    ];
  selection = new SelectionModel<any>(true, []);

  constructor(private storageService: StorageService,
    private dialogRef: MatDialogRef<RenewCustomersComponent>,
    private snackBar: MatSnackBar,
    private router: Router,
    protected http: HttpClient,
    protected customerService: RenewCustomerService) {
    super(customerService);

  }

  uploadCSV() {
    if (this.newCustomersFile !== undefined) {
      const d = new Date();
      this.storageService
        .getUploadOnlyTokenForContainer("renew-customers-csv")
        .subscribe((token) => {
          this.storageService
            .uploadFileToAzure(
              `/renew-customers-csv/${
                this.selectedDepartment.id +
                "__" +
                this.account.id +
                "__" +
                d.getTime().toString() +
                ".csv"
              }${token.sasToken}`,
              this.newCustomersFile
              ,
              "image/jpg"
            )
            .subscribe({
              next: (res) => {
                this.dialogRef.close();
                this.snackBar.open(
                  "The file has been uploaded. An email will be sent to your account as soon as processed.",
                  "CANCEL",
                  {
                    horizontalPosition: "right",
                    duration: 4000,
                  }
                );
              },
            });
        });
    }
  }

  onnewCustomers(event) {
    const file = event.target.files[0];
    this.newCustomersEle = file.name;
    this.newCustomersFile = file;
  }

  openCustomer(row) {
    this.router.navigate(['/renew-customers/' + row.id]);
  }

  hasPurchased(purchased) {
    return purchased ? 'Yes' : 'No';
  }


  getCount() {
    return this.customerService.getCount({
      filter: this.input.nativeElement.value,
      startExpiryDate: this.startExpiryDate,
      endExpiryDate: this.endExpiryDate
    });
  }

  protected getData(): any {
    let pageSize = this.paginator.pageSize ? this.paginator.pageSize : 10;
    return {
      skip: this.paginator.pageIndex * pageSize,
      take: pageSize,
      filter: this.input.nativeElement.value,
      orderBy: this.sort.active,
      orderByDescending: this.sort.direction === 'desc',
      startExpiryDate: this.startExpiryDate,
      endExpiryDate: this.endExpiryDate,
    };
  }

  filter() {
    super.ngOnInit();
  }
}

