import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';


@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog, private snackBar: MatSnackBar) { }

  // confirmDialog(msg:string){
  //   return this.dialog.open(ConfirmDialogComponent,{
  //     width:'380px',
  //     disableClose:true,
  //     data:{
  //       msg:msg
  //     }
  //   });
  // }

  successSnackBar(msg: string) {
    this.snackBar.open(msg, 'CANCEL', {
      horizontalPosition: 'right',
      duration: 2000
    });
  }

  errorSanckBar(err: any) {
    this.snackBar.open(err, 'CANCEL', {
      horizontalPosition: 'right'
    });
  }
}
