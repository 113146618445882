import { Component, OnInit } from '@angular/core';
import { AddAgent } from 'src/models/add-agent';
import { AgentService } from 'src/services/agent.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-agent-add',
  templateUrl: './agent-add.component.html',
  styleUrls: ['./agent-add.component.css']
})
export class AgentAddComponent implements OnInit {
  pageTitle = 'Agent Users';
  agentData: AddAgent = new AddAgent();
  isAddAgent = false;
  formDisabled = true;

  constructor(private agentService: AgentService, private snackBar: MatSnackBar, private route: ActivatedRoute, private router: Router,
    private location: Location,
    private diloag: MatDialog) {
    this.agentData.role = 'OfficeAdministrator';
  }

  ngOnInit() {

    const agentId = this.route.snapshot.paramMap.get('id');
    const id = this.route.snapshot.paramMap.get('id');
    if (id === 'add') {
      this.isAddAgent = true;
      this.formDisabled = false;
    } else {
      this.loadAgent(id);
    }
  }
  enableFormForEdit() {
    if (this.formDisabled === true) {
      this.formDisabled = false;
    } else {
      this.formDisabled = true;
    }
  }

  addAgent(form) {
    this.agentService.createAgent(form.value).subscribe(res => {
      this.snackBar.open('Success', 'CANCEL', {
        horizontalPosition: 'right',
        duration: 2000
      });
      this.router.navigate(['/setup/agent-list']);
    }, err => {
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right'
      });
    });
  }
  loadAgent(id) {
    this.agentService.getAgentById(id).subscribe(res => {
      this.agentData = res;
    }, err => {
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right'
      });
    });
  }

  updateAgent(form) {
    const id = this.route.snapshot.paramMap.get('id');
    this.agentService.updateAgent(id, this.agentData).subscribe(res => {
      this.snackBar.open('Agent update successfully.', 'CANCEL', {
        horizontalPosition: 'right',
        duration: 2000
      });
      this.router.navigate(['/setup/agent-list']);
    }, err => {
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right'
      });
    });
  }
  back() {
    this.location.back();
  }

  resetPassword() {
    const id = this.route.snapshot.paramMap.get('id');
    this.agentService.resetPasswordAgent(id).subscribe(res => {
      // Reset Password link sent successfully.
      this.snackBar.open('Reset Password link sent successfully.', 'CANCEL', {
        horizontalPosition: 'right',
        duration: 2000
      });
    }, err => {
      const snackBarRef = this.snackBar.open(err, 'Retry', {
        horizontalPosition: 'right'
      });
      // snackBarRef.afterDismissed().subscribe(() => {
      //   //console.log('The snack-bar was dismissed');
      //   this.resetPassword(agent);
      // });
    });
  }
  deleteAgent() {
    const dialogRef = this.diloag.open(ConfirmDialogComponent, { data: { title: 'Confirm', message: 'Are you sure want delete agent?' } });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result === 'true') {
        // TODO : logic for ok
        const id = this.route.snapshot.paramMap.get('id');
        this.agentService.deleteAgent(id).subscribe(res => {
          this.snackBar.open('Agent deleted successfully.', 'CANCEL', {
            horizontalPosition: 'right',
            duration: 2000
          });
          this.router.navigate(['/setup/agent-list']);
        }, err => {
          this.snackBar.open(err, 'CANCEL', {
            horizontalPosition: 'right'
          });
        });
      } else {
        // TODO : logi for cancel
      }
    });
  }


}
