import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TplService } from 'src/services/tpl.service';

@Component({
  selector: 'app-doc-edit-xy-model',
  templateUrl: './doc-edit-xy-model.component.html',
  styleUrls: ['./doc-edit-xy-model.component.css']
})
export class DocEditXyModelComponent implements OnInit {
  jsonData: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private tplService: TplService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    // console.log(this.data);
    this.jsonData = this.data;
  }

  updatTplLabels() {
    //console.log(this.data.labels);
    if (this.data.companyType === 'Saiyarti') {
      this.tplService.updateTPLLabels(this.data.companyId, this.data.labels).subscribe(res => {
        // console.log(res);
        this.snackBar.open('Successfully.', 'CANCEL', {
          duration: 2000,
          horizontalPosition: 'right'
        });
      }, err => {
        //console.log(err);
        this.snackBar.open(err, 'CANCEL', {
          horizontalPosition: 'right'
        });
      });
    }
    if (this.data.companyType === 'Safratti') {
      this.tplService.updateSafrattiLabels(this.data.companyId, this.data.labels).subscribe(res => {
        // console.log(res);
        this.snackBar.open('Successfully.', 'CANCEL', {
          duration: 2000,
          horizontalPosition: 'right'
        });
      }, err => {
        //console.log(err);
        this.snackBar.open(err, 'CANCEL', {
          horizontalPosition: 'right'
        });
      });
    }
  }

}
