import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { StorageService } from "src/services/storage.service";
import { Lightbox } from "ngx-lightbox";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { TplService } from "src/services/tpl.service";
import { environment } from "../../../environments/environment";
import { ComprehensiveRequestsService } from "src/services/comprehensive-requests.service";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ForwardQuoteDialogComponent } from "src/app/forward-quote-dialog/forward-quote-dialog.component";
import { CompanyService } from "src/services/company.service";
import { QuoteStatus } from "src/models/comprehensive-quote";
import { RateCard } from "src/models/rate-card";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-cr-detail",
  templateUrl: "./cr-detail.component.html",
  styleUrls: ["./cr-detail.component.css"],
})
export class CrDetailComponent implements OnInit {
  QuoteStatus = QuoteStatus;
  quoteHistoryDataSource: MatTableDataSource<any>;

  totalComprehensiveAmount = 0;

  // Mandatory Limited Coverage is selected
  showLimitedCoverage = true;

  // Comprehensive
  showComprehensive = true;

  comprehensiveStatus = 0;
  albums: any = [];
  Object = Object;

  SASTokenForUploadCivil: string;
  SASTokenForUploadRCBook: string;
  
  oldMarketValue: number = 0;

  constructor(
    private lightbox: Lightbox,
    private route: ActivatedRoute,
    protected _sanitizer: DomSanitizer,
    private router: Router,
    private storageService: StorageService,
    private requestService: ComprehensiveRequestsService,
    private location: Location,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<CrDetailComponent>,
    private tplService: TplService,
    private dialog: MatDialog,
    private companyService: CompanyService
  ) {
    const id = +this.route.snapshot.paramMap.get("id");
    if (this.router.getCurrentNavigation().extras.state) {
      this.requestDetails = new RateCard(
        id,
        this.router.getCurrentNavigation().extras.state.rateCard
      );
    } else {
      this.requestService.getQuoteRequestByVehicleId(id).subscribe((x) => {
        this.requestDetails = x;
        this.ngOnInit();
      });
    }
  }

  requestDetails?: RateCard;
  carReadToken: string;
  carUploadToken: string;
  carImagesList: any;
  carVideo: SafeUrl;
  quoteHistory: RateCard[];
  thumbRCBookImageUrl: any;
  thumbCivilFrontImageUrl: any;
  thumbCivilBackImageUrl: any;
  civilFront: string;
  civilBack: string;
  rcBook: string;
  formDisabled: boolean = true;
  companyList: any;
  forwardData: any = {};
  account: any;
  selectedDepartment: any;
  // requestDetails: RateCard;

  qsDisplayedColumns = [
    "quoteNumber",
    "submissionDate",
    "insurer",
    "status",
    "notes",
  ];

  fiDisplayedColumns = ["insurer", "status", "notes"];

  ngOnInit() {
    if (this.requestDetails) {
      console.log(this.requestDetails);
      this.companyService
        .getCompnayList("Saiyarti")
        .subscribe((companyList) => {
          this.companyList = companyList;
        });

      this.oldMarketValue = this.requestDetails.vehicle.marketValue;

      // Get Car Images
      this.storageService
        .getSASTokenForContainer("comprehensive-car-book")
        .subscribe((carReadToken) => {
          this.carReadToken = carReadToken.sasToken;
          this.requestService
            .getCarImagesByVehicleId(this.requestDetails.vehicleId)
            .subscribe((carImagesList) => {
              this.setCarImages(carImagesList);
            });
        });

      this.storageService
        .getUploadOnlyTokenForContainer("comprehensive-car-book")
        .subscribe((carUploadToken) => {
          this.carUploadToken = carUploadToken.sasToken;
        });

      this.storageService
        .getUploadOnlyTokenForContainer("comprehensive-rc-book")
        .subscribe((rcUploadToken) => {
          this.SASTokenForUploadRCBook = rcUploadToken.sasToken;
        });

      this.storageService
        .getUploadOnlyTokenForContainer("comprehensive-civilcard-book")
        .subscribe((civilIdUploadToken) => {
          this.SASTokenForUploadCivil = civilIdUploadToken.sasToken;
        });

      // get Quote History
      this.getQuoteHistory();

      this.civilFront = this.requestDetails.vehicleId + "_front";
      this.civilBack = this.requestDetails.vehicleId + "_back";
      this.rcBook = this.requestDetails.vehicleId.toString();

      // Civil Card Front
      this.storageService
        .getSASToken(this.civilFront, "comprehensive-civilcard-book")
        .subscribe((token: any) => {
          const civilFrontImageUrl =
            environment.cdnUrl +
            "/comprehensive-civilcard-book/" +
            this.civilFront +
            token.sasToken;
          this.thumbCivilFrontImageUrl =
            environment.cdnUrl +
            "/comprehensive-civilcard-book/" +
            this.civilFront +
            token.sasToken;
          const src = civilFrontImageUrl;
          const caption = "Civil Card Front";
          const thumb = this.thumbCivilFrontImageUrl;
          const album = {
            src,
            caption,
            thumb,
          };
          this.albums[0] = album;
        });

      // Civil Card Back
      this.storageService
        .getSASToken(this.civilBack, "comprehensive-civilcard-book")
        .subscribe((token: any) => {
          const civilBackImageUrl =
            environment.cdnUrl +
            "/comprehensive-civilcard-book/" +
            this.civilBack +
            token.sasToken;
          this.thumbCivilBackImageUrl =
            environment.cdnUrl +
            "/comprehensive-civilcard-book/" +
            this.civilBack +
            token.sasToken;
          const src = civilBackImageUrl;
          const caption = "Civil Card Back";
          const thumb = this.thumbCivilBackImageUrl;
          const album = {
            src,
            caption,
            thumb,
          };
          this.albums[1] = album;
        });

      // RC Book
      this.storageService
        .getSASToken(this.rcBook, "comprehensive-rc-book")
        .subscribe((token: any) => {
          const rcBookImageUrl =
            environment.cdnUrl +
            "/comprehensive-rc-book/" +
            this.rcBook +
            token.sasToken;
          this.thumbRCBookImageUrl =
            environment.cdnUrl +
            "/comprehensive-rc-book/" +
            this.rcBook +
            token.sasToken;
          const src = rcBookImageUrl;
          const caption = "RC Book";
          const thumb = this.thumbRCBookImageUrl;
          const album = {
            src,
            caption,
            thumb,
          };
          this.albums[2] = album;
        });
    }
  }

  getQuoteHistory(): void {
    this.requestService
      .getQuoteHistory(this.requestDetails.rateCardId)
      .subscribe((qHList: any) => {
        this.quoteHistory = qHList;
        this.quoteHistoryDataSource = new MatTableDataSource(this.quoteHistory);
      });
  }

  enableEditRequest(): void {
    this.formDisabled = !this.formDisabled;
  }

  setCarImages(carImagesList): void {
    let tempCarImages: any = [];
    for (var carImage of carImagesList) {
      if (carImage.name.indexOf("_video") < 0) {
        let image: any = {};
        let carImageIndex = +carImage.name.split("_")[1];
        let carImageUrl =
          environment.cdnUrl +
          "/comprehensive-car-book/" +
          carImage.name +
          this.carReadToken;
        image.src = carImageUrl;
        image.caption = carImage.name;
        image.carIndex = carImageIndex;

        tempCarImages.push(image);
      } else {
        this.carVideo = this._sanitizer.bypassSecurityTrustUrl(
          environment.cdnUrl +
            "/comprehensive-car-book/" +
            carImage.name +
            this.carReadToken
        );
      }
    }

    if (carImagesList.length < 6) {
      let image: any = {};
      image.src = null;
      image.caption = "Add car image";
      image.carIndex = carImagesList.length + 1;

      tempCarImages.push(image);
    }

    this.carImagesList = Object.assign([], tempCarImages);
  }

  uploadCarImage(event, car) {
    const file = event.target.files[0];
    // const reader = new FileReader();
    const d = new Date();
    if (file !== undefined) {
      this.storageService
        .getUploadOnlyTokenForContainer("comprehensive-car-book")
        .subscribe((token) => {
          this.storageService
            .uploadFileToAzure(
              `/comprehensive-car-book/${
                this.selectedDepartment.id +
                "__" +
                this.account.id +
                "__" +
                d.getTime().toString() +
                ".csv"
              }${token.sasToken}`,
              this.carImagesList,
              "image/jpg"
            )
            .subscribe({
              next: (res) => {
                this.dialogRef.close();
                this.snackBar.open(
                  "The file has been uploaded. An email will be sent to your account as soon as processed.",
                  "CANCEL",
                  {
                    horizontalPosition: "right",
                    duration: 4000,
                  }
                );
              },
            });
        });
    }
  }

  storeCarImageName(car): void {
    let uploadCarBody = [car.caption];
    this.requestService
      .uploadCarImages(this.requestDetails.vehicleId.toString(), uploadCarBody)
      .subscribe(
        () => {},
        () => {}
      );
  }

  open(index: number): void {
    this.lightbox.open(this.albums, index);
  }

  openCarImage(car): void {
    const src = car.src;
    const caption = car.caption;
    const thumb = car.src;
    const album = {
      src,
      caption,
      thumb,
    };

    this.lightbox.open([album], 0);
  }

  goBack(): void {
    this.location.back();
  }

  updateQuoteDetail() {
    this.recalculateQuote();

    // let updateBody: {
    //   status?: string,
    //   firstName?: string,
    //   lastName?: string,
    //   marketValue?: number,
    //   carTrim?: string,
    //   requestStatus?: string,
    //   notes?: string,
    //   insurerId?: string,
    //   premium?: number,
    //   isForwarded?: boolean
    // } = {
    //   firstName: this.requestDetails.user.firstName,
    //   lastName: this.requestDetails.user.lastName,
    //   carTrim: this.requestDetails.vehicle.carTrim,
    //   marketValue: this.requestDetails.vehicle.marketValue,
    //   requestStatus: QuoteStatus.UPDATED_REQUEST,
    //   status: this.requestDetails.status,
    //   premium: this.totalComprehensiveAmount,
    //   isForwarded: true
    // }

    // if (this.requestDetails.requestStatus == QuoteStatus.REJECTED || this.requestDetails.requestStatus == QuoteStatus.APPROVED) {
    //   updateBody.requestStatus = this.requestDetails.requestStatus;
    //   updateBody.status = this.requestDetails.requestStatus;
    // }

    this.requestService
      .updateRequestOfCustomer(
        this.requestDetails.rateCardId,
        this.requestDetails
      )
      .subscribe(
        () => {
          this.snackBar.open("Details are updated successfully.", "OK", {
            horizontalPosition: "center",
            duration: 2000,
          });
          this.getQuoteHistory();
          this.formDisabled = true;
        },
        (err) => {
          this.snackBar.open(err, "CANCEL", {
            horizontalPosition: "right",
          });
        }
      );
  }

  recalculateQuote(): void {
    // Reset all values
    this.totalComprehensiveAmount = 0;

    if (Object.keys(this.requestDetails.parametersMap).length == 0) {
      this.showComprehensive = false;
    }

    // Mandatory Limited Coverage is selected
    if (this.showLimitedCoverage) {
      this.calculateLimitedCoverage();
    }

    // Comprehensive is Selected
    if (this.showComprehensive) {
      this.calculateComprehensive();
    }
    //this.requestDetails.amount = this.totalComprehensiveAmount;
  }

  private calculateLimitedCoverage() {
    if (this.showComprehensive === false) {
      const minimumPremiumAmount = this.requestDetails.minimumPremiumAmount;

      var amountToAdd = 0;
      for (let index = 0; index < this.requestDetails.fees.length; index++) {
        const fee = this.requestDetails.fees[index];
        amountToAdd += fee.amount;
      }

      this.totalComprehensiveAmount +=
        (this.requestDetails.vehicle.marketValue *
          this.requestDetails.limitedComprehensiveCover) /
        100;
      if (this.totalComprehensiveAmount < minimumPremiumAmount) {
        this.totalComprehensiveAmount = minimumPremiumAmount;
      }
      this.totalComprehensiveAmount += amountToAdd;
      this.totalComprehensiveAmount = +this.totalComprehensiveAmount.toFixed(3);
    }
  }

  private calculateComprehensive() {
    let i = 0;
    // Loop over parameters for each insurance company to calculate total comprehensive amount

    // Extract the variables needed for further calculations
    const saiyartiPercentage = this.requestDetails.saiyartiPercentage;
    const insurerPercentage = this.requestDetails.insurerPercentage;
    const minimumPremiumAmount = this.requestDetails.minimumPremiumAmount;

    var fees = 0;
    for (let index = 0; index < this.requestDetails.fees.length; index++) {
      const fee = this.requestDetails.fees[index];
      fees += fee.amount;
    }

    var premium = 0;
    var comprehensivePercentage = saiyartiPercentage + insurerPercentage;
    // Calculate the premium based on the subLimit and KD values
    // For Waiver of Subrogation use KD values
    // For anything else with subLimit use their percentage values
    for (const key in this.requestDetails.parametersMap) {
      var parameter = this.requestDetails.parametersMap[key];

      if (parameter.name === "Waiver of Subrogation") {
        for (const key in parameter.subParametersMap) {
          const subParameter = parameter.subParametersMap[key];
          if (subParameter.isSelected) {
            premium += +subParameter.valueForInsurer;
          }
        }
      } else {
        for (const key in parameter.subParametersMap) {
          const subParameter = parameter.subParametersMap[key];
          if (subParameter.isSelected) {
            comprehensivePercentage -= +subParameter.percentageValue;
          }
        }
      }
    }

    premium +=
      (+comprehensivePercentage * this.requestDetails.vehicle.marketValue) /
      100.0;
    // If the premium is less than minimum premium amount
    // for the insurance company use the minimumPremiumAmount
    if (premium < minimumPremiumAmount) {
      premium = minimumPremiumAmount;
    }

    // Add premium & fees to final amount
    this.totalComprehensiveAmount += premium + fees;
    this.totalComprehensiveAmount = +this.totalComprehensiveAmount.toFixed(3);
  }

  getRateCardValue(rateCard: RateCard): any {
    let bRetVal = "";
    if (rateCard) {
      for (const key in rateCard.parametersMap) {
        var parameter = rateCard.parametersMap[key];
        if (parameter.isSelected) {
          bRetVal = parameter.name;
          break;
        }
      }
    } else {
      return "Included";
    }
    return bRetVal;
  }

  downloadQuotePDF(): void {
    window.open(
      `${environment.cdnUrl}/comprehensive-invoice/${this.requestDetails.rateCardId}.pdf`,
      "_blank"
    );
  }

  downloadInsurerQuotePDF(): void {
    window.open(
      `${environment.cdnUrl}/comprehensive-invoice/insurer-${this.requestDetails.rateCardId}.pdf`,
      "_blank"
    );
  }

  forwardToInsurer(): void {
    let insurer = this.companyList.find(
      (company) => company.id == this.forwardData.insurerId
    );
    if (insurer) {
      let dialogData = {
        insurerName: insurer.companyName,
        email: insurer.emails,
      };
      const forwardDialogRef = this.dialog.open(ForwardQuoteDialogComponent, {
        disableClose: true,
        data: dialogData,
      });

      forwardDialogRef.afterClosed().subscribe((result) => {
        if (result == "true") {
          this.requestDetails.forwardedToInsurer = {
            insurerId: insurer.id,
            name: insurer.companyName,
          };
          this.requestDetails.notes = this.forwardData.notes;
          this.requestService
            .rateCardForwarded(this.requestDetails.id, this.requestDetails)
            .subscribe(
              (result) => {
                this.snackBar.open(
                  "Quote forward history saved for " + insurer.companyName,
                  "OK",
                  {
                    horizontalPosition: "right",
                    duration: 2000,
                  }
                );

                // get Quote History
                this.getQuoteHistory();
              },
              (error) => {
                this.snackBar.open(error, "CANCEL", {
                  horizontalPosition: "right",
                  duration: 2000,
                });
              }
            );
        }
      });
    }
  }

  approveRateCard(): void {
    this.requestService.approveRateCard(this.requestDetails.id).subscribe(
      (result) => {
        this.snackBar.open("Quote has been marked as approved", "OK", {
          horizontalPosition: "right",
          duration: 2000,
        });

        // get Quote History
        this.getQuoteHistory();
      },
      (error) => {
        this.snackBar.open(error, "CANCEL", {
          horizontalPosition: "right",
          duration: 2000,
        });
      }
    );
  }

  rejectRateCard(): void {
    this.requestService.rejectRateCard(this.requestDetails.id).subscribe(
      (result) => {
        this.snackBar.open("Quote has been marked as rejected", "OK", {
          horizontalPosition: "right",
          duration: 2000,
        });

        // get Quote History
        this.getQuoteHistory();
      },
      (error) => {
        this.snackBar.open(error, "CANCEL", {
          horizontalPosition: "right",
          duration: 2000,
        });
      }
    );
  }

  approvedByInsurer(): void {
    this.requestDetails.insurer = {
      insurerId: this.requestDetails.forwardedToInsurer.insurerId,
      name: this.requestDetails.forwardedToInsurer.name,
    };
    this.requestDetails.forwardedToInsurer = undefined;

    this.requestService
      .updateRequestOfCustomer(this.requestDetails.id, this.requestDetails)
      .subscribe(
        (result) => {
          this.snackBar.open("Quote has been marked as forwarded", "OK", {
            horizontalPosition: "right",
            duration: 2000,
          });

          // get Quote History
          this.getQuoteHistory();
        },
        (error) => {
          this.snackBar.open(error, "CANCEL", {
            horizontalPosition: "right",
            duration: 2000,
          });
        }
      );
  }

  uploadPolicy() {
    if (this.requestDetails.status == QuoteStatus.PENDING) {
      this.router.navigate([
        "/comprehensive-consumer-request",
        "policy-info",
        this.requestDetails.id,
      ]);
    }
  }

  openQuoteHistory(rateCard: RateCard) {
    if (rateCard && rateCard.historyId) {
      this.router.navigate(
        ["/comprehensive-consumer-request/qh-detail/" + rateCard.historyId],
        {
          state: {
            rateCard: rateCard,
          },
        }
      );
    }
  }

  uploadDoc(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    const d = new Date();
    if (file !== undefined) {
      let fileName = "";
      if (
        event.srcElement.id === "civilCardFront" ||
        event.srcElement.id === "civilCardBack"
      ) {
        if (event.srcElement.id === "civilCardFront") {
          fileName = this.civilFront;
          reader.onload = (e: any) => {
            this.thumbCivilFrontImageUrl = e.target.result;
          };
        }
        this.storageService
          .getUploadOnlyTokenForContainer("comprehensive-civilcard-book")
          .subscribe((token) => {
            this.storageService
              .uploadFileToAzure(
                `/comprehensive-civilcard-book/${
                  this.selectedDepartment.id +
                  "__" +
                  this.account.id +
                  "__" +
                  d.getTime().toString() +
                  ".csv"
                }${token.sasToken}`,
                this.carImagesList,
                "image/jpg"
              )
              .subscribe({
                next: (res) => {
                  this.dialogRef.close();
                  this.snackBar.open(
                    "The file has been uploaded. An email will be sent to your account as soon as processed.",
                    "CANCEL",
                    {
                      horizontalPosition: "right",
                      duration: 4000,
                    }
                  );
                },
              });
          });
      } else if (event.srcElement.id === "rcBook") {
        this.storageService
          .getUploadOnlyTokenForContainer("comprehensive-rc-book")
          .subscribe((token) => {
            this.storageService
              .uploadFileToAzure(
                `/comprehensive-rc-book/${
                  this.selectedDepartment.id +
                  "__" +
                  this.account.id +
                  "__" +
                  d.getTime().toString() +
                  ".csv"
                }${token.sasToken}`,
                this.carImagesList,
                "image/jpg"
              )
              .subscribe({
                next: (res) => {
                  this.dialogRef.close();
                  this.snackBar.open(
                    "The file has been uploaded. An email will be sent to your account as soon as processed.",
                    "CANCEL",
                    {
                      horizontalPosition: "right",
                      duration: 4000,
                    }
                  );
                },
              });
          });
      }

      reader.readAsDataURL(file);
      this.storageService
        .getUploadOnlyTokenForContainer("comprehensive-car-book")
        .subscribe((token) => {
          this.storageService
            .uploadFileToAzure(
              `/comprehensive-car-book/${
                this.selectedDepartment.id +
                "__" +
                this.account.id +
                "__" +
                d.getTime().toString() +
                ".csv"
              }${token.sasToken}`,
              this.carImagesList,
              "image/jpg"
            )
            .subscribe({
              next: (res) => {
                this.dialogRef.close();
                this.snackBar.open(
                  "The file has been uploaded. An email will be sent to your account as soon as processed.",
                  "CANCEL",
                  {
                    horizontalPosition: "right",
                    duration: 4000,
                  }
                );
              },
            });
        });
    }
  }
}
