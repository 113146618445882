import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TransportService } from 'src/services/transport.service';

@Component({
  selector: 'app-transport-doc-xy-model',
  templateUrl: './transport-doc-xy-model.component.html',
  styleUrls: ['./transport-doc-xy-model.component.css']
})
export class TransportDocXyModelComponent implements OnInit {
  labels:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private trasportService: TransportService,private snackBar:MatSnackBar) {
    this.loadLales();
   }

  ngOnInit() {
  }

  loadLales(){
    this.trasportService.getDocLabels().subscribe(res=>{
      this.labels = res;
    },err=>{
      console.log(err);
    });
  }
  
  updatDocLabels(){
    this.trasportService.updateDocLabels(this.labels).subscribe(res=>{
      this.snackBar.open('Successfully.', 'CANCEL', {
        duration: 2000,
        horizontalPosition: 'right'
      });
    },err=>{
      console.log(err);
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right'     
      });
    });

  }
}
