import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataListRowResponse } from 'src/models/data-list-row-response';
import { StorageMember } from 'src/models/storageMember';
import { MY_DATE_FORMATS } from '../app.module';
import { GTSService } from 'src/services/gts.service';

@Component({
  selector: 'app-safratti-policy-traveler-details',
  templateUrl: './safratti-policy-traveler-details.component.html',
  styleUrls: ['./safratti-policy-traveler-details.component.css']
})
export class SafrattiPolicyTravelerDetailsComponent {
  displayedColumns: string[] = ['serialNo', 'relation', 'firstName', 'lastName', 'dateOfBirth', 'passportNo', 'sportsActivityIncluded', 'edit', 'delete'];
  @Input() dataSource: any
  @Input() userData: any
  @Input() formName: FormGroup
  showTripDetailsForm: boolean;
  @Input() currentMember?: StorageMember;
  relations: DataListRowResponse[] = [];
  @Input() members: StorageMember[] = [];
  myDateFormats = MY_DATE_FORMATS;
  @Output() updatePremiumChanged = new EventEmitter<Boolean>()

  @Output() onUpdate = new EventEmitter()
  @Input() safrattiPolicyForm:FormGroup 

  constructor(private _gtsService:GTSService){}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userData) {
      this.members = changes.userData.currentValue.travelersDetails;
    }
  }

  startUpdateMember(member: StorageMember) {
    this.showTripDetailsForm = true;
    const dob = member.dateOfBirth.toString()
    this.currentMember = member;


    if (dob.indexOf('T') > 0) {
      const dobSplit = dob.split(/[\s-,T]+/);
      
      const dobDate = `${dobSplit[0]}-${dobSplit[1]}-${dobSplit[2]}`
      this.currentMember.dateOfBirth = dobDate
      
    }
    else {

      const dobSplit = dob.split(/[\s-,]+/)
      const day = dobSplit[2].padStart(2, '0');
      const month = dobSplit[1].padStart(2, '0')
      const dobDate = `${day}-${month}-${dobSplit[0]}`
      this.currentMember.dateOfBirth = dobDate

    }

    this.formName.patchValue(this.currentMember)
    

  }
  addMember() {
    this.updatePremiumChanged.emit(true)
    const relation =
      this.formName.get('relation')?.value ?? this.relations[0];
    const passportNo = this.formName.get('passportNo')?.value ?? '';
    const firstName = this.formName.get('firstName')?.value ?? '';
    const middleName = this.formName.get('middleName')?.value ?? '';
    const lastName = this.formName.get('lastName')?.value ?? '';
    const dateOfBirth = this.formName.get('dateOfBirth')?.value ?? '';
    const gender = this.formName.get('gender')?.value ?? '';

    const dobString = dateOfBirth.toISOString();

    const member: StorageMember = {
      id: passportNo + firstName + lastName + gender + relation.code,
      relation,
      passportNo,
      firstName,
      lastName,
      middleName,
      dateOfBirth:dobString,
      gender,
    };
    

    this.members = [...this.members, member];
    if (this.onUpdate) {
      this.onUpdate.emit();
    }
    this.formName.reset();

    this.showTripDetailsForm = false
  
  }

  updateMember() {
    this.updatePremiumChanged.emit(true)
    this.showTripDetailsForm = false
    const relation =
      this.formName.get('relation')?.value ?? this.relations[0];
    const passportNo = this.formName.get('passportNo')?.value ?? '';
    const firstName = this.formName.get('firstName')?.value ?? '';
    const middleName = this.formName.get('middleName')?.value ?? '';
    const lastName = this.formName.get('lastName')?.value ?? '';
    const dateOfBirth = this.formName.get('dateOfBirth')?.value ?? '';
    const gender = this.formName.get('gender')?.value ?? '';
    
    const dobString = dateOfBirth.toISOString();

    const member: StorageMember = {
      id: this.currentMember?.id ?? '',
      relation,
      passportNo,
      firstName,
      lastName,
      middleName,
      dateOfBirth:dobString,
      gender,
    };
    

    // Remove the old member from the list and push the new member object created
    this.members = this.members.filter((x) => x.id !== this.currentMember?.id);
    this.members = [...this.members, member];
    this.members = this.members.slice(); // Reassign the array to trigger change detection
    if (this.onUpdate) {
      this.onUpdate.emit();
    }


    // Reset the values
    this.currentMember = undefined;

    this.formName.reset();
    
  }

  deleteMember(member: StorageMember) {
    this.members = this.members.filter((x) => x.id !== member.id);
    if (this.members.length === 0) {
      const mainRelations = this.relations.filter(
        (x) => (x?.code?.toLowerCase() ?? '') === 'main'
      );
      if (mainRelations.length > 0) {
        this.formName.get('relation')?.patchValue(mainRelations[0]);
      }
      if (this.onUpdate) {
        this.onUpdate.emit();
      }
    }
  }

  formatDate(dateString: string): String{
    if (!dateString) return "";

    let formattedDate: string;


    if (dateString.includes('T')) {
      const parts = dateString.split('T')[0].split('-');
      formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

    }
    else if (dateString.includes(' - ')) {
      const parts = dateString.split(' - ');
      formattedDate = `${parts[2]} - ${parts[1]} - ${parts[0]}`;
      
      
    }
    else {
      console.error('Unrecognized date format:', dateString);
      formattedDate = dateString; 
    }


    return formattedDate
  }
}
