import { Component, OnInit } from "@angular/core";

import { Location } from "@angular/common";

import { ActivatedRoute } from "@angular/router";

import { Router } from "@angular/router";

import { DomSanitizer } from "@angular/platform-browser";

import { StorageService } from "src/services/storage.service";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { PrintLabelComponent } from "src/app/model-dialog/print-label/print-label.component";
import { environment } from "../../../environments/environment";
import { ComprehensiveRequestsService } from "src/services/comprehensive-requests.service";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: "app-comprehensive-policy-status",
  templateUrl: "./comprehensive-policy-status.component.html",
  styleUrls: ["./comprehensive-policy-status.component.css"],
})
export class ComprehensivePolicyStatusComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
    },
    nav: false,
  };

  public slideIndex = 1;
  comprehensiveStatus = 3;
  pageTitle = "Comprehensive Request";
  constructor(
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private requestService: ComprehensiveRequestsService,
    private router: Router,
    private location: Location,
    private storageService: StorageService,
    private dialog: MatDialog
  ) {}

  step = 0;
  buttonName = "POLICY PRINTED";
  backButtonName = "Back";
  policyDocumentUrl;
  moroorDocumentUrl;
  invoiceDocumentUrl;
  purchaseDetail: any;
  error: any;
  serializedStartDate: any = "";
  serializedIssueDate: any = "";
  expiryDate: any = "";
  docContainerName: string = "comprehensive-policy-book";

  ngOnInit() {
    this.getPurchaseDetail();
    // this.loadImage();
    this.loadDocuments();
  }

  loadDocuments(): void {
    this.storageService
      .getSASToken(
        this.route.snapshot.paramMap.get("id") + "_policy",
        this.docContainerName
      )
      .subscribe((token: any) => {
        const encodedSasToken = encodeURIComponent(token.sasToken);
        let url =
          environment.cdnUrl +
          "/" +
          this.docContainerName +
          "/" +
          this.route.snapshot.paramMap.get("id") +
          "_policy" +
          encodedSasToken;
        this.policyDocumentUrl =
          this.sanitizer.bypassSecurityTrustResourceUrl(url);
      });
    this.storageService
      .getSASToken(
        this.route.snapshot.paramMap.get("id") + "_moroor",
        this.docContainerName
      )
      .subscribe((token: any) => {
        const encodedSasToken = encodeURIComponent(token.sasToken);
        let url =
          environment.cdnUrl +
          "/" +
          this.docContainerName +
          "/" +
          this.route.snapshot.paramMap.get("id") +
          "_moroor" +
          encodedSasToken;
        this.moroorDocumentUrl =
          this.sanitizer.bypassSecurityTrustResourceUrl(url);
      });
    this.storageService
      .getSASToken(
        this.route.snapshot.paramMap.get("id") + "_invoice",
        this.docContainerName
      )
      .subscribe((token: any) => {
        const encodedSasToken = encodeURIComponent(token.sasToken);
        let url =
          environment.cdnUrl +
          "/" +
          this.docContainerName +
          "/" +
          this.route.snapshot.paramMap.get("id") +
          "_invoice" +
          encodedSasToken;
        this.invoiceDocumentUrl =
          this.sanitizer.bypassSecurityTrustResourceUrl(url);
      });
  }

  getPurchaseDetail(): void {
    const id = +this.route.snapshot.paramMap.get("id");

    this.requestService.getQuoteRequestByVehicleId(id).subscribe(
      (purchaseDetail: any) => {
        if (purchaseDetail === undefined) {
          this.router.navigate(["/dashboard"]);
        }

        this.purchaseDetail = purchaseDetail;

        if (this.purchaseDetail.status === "PENDING") {
          this.step = 0;
        }
        if (this.purchaseDetail.status === "POLICY_PRINTED") {
          this.step = 1;
          this.backButtonName = "Home";
          this.buttonName = "DISPATCH POLICY";
        }

        if (this.purchaseDetail.status === "DISPATCHED") {
          this.step = 2;
          this.buttonName = "DELIVER POLICY";
          this.comprehensiveStatus = 2;
          this.backButtonName = "Home";
        }

        if (this.purchaseDetail.status === "DELIVERED") {
          this.step = 3;
          this.buttonName = "HOME";
          this.comprehensiveStatus = 3;
          this.backButtonName = "BACK";
        }
      },
      (err) => {
        this.error = err;
      }
    );
  }

  printLabel() {
    this.dialog.open(PrintLabelComponent, { data: this.purchaseDetail });
  }

  goBack(): void {
    if (this.step === 0) {
      this.location.back();
    } else {
      this.router.navigate(["/comprehensive-consumer-request"]);
    }
  }

  updateStatus(): void {
    if (this.step === 0) {
      this.requestService
        .setPolicyUpdateStatus(this.purchaseDetail.id, "POLICY_PRINTED")
        .subscribe(
          (response: any) => {
            this.step++;
            this.buttonName = "DISPATCH POLICY";
          },
          (err) => {
            this.error = err;
          }
        );
    }

    if (this.step === 1) {
      this.requestService
        .setPolicyUpdateStatus(this.purchaseDetail.id, "DISPATCHED")
        .subscribe(
          (response: any) => {
            this.step++;
            this.buttonName = "DELIVER POLICY";
          },
          (err) => {
            this.error = err;
          }
        );
    }

    if (this.step === 2) {
      this.requestService
        .setPolicyUpdateStatus(this.purchaseDetail.id, "DELIVERED")
        .subscribe(
          (response: any) => {
            this.step++;
            this.buttonName = "HOME";
          },
          (err) => {
            this.error = err;
          }
        );
    }

    if (this.step === 3) {
      this.router.navigate(["/dashboard"]);
    }
  }

  public onSlide(slideData) {
    console.log("SLIDE:", slideData);
    if (slideData.direction === "left") {
      if (this.slideIndex < 3) {
        this.slideIndex++;
      } else {
        this.slideIndex = 1;
      }
    } else if (slideData.direction === "right") {
      if (this.slideIndex > 1) {
        this.slideIndex--;
      } else {
        this.slideIndex = 3;
      }
    }

    switch (this.slideIndex) {
      case 1: {
        if (this.policyDocumentUrl === undefined) {
          this.storageService
            .getSASToken(
              this.route.snapshot.paramMap.get("id") + "_policy",
              this.docContainerName
            )
            .subscribe((token: any) => {
              const encodedSasToken = encodeURIComponent(token.sasToken);
              let url =
                environment.cdnUrl +
                "/" +
                this.docContainerName +
                "/" +
                this.route.snapshot.paramMap.get("id") +
                "_policy" +
                encodedSasToken;
              this.policyDocumentUrl =
                this.sanitizer.bypassSecurityTrustResourceUrl(url);
            });
        }
        break;
      }

      case 2: {
        if (this.moroorDocumentUrl === undefined) {
          this.storageService
            .getSASToken(
              this.route.snapshot.paramMap.get("id") + "_moroor",
              this.docContainerName
            )
            .subscribe((token: any) => {
              const encodedSasToken = encodeURIComponent(token.sasToken);
              let url =
                environment.cdnUrl +
                "/" +
                this.docContainerName +
                "/" +
                this.route.snapshot.paramMap.get("id") +
                "_moroor" +
                encodedSasToken;
              this.moroorDocumentUrl =
                this.sanitizer.bypassSecurityTrustResourceUrl(url);
            });
        }
        break;
      }

      case 3: {
        if (this.invoiceDocumentUrl === undefined) {
          this.storageService
            .getSASToken(
              this.route.snapshot.paramMap.get("id") + "_invoice",
              this.docContainerName
            )
            .subscribe((token: any) => {
              const encodedSasToken = encodeURIComponent(token.sasToken);
              let url =
                environment.cdnUrl +
                "/" +
                this.docContainerName +
                "/" +
                this.route.snapshot.paramMap.get("id") +
                "_invoice" +
                encodedSasToken;
              this.invoiceDocumentUrl =
                this.sanitizer.bypassSecurityTrustResourceUrl(url);
            });
        }
      }
    }
    // your JS here
  }
}
