
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { catchError } from 'rxjs/operators';

import { environment } from '../environments/environment';

import { BaseService } from './base.service';


import { Helpers } from '../helpers/helpers';
import { Observable } from 'rxjs';


@Injectable()

export class TokenService extends BaseService {

  private pathAPI = environment.apiUrl;

  public errorMessage: string;

  constructor(private http: HttpClient, helper: Helpers) { super(helper); }

  auth(data: any): any {

    const body = JSON.stringify(data);

    return this.getToken(body);

  }

  getTokenForPushNotification(data: any): any {
    return this.http.post<any>(this.pathAPI + 'Account/LoginForPushNotification', data, super.header()).pipe(
      catchError(super.handleError));
  }

  private getToken(body: any): Observable<any> {

    return this.http.post<any>(this.pathAPI + 'Account/LoginForPortal', body, super.header()).pipe(

      catchError(super.handleError)

    );

  }


  public loginForCompanyPortal(body: any): Observable<any> {

    return this.http.post<any>(this.pathAPI + 'Account/LoginForCompanyPortal', body, super.header()).pipe(

      catchError(super.handleError)

    );

  }

  public loginForUser(body: any): Observable<any> {

    return this.http.post<any>(this.pathAPI + 'Account/Login', body, super.header()).pipe(

      catchError(super.handleError)

    );

  }

}
