import { Entity } from "./entity";
import { Parameter } from "./parameter";
import { RateCardCivilCard } from "./rate-card-civil-card";
import { RateCardFee } from "./rate-card-fee";
import { RateCardInsurer } from "./rate-card-insurer";
import { RateCardTransaction } from "./rate-card-transaction";
import { RateCardUser } from "./rate-card-user";
import { RateCardVehicle } from "./rate-card-vehicle";

export class RateCard extends Entity {

    constructor(id: number, obj: any) {
        super(id)
        if (obj) {
            Object.assign(this, obj)
            if (obj.parametersMap) {
                this.parametersMap = {}
                for (const key in obj.parametersMap) {
                    const val = obj.parametersMap[key]
                    this.parametersMap[key] = new Parameter(val)
                }
            }


            if (obj.addOnsMap) {
                this.addOnsMap = {}
                for (const key in obj.addOnsMap) {
                    const val = obj.addOnsMap[key]
                    this.addOnsMap[key] = new Parameter(val)
                }
            }
        }
    }
    historyId: number
    rateCardId?: number
    imageLink?: string
    parametersMap: { [key: string]: Parameter } = {}
    addOnsMap: { [key: string]: Parameter } = {}
    saiyartiRecommended?: boolean
    insurerId?: string
    vehicleId?: number
    isConfirmed: boolean = true
    userId?: string
    validity?: Date
    createdAt?: Date
    status?: string
    policyNumber?: string
    amount?: number
    premium?: number
    notes?: string
    policyOrderDate?: Date
    policyStartDate?: Date
    policyExpiryDate?: Date
    policyIssueDate?: Date
    policyDeliveryDate?: Date

    saiyartiPercentage?: number
    validityPeriod?: number
    isRejected: boolean = false
    insurerPercentage?: number
    type?: string
    minimumPremiumAmount?: number
    limitedComprehensiveCover?: number
    addOnAmount?: number
    saiyartiAssistAmount?: number
    tplAmount?: number
    comprehensiveAmount?: number
    address?: string
    vehicle?: RateCardVehicle
    insurer: RateCardInsurer
    forwardedToInsurer: RateCardInsurer
    fees: RateCardFee[] = []
    user: RateCardUser
    civilCard: RateCardCivilCard = {}
    transaction?: RateCardTransaction

    carMake:string[]=[]
    carModel:string[]=[]
    purposeOfLicence:string[]=[]
    
}

export interface RateCardSelections {
    tpl: boolean
    comprehensive: boolean
    addOns: boolean
    limitedCoverage: boolean
}