import { Component, OnInit } from '@angular/core';
import { TableComponent } from 'src/app/table.component';
import { UnIssuedReportService } from 'src/services/reports/unissued.service';
import { IssuanceModel } from 'src/models/issuance';
import * as moment from 'moment';

@Component({
  selector: 'app-unissued-policies-report',
  templateUrl: './unissued-policies-report.component.html',
  styleUrls: ['./unissued-policies-report.component.css']
})
export class UnissuedPoliciesReportComponent extends TableComponent<IssuanceModel> implements OnInit {

  pageTitle = 'Unissued Policies Report';
  startDate: Date;
  endDate: moment.Moment;
  get EndDate() {
    return this.endDate;
  }
  set EndDate(value) {
    if ( value ) {
      this.endDate = value.endOf('day');
    }
  }

  displayedColumns: string[] = [
    'orderdate',
    'insuranceCompany',
    'product',
    'requestType',
    'customerId',
    'customerName',
    'userName',
    'userId',
    'vehicleRcId',
    'vehicleMake',
    'vehicleModel',
    // 'vehicleManufacturingDate',
    'vehiclePlateNumber',
    'basePremium',
    'addOn',
    'fees',
    'totalPremium',
    'netAmount',
    'paymentDate',
    'paymentReferenceNumber'

  ];
  constructor(private reportService: UnIssuedReportService) {
    super(reportService);
  }

  getCount() {
    const data = {
      startDate: this.startDate,
      endDate: this.endDate,
      skip: this.paginator.pageIndex * this.paginator.pageSize,
      take: this.paginator.pageSize
    };
    return this.reportService.getCount(data);
  }
  
  getData(): any {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      skip: this.paginator.pageIndex * this.paginator.pageSize,
      take: this.paginator.pageSize,
      orderBy: this.sort.active,
      orderByDescending: this.sort.direction === 'desc'
    };
  }

  getDataByFilter() {
    const data = this.getData();
    this.getCount().subscribe(res => {
      this.count = res.count;
      this.loadRequests(data);
    });
  }

  downloadXLS(isXLS: boolean) {
    const data = {
      startDate: this.startDate,
      endDate: this.endDate,
      skip: 0,
      take: 2147483647,
      asXLS: isXLS
    };

    this.reportService.downloadReport(data);
  }
}
