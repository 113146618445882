import { Component, OnInit } from '@angular/core';
import { TableComponent } from 'src/app/table.component';
import * as moment from 'moment';
import { ComprehensiveIssuanceSummaryService } from 'src/services/reports/comprehensive-issuance-summary.service';

export class IssuanceSummary {
  orderDate: Date;
  orders: number;
  customers: number;
  basePremium: number;
  fees: number;
  totalPremium: number;
}


@Component({
  selector: 'app-comprehensive-issuance-summary-reports',
  templateUrl: './comprehensive-issuance-summary-reports.component.html',
  styleUrls: ['./comprehensive-issuance-summary-reports.component.css']
})
export class ComprehensiveIssuanceSummaryReportsComponent extends TableComponent<Request> implements OnInit {

  pageTitle = 'Comprehensive Issuance Summary Report';
  startDate: Date;
  endDate: moment.Moment;
  get EndDate() {
    return this.endDate;
  }
  set EndDate(value) {
    if ( value ) {
      this.endDate = value.endOf('day');
    }
  }

  issuanceList: IssuanceSummary[] = [];

  displayedColumns: string[] = ['orderDate', 'orders', 'customers', 'basePremium', 'addOn' ,'fees', 'totalPremium', 'netAmount'];

  constructor(private reportService: ComprehensiveIssuanceSummaryService) {
    super(reportService);
  }


  getCount(): any {
    const data = {
      startDate: this.startDate,
      endDate: this.endDate,
      skip: this.paginator.pageIndex * this.paginator.pageSize,
      take: this.paginator.pageSize
    };
    return this.reportService.getCount(data);
  }

  getData(): any {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      skip: this.paginator.pageIndex * this.paginator.pageSize,
      take: this.paginator.pageSize,
      orderBy: this.sort.active,
      orderByDescending: this.sort.direction === 'desc'
    };
  }

  getDataByFilter() {
    const data = this.getData();
    this.getCount().subscribe(res => {
      this.count = res.count;
      this.loadRequests(data);
    });
  }

  downloadXLS(isXLS: boolean) {
    const data = {
      startDate: this.startDate,
      endDate: this.endDate,
      skip: 0,
      take: 2147483647,
      asXLS: isXLS
    };

    this.reportService.downloadReport(data);
  }

}
