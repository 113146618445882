import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Helpers } from "src/helpers/helpers";
import { BaseService } from "./base.service";
import { ICountService } from "./icount.service";
import { catchError } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
  })
export class SafrattiOrderService extends BaseService implements ICountService {

    private pathAPI = environment.apiUrl;
  
    constructor(private http: HttpClient, helper: Helpers) { super(helper); }
    getCount(body: any) {
      return this.http.post<any>(this.pathAPI + 'Safratti/GetOrdersCount', body, super.header()).pipe(catchError(super.handleError))
    }
    getData(body: any) {
      return this.http.post<any>(this.pathAPI + 'Safratti/GetOrdersForSafratti', body, super.header())
      .pipe(catchError(super.handleError));
    }
  
}