import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { StorageService } from 'src/services/storage.service';
import { environment } from '../../../environments/environment';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-comprehensive-upload-doc',
  templateUrl: './comprehensive-upload-doc.component.html',
  styleUrls: ['./comprehensive-upload-doc.component.css']
})
export class ComprehensiveUploadDocComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
    },
    nav: false,
  };

  public slideIndex = 1;

  comprehensiveStatus = 2;
  pageTitle = 'Comprehensive Request';
  policyDocumentUrl;
  moroorDocumentUrl;
  invoiceDocumentUrl;
  docContainerName: string = 'comprehensive-policy-book';

  constructor(private location: Location,
              private router: Router, private route: ActivatedRoute, private storageService: StorageService) {

  }

  ngOnInit() {
    this.loadImage();
  }

  loadImage(): void {
    this.storageService.getSASToken(this.route.snapshot.paramMap.get('id') + '_policy', this.docContainerName).subscribe((token: any) => {
      this.policyDocumentUrl = environment.cdnUrl +  '/' + this.docContainerName + '/'
        + this.route.snapshot.paramMap.get('id') + '_policy' + token.sasToken;
    });
  }

  goBack(): void {
    this.location.back();
  }

  print() {
    switch (this.slideIndex) {
      case 1: {
        this.storageService.getSASToken(this.route.snapshot.paramMap.get('id') + '_policy', this.docContainerName).subscribe((token: any) => {
          const url = environment.cdnUrl + '/' + this.docContainerName + '/'
            + this.route.snapshot.paramMap.get('id') + '_policy' + token.sasToken;
          this.storageService.getImage(url).subscribe((response) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            const blobUrl = URL.createObjectURL(blob);
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = blobUrl;
            document.body.appendChild(iframe);
            iframe.contentWindow.print();
          });
        });
        break;
      }
      case 2: {
        console.log(this.slideIndex);
        this.storageService.getSASToken(this.route.snapshot.paramMap.get('id') + '_moroor', this.docContainerName).subscribe((token: any) => {
          const url = environment.cdnUrl + '/' + this.docContainerName + '/'
            + this.route.snapshot.paramMap.get('id') + '_moroor' + token.sasToken;
          this.storageService.getImage(url).subscribe((response) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            const blobUrl = URL.createObjectURL(blob);
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = blobUrl;
            document.body.appendChild(iframe);
            iframe.contentWindow.print();

          });
        });
        break;
      }
      case 3: {
        console.log(this.slideIndex);
        this.storageService.getSASToken(this.route.snapshot.paramMap.get('id') + '_invoice', this.docContainerName).subscribe((token: any) => {
          const url = environment.cdnUrl + '/' + this.docContainerName + '/'
            + this.route.snapshot.paramMap.get('id') + '_invoice' + token.sasToken;
          this.storageService.getImage(url).subscribe((response) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            const blobUrl = URL.createObjectURL(blob);
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = blobUrl;
            document.body.appendChild(iframe);
            iframe.contentWindow.print();

          });
        });
        break;
      }

    }

  }
  done() {
    this.router.navigate(['/comprehensive-consumer-request/policy-status/' + this.route.snapshot.paramMap.get('id')]);
  }

  slideChange(index) {
    this.slideIndex = index;
  }


  public onSlide(slideData) {
    console.log('SLIDE:', slideData);
    if (slideData.direction === 'left') {
      if (this.slideIndex < 3) {
        this.slideIndex++;
      } else {
        this.slideIndex = 1;
      }
    } else if (slideData.direction === 'right') {
      if (this.slideIndex > 1) {
        this.slideIndex--;
      } else {
        this.slideIndex = 3;
      }
    }

    switch (this.slideIndex) {

      case 1: {
        if (this.policyDocumentUrl === undefined) {
          this.storageService.getSASToken(this.route.snapshot.paramMap.get('id') + '_policy', this.docContainerName).subscribe((token: any) => {
            this.policyDocumentUrl = environment.cdnUrl + '/' + this.docContainerName + '/'
              + this.route.snapshot.paramMap.get('id') + '_policy' + token.sasToken;
          });
        }
        break;
      }

      case 2: {
        if (this.moroorDocumentUrl === undefined) {
          this.storageService.getSASToken(this.route.snapshot.paramMap.get('id')
            + '_moroor', this.docContainerName).subscribe((token: any) => {
              this.moroorDocumentUrl = environment.cdnUrl + '/' + this.docContainerName + '/'
                + this.route.snapshot.paramMap.get('id') + '_moroor' + token.sasToken;
            });
        }
        break;
      }


      case 3: {
        if (this.invoiceDocumentUrl === undefined) {
          this.storageService.getSASToken(this.route.snapshot.paramMap.get('id') + '_invoice', this.docContainerName).subscribe((token: any) => {
            this.invoiceDocumentUrl = environment.cdnUrl + '/' + this.docContainerName + '/'
              + this.route.snapshot.paramMap.get('id') + '_invoice' + token.sasToken;
          });
        }
      }
    }
    // your JS here
  }

}
