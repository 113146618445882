import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Helpers } from 'src/helpers/helpers';
import { BaseService } from './base.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ICountService } from './icount.service';

@Injectable({
  providedIn: 'root'
})
export class StorePersonService extends BaseService implements ICountService {

  constructor(private http: HttpClient, helper: Helpers) {
    super(helper);
  }

  private pathAPI = environment.apiUrl;

  getData(body: any): any {
    return this.http.post<any>(this.pathAPI + 'User/StoresUser', body, this.storePersonheader()).pipe(catchError(super.handleError));
  }

  getCount(body: any) {
    return this.http.get<any>(this.pathAPI + 'User/StoreUserCount', this.storePersonheader()).pipe(catchError(super.handleError));
  }

  createStoreUser(data: any): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'User', data, this.storePersonheader()).pipe(catchError(super.handleError));
  }

  getStoreUserById(id: any): any {
    return this.http.get<any>(this.pathAPI + 'User/StoreUser/' + id, this.storePersonheader()).pipe(catchError(super.handleError));
  }

  updateStoreUser(id: any, data: any): any {
    return this.http.put<any>(this.pathAPI + 'User/StoreUser/' + id, data, this.storePersonheader()).pipe(catchError(super.handleError));
  }

  updateStoreUserStatus(id: any, value: boolean): any {
    return this.http.put<any>(this.pathAPI + 'User/StoreUser' + ( value ? '' : 'De' ) + 'Activate/' + id, null, this.storePersonheader()).pipe(catchError(super.handleError));
  }

  protected storePersonheader() {
    return { headers: super.header().headers.append( 'accept-language', 'en' ) };
  }
}
