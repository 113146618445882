import { GTSService } from "./gts.service";
import { PolicyDocumentService } from "./policy-document-abstract";

export class GTSPolicyDocumentService extends PolicyDocumentService {
  _policyId: string;
  _token: string;
  _gtsService: GTSService;
  constructor(policyId: string, token: string, gtsService: GTSService) {
    super();
    this._policyId = policyId;
    this._token = token;
    this._gtsService = gtsService;
  }

  downloadMembershipCard(cb: (blob: Blob) => void) {         
    return this._gtsService.downloadReciept(this._policyId, this._token).subscribe( 
      (data)=>{
       cb(data.body)
      }
      );
  }
  downloadPolicy(cb: (blob: Blob) => void) {
    return this._gtsService.downloadCertificate(this._policyId, this._token).subscribe( 
      (data)=>{
       cb(data.body)
      }
      );
  }
  downloadTermsAndConditions():any {
    return this._gtsService.downloadTermsAndConditions(
      this._policyId,
      this._token
    )
    
  }

  uploadMembershipCard(cb: () => void): void {
    throw new Error("Method not implemented.");
  }
  uploadPolicy(cb: () => void): void {
    throw new Error("Method not implemented.");
  }
}
