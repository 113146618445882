import { Component } from '@angular/core';
import { StorageService } from 'src/services/storage.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { TableComponent } from 'src/app/table.component';
import { SelectionModel } from '@angular/cdk/collections';
import { NewCustomerService } from 'src/services/newcustomer.service';
import { NewCustomer } from 'src/models/newcustomer';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-new-customer-import',
  templateUrl: './new-customer-import.component.html',
  styleUrls: ['./new-customer-import.component.css']
})
export class NewCustomerImportComponent extends TableComponent<NewCustomer> {

  newCustomersFile: any;
  newCustomersEle: string;

  newCustomerImagesFile: any;
  newCustomerImagesEle: string;


  processing = false;
  displayedColumns: string[] =
    [
      'id',
      'name',
      'phoneNumber',
      'plateNumber',
      'rcCivilCardNumber',
      'purposeOfLicense',
      'rcOwnerName',
      'rcNationality',
      'referenceNumber',
      'chassisNumber',
      'carMake',
      'carModel',
      'carTrim',
      'modelYear',
      'fuelType',
      'purposeOfLicenseId',
      'passengers',
      'rcExpiryDate',
      'bodyStyle',
      'color',
      'weight',
      'load',
      'height',

      ////////////////////// Civil Card ///////////////////////
      'civilCardExpiryDate',
      'civilCardNumber',
      'civilCardOwnerName',
      'civilCardNationality',
      'address',
      'civilCardGender',
      'block',
      'street',
      'buildingNumber',
      'buildingKind',
      'flatNumber',
      'floorNumber',
      'paciNumber',

      ////////////////////// Policy Details  //////////////////////////

      'policyNumber',
      'deliveryAddress',
      'issueDate',
      'startDate',
      'deliveryDate',
      'totalAmount',
      'basePremium',
      'convenienceFee',
      'issueFee',
      'supervisionFee',
      'deliveryFee'
    ];
  selection = new SelectionModel<any>(true, []);
  selectedDepartment:any;
  account:any;

  constructor(private storageService: StorageService,
    private snackBar: MatSnackBar,
    private router: Router,
    protected http: HttpClient, protected customerService: NewCustomerService,private dialogRef: MatDialogRef<NewCustomerImportComponent>,) {
    super(customerService);
  }

  uploadCSV() {
    if (this.newCustomersFile !== undefined) {
      const d = new Date();
      this.storageService
      .getUploadOnlyTokenForContainer("newcustomers-csv")
      .subscribe((token) => {
        this.storageService
          .uploadFileToAzure(
            `/newcustomers-csv/${
              this.selectedDepartment.id +
              "__" +
              this.account.id +
              "__" +
              d.getTime().toString() +
              ".csv"
            }${token.sasToken}`,
            this.newCustomersFile,
            "image/jpg"
          )
          .subscribe({
            next: (res) => {
              this.dialogRef.close();
              this.snackBar.open(
                "The file has been uploaded. An email will be sent to your account as soon as processed.",
                "CANCEL",
                {
                  horizontalPosition: "right",
                  duration: 4000,
                }
              );
              this.uploadImages();
            },
            error:(err:Error)=>{
              err
            }
          });
      });
    }
  }

  private uploadImages() {
    if (this.newCustomerImagesFile !== undefined) {
      const d = new Date();
      this.storageService
      .getUploadOnlyTokenForContainer("newcustomer-img-csv")
      .subscribe((token) => {
        this.storageService
          .uploadFileToAzure(
            `/newcustomer-img-csv/${
              this.selectedDepartment.id +
              "__" +
              this.account.id +
              "__" +
              d.getTime().toString() +
              ".csv"
            }${token.sasToken}`,
            this.newCustomerImagesFile,
            "image/jpg"
          )
          .subscribe({
            next: (res) => {
              this.dialogRef.close();
              this.processing = false;
              this.snackBar.open(
                "The file has been uploaded. An email will be sent to your account as soon as processed.",
                "CANCEL",
                {
                  horizontalPosition: "right",
                  duration: 4000,
                }
              );
            },
          });
      });
    }
  }

  onnewCustomers(event) {
    const file = event.target.files[0];
    this.newCustomersEle = file.name;
    this.newCustomersFile = file;
  }

  onnewCustomerImages(event) {
    const file = event.target.files[0];
    this.newCustomerImagesEle = file.name;
    this.newCustomerImagesFile = file;
  }

  openCustomer(row) {
    this.router.navigate(['setup/customers/' + row.id]);
  }

}
