import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { StorePersonService } from 'src/services/storeperson.service';
import { TableComponent } from '../table.component';
import { StoreUser } from 'src/models/storeUser';
import { AccountService } from 'src/services/account.service';

@Component({
  selector: 'app-sperson-scoordinator',
  templateUrl: './sperson-scoordinator.component.html',
  styleUrls: ['./sperson-scoordinator.component.css']
})
export class SpersonScoordinatorComponent extends TableComponent<StoreUser> implements OnInit {

  pageTitle = 'Admins';
  storesUserList: any;
  displayedColumns: string[] = ['name', 'type', 'phone', 'email', 'dateCreated', 'isactivated', 'optionMenu'];
  storeUserTypes: any;

  constructor(private router: Router,
    private storePersonService: StorePersonService,
    private snackBar: MatSnackBar,
    private accountService: AccountService,
    private dialog: MatDialog) {
    super(storePersonService);
    this.storeUserTypes = {
      'StoreSalesPerson': 'Sales Person',
      'StoreCoordinator': 'Store Coordinator'
    }
  }

  ngOnInit() {
    super.ngOnInit();
  }

  updateUserActivateStatus(storePerson) {
    const dialogMsg = storePerson.activated ? 'Once deactivated the user will not be visible in the list. Are you sure you want deactivate?' :
      'Once activated the user will be visible in the list. Are you sure you want activate?';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { title: 'Confirm', message: dialogMsg } });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true') {
        this.storePersonService.updateStoreUserStatus(storePerson.id, !storePerson.activated).subscribe(res => {
          this.loadRequests(this.getData());
          this.snackBar.open('User ' + (storePerson.activated ? 'de' : '') + 'activated successfully.', 'CANCEL', {
            horizontalPosition: 'right',
            duration: 2000
          });
        }, err => {
          this.snackBar.open(err, 'CANCEL', {
            horizontalPosition: 'right'
          });
        });
      }
    });
  }


  resetPassword(element) {
    this.accountService.sendResetPasswordEmail({ email: element.email }).subscribe(res => {
      // Reset Password link sent successfully.
      this.snackBar.open('Reset Password link sent successfully.', 'CANCEL', {
        horizontalPosition: 'right',
        duration: 2000
      });
    }, err => {
      const snackBarRef = this.snackBar.open(err, 'Retry', {
        horizontalPosition: 'right'
      });
      // snackBarRef.afterDismissed().subscribe(() => {
      //   //console.log('The snack-bar was dismissed');
      //   this.resetPassword(agent);
      // });
    });
  }

}
