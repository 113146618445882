import { Component, OnInit } from '@angular/core';
import { IssuanceReportService } from 'src/services/reports/issuance.service';
import { TableComponent } from 'src/app/table.component';
import { IssuanceModel } from 'src/models/issuance';
import * as moment from 'moment';

@Component({
  selector: 'app-issuance-report',
  templateUrl: './issuance-report.component.html',
  styleUrls: ['./issuance-report.component.css']
})
export class IssuanceReportComponent extends TableComponent<IssuanceModel> implements OnInit {
  pageTitle = 'Issuance Report';
  startDate: Date;
  endDate: moment.Moment;
  get EndDate() {
    return this.endDate;
  }
  set EndDate(value) {
    if ( value ) {
      this.endDate = value.endOf('day');
    }
  }

  displayedColumns: string[] = [
    'orderdate',
    'issuedate',
    // 'daysToIssueThePolicy',
    'insuranceCompany',
    'product',
    'startDate',
    'endDate',
    'policyNumber',
    'orderType',
    'customerID',
    'customerName',
    'phoneNumber',
    'email',
    'userID',
    'userName',
    'vehicleRcId',
    'vehicleMake',
    'vehicleModel',
    'vehicleModelYear',
    // 'vehicleManufacturingDate',
    'vehiclePlateNumber',
    'carStatus',
    'addOnAmount',
    'basePremium',
    'fees',
    'totalPremium',
    'netAmount',
    'paymentDate',
    'paymentReferenceNumber',
    'deliveryStatus',
    'deliveryDate'
  ];

  constructor(private reportService: IssuanceReportService) {
    super(reportService);
  }

  
  getCount(): any {
    const data = {
      startDate: this.startDate,
      endDate: this.endDate,
      skip: this.paginator.pageIndex * this.paginator.pageSize,
      take: this.paginator.pageSize
    };
    return this.reportService.getCount(data);
  }

  getData(): any {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      skip: this.paginator.pageIndex * this.paginator.pageSize,
      take: this.paginator.pageSize,
      orderBy: this.sort.active,
      orderByDescending: this.sort.direction === 'desc'
    };
  }

  getDataByFilter() {
    const data = this.getData();
    this.getCount().subscribe(res => {
      this.count = res.count;
      this.loadRequests(data);
    });
  }

  downloadXLS(isXLS: boolean) {
    const data = {
      startDate: this.startDate,
      endDate: this.endDate,
      skip: 0,
      take: 2147483647,
      asXLS: isXLS
    };

    this.reportService.downloadReport(data);
  }

  // getIssuanceReport(data: any) {
  //   const lMe = this;
  //   this.reportService.getIssuanceReport(data).subscribe(res => {

  //     res.forEach(value => {
  //       const issuance = new IssuanceModel();
  //       issuance.startDate = value.startDate;
  //       issuance.endDate = value.endDate;
  //       issuance.insuranceCompany = value.company.companyName;
  //       issuance.policyNumber = value.policyNumber;
  //       issuance.orderdate = value.createdAt;
  //       issuance.issuedate = value.issueDate;
  //       issuance.product = value.product;
  //       issuance.basePremium = value.amountPaid;
  //       issuance.fees = value.totalFee;
  //       issuance.totalPremium = value.totalAmount;
  //       issuance.deliveryStatus = value.status;
  //       issuance.deliveryDate = value.deliveryDate;
  //       issuance.paymentDate = value.createdAt;
  //       issuance.paymentReferenceNumber = value.paymentToken;
  //       issuance.vehicleRcId = value.rcBook.id;
  //       issuance.vehicleMake = value.rcBook.carMake;
  //       issuance.vehicleModel = value.rcBook.carModel;
  //       issuance.vehiclePlateNumber = value.rcBook.plateNumber;
  //       issuance.userName = value.owner.firstName + ' ' + value.owner.lastName;
  //       issuance.userID = value.owner.id;
  //       issuance.customerName = value.civilCard.ownerName;
  //       issuance.customerID = value.civilCard.id;

  //       this.issuanceList.push(issuance);
  //     });

  //     lMe.dataSource = new MatTableDataSource(this.issuanceList.reverse());
  //     setTimeout(x => {
  //       lMe.dataSource.paginator = lMe.paginator;
  //     }, 100);

  //   }, err => {
  //     if (err) {
  //       this.dialogService.errorSanckBar(err);
  //     } else {
  //       this.dialogService.errorSanckBar('Something went wrong. Please contact admin.');
  //     }
  //   });
  // }
}
