export class Store {
    id: string;
    createdAt: string;
    firstName: string;
    lastName: string;
    storeName: string;
    civilCardNumber: string;
    email: string;
    countryCode: number;
    phoneNumber: string;
    activated: boolean;
    address: string;
    type: string;
}