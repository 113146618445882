import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { ICountService } from "./icount.service";
import { environment } from "src/environments/environment";
import { catchError } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Helpers } from "src/helpers/helpers";
import { Observable } from "rxjs";
import { StorageMember } from "src/models/storageMember";
import { ModifyPolicy } from "src/models/modifyPolicy";
import { ModifyPolicyResponse } from "src/models/modify-policy.response";

@Injectable({
  providedIn: "root",
})
export class SafrattiRequestService
  extends BaseService
  implements ICountService
{
  private pathAPI = environment.apiUrl;

  constructor(private http: HttpClient, helper: Helpers) {
    super(helper);
  }
  getCount(body: any) {
    return this.http
      .post<any>(
        this.pathAPI + "Safratti/GetRequestCount",
        body,
        super.header()
      )
      .pipe(catchError(super.handleError));
  }
  getData(body: any) {
    return this.http
      .post<any>(
        this.pathAPI + "Safratti/SafrattiFiltered",
        body,
        super.header()
      )
      .pipe(catchError(super.handleError));
  }

  getPolicyDetail(id: number) {
    return this.http
      .get<any>(this.pathAPI + "Safratti/Request/" + id, super.header())
      .pipe(catchError(super.handleError));
  }

  getDefaultCompanyForSafratti(): any {
    return this.http
      .get<any>(this.pathAPI + 'Company/Safratti/Default', super.header())
      .pipe(catchError(super.handleError));
  }

  getPolicyHistory(id: number) {
    return this.http
      .get<any>(this.pathAPI + `Safratti/Policy/${id}/History`, super.header())
      .pipe(catchError(super.handleError));
  }

  cancelPolicy(reqId: number) { 
    return this.http
      .post<any>(
        this.pathAPI + `Safratti/Policy/${reqId}/Cancel` , {},
        super.header()
      )
      .pipe(catchError(super.handleError));
  }

            

    modifyPolicy(id:number,body:ModifyPolicy):Observable<ModifyPolicyResponse>{
        return this.http.put<ModifyPolicyResponse>(this.pathAPI+`Safratti/Policy/${id}`,body,super.header()).pipe(catchError(super.handleError))
    }


    updateRewardCredit(id: number,isApproved:boolean){
      return this.http.put<any>(this.pathAPI + `Safratti/RewardCredit/${id}?IsApproved=${isApproved}`,{} ,super.header())
      .pipe(catchError(super.handleError));
    }
 
}

