import { Benefit } from "./benefit";
import { SafrattiAges } from "./safratti-fee-structure-age";

export class SafrattiFee{
    id: number;
    name:string;
    travelDays:number;
    age:number;
    sportsActivity:boolean;
    policyFee:number;
    issueFee:number;
    otherFee:number;
    basicPremium: number;
    benefits: Benefit[];
    safrattiAges: SafrattiAges[];

}