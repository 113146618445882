import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { TravelerDetail } from "src/models/traveler-detail";
import { SafrattiRequestService } from "src/services/safratti-request.service";

@Component({
  selector: "app-safratti-policy-history",
  templateUrl: "./safratti-policy-history.component.html",
  styleUrls: ["./safratti-policy-history.component.css"],
})
export class SafrattiPolicyHistoryComponent implements OnInit {
  constructor(
    private safrattiService: SafrattiRequestService,
    private route: ActivatedRoute,
    private location: Location
  ) {}
  policyhistory: TravelerDetail;

  ngOnInit(): void {
    const id = +this.route.snapshot.paramMap.get("id");
    this.safrattiService.getPolicyDetail(id).subscribe((x) => {
      this.policyhistory = x;
    });
  }

  goBack(): void {
    this.location.back();
  }
}
