import { Injectable } from '@angular/core';

import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { throwError, Subject } from 'rxjs';

import { Helpers } from '../helpers/helpers';

import { environment } from '../environments/environment';

@Injectable()

export class BaseService {

  constructor(protected helper: Helpers) { }

  public extractData(res: Response) {

    const body = res.json();

    return body || {};

  }

  private loader = new Subject<boolean>();
  loader$ = this.loader.asObservable();

  protected receviedResponse() {
    this.loader.next(false);
  }

  public handleError(error: HttpErrorResponse | any) {

    // In a real-world app, we might use a remote logging infrastructure

    // let errMsg: string;
    let errMsg: any;

    if (error instanceof HttpErrorResponse) {

      const body = error.error || '';

      const err = body || JSON.stringify(body);

      errMsg = err.text;
      // errMsg = error;

    } else {

      errMsg = error.message ? error.message : error.toString();

    }

    return throwError(errMsg);



  }



  protected header(token?: string, lang?: string) {

    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    header = header.append('apiKey', environment.apiKey);
    header = header.append('apiPassword', environment.apiPassword);
    header = header.append('accept-language', lang ? lang : 'en' );

    if (token && token.length > 0) {
      header = header.append('Authorization', 'Bearer ' + token);
    } else if (this.helper.isAuthenticated()) {
      header = header.append('Authorization', 'Bearer ' + this.helper.getToken());
    }

    return { headers: header };
  }




  public setToken(data: any) {



    this.helper.setToken(data);

  }

  public failToken(error: Response | any) {



    this.helper.failToken();

    return this.handleError(Response);

  }
  public parseResponse(
    body: HttpErrorResponse | any) {
    if (body instanceof HttpErrorResponse) {
      const errBody = body.error || '';
      const err = errBody || JSON.stringify(errBody);
      throw new Error(err);
    } else if (body.success == false) {
      if (body.errors.length > 0) {
        throw new Error(body.errors[0]);
      } else {
        throw new Error(body.status.message);
      }
    } else {
      if (body.response) {
        return body.response
      }
      return body;
    }
  }
}
