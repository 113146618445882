import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Helpers } from 'src/helpers/helpers';
import { BaseService } from './base.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccountService extends BaseService {

  constructor(private http: HttpClient, helper: Helpers) {
    super(helper);
  }

  private pathAPI = environment.apiUrl;

  changePassword(data: any): any {
    return this.http.post<any>(this.pathAPI + 'Account/ChangePassword', data, super.header()).pipe(catchError(super.handleError));
  }

  getAccount(): any {
    return this.http.get<any>(this.pathAPI + 'Account', super.header()).pipe(catchError(super.handleError));
  }

  getName(id: string): any {
    return this.http.get<any>(this.pathAPI + 'Account/Name/' + id, super.header()).pipe(catchError(super.handleError));
  }

  resetPassword(data: any): any {
    return this.http.post<any>(this.pathAPI + 'ResetPassword', data, super.header()).pipe(catchError(super.handleError));
  }

  setPassword(data: any, token: string): any {
    return this.http.post<any>(this.pathAPI + 'Account/SetPassword', data, super.header(token)).pipe(catchError(super.handleError));
  }


  sendResetPasswordEmail(data: any): any {
    return this.http.post<any>(this.pathAPI + 'Account/ForgotPassword', data, super.header()).pipe(catchError(super.handleError));
  }

  sendPushNotification(data: any): any {
    return this.http.post<any>(this.pathAPI + 'Account/SendPushNotificationToAll', data, this.header())
      .pipe(catchError(super.handleError));
  }

  addAttachments(claimId: number, fileNames: [string]) {
    return this.http.post<any>(this.pathAPI + 'Claim/Attachments/' + claimId, fileNames, super.header()).pipe(
      map(res => {
        super.receviedResponse();
        return res;
      }), catchError(err => super.handleError(err)));
  }

  getPolicy(id: number): any {
    return this.http.get<any>(this.pathAPI + 'Claim/' + id, this.header()).pipe(
      map(res => {
        super.receviedResponse();
        return res;
      }), catchError(err => super.handleError(err)));
  }

  protected header() {

    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    header = header.append('apiKey', environment.apiKey);
    header = header.append('apiPassword', environment.apiPassword);
    header = header.append('Authorization', 'Bearer ' + this.helper.getPushNotificationToken());

    return { headers: header };
  }
}
