import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { StorageService } from 'src/services/storage.service';
import { environment } from '../../../environments/environment';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-policy-verify-print',
  templateUrl: './policy-verify-print.component.html',
  styleUrls: ['./policy-verify-print.component.css']
})
export class PolicyVerifyPrintComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
    },
    nav: false,
  };

  public slideIndex = "1";

  tplStatus = 2;
  pageTitle = 'TPL Request';
  safePDFDocumentUrl:SafeResourceUrl;
  transportPDFDocumentUrl:SafeResourceUrl;
  invoicePDFDocumentUrl:SafeResourceUrl;

  constructor(private location: Location,
              private router: Router, private route: ActivatedRoute, private storageService: StorageService, private sanitizer: DomSanitizer) {
                this.loadImage();

  }

  ngOnInit() {
  }

  loadImage(): void {
    this.storageService.getSASToken(this.route.snapshot.paramMap.get('id') + '_background.pdf', 'policies').subscribe((token: any) => {
        const PDFDocumentUrl = environment.cdnUrl +  '/policies/'
        + this.route.snapshot.paramMap.get('id') + '_background.pdf' + token.sasToken;
        this.storageService.getImage(PDFDocumentUrl).subscribe((response) => {
          const blob = new Blob([response], { type: 'application/pdf' });
          const blobUrl = URL.createObjectURL(blob);
          this.safePDFDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl)

      });})

      this.storageService.getSASToken(this.route.snapshot.paramMap.get('id')
            + '_transport_background.pdf', 'policies').subscribe((token: any) => {
              const temptransportPDFDocumentUrl = environment.cdnUrl + '/policies/'
                + this.route.snapshot.paramMap.get('id') + '_transport_background.pdf' + token.sasToken;
                this.storageService.getImage(temptransportPDFDocumentUrl).subscribe((response) => {
                  const blob = new Blob([response], { type: 'application/pdf' });
                  const blobUrl = URL.createObjectURL(blob);
                this.transportPDFDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl)})
            });

        this.storageService.getSASToken(this.route.snapshot.paramMap.get('id') + '_invoice.pdf', 'policies').subscribe((token: any) => {
              const tempinvoicePDFDocumentUrl = environment.cdnUrl + '/policies/'
                + this.route.snapshot.paramMap.get('id') + '_invoice.pdf' + token.sasToken;
                this.storageService.getImage(tempinvoicePDFDocumentUrl).subscribe((response) => {
                  const blob = new Blob([response], { type: 'application/pdf' });
                  const blobUrl = URL.createObjectURL(blob);
                this.invoicePDFDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl)})
            });

      
  }

  goBack(): void {
    this.location.back();
  }

  print(indextoPrint) {
    switch (indextoPrint) {
      case "1": {
        this.storageService.getSASToken(this.route.snapshot.paramMap.get('id') + '.pdf', 'policies').subscribe((token: any) => {
          const url = environment.cdnUrl + '/policies/'
            + this.route.snapshot.paramMap.get('id') + '.pdf' + token.sasToken;
          this.storageService.getImage(url).subscribe((response) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            const blobUrl = URL.createObjectURL(blob);
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = blobUrl;
            document.body.appendChild(iframe);
            iframe.contentWindow.print();

          });
        });
        break;
      }
      case "2": {
        // console.log(this.slideIndex);

        this.storageService.getSASToken(this.route.snapshot.paramMap.get('id') + '_transport.pdf', 'policies').subscribe((token: any) => {

          const url = environment.cdnUrl + '/policies/'
            + this.route.snapshot.paramMap.get('id') + '_transport.pdf' + token.sasToken;
          this.storageService.getImage(url).subscribe((response) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            const blobUrl = URL.createObjectURL(blob);
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = blobUrl;
            document.body.appendChild(iframe);
            iframe.contentWindow.print();

          });
        });
        break;
      }
      case "3": {
        // console.log(this.slideIndex);
        this.storageService.getSASToken(this.route.snapshot.paramMap.get('id') + '_invoice.pdf', 'policies').subscribe((token: any) => {
          const url = environment.cdnUrl + '/policies/'
            + this.route.snapshot.paramMap.get('id') + '_invoice.pdf' + token.sasToken;
          this.storageService.getImage(url).subscribe((response) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            const blobUrl = URL.createObjectURL(blob);
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = blobUrl;
            document.body.appendChild(iframe);
            iframe.contentWindow.print();

          });
        });
        break;
      }

    }

  }
  done() {
    this.router.navigate(['/consumer-request/policy-status/' + this.route.snapshot.paramMap.get('id')]);
  }



  onSlide(slideData) {
    this.slideIndex = slideData.slides[0].id  

    switch (this.slideIndex) {

      case "1": {
        if (this.safePDFDocumentUrl === undefined) {
          this.storageService.getSASToken(this.route.snapshot.paramMap.get('id') + '_background.pdf', 'policies').subscribe((token: any) => {
            const unsafePDFDocumentUrl = environment.cdnUrl + '/policies/'
              + this.route.snapshot.paramMap.get('id') + '_background.pdf' + token.sasToken;
              this.storageService.getImage(unsafePDFDocumentUrl).subscribe((response) => {
                const blob = new Blob([response], { type: 'application/pdf' });
                const blobUrl = URL.createObjectURL(blob);
              this.safePDFDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl)})
          });
        }
        break;
      }

      case "2": {
        if (this.transportPDFDocumentUrl === undefined) {
          this.storageService.getSASToken(this.route.snapshot.paramMap.get('id')
            + '_transport_background.pdf', 'policies').subscribe((token: any) => {
              const temptransportPDFDocumentUrl = environment.cdnUrl + '/policies/'
                + this.route.snapshot.paramMap.get('id') + '_transport_background.pdf' + token.sasToken;
                this.storageService.getImage(temptransportPDFDocumentUrl).subscribe((response) => {
                  const blob = new Blob([response], { type: 'application/pdf' });
                  const blobUrl = URL.createObjectURL(blob);
                this.transportPDFDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl)})
            });
        }
        break;
      }


      case "3": {
        if (this.invoicePDFDocumentUrl === undefined) {
          this.storageService.getSASToken(this.route.snapshot.paramMap.get('id') + '_invoice.pdf', 'policies').subscribe((token: any) => {
            const tempinvoicePDFDocumentUrl = environment.cdnUrl + '/policies/'
              + this.route.snapshot.paramMap.get('id') + '_invoice.pdf' + token.sasToken;
              this.storageService.getImage(tempinvoicePDFDocumentUrl).subscribe((response) => {
                const blob = new Blob([response], { type: 'application/pdf' });
                const blobUrl = URL.createObjectURL(blob);
              this.invoicePDFDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl)})
          });
        }
      }
    }
  
  }
}
