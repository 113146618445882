import { Component, OnInit } from '@angular/core';
import { RCBookService } from 'src/services/rcbook.service';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from 'src/services/storage.service';
import { Lightbox } from 'ngx-lightbox';
import { Location } from '@angular/common';
import { environment } from '../../../environments/environment';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-rcbook-detail',
  templateUrl: './rcbook-detail.component.html',
  styleUrls: ['./rcbook-detail.component.css']
})
export class RcbookDetailComponent implements OnInit {

  pageTitle = 'RC Book details';
  rcBookImageUrl: any;
  rcBook: any = {};
  albums: any = [];

  constructor(private rcbookService: RCBookService, private route: ActivatedRoute,
    private lightbox: Lightbox, private location: Location,
    private storageService: StorageService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    const id = +this.route.snapshot.paramMap.get('id');

    this.rcbookService.getRCBook(id).subscribe(res => {
      this.rcBook = res;
      this.loadImages();
    }, err => {

    });
  }

  goBack(): void {
    this.location.back();
  }


  loadImages() {
    const rcBook = this.rcBook.id + '.jpg';

    this.storageService.getSASToken(rcBook, 'rc-book').subscribe((token: any) => {
      this.storageService.getSASToken(rcBook, 'thumbnail-rc-book').subscribe((thumbToken: any) => {
        this.rcBookImageUrl = environment.cdnUrl + '/rc-book/' + rcBook + token.sasToken;
        const thumbRCBookImageUrl = environment.cdnUrl + '/thumbnail-rc-book/' + rcBook + thumbToken.sasToken;
        const src = this.rcBookImageUrl;
        const caption = 'RC Book';
        const thumb = thumbRCBookImageUrl;
        const album = {
          src,
          caption,
          thumb
        };
        this.albums[0] = album;
      });
    });
  }

  open(): void {
    this.lightbox.open(this.albums, 0);
  }

  markCarAsSold() {
    if ( this.rcBook && this.rcBook.id ) {
      this.rcbookService.markCarAsSold( this.rcBook.id, !this.rcBook.isSold ).subscribe( res => {
        this.rcBook.isSold = res.isSold;
        this.snackBar.open( ( 'Car marked as ' + ( this.rcBook.isSold ? '' : 'un' ) +'sold successfully' ), 'CANCEL', {
          duration: 2000,
          horizontalPosition: 'right'
        });
      }, error => {
        this.snackBar.open(error, 'CANCEL', {
          duration: 2000,
          horizontalPosition: 'right'
        });
      });
    }
  }

}
