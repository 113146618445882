export enum RateCardType {
    RATE_CARD = "rate_card",
    ADD_ONS = "add_ons",
    ADD_ONS_LIMITS = "add_ons_limits",
    ADD_ONS_LIMITS_SUBLIMITS = "add_ons_limits_sublimit"
}

export enum RCValueType {
    CALCULATIVE = "Calculative",
    TEXT = "Text",
}

// export class MasterRateCardModel {
//     insurerId?: string;
//     parameter: RateCardLimitModel[];
//     addOns: AddOnsLimitModel[];
//     // fee: FeesOptionModel[];
//     isNewAddRateCard?: boolean;
//     convenienceFee?: number;
//     supervisionFee?: number;
//     deliveryFee?: number;
//     issueFee?: number;
//     minimumPremiumAmount?: number;
// }

// export class ComprehensiveQuoteModel extends MasterRateCardModel {
//     status?: QuoteStatus;
//     amount: number;
//     insurerName: string;
//     insuranceCompanyName?: string;
//     vehicleId: number;
//     totalInsurance?: number;
//     startDate: Date;
//     isConfirmed?: boolean = false;
//     userId?: string;
//     createdAt?: Date;
//     requestStatus?: QuoteStatus;
//     saiyartiPercentage?: number;
//     insurerPercentage?: number;
//     limitedCoverage?: number;
//     validityPeriod?: number;
//     processingFee?: number;
//     ministryFee?: number;
//     deliveryFee?: number;
//     validity: Date;
//     saiyartiRecommended: boolean;
// }

// export class InsurerRateCardModel extends ComprehensiveQuoteModel {
//     firstName?: string;
// }

// export class RateCardLimitModel {
//     name: string;
//     arabicName: string;
//     isSelected: boolean = false;
//     subLimit: RateCardSubLimitModel[];
//     type: RCValueType;
//     description: string;
//     arabicDescription: string;
//     isEnabled?: boolean;
//     higherRange?: number;
//     lowerRange?: number;
//     isMandatory?: boolean;
//     valueForInsurer?: string;
//     percentageValue?: string;

//     constructor( name?: string, arabicName?: string, isSelected?: boolean, subLimit?: RateCardSubLimitModel[], type?: RCValueType, description?: string,arabicDescription?: string, isEnabled?: boolean, higherRange?: number, lowerRange?: number, isMandatory?: boolean , valueForInsurer?: string , percentageValue?: string ) {
//         this.name = name,
//         this.arabicName = arabicName;
//         this.isSelected = isSelected,
//         this.subLimit = subLimit ? Object.assign([], subLimit) : subLimit;
//         this.type = type;
//         this.description = description;
//         this.arabicDescription = arabicDescription;
//         if ( isEnabled != undefined || isEnabled != null ) {
//             this.isEnabled = isEnabled;
//         }
//         this.higherRange = higherRange;
//         this.lowerRange = lowerRange;
//         this.isMandatory = isMandatory;
//         this.valueForInsurer = valueForInsurer;
//         this.percentageValue = percentageValue;
//     }
// }

// export class RateCardSubLimitModel {
//     name: string;
//     arabicName: string;
//     valueForInsurer?: string;
//     isSelected?: boolean = false;
//     description: string;
//     arabicDescription: string;
//     isEnabled?: boolean;
//     higherRange?: number;
//     lowerRange?: number;
//     isMandatory?: boolean;
//     percentageValue?: string;

//     constructor(name?: string,arabicName?: string, valueForInsurer?: string, isSelected?: boolean, description?: string,arabicDescription?: string, isEnabled?: boolean, higherRange?: number, lowerRange?: number, isMandatory?: boolean, percentageValue?: string) {
//         this.name = name;
//         this.arabicName = arabicName;
//         this.valueForInsurer = valueForInsurer;
//         this.isSelected = isSelected;
//         this.description = description;
//         this.arabicDescription = arabicDescription;
//         if ( isEnabled != undefined || isEnabled != null ) {
//             this.isEnabled = isEnabled;
//         }
//         this.higherRange = higherRange;
//         this.lowerRange = lowerRange;
//         this.isMandatory = isMandatory;
//         this.percentageValue = percentageValue;
//     }
// }

// export class AddOnsLimitModel {
//     name: string;
//     arabicName: string;
//     isSelected: boolean = false;
//     valueForInsurer: string;
//     type: RCValueType;
//     description: string;
//     arabicDescription: string;
//     isEnabled?: boolean;
//     limits: limitModel[];
//     isMandatory?: boolean;

//     constructor( name?: string,arabicName?: string, isSelected?: boolean, valueForInsurer?: string, type?: RCValueType, description?: string,arabicDescription?: string, isEnabled?: boolean, limits?: limitModel[], isMandatory?: boolean) {
//         this.name = name,
//         this.arabicName = arabicName;
//         this.isSelected = isSelected;
//         this.valueForInsurer = valueForInsurer;
//         this.type = type;
//         this.description = description;
//         this.arabicDescription = arabicDescription;
//         if ( isEnabled != undefined || isEnabled != null ) {
//             this.isEnabled = isEnabled;
//         }
//         this.limits = limits ? Object.assign([], limits) : limits;
//         this.isMandatory = isMandatory;
//     }
// }
// export class limitModel{
//     name: string;
//     arabicName: string;
//     isSelected?: boolean = false;
//     valueForInsurer?: string;
//     addOnSublimits?: addOnSublimitModel[];
//     isEnabled?: boolean;
//     isMandatory?: boolean;

//     constructor( name?: string,arabicName?: string, isSelected?: boolean, valueForInsurer?: string, addOnSublimits?: addOnSublimitModel[], isEnabled?: boolean,  isMandatory?: boolean){
//         this.name = name,
//         this.arabicName = arabicName,
//         this.isSelected = isSelected,
//         this.valueForInsurer = valueForInsurer;
//         this.addOnSublimits = addOnSublimits ? Object.assign([], addOnSublimits) : addOnSublimits;
//         if ( isEnabled != undefined || isEnabled != null ) {
//             this.isEnabled = isEnabled;
//         }
//         this.isMandatory = isMandatory;
//     }
// }
// export class addOnSublimitModel{
//     name: string;
//     arabicName: string;
//     isSelected?: boolean = false;
//     valueForInsurer?: string;
//     isEnabled?: boolean;
//     isMandatory?: boolean;

//     constructor( name?: string,arabicName?: string, isSelected?: boolean, valueForInsurer?: string, isEnabled?: boolean,  isMandatory?: boolean){
//         this.name = name,
//         this.arabicName = arabicName,
//         this.isSelected = isSelected,
//         this.valueForInsurer = valueForInsurer;
//         if ( isEnabled != undefined || isEnabled != null ) {
//             this.isEnabled = isEnabled;
//         }
//         this.isMandatory = isMandatory;
//     }
// }
// export class FeesOptionModel {
//     name: string;
//     value: number;
//     isEnabled?: boolean;

//     constructor(name?: string, value?: number, isEnabled?: boolean) {
//         this.name = name;
//         this.value = value;
//         if ( isEnabled != undefined || isEnabled != null ) {
//             this.isEnabled = isEnabled;
//         }
//     }
// }

export enum QuoteStatus {
    NEW_REQUEST = "New Request",
    APPROVED = "Approved Request",
    UPDATED_REQUEST = "Updated Request",
    REJECTED = "Rejected",
    PENDING = "PENDING",
    WAITING_FOR_APPROVAL = "Waiting For Approval",
    POLICY_PRINTED = "POLICY_PRINTED",
    PAYMENT_DONE = "PAYMENT_DONE",
    DELIVERED = "DELIVERED",
    DISPATCHED = "DISPATCHED",
}