export class VehicleAccordion{
    title: string;
    policies: Array<VehiclePolicy>;
    month: number;
    year: number;
    policyCount: number;
    isExpanded: boolean = false;

    constructor(title: string, policies: Array<VehiclePolicy>, month: number, year: number, policyCount?: number, isExpanded?: boolean) {
        this.title = title;
        this.policies = policies;
        this.month = month;
        this.year = year;
        this.policyCount = policyCount || 0;
    }
}

export class VehiclePolicy {
    expiryDate: any;
    plateNumber: string;
    groupName: string;
    departmentName: string;

    constructor(expiryDate: any, plateNumber: string, groupName: string, departmentName: string) {
        this.expiryDate = expiryDate;
        this.plateNumber = plateNumber;
        this.groupName = groupName;
        this.departmentName = departmentName;
    }
}