import { Component, OnInit } from '@angular/core';

import { UntypedFormControl } from '@angular/forms';

import { ActivatedRoute } from '@angular/router';

import { Router } from '@angular/router';

import { CompanyService } from '../../../services/company.service';

import { Location } from '@angular/common';

import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ComprehensiveRequestsService } from 'src/services/comprehensive-requests.service';
import { environment } from 'src/environments/environment';
import { DocViewComponent } from 'src/app/model-dialog/doc-view/doc-view.component';
import { DomSanitizer } from '@angular/platform-browser';
import { StorageService } from 'src/services/storage.service';
import { Lightbox } from 'ngx-lightbox';
import { BlobService, UploadConfig, UploadParams } from 'angular-azure-blob-service';
import { RateCard } from 'src/models/rate-card';

@Component({
  selector: 'app-comprehensive-policy-info',
  templateUrl: './comprehensive-policy-info.component.html',
  styleUrls: ['./comprehensive-policy-info.component.css']
})
export class ComprehensivePolicyInfoComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router,
    private requestService: ComprehensiveRequestsService,
    private companyService: CompanyService, private dialog: MatDialog,
    private location: Location, private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer, private storageService: StorageService,
    private lightBox: Lightbox, private blobService: BlobService) {
    this.storageService.getSASTokenForContainer(this.AzureConfig.containerName).subscribe(token => {
      this.SASToken = token.sasToken;

    });
    // Token for upload file.
    this.storageService.getUploadOnlyTokenForContainer(this.AzureConfig.containerName).subscribe(token => {
      this.SASTokenForUpload = token.sasToken;
    });
  }

  comprehensiveStatus = 1;
  startDate: Date;
  minStartDate: Date;
  minCivilCardExpiryDate: Date;
  purchaseDetail?: RateCard;
  companies: any;
  serializedStartDate: UntypedFormControl;
  expiryDate: UntypedFormControl;
  civilCardExpiryDate: UntypedFormControl;
  purposeOfLicenseList =
    ['خاصة', 'تاكسي', 'ونيت ياباني', 'ونيت أمريكي', 'خزان مياه', 'إسعاف', 'دراجة بخارية', '(رافعة (كرين', 'باص'];

  SASToken: string;
  SASTokenForUpload: string;
  AzureConfig: UploadParams = {
    sas: '',
    storageAccount: environment.storageAccount,
    containerName: 'comprehensive-policy-book'
  };
  uploadConfig: UploadConfig;

  // Policy Document
  policyDocFile: any;
  policyDocName: string;

  // Moroor Document
  moroorDocFile: any;
  moroorDocName: string;

  // Invoice Document
  invoiceDocFile: any;
  invoiceDocName: string;

  ngOnInit() {
    this.getPurchaseDetail();
    this.minStartDate = new Date();
    this.minCivilCardExpiryDate = new Date(this.minStartDate);
    this.minCivilCardExpiryDate.setDate(this.minCivilCardExpiryDate.getDate() + 1);
  }

  getCompanies(): void {
    this.companyService.getCompnayList('Saiyarti').subscribe((companies: any) => {
      this.companies = companies;
    });
  }

  getPurchaseDetail(): void {
    const id = +this.route.snapshot.paramMap.get('id');

    this.requestService.getQuoteRequestByVehicleId(id).subscribe((purchaseDetail: any) => {
      this.getCompanies();


      this.purchaseDetail = purchaseDetail;

      //! To be updated from final build.
      if (purchaseDetail.startDate) {
        this.serializedStartDate = new UntypedFormControl(new Date(purchaseDetail.startDate));
      } else {
        this.serializedStartDate = new UntypedFormControl(new Date());
      }

      this.civilCardExpiryDate = new UntypedFormControl(new Date(purchaseDetail.civilCardExpiry));
      this.expiryDate = new UntypedFormControl(new Date(purchaseDetail.expiryDate));
    }, err => {
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right',
      });
    });
  }

  goBack(): void {
    this.location.back();
  }

  onPolicyDocument(event) {
    const file = event.target.files[0];
    let fileExt = file.name.substr(file.name.lastIndexOf("."), file.name.length)
    let fileName = this.purchaseDetail.rateCardId + '_policy';

    this.uploadFile(file, fileName, (err) => {
      if (!err) {
        this.policyDocFile = file;
        this.policyDocName = fileName;
      }
    });
  }

  onMoroorDocument(event) {
    const file = event.target.files[0];
    let fileExt = file.name.substr(file.name.lastIndexOf("."), file.name.length)
    let fileName = this.purchaseDetail.rateCardId + '_moroor';

    this.uploadFile(file, fileName, (err) => {
      if (!err) {
        this.moroorDocFile = file;
        this.moroorDocName = fileName;
      }
    });
  }

  onInvoiceDocument(event) {
    const file = event.target.files[0];
    let fileExt = file.name.substr(file.name.lastIndexOf("."), file.name.length)
    let fileName = this.purchaseDetail.rateCardId + '_invoice';

    this.uploadFile(file, fileName, (err) => {
      if (!err) {
        this.invoiceDocFile = file;
        this.invoiceDocName = fileName;
      }
    });
  }

  viewPolicyDoc() {
    this.showOverlay(this.policyDocName, 'Policy Document');
  }

  viewMoroorDoc() {
    this.showOverlay(this.moroorDocName, 'Moroor Document');
  }

  viewInvoiceDoc() {
    this.showOverlay(this.moroorDocName, 'Invoice Document');
  }

  showOverlay(filename: string, caption) {
    if (filename.substr(filename.length - 3, 3).toLowerCase() === 'pdf') {
      this.showPdf(filename);
    } else {
      this.showImage(filename, caption);
    }
  }

  showPdf(filename: string) {
    const url = environment.cdnUrl + '/' + this.AzureConfig.containerName + '/' + filename + this.SASToken + '#toolbar=0';
    const pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.dialog.open(DocViewComponent, { data: { url: pdfUrl } });
  }

  showImage(filename: string, caption: string) {
    const src = environment.cdnUrl + '/' + this.AzureConfig.containerName + '/' + filename + this.SASToken;
    const thumb = src;
    const album = {
      src,
      caption,
      thumb
    };

    this.lightBox.open([album], 0);
  }

  uploadFile(yourfile: any, fileName: string, cb: (error: Error) => void) {
    this.AzureConfig.sas = this.SASTokenForUpload;
    if (yourfile !== null) {
      const baseUrl = this.blobService.generateBlobUrl(this.AzureConfig, fileName);
      this.uploadConfig = {
        baseUrl,
        sasToken: this.AzureConfig.sas,
        // blockSize: 1024 * 64, // OPTIONAL, default value is 1024 * 32
        file: yourfile,
        complete: () => {
          cb(null);
        },
        error: (err) => {
          cb(err);
          this.snackBar.open('There was an error uploading your document: ' + fileName, 'CANCEL', {
            horizontalPosition: 'right'
          });
        },
        progress: (percent) => {
          // this.percent = percent;
        }
      };
      // Upload to server
      this.blobService.upload(this.uploadConfig);
    }
  }

  updatePurchaseDetail(form) {
    if (!this.policyDocFile) {
      this.snackBar.open("Policy Document is not uploaded", "CANCEL", {
        horizontalPosition: 'right'
      });
      return;
    }

    if (!this.moroorDocFile) {
      this.snackBar.open("Moroor Document is not uploaded", "CANCEL", {
        horizontalPosition: 'right'
      });
      return;
    }

    if (!this.invoiceDocFile) {
      this.snackBar.open("Invoice Document is not uploaded", "CANCEL", {
        horizontalPosition: 'right'
      });
      return;
    }

    this.purchaseDetail.policyStartDate = this.serializedStartDate.value.toISOString();
    this.purchaseDetail.policyExpiryDate = this.expiryDate.value.toISOString();
    this.purchaseDetail.civilCard.expiry = this.civilCardExpiryDate.value.toISOString();

    this.requestService.setPolicyInfo(this.purchaseDetail.rateCardId, this.purchaseDetail).subscribe((response: any) => {
      this.router.navigate(['/comprehensive-consumer-request/upload-document/' + this.purchaseDetail.rateCardId]);
    }, err => {
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right'
      });
    });
  }
}
