import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from 'src/services/storage.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { BlobService, UploadConfig, UploadParams } from 'angular-azure-blob-service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { DocEditXyModelComponent } from 'src/app/model-dialog/doc-edit-xy-model/doc-edit-xy-model.component';
import { TplService } from 'src/services/tpl.service';
import { CompanyService } from 'src/services/company.service';
import { environment } from '../../../environments/environment';


export class IBroker {
  id: number;
  companyName: string;
  address: string;
  primaryContact: string;
  companyTradeLicense: string;
  isPrimary: boolean;
  phone: string;
  countryCode: number;
  createdAt: string;
  updatedAt: string;
  copies: number;
  tplWidth: number;
  tplHeight: number;
}




@Component({
  selector: 'app-broker-detail',
  templateUrl: './broker-detail.component.html',
  styleUrls: ['./broker-detail.component.css']
})

export class BrokerDetailComponent implements OnInit {
  isSubmited = false;
  cdnUrl = environment.cdnUrl + '/company';
  AzureConfig: UploadParams = {
    sas: '',
    storageAccount: environment.storageAccount,
    containerName: 'company'
  };
  uploadConfig: UploadConfig;
  formDisabled = false;

  public imagePath;

  logoImgURL: any;
  logoImageFile: any;

  tradeLicence: any;
  tradeLicenceFile: any;

  authorizedSignatory: any;
  authorizedSignatoryFile: any;

  civilIDofSignatory: any;
  civilIDofSignatoryFile: any;

  requestLetter: any;
  requestLetterFile: any;

  thirdPartyLiability: any;
  thirdPartyLiabilityFile: any;

  comprehensive: any;
  comprehensiveFile: any;

  public message: string;
  pageTitle = '';

  brokerDetail: IBroker = new IBroker();
  error: any;
  SASToken: string;
  SASTokenForUpload: string;
  addBroker: any;
  files: any = [{}, {}, {}, {}, {}, {}];
  brokerId;
  tplLables = [];

  constructor(private route: ActivatedRoute,
              private companyService: CompanyService,
              private storageService: StorageService,
              private diloag: MatDialog,
              private blobService: BlobService,
              private snackBar: MatSnackBar,
              private router: Router,
              private location: Location,
              private tplService: TplService) {
    this.storageService.getSASTokenForContainer('company').subscribe(token => {
      this.SASToken = token.sasToken;
      // this.AzureConfig.sas = this.SASToken;

    });
    // Token for upload file.
    this.storageService.getUploadOnlyTokenForContainer('company').subscribe(token => {
      this.SASTokenForUpload = token.sasToken;
    });
  }

  ngOnInit() {
    // this.pageTitleService.setTitle('Warba Insurance Brokers');
    for ( let i = 0; i < 6; i++) {
      this.files[i] = {
        isRequired: true
      };
    }

    const id = this.route.snapshot.paramMap.get('id');
    if (id === 'add') {
      this.addBroker = true;
      let result           = '';
      const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for ( let i = 0; i < 5; i++ ) {
         result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      this.brokerId = result;
    } else {
      this.loadBrokerDetail(+id);
      this.getTplLabels(id);
    }
    // console.log(id);

  }
  getBrokerImageUrl(broker): any {
    if (broker.id) {
      return this.cdnUrl + '/' + broker.id + '.jpg' + this.SASToken;
    } else {
      return;
    }
  }
  enableEditBroker() {
    if (this.formDisabled === true) {
      this.formDisabled = false;
    } else {
      this.formDisabled = true;
    }
  }
  loadBrokerDetail(id: number) {
    this.companyService.getCompanyById(id).subscribe(brokerDetail => {
      this.formDisabled = true;
      this.brokerDetail = brokerDetail;
      this.tradeLicence = brokerDetail.tradeLicense;
      this.brokerId = this.tradeLicence.split('_')[0];
      this.authorizedSignatory = brokerDetail.authorizedSignatory;
      this.civilIDofSignatory = brokerDetail.civilIDSignatory;
      this.requestLetter = brokerDetail.requestLetter;
      this.thirdPartyLiability = brokerDetail.tplTemplate;
      this.comprehensive = brokerDetail.comprehensive;
      for ( let i = 0; i < 6; i++) {
        this.files[i] = {
          isRequired: false
        };
      }
    }, err => {
      // this.error = err;
      // console.log(this.error);
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right'
      });
    });
  }

  markDefault(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.companyService.markCompanyDefault(+id,'Saiyarti').subscribe(broker => {
      this.snackBar.open('Broker has been marked default successfully.', 'CANCEL', {
        duration: 2000
      });
    });
  }

  createBroker(form) {
    this.isSubmited = true;
    // console.log(form.controls['brokerName'].status);
    const brokerData = {
      companyName: form.value.companyName,
      address: form.value.address,
      isBroker: true,
      primaryContact: form.value.primaryContact,
      companyTradeLicense: form.value.brokerTradeLicense,
      phone: form.value.phone,
      copies: form.value.copies,
      countryCode: form.value.countryCode,
      comprehensive: this.comprehensive,
      tradeLicense: this.tradeLicence,
      authorizedSignatory: this.authorizedSignatory,
      civilIDSignatory: this.civilIDofSignatory,
      requestLetter: this.requestLetter,
      tPLTemplate: this.thirdPartyLiability,
      tplWidth: form.value.tplWidth,
      tplHeight: form.value.tplHeight,
    };
    this.uploadDocuments();
    this.companyService.createCompany(brokerData).subscribe(compnayRes => {
      // console.log(compnayRes);
      const brokerId = compnayRes.id;
      if (this.logoImageFile !== undefined) {
        this.uploadFile(this.logoImageFile, brokerId + '.jpg', (x) => {
          this.snackBar.open('Broker created successfully.', 'CANCEL', {
            duration: 2000,
            horizontalPosition: 'right'
          });
          this.router.navigate(['/brokers']);
        });
      }
    }, err => {
      // console.log(err.error.Text);
      // this.error = err;
      // this.diloag.open(AlertDialogComponent, { data: { title: 'Error', message: 'Error to create broker.' } });
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right',
      });
    });

  }

  updateBroker(form): void {
    this.isSubmited = true;
    const diloagRef = this.diloag.open(ConfirmDialogComponent,
        { data: { title: 'Confirm', message: 'Are you sure you want to update broker?' } }
      );
    diloagRef.afterClosed().subscribe(result => {

      if (result === 'true') {
        const id = +this.route.snapshot.paramMap.get('id');
        const brokerData = {
          companyName: form.value.companyName,
          address: form.value.address,
          copies: form.value.copies,
          primaryContact: form.value.primaryContact,
          companyTradeLicense: form.value.brokerTradeLicense,
          phone: form.value.phone,
          countryCode: form.value.countryCode,
          comprehensive: this.comprehensive,
          tradeLicense: this.tradeLicence,
          authorizedSignatory: this.authorizedSignatory,
          civilIDSignatory: this.civilIDofSignatory,
          requestLetter: this.requestLetter,
          tPLTemplate: this.thirdPartyLiability,
          tplWidth: form.value.tplWidth,
          tplHeight: form.value.tplHeight
        };
        this.uploadDocuments();
        this.companyService.updateCompany(id, brokerData).subscribe(broker => {
          // this.diloag.open(AlertDialogComponent, { data: { title: 'Success', message: 'Broker updated successfully.' } });
          this.snackBar.open('Broker updated successfully.', 'CANCEL', {
            duration: 2000,
            horizontalPosition: 'right'
          });
          this.loadBrokerDetail(id);
        }, err => {
          // this.diloag.open(AlertDialogComponent, { data: { title: 'Error', message: 'Error to update copany' } });
          // this.error = err;
          this.snackBar.open(err, 'CANCEL', {
            horizontalPosition: 'right'
          });
        });
      }
    });

  }

  onLogoFileSelected(files) {
    if (files.length === 0) {
      return;
    }
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (event) => {
      this.logoImgURL = reader.result;
    };
    this.logoImageFile = files[0];
    // this.uploadFile (files[0],'' + this.brokerDetail.id + '.jpg');
  }

  onTradeLicence(event) {
    const file = event.target.files[0];
    this.tradeLicence = this.brokerId + '_Trade_Licence_' + file.name;
    this.tradeLicenceFile = file;
    // this.uploadFile(file,this.brokerDetail.id + file.name.split('.')[1]);
    // this.uploadFile(file,file.name);
  }
  onAuthorizedSignatory(event) {
    const file = event.target.files[0];
    this.authorizedSignatory = this.brokerId + '_Authorized_Signatory_' + file.name;
    this.authorizedSignatoryFile = file;
  }
  onCivilIDofSignatory(event) {
    const file = event.target.files[0];
    this.civilIDofSignatory = this.brokerId  + '_CivilId_Signatory_' + file.name;
    this.civilIDofSignatoryFile = file;
  }
  onRequestLetter(event) {
    const file = event.target.files[0];
    this.requestLetter = this.brokerId  + '_Request_Letter_' + file.name;
    this.requestLetterFile = file;
  }
  onThirdPartyLiability(event) {
    const file = event.target.files[0];
    this.thirdPartyLiability = this.brokerId  + '_TPL_Template_' + file.name;
    this.thirdPartyLiabilityFile = file;
  }
  onComprehensive(event) {
    const file = event.target.files[0];
    this.comprehensive = this.brokerId  + '_Comprehensive_' + file.name;
    this.comprehensiveFile = file;
  }



  uploadLogo(brokerId: number) {
    // Broker Logo
    if (this.logoImageFile !== undefined) {
      this.uploadFile(this.logoImageFile, brokerId + '.jpg', (x) => { });
    }
  }
  uploadDocuments() {

    // Trade Licence
    if (this.tradeLicenceFile !== undefined) {
      this.uploadFile(this.tradeLicenceFile, this.tradeLicence, (x) => { });
    }

    // Authorized Signatory
    if (this.authorizedSignatoryFile !== undefined) {
      this.uploadFile(this.authorizedSignatoryFile, this.authorizedSignatory, (x) => { });
    }

    // Civil ID of Signatory
    if (this.civilIDofSignatoryFile !== undefined) {
      this.uploadFile(this.civilIDofSignatoryFile, this.civilIDofSignatory, (x) => { });
    }

    // Request Letter
    if (this.requestLetterFile !== undefined) {
      this.uploadFile(this.requestLetterFile, this.requestLetter, (x) => { });
    }

    // Third Party Liability
    if (this.thirdPartyLiabilityFile !== undefined) {
      this.uploadFile(this.thirdPartyLiabilityFile, this.thirdPartyLiability, (x) => { });
    }

    // Comprehensive
    if (this.comprehensiveFile !== undefined) {
      this.uploadFile(this.comprehensiveFile, this.comprehensive, (x) => { });
    }
  }

  goBack(): void {
    this.location.back();
  }

  uploadFile(yourfile: any, fileName: string, cb: (error: Error) => void) {
    this.AzureConfig.sas = this.SASTokenForUpload;
    if (yourfile !== null) {
      const baseUrl = this.blobService.generateBlobUrl(this.AzureConfig, fileName);
      this.uploadConfig = {
        baseUrl,
        sasToken: this.AzureConfig.sas,
        // blockSize: 1024 * 64, // OPTIONAL, default value is 1024 * 32
        file: yourfile,
        complete: () => {
          cb(null);
        },
        error: (err) => {
          this.snackBar.open('There was an error uploading your document: ' + fileName, 'CANCEL', {
            horizontalPosition: 'right'
          });
        },
        progress: (percent) => {
          // this.percent = percent;
        }
      };
      // Upload to server
      this.blobService.upload(this.uploadConfig);
    }
  }


  editXYDoc() {
    const id = +this.route.snapshot.paramMap.get('id');
    const dialogConfig = new MatDialogConfig();
    const title = this.brokerDetail.companyName + ' > ' + ' Print Configuration';
    dialogConfig.data = { title, message: 'Error to create broker.', labels: this.tplLables, companyId: id };
    dialogConfig.width = '60%';
    dialogConfig.position = {
      // 'top':'2%'
    };
    const diloagRef = this.diloag.open(DocEditXyModelComponent, dialogConfig);
  }

  getTplLabels(id: string) {

    this.tplService.getTPLLabels(id).subscribe(res => {
      this.tplLables = res;
    }, err => {
      console.log(err);
    });
  }

}
