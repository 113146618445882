import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/services/account.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  pageTitle = 'Change Password';
  confirmNewPassword: string;
  newPassword: string;
  currentPassword: string;
  
  constructor(private accountService: AccountService, private snackBar: MatSnackBar, private router: Router) { }

  ngOnInit() {
  }

  changePassword(form) {
    console.log(form);
    const data = {
      currentPassword: form.value.currentPassword,
      newPassword: form.value.conformPasswordGroup.newPassword
    };
    console.log(data);
    this.accountService.changePassword(data).subscribe(res => {
      this.snackBar.open('Password changed successfully.', 'CANCEL', {
        duration: 2000,
        horizontalPosition: 'right'
      });
      this.router.navigate(['/dashboard']);

    }, err => {
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right'
      });
    });
  }

}
