import { SelectionModel } from '@angular/cdk/collections';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerService } from '../../../services/customer.service';
import { TableComponent } from 'src/app/table.component';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent extends TableComponent<Request> {

  pageTitle = 'All Customers';
  selection = new SelectionModel<any>(true, []);

  error: any;

  constructor(private router: Router, protected customerServices: CustomerService) {
    super(customerServices);
  }

  displayedColumns: string[] = ['id', 'customerName', 'username', 'phone', 'emailId', 'vehicles', 'saiyartPolicies','safrattiPolicies'];

  /** open customer detail */
  openCustomer(row): void {
    this.router.navigate(['/customers/' + row.id]);
  }
}
