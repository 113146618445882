import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { TableComponent } from 'src/app/table.component';
import { ReferralReportService } from 'src/services/reports/referral.service';

@Component({
  selector: 'app-referral-report',
  templateUrl: './referral-report.component.html',
  styleUrls: ['./referral-report.component.css']
})
export class ReferralReportComponent extends TableComponent<Request> implements OnInit {

  pageTitle = 'Referral Report';
  startDate: Date;
  endDate: moment.Moment;
  get EndDate() {
    return this.endDate;
  }
  set EndDate(value) {
    if ( value ) {
      this.endDate = value.endOf('day');
    }
  }

  displayedColumns: string[] = [
    'userName',
    'salesB',
    'salesC',
    'salesD',
    'totalSales',
    'pointsB',
    'pointsC',
    'pointsD',
    'totalPoints',
    'countB',
    'countC',
    'countD',
    'totalCount',
  ];
  constructor(private reportService: ReferralReportService, private router: Router) {
    super(reportService);
  }

  getCount() {
    const data = {
      startDate: this.startDate,
      endDate: this.endDate,
      skip: this.paginator.pageIndex * this.paginator.pageSize,
      take: this.paginator.pageSize
    };
    return this.reportService.getCount(data);
  }


  getData(): any {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      skip: this.paginator.pageIndex * this.paginator.pageSize,
      take: this.paginator.pageSize,
      orderBy: this.sort.active,
      orderByDescending: this.sort.direction === 'desc'
    };
  }

  getDataByFilter() {
    const data = this.getData();
    this.getCount().subscribe(res => {
      this.count = res.count;
      this.loadRequests(data);
    });
  }

  downloadXLS(isXLS: boolean) {
    const data = {
      startDate: this.startDate,
      endDate: this.endDate,
      skip: 0,
      take: 2147483647,
      asXLS: isXLS
    };

    this.reportService.downloadReport(data);
  }

  // getReferralReport(data: any) {
  //   const lMe = this;
  //   this.reportService.getReferralReport(data).subscribe(res => {
  //     lMe.dataSource = new MatTableDataSource(res);
  //     setTimeout(x => {
  //       lMe.dataSource.paginator = lMe.paginator;
  //     }, 100);
  //   }, err => {
  //     if (err) {
  //       this.dialogService.errorSanckBar(err);
  //     } else {
  //       this.dialogService.errorSanckBar('Something went wrong. Please contact admin.');
  //     }
  //   });
  // }

  openReferral(data: any) {
    this.router.navigate(['/reports/referral-report/' + data.ownerId + '/' + data.firstName + ' ' + data.lastName]);
  }

}
