import { Injectable } from "@angular/core";
import { GTSPolicyDocumentService } from "../gts-policy-document.service";
import { SafrattiPolicyDocumentService } from "../safratti-policy-document.service";
import { GTSService } from "../gts.service";
import { environment } from "src/environments/environment";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { StorageService } from "../storage.service";

export interface PolicyDetails {
  policyId: string;
  policyNumber: string;
  startDate: Date;
  endDate: Date;
  name: string;
  token: string;

  policyBkgImg: any;

  reference: string;
  agent: string;
  date: string;
  fullName: string;
  passportNo: string;
  countryofResidence: string;
  gender: string;
  dateofBirth: string;
  phone: string;
  email: string;
  plan: string;
  coverageZone: string;
  from: string;
  to: string;
  sportsActivities: any;
  destination: string;
  periodofCover: string;

  referencePosition: { x: number; y: number };
  agentPosition: { x: number; y: number };
  datePosition: { x: number; y: number };
  fullNamePosition: { x: number; y: number };
  passportNoPosition: { x: number; y: number };
  countryofResidencePosition: { x: number; y: number };
  genderPosition: { x: number; y: number };
  dateofBirthPosition: { x: number; y: number };
  phonePosition: { x: number; y: number };
  emailPosition: { x: number; y: number };
  planPosition: { x: number; y: number };
  coverageZonePosition: { x: number; y: number };
  fromPosition: { x: number; y: number };
  toPosition: { x: number; y: number };
  sportsActivitiesPosition: { x: number; y: number };
  destinationPosition: { x: number; y: number };
  periodofCoverPosition: { x: number; y: number };
}
// export interface PolicyCopy{
//   reference:string
//   date: string
//   fullName:string
//   passportNo:string
//   countryofResidence:string
//   gender:string
//   dateofBirth:string
//   phone:string
//   email:string
//   plan:string
//   coverageZone:string
//   from:string
//   to:string
//   sportsActivities:string
//   destination:string
//   periodofCover:string
// }

@Injectable({
  providedIn: "root",
})
export class PolicyDocumentServiceFactory {
  constructor(
    private _gtsService: GTSService,
    private _storageService: StorageService,
    private sanitizer: DomSanitizer
  ) {}

  getPolicyDocumentService(policyDetails?: PolicyDetails) {
    if (environment.policyIssuer === "Safratti") {
      return new SafrattiPolicyDocumentService(
        policyDetails,
        this._storageService
      );
    } else {
      return new GTSPolicyDocumentService(
        policyDetails.policyId,
        policyDetails.token,
        this._gtsService
      );
    }
  }

  getPolicyDocumentServiceWithPolicyId(policyId: string,policyIssuer:string) {
    if (policyIssuer === "Safratti") {
      const service = new SafrattiPolicyDocumentService(
        undefined,
        this._storageService
      );
      service._policyId = policyId;
      return service
    } else {
      return new GTSPolicyDocumentService(policyId, policyId, this._gtsService);
    }
  }

}
