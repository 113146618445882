import { Injectable } from "@angular/core";
import { ReportService } from "../report.service";
import { ICountService } from "../icount.service";
import { HttpClient } from "@angular/common/http";
import { Helpers } from "src/helpers/helpers";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { catchError } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class SafrattiIssuanceReportService extends ReportService implements ICountService {
    constructor(protected http: HttpClient, helper: Helpers, protected snackBar: MatSnackBar) {
        super(http, helper, snackBar);
    }
    getCount(body: any) {
        return this.http.post<any>(this.pathAPI + 'Reports/GetSafrattiReportCount', body, super.header())
            .pipe(catchError(super.handleError));
    }

    getData(body: any) {
        return this.http.post<any>(this.pathAPI + 'Reports/GetSafrattiReport', body, super.header())
            .pipe(catchError(super.handleError));
    }


    downloadReport(body: any) {
        return super.downloadSafrattiReport('DownloadSafrattiReport', body);
    }
}