import { Component, OnInit } from '@angular/core';
import { IssuanceSummaryReportService } from 'src/services/reports/issuance-summary.service';
import { TableComponent } from 'src/app/table.component';
import * as moment from 'moment';

export class IssuanceSummary {
  orderDate: Date;
  orders: number;
  customers: number;
  basePremium: number;
  fees: number;
  totalPremium: number;
}

@Component({
  selector: 'app-issuance-summary-report',
  templateUrl: './issuance-summary-report.component.html',
  styleUrls: ['./issuance-summary-report.component.css']
})
export class IssuanceSummaryReportComponent extends TableComponent<Request> implements OnInit {

  pageTitle = 'Issuance Summary Report';
  startDate: Date;
  endDate: moment.Moment;
  get EndDate() {
    return this.endDate;
  }
  set EndDate(value) {
    if ( value ) {
      this.endDate = value.endOf('day');
    }
  }

  issuanceList: IssuanceSummary[] = [];

  displayedColumns: string[] = ['orderDate', 'orders', 'customers', 'basePremium', 'fees', 'totalPremium', 'netAmount'];

  constructor(private reportService: IssuanceSummaryReportService) {
    super(reportService);
  }


  getCount(): any {
    const data = {
      startDate: this.startDate,
      endDate: this.endDate,
      skip: this.paginator.pageIndex * this.paginator.pageSize,
      take: this.paginator.pageSize
    };
    return this.reportService.getCount(data);
  }

  getData(): any {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      skip: this.paginator.pageIndex * this.paginator.pageSize,
      take: this.paginator.pageSize,
      orderBy: this.sort.active,
      orderByDescending: this.sort.direction === 'desc'
    };
  }

  getDataByFilter() {
    const data = this.getData();
    this.getCount().subscribe(res => {
      this.count = res.count;
      this.loadRequests(data);
    });
  }

  downloadXLS(isXLS: boolean) {
    const data = {
      startDate: this.startDate,
      endDate: this.endDate,
      skip: 0,
      take: 2147483647,
      asXLS: isXLS
    };

    this.reportService.downloadReport(data);
  }

  // getIssuanceSummaryReport(data: any) {
  //   const lMe = this;
  //   this.reportService.getIssuanceSummaryReport(data).subscribe(res => {
  //     this.issuanceList = [];
  //     res.forEach(obj => {
  //       const order = new IssuanceSummary();
  //       order.orderDate = obj.issueDate;
  //       order.orders = obj.policies;
  //       order.customers = obj.customers;
  //       order.basePremium = obj.amountPaid;
  //       order.fees = obj.fees;
  //       order.totalPremium = order.basePremium * order.fees;

  //       lMe.issuanceList.push(order);
  //     });

  //     lMe.dataSource = new MatTableDataSource(this.issuanceList);
  //     setTimeout(() => {
  //       lMe.dataSource.paginator = lMe.paginator;
  //     }, 100);

  //   }, err => {
  //     if (err) {
  //       this.dialogService.errorSanckBar(err);
  //     } else {
  //       this.dialogService.errorSanckBar('Something went wrong. Please contact admin.');
  //     }
  //   });
  // }
}
