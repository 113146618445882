import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import { TokenService } from '../../services/token.service';

import { Helpers } from '../../helpers/helpers';

import jwt_decode from 'jwt-decode';


@Component({

  selector: 'app-login',

  templateUrl: './login.component.html',

  styleUrls: ['./login.component.css']

})

export class LoginComponent implements OnInit {
  hide = true;
  constructor(private helpers: Helpers, private router: Router, private tokenService: TokenService) { }

  email: string;
  password: string;
  error: any;
  ngOnInit() {

    if (this.helpers.isAuthenticated()) {
      const obj = this.getDecodedAccessToken(this.helpers.getToken());
      // if (obj.type.indexOf('Administrator') === 0) {
      //   this.router.navigate(['/dashboard']);
      // } else {
      //   this.router.navigate(['/consumer-request']);
      // }
    } else {
      this.helpers.logout();
    }

  }

  login(): void {
    const authValues = { email: this.email, password: this.password };

    this.tokenService.auth(authValues).subscribe(token => {
      this.helpers.setToken(token);
      this.helpers.setEmail(this.email);
      const obj = this.getDecodedAccessToken(this.helpers.getToken());
      this.helpers.setId(obj["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"]);
      if (obj.type.indexOf('Administrator') === 0) {
        this.router.navigate(['/dashboard']);
      } else if (obj.type.indexOf('OfficeAdministrator') === 0) {
        this.router.navigate(['/consumer-request']);
      } else if (obj.type.indexOf('B2BPolicyAdministrator') === 0) {
        this.helpers.setName('Saiyarti Admin');
        this.router.navigate(['/policies']);
      } else if (obj.type.indexOf('StoreCoordinator') === 0 || obj.type.indexOf('StoreSalesPerson') === 0) {
        this.router.navigate(['/store']);
      }
    }, err => {
      this.error = err;
    });

  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

}
