import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { SafrattiFee } from "src/models/safratti-fee";
import { SafrattiFeeService } from "src/services/safratti-fee.service";

@Component({
  selector: "app-safratii-fee-structure",
  templateUrl: "./safratii-fee-structure.component.html",
  styleUrls: ["./safratii-fee-structure.component.css"],
})
export class SafratiiFeeStructureComponent implements OnInit {
  displayedColumns: string[] = [
    "name",
    "travelDays",
    "basicPremium",
    "sportsActivity",
    "policyFee",
    "issueFee",
    "otherFee",
  ];
  dataSource: MatTableDataSource<SafrattiFee>;

  constructor(
    private safrattiFeeService: SafrattiFeeService,
    private router: Router
  ) {}

  ngOnInit() {
    this.safrattiFeeService.getSafrattiFees().subscribe((fees) => {
      this.dataSource = new MatTableDataSource(fees);
    });
  }
}
