import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Helpers } from 'src/helpers/helpers';
import jwt_decode from 'jwt-decode';


@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.css']
})
export class PageHeaderComponent implements OnInit {
  @Input() pageTitle: string;
  @Input() showH2: boolean = false;
  obj: any;
  @Input() type: string = 'TPL'
  @Input() project: string = 'Saiyarti'
  @Output() typeOfData=new EventEmitter<string>();
  @Output() typeOfProject = new EventEmitter<string>();
  @Input() dropdown:boolean=false;
  @Input() projectSelect: boolean = false
  constructor(private router: Router, private helpers: Helpers) { }

  ngOnInit() {
    this.obj = this.getDecodedAccessToken(this.helpers.getToken());
  }

  onDataSelected(t:any){
    this.typeOfData.emit(t);
    window.localStorage['type']=t
  }

  onProjectSelected(p:any){
    this.typeOfProject.emit(p);
    window.localStorage['projectType']=p
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  logout() {
    this.helpers.logout();
    if (this.obj.type === 'CompanyAdministrator') {
      this.router.navigate(['/company-login']);
    } else {
      this.router.navigate(['/login']);
    }
  }

}
