/**
 * @auther : Hansraj Sharma
 * @Date   : 22, May 2019
 */

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Helpers } from '../helpers/helpers';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CompanyService extends BaseService {
  private pathAPI = environment.apiUrl;

  constructor( private http: HttpClient,  helper: Helpers) {
    super(helper);
  }

  /**
   * To get company list
   */
  getCompnayList(company:string): any {
    return this.http.get<any>(this.pathAPI + 'Company?IsBroker=false&CompanyType='+ company, super.header()).pipe(catchError(super.handleError));
  }

  /**
   * To get company list
   */
  getBrokerList(): any {
    return this.http.get<any>(this.pathAPI + 'Company?IsBroker=true', super.header()).pipe(catchError(super.handleError));
  }

  /**
   * To mark compnay as default.
   * @param id
   */
  markCompanyDefault(id: number,company:string): any {
    return this.http.post<any>(this.pathAPI + 'Company/MarkDefault/' + id +'?CompanyType='+ company, {}, super.header()).pipe(catchError(super.handleError));
  }

  /**
   * To get company using company id...
   * @param id
   */
  getCompanyById(id: number): any {
    return this.http.get<any>(this.pathAPI + 'Company/' + id , super.header()).pipe(catchError(super.handleError));
    // return this.http.get<any>(this.pathAPI + 'Company/' + id,super.header()).pipe(catchError(super.handleError));
  }


  /**
   * To create company
   * @param data
   */
  createCompany(data: any): Observable <any> {
    return this.http.post<any>(this.pathAPI + 'Company', data, super.header()).pipe(catchError(super.handleError));
  }


  /**
   * To Update company...
   * @param data
   */
  updateCompany(id: number, data: any): any {
    return this.http.put<any>(this.pathAPI + 'Company/' + id, data, super.header()).pipe(catchError(super.handleError));
  }


  isImageExist(url: string): Observable<any> {
    return this.http.get<any>(url, {}).pipe(catchError(super.handleError));
  }

  isFileExist(url: string): Observable<any> {
    return this.http.get<any>(url, {}).pipe(catchError(super.handleError));
  }

  getGroups(): any {
    return this.http.get<any>(this.pathAPI + 'Company/GetGroups', this.header()).pipe(
      map(res => {
        super.receviedResponse();
        return res;
      }), catchError(err => super.handleError(err)));
  }

  id=new BehaviorSubject<number>(114)

  getId= this.id.asObservable()
  sendId(id:number){
    this.id.next(id)
  }
}
