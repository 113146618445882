import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TableComponent } from 'src/app/table.component';
import { ReferralUserReportService } from 'src/services/reports/referral-user.service';

@Component({
  selector: 'app-referral-user-report',
  templateUrl: './referral-user-report.component.html',
  styleUrls: ['./referral-user-report.component.css']
})
export class ReferralUserReportComponent extends TableComponent<Request> implements OnInit {

  pageTitle = 'Referral Report';
  userId: string;
  startDate: Date;
  endDate: Date;

  displayedColumns: string[] = [
    'date',
    'salesB',
    'salesC',
    'salesD',
    'totalSales',
    'pointsB',
    'pointsC',
    'pointsD',
    'totalPoints',
    'countB',
    'countC',
    'countD',
    'totalCount',
  ];

  constructor(private reportService: ReferralUserReportService,
    private route: ActivatedRoute
  ) {
    super(reportService);
  }

  ngOnInit() {
    this.userId = this.route.snapshot.paramMap.get('id');
    this.pageTitle = this.route.snapshot.paramMap.get('name');
    super.ngOnInit();
  }


  getCount() {
    const data = {
      startDate: this.startDate,
      endDate: this.endDate,
      userId: this.userId,
      skip: this.paginator.pageIndex * this.paginator.pageSize,
      take: this.paginator.pageSize
    };
    return this.reportService.getCount(data);
  }


  getData(): any {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      skip: this.paginator.pageIndex * this.paginator.pageSize,
      take: this.paginator.pageSize,
      userId: this.userId,
      orderBy: this.sort.active,
      orderByDescending: this.sort.direction === 'desc'
    };
  }

  getDataByFilter() {
    const data = this.getData();
    this.getCount().subscribe(res => {
      this.count = res.count;
      this.loadRequests(data);
    });
  }

  downloadXLS(isXLS: boolean) {
    const data = {
      startDate: this.startDate,
      userId: this.userId,
      endDate: this.endDate,
      skip: 0,
      take: 2147483647,
      asXLS: isXLS
    };

    this.reportService.downloadReport(data);
  }

  // getReferralReportForUser(data: any) {
  //   const lMe = this;
  //   this.reportService.getReferralReportForUser(data).subscribe(res => {
  //     lMe.dataSource = new MatTableDataSource(res);
  //     setTimeout(x => {
  //       lMe.dataSource.paginator = lMe.paginator;
  //     }, 100);
  //   }, err => {
  //     if (err) {
  //       this.dialogService.errorSanckBar(err);
  //     } else {
  //       this.dialogService.errorSanckBar('Something went wrong. Please contact admin.');
  //     }
  //   });
  // }
}
