import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SelectionModel } from '@angular/cdk/collections';
import { AgentService } from 'src/services/agent.service';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';


@Component({
  selector: 'app-agent-list',
  templateUrl: './agent-list.component.html',
  styleUrls: ['./agent-list.component.css']
})
export class AgentListComponent implements OnInit {
  pageTitle = 'Admins';
  agentList = [];
  displayedColumns: string[] = ['agentName', 'role', 'phone', 'email', 'dateCreated', 'region', 'optionMenu'];

  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  constructor(private router: Router, private agentServices: AgentService, private snackBar: MatSnackBar, private diloag: MatDialog) {

    if (this.router.url === '/agents') {
      this.pageTitle = 'Agent Users';
    }
    console.log(this.router.url);
    // this.getAgentList();

  }

  ngOnInit() {
    this.getAgentList();
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }



  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;

    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id}`;
  }

  getAgentList() {
    this.agentServices.getAgentList().subscribe(res => {
      console.log('response');
      console.log(res);
      this.agentList = res;
      // Assign the data to the data source for the table to render
      this.dataSource = new MatTableDataSource(this.agentList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, err => {
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right'
      });
    });
  }

  viewAgentDetail(agent) {
    this.router.navigate(['/setup/agents/' + agent.id]);
  }
  resetPassword(agent) {
    this.agentServices.resetPasswordAgent(agent.id).subscribe(res => {
      // Reset Password link sent successfully.
      this.snackBar.open('Reset Password link sent successfully.', 'CANCEL', {
        horizontalPosition: 'right',
        duration: 2000
      });
    }, err => {
      const snackBarRef = this.snackBar.open(err, 'Retry', {
        horizontalPosition: 'right'
      });
      // snackBarRef.afterDismissed().subscribe(() => {
      //   //console.log('The snack-bar was dismissed');
      //   this.resetPassword(agent);
      // });
    });
  }
  deleteAgent(agent) {
    const dialogRef = this.diloag.open(ConfirmDialogComponent, { data: { title: 'Confirm', message: 'Are you sure want delete agent?' } });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result === 'true') {
        // TODO : logic for ok
        this.agentServices.deleteAgent(agent.id).subscribe(res => {
          this.getAgentList();
          this.snackBar.open('Agent deleted successfully.', 'CANCEL', {
            horizontalPosition: 'right',
            duration: 2000
          });
        }, err => {
          this.snackBar.open(err, 'CANCEL', {
            horizontalPosition: 'right'
          });
        });
      } else {
        // TODO : logi for cancel
      }
    });
  }

}
